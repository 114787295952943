import type { FC } from 'react'
import React from 'react'
import { useColor } from '_BRIGHT_/components'
import { AccordionPanel } from '@chakra-ui/react'
import type { Space } from '_BRIGHT_/components/Layout/Common'

type AccordionPanelProps = {
  paddingBottom?: Space
  paddingTop?: Space
}

const Panel: FC<AccordionPanelProps> = ({
  paddingTop = 'xs',
  paddingBottom = 'lg',
  children,
}) => {
  const color = useColor('contentSecondary')

  return (
    <AccordionPanel
      color={color}
      px="0"
      paddingBottom={paddingBottom}
      paddingTop={paddingTop}
    >
      {children}
    </AccordionPanel>
  )
}

export { Panel }
