import { createSelector } from '@reduxjs/toolkit'
import { useAppSelector } from './hooks'
import { RootState } from './store'

export const authSelector = (rootState: RootState) => rootState.auth

export const userDataSelector = createSelector(authSelector, ({ data }) => data)
export const authStatusSelector = createSelector(
  authSelector,
  ({ status }) => status,
)

export const scopeSelector = createSelector(
  userDataSelector,
  ({ scope }) => scope,
)

export const companiesSelector = createSelector(
  userDataSelector,
  ({ companies }) => companies,
)

export const allAdviserCompaniesSelector = createSelector(
  userDataSelector,
  ({ companies }) => companies.map(({ id }) => String(id)),
)

export const authHelperSelector = createSelector(
  [scopeSelector, authStatusSelector],
  (scope, status) => ({
    scope,
    status,
  }),
)

export const organisationHelperSelector = createSelector(
  [scopeSelector, userDataSelector],
  (scope, { userId, organisationId, organisationSlug, organisationName }) => ({
    scope,
    userId,
    organisationId,
    organisationSlug,
    organisationName,
  }),
)

export const useAuthStatus = () => useAppSelector(authStatusSelector)
export const useAppScope = () => useAppSelector(scopeSelector)
export const useAuthData = () => useAppSelector(userDataSelector)
export const useCompanies = () => useAppSelector(companiesSelector)
export const useAllAdviserCompanies = () =>
  useAppSelector(allAdviserCompaniesSelector)
export const useAuthHelper = () => useAppSelector(authHelperSelector)
export const useOrganisationHelper = () =>
  useAppSelector(organisationHelperSelector)
