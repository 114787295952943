import type { CSSObject, CheckboxProps } from '@chakra-ui/react'
import { colors } from './colors'

// eslint-disable-next-line import/no-default-export
export default {
  /*
  Because we don't follow Chakra's convention of numerically-scaled colours in our theme,
  passing one of our colour schemes won't work out of the box in the same way one of the
  defaults ie. gray, red, green, etc. would.  So, we need to add our own component variant.
  */

  /*
  Available styled properties reference can be found in the source code for a given component:
  https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/table.ts
  */
  Table: {
    baseStyle: {
      table: {
        '& >:last-child': {
          '& >:last-child': {
            th: {
              borderBottomWidth: 0,
            },
            td: {
              borderBottomWidth: 0,
            },
          },
        },
      },
    },
    variants: {
      striped: (): CSSObject => {
        const backgroundColor = colors.baseTwo.baseTwo.contrast
        const borderColor = colors.baseTwo.baseTwo.borderSecondary

        return {
          tbody: {
            tr: {
              _odd: {
                th: {
                  borderColor,
                },
                td: {
                  background: 'none',
                  borderColor,
                },
              },
              _even: {
                td: {
                  backgroundColor,
                },
              },
            },
          },
        }
      },
    },
  },
  /*
  Available styled properties reference can be found in the source code for a given component:
  https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/checkbox.ts
  */
  Checkbox: {
    variants: {
      primary: (props: Partial<CheckboxProps>): CSSObject => ({
        control: {
          // Default styling from Chakra source:
          // const { colorScheme: c } = props

          // _checked: {
          //    bg: mode(`${c}.500`, `${c}.200`)(props),
          //    borderColor: mode(`${c}.500`, `${c}.200`)(props),
          //  }

          //  Use our own colour passed via the colorScheme prop from our useColor instead:
          _checked: {
            bg: props.colorScheme,
            border: 'none',
            ...props._checked,
          },
          // The checkbox has no error-coloured border when invalid, so if isChecked and invalid,
          // use the colorScheme fill, otherwise keep the default borderColor
          _invalid: {
            borderColor: props.isChecked ? props.colorScheme : props.borderColor,
          },
          _focus: {
            ...props._focus,
          },
        },
      }),
    },
  },
}
