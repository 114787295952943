import { useAppDispatch } from 'redux/hooks'
import { useEffect } from 'react'
import { login } from 'redux/slice/authSlice'
import { useAuthHelper } from 'redux/selectors'

export const useStartApp = () => {
  const dispatch = useAppDispatch()
  const { scope, status } = useAuthHelper()

  useEffect(() => {
    dispatch(login())
  }, [dispatch])

  return {
    scope,
    status,
  }
}
