import type { FC } from 'react'
import { Copy, Block } from '_BRIGHT_/components'
import type { BlockPaletteName } from '_BRIGHT_/components/PaletteProvider'

export type FooterProps = {
  content: string
  palette?: BlockPaletteName
}

const Footer: FC<FooterProps> = ({
  content,
  palette = 'baseOne',
  children,
}) => {
  return (
    <Block palette={palette} paddingTop="xl" paddingBottom="7xl">
      <footer>
        <Copy html={content} level="2xs" variant="tertiary" />
        {children}
      </footer>
    </Block>
  )
}

export { Footer }
