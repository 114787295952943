import { Block, Button } from '_BRIGHT_/components'
import { Image, Link } from '@chakra-ui/react'
import type { OnClick } from '_BRIGHT_/utils'
import React from 'react'
import type { FC, AriaAttributes } from 'react'
import type { NavigationPalette } from '_BRIGHT_/components/Navigation'
import { Column, Columns } from '_BRIGHT_/components/Layout'

export type NavigationTopBarProps = {
  imageUrl: string
  imageAltText: string
  imageLink: string
  palette: NavigationPalette
  onOpen: OnClick
  menuButtonLabel: string
  menuButtonAriaLabel: string
  ariaExpanded: AriaAttributes['aria-expanded']
  /**
   * MODIFIED:
   * content
   * import Link from chakra
   */
  content?: JSX.Element
}

const NavigationTopBar: FC<NavigationTopBarProps> = ({
  imageUrl,
  palette,
  onOpen,
  menuButtonLabel,
  ariaExpanded,
  menuButtonAriaLabel,
  imageAltText,
  imageLink,
  content,
}) => {
  return (
    <Block palette={palette} paddingY="xs">
      <Columns alignY="center">
        <Column width="fluid" alignY="center">
          <Link href={imageLink}>
            <Image src={imageUrl} alt={imageAltText} maxHeight="7" />
          </Link>
        </Column>
        <Column width="content">
          {content || (
            <Button
              rightIconName="FunctionalBurger"
              size="md"
              variant="ghostButton"
              ariaExpanded={ariaExpanded}
              ariaLabel={menuButtonAriaLabel}
              isFullWidthForMobile={false}
              onClick={onOpen}
              hasPaddingX={false}
            >
              {menuButtonLabel}
            </Button>
          )}
        </Column>
      </Columns>
    </Block>
  )
}

export { NavigationTopBar }
