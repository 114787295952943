import { FC } from 'react'
import { PageHeadingBlock } from '_BRIGHT_'
import { CenteredBlock } from 'components/CenteredBlock'

export const NotFoundPage: FC = ({ children }) => {
  return (
    <>
      <PageHeadingBlock
        backLink="/"
        backLinkText="Back"
        pageHeading="Page not found"
      />
      <CenteredBlock>{children}</CenteredBlock>
    </>
  )
}
