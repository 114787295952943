import { cubeBaseQuery } from '../cubeBaseQuery'
import { createApi, retry } from '@reduxjs/toolkit/query/react'
import {
  CubeTablePivot,
  formatCubeTableToDataSet,
  GeniusDataSet,
} from 'lib/cubeJs'
import {
  AllEmployeesQueryConfig,
  employeeFilter,
  employeesDimensions,
  orderEmployees,
} from '.'
import { ResultSet } from '@cubejs-client/core'

const staggeredBaseQuery = retry(cubeBaseQuery(), {
  maxRetries: 3,
})

export const employeeApi = createApi({
  reducerPath: 'employeeApi',
  baseQuery: staggeredBaseQuery,
  keepUnusedDataFor: 180,

  endpoints: build => ({
    employees: build.query<
      GeniusDataSet,
      { config: AllEmployeesQueryConfig; companyId: string }
    >({
      query: ({ config: { offset, limit, filter, sort }, companyId }) => ({
        cubeQuery: {
          dimensions: employeesDimensions,
          filters: [
            {
              member: 'SMART_ANALYTICS_EMPLOYEES.companyId',
              operator: 'equals',
              values: [companyId],
            },
            ...employeeFilter(filter),
          ],
          order: orderEmployees(sort),
          limit,
          offset,
        },
      }),
      transformResponse: (cubeResponse: ResultSet<any>) => {
        const tables = cubeResponse.tablePivot() as CubeTablePivot[]
        const metaDataList = formatCubeTableToDataSet(tables)
        return metaDataList
      },
    }),

    employeesCount: build.query<number, { filter: any; companyId: string }>({
      query: ({ filter, companyId }) => ({
        cubeQuery: {
          measures: ['SMART_ANALYTICS_EMPLOYEES.count'],
          filters: [
            ...employeeFilter(filter),
            {
              member: 'SMART_ANALYTICS_EMPLOYEES.companyId',
              operator: 'equals',
              values: [companyId],
            },
          ],
        },
      }),
      transformResponse: (cubeResponse: ResultSet<any>) => {
        return Number(
          cubeResponse?.tablePivot()[0]['SMART_ANALYTICS_EMPLOYEES.count'],
        )
      },
    }),
  }),
})

export const { useEmployeesCountQuery, useEmployeesQuery } = employeeApi
