import { Danger, Success, Upload, Warning } from './icons'
import type { FC } from 'react'
import React from 'react'
import gsap from 'gsap'
import { DrawSVGPlugin } from 'gsap/dist/DrawSVGPlugin'
import { CustomEase } from 'gsap/dist/CustomEase'
import { useIsMobileViewport } from '_BRIGHT_/utils'

gsap.registerPlugin(DrawSVGPlugin, CustomEase)

export const animatedIconComponentMap = {
  Danger,
  Success,
  Upload,
  Warning,
}

export type AnimatedIconVariant = keyof typeof animatedIconComponentMap

export type AnimatedIconProps = {
  variant: AnimatedIconVariant
  autoPlay?: boolean
}

export const AnimatedIcon: FC<AnimatedIconProps> = ({
  variant,
  autoPlay = true,
}) => {
  const Icon = animatedIconComponentMap[variant]
  const isMobile = useIsMobileViewport()

  return <Icon size={isMobile ? '80' : '96'} autoPlay={autoPlay} />
}
