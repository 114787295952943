export type QueryParams = Record<string, string>

export const getNamespacedQueryParamKey = (namespace: string, key: string): string => `${namespace}-${key}`

export const getNamespacedQueryParamKeys = (namespace: string, params: string[]): string[] => {
  return params.map(param => getNamespacedQueryParamKey(namespace, param))
}

export const getNamespacedQueryParams = (namespace: string, params: QueryParams): QueryParams => {
  return Object.keys(params).reduce(
    (paramsWithoutNamespace, key) => ({
      ...paramsWithoutNamespace,
      [getNamespacedQueryParamKey(namespace, key)]: params[key],
    }),
    {}
  )
}

export const stripNamespaceFromQueryParamKey = (namespace: string, key: string): string =>
  key.replace(`${namespace}-`, '')

export const stripNamespaceFromQueryParams = (namespace: string, params: QueryParams): QueryParams => {
  return Object.keys(params).reduce(
    (paramsWithoutNamespace, key) => ({
      ...paramsWithoutNamespace,
      [stripNamespaceFromQueryParamKey(namespace, key)]: params[key],
    }),
    {}
  )
}
