export const fonts = {
  heading:
    '"Museo Sans", museo-sans, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  body: '"Lato", lato, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  // chakra default
  mono: 'SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
}

export const fontSizes = {
  '2xs': '0.75rem',
  xs: '0.875rem',
  sm: '1rem',
  md: '1.125rem',
  lg: '1.25rem',
  xl: '1.5rem',
  '2xl': '1.75rem',
  '3xl': '2rem',
  '4xl': '2.75rem',
}

export const fontWeights = {
  normal: 400,
  medium: 500,
  bold: 700,
}

export const letterSpacings = {
  normal: '0',
}

export const lineHeights = {
  none: '1',
  shorter: '1.1',
  short: '1.2',
  base: '1.3',
  tall: '1.4',
  taller: '1.5',
}
