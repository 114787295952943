import { FC } from 'react'
import { Box } from '@chakra-ui/layout'
import { useScopeBaseURL } from 'lib/hooks'
import { AnimatedIcon, Block, Heading, Stack, Text } from '_BRIGHT_/components'
import { Flex, Link } from '@chakra-ui/react'

export const NotAuthorisedPage: FC = () => {
  const { baseURL } = useScopeBaseURL()

  return (
    <Box height="100vh">
      <SystemErrors
        buttonLabel="Return to home"
        buttonLink={`${baseURL}/`}
        errorHeading="Sorry, you don’t have permission to view this page"
        errorMessage="If you are having problems, please contact our customer support team."
      />
    </Box>
  )
}

export type SystemErrorsProps = {
  errorHeading: string
  errorMessage: string
  buttonLabel: string
  buttonLink: string
}

export const SystemErrors: FC<SystemErrorsProps> = ({
  errorHeading,
  errorMessage,
  buttonLabel,
  buttonLink,
}): JSX.Element => {
  return (
    <Flex height="100%" align="center">
      <Block palette="baseTwo" paddingY="4xl">
        <Stack space="4xl" alignX="center">
          <AnimatedIcon variant="Danger" />
          <Heading as="h2" textAlign="center">
            {errorHeading}
          </Heading>
          <Text align="center">{errorMessage}</Text>
          <Link href={buttonLink} variant="primaryButton">
            {buttonLabel}
          </Link>
        </Stack>
      </Block>
    </Flex>
  )
}
