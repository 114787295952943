import React from 'react'
import { useColor } from '_BRIGHT_/components/PaletteProvider'

export const DragHandle = (): JSX.Element => {
  const fill = useColor('borderPrimary')
  return (
    <svg
      width="8"
      height="18"
      viewBox="0 0 8 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="1.5" cy="1.5" r="1.5" fill={fill} />
      <circle cx="6.5" cy="1.5" r="1.5" fill={fill} />
      <circle cx="1.5" cy="6.5" r="1.5" fill={fill} />
      <circle cx="6.5" cy="6.5" r="1.5" fill={fill} />
      <circle cx="1.5" cy="11.5" r="1.5" fill={fill} />
      <circle cx="6.5" cy="11.5" r="1.5" fill={fill} />
      <circle cx="1.5" cy="16.5" r="1.5" fill={fill} />
      <circle cx="6.5" cy="16.5" r="1.5" fill={fill} />
    </svg>
  )
}
