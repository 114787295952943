import { Box, TooltipProps as ChakraTooltipProps } from '@chakra-ui/react'
import { forwardRef, Tooltip as ChakraTooltip } from '@chakra-ui/react'
import { FC, useState } from 'react'
import React from 'react'

export type TooltipProps = Pick<
  ChakraTooltipProps,
  'label' | 'aria-label' | 'children' | 'placement'
>

const Tooltip: FC<TooltipProps> = forwardRef(
  ({ children, label, placement = 'top', 'aria-label': ariaLabel }, ref) => {
    /**
     * MODIFIED:
     * added placement prop
     * added additional styling
     * added support for touch devices
     * added keyboard support
     */
    const [isLabelOpen, setIsLabelOpen] = useState(false)
    return (
      <ChakraTooltip
        ref={ref}
        hasArrow
        placement={placement}
        label={label}
        aria-label={ariaLabel}
        p="xs"
        borderRadius="md"
        shadow="none"
        fontSize="xs"
        isOpen={isLabelOpen}
      >
        <Box
          as="button"
          px="1"
          onMouseEnter={() => setIsLabelOpen(true)}
          onMouseLeave={() => setIsLabelOpen(false)}
          onTouchEnd={() => setIsLabelOpen(!isLabelOpen)}
          onKeyDown={() => setIsLabelOpen(!isLabelOpen)}
          onFocus={() => setIsLabelOpen(true)}
          onBlur={() => setIsLabelOpen(false)}
        >
          {children}
        </Box>
      </ChakraTooltip>
    )
  },
)

export { Tooltip }
