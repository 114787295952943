import type { FC } from 'react'
import React from 'react'
import type { HiFiIconPathProps } from '_BRIGHT_/components/Icons'

const Guarantee: FC<HiFiIconPathProps> = ({
  primaryColor,
  secondaryColor,
  baseColor,
  strokeWidth,
  hasShadow,
}) => {
  return (
    <>
      {hasShadow && (
        <path
          opacity="0.2"
          d="M25.2761 30.3598C26.3799 29.3096 27 27.8852 27 26.4V4H11.3045V18.9333V26.4C11.3045 26.4 11.0922 29.5 6.40542 29.5C4.56524 29.5 4.46254 32 6.5113 32H21.1142C22.6752 32 24.1723 31.41 25.2761 30.3598Z"
          fill={primaryColor}
        />
      )}
      <path
        d="M23 6.5C23 7.42826 23.3687 8.3185 24.0251 8.97487C24.6815 9.63125 25.5717 10 26.5 10C27.4283 10 28.3185 9.63125 28.9749 8.97487C29.6313 8.3185 30 7.42826 30 6.5C30 5.57174 29.6313 4.6815 28.9749 4.02513C28.3185 3.36875 27.4283 3 26.5 3C25.5717 3 24.6815 3.36875 24.0251 4.02513C23.3687 4.6815 23 5.57174 23 6.5V6.5Z"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.51147 3.3135C8.55692 3.21932 8.62625 3.14023 8.71179 3.08498C8.79734 3.02973 8.89574 3.00049 8.99611 3.00049C9.09648 3.00049 9.19488 3.02973 9.28042 3.08498C9.36597 3.14023 9.4353 3.21932 9.48075 3.3135L10.6079 5.70216C10.6468 5.78411 10.7041 5.8549 10.7748 5.90848C10.8454 5.96206 10.9274 5.99682 11.0136 6.0098L13.5401 6.39349C13.6388 6.40839 13.7315 6.45183 13.8081 6.51896C13.8846 6.58608 13.9419 6.67426 13.9736 6.77366C14.0046 6.87267 14.0084 6.97885 13.9846 7.08002C13.9608 7.1812 13.9104 7.27327 13.8391 7.34567L12.0051 9.21729C11.9435 9.28014 11.8973 9.35781 11.8706 9.4436C11.8439 9.5294 11.8374 9.62074 11.8517 9.70976L12.2841 12.3476C12.3009 12.4509 12.2896 12.5572 12.2514 12.6541C12.2132 12.751 12.1498 12.8345 12.0684 12.895C11.9865 12.9561 11.89 12.9922 11.7898 12.9992C11.6895 13.0062 11.5894 12.9839 11.5004 12.9348L9.24843 11.6972C9.17067 11.654 9.08405 11.6314 8.99611 11.6314C8.90816 11.6314 8.82155 11.654 8.74379 11.6972L6.49512 12.9348C6.40616 12.9839 6.30603 13.0062 6.20579 12.9992C6.10554 12.9922 6.0091 12.9561 5.92711 12.895C5.84574 12.8345 5.78231 12.751 5.74415 12.6541C5.70599 12.5572 5.69466 12.4509 5.71147 12.3476L6.14387 9.70976C6.15815 9.62074 6.15166 9.5294 6.12493 9.4436C6.09821 9.35781 6.05206 9.28014 5.99047 9.21729L4.16086 7.34567C4.08962 7.27327 4.03921 7.1812 4.01543 7.08002C3.99164 6.97885 3.99543 6.87267 4.02636 6.77366C4.05797 6.67419 4.11526 6.58594 4.19182 6.5188C4.26839 6.45166 4.3612 6.40827 4.45987 6.39349L6.98531 6.0098C7.07033 5.99576 7.15092 5.96051 7.22034 5.90699C7.28977 5.85347 7.34601 5.78323 7.38436 5.70216L8.51147 3.3135Z"
        fill={baseColor}
        stroke={secondaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26 3H14"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23 6V23.6923C23 25.1 22.4541 26.45 21.4823 27.4454C20.5105 28.4408 19.1925 29 17.8182 29H4C5.3743 29 6.69232 28.4408 7.6641 27.4454C8.63588 26.45 9.18182 25.1 9.18182 23.6923V16.6154"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.75 22.25H19.75"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.75 18.75H19.75"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.25 15.25H19.75"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.25 11.75H19.75"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  )
}

export { Guarantee }
