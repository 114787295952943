import React from 'react'
import type { FC } from 'react'
import { Frame, Grid, GridItem } from '_BRIGHT_/components/Layout'

type ButtonsSectionProps<ButtonsProps> = {
  buttonsRendererList: FC<ButtonsProps>[]
  buttonsProps: ButtonsProps
}

export const ButtonsSection = <ButtonsProps,>({
  buttonsRendererList,
  buttonsProps,
}: ButtonsSectionProps<ButtonsProps>): JSX.Element => (
  <Frame palette="baseTwo" paddingBottom="4xl">
    <Grid space="md" isInline={true} wrap={true} alignY="center">
      {buttonsRendererList.map((ButtonRenderer, i) => (
        <GridItem width="content" key={i}>
          <ButtonRenderer {...(buttonsProps as ButtonsProps)} />
        </GridItem>
      ))}
    </Grid>
  </Frame>
)
