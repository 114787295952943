import { BinaryFilter, BinaryOperator, Filter } from '@cubejs-client/core'
import dayjs from 'dayjs'
import {
  CubeEmployeesAccessor,
  CubeKey,
  AppQuery,
  membershipValues,
} from 'lib/cubeJs'
import { fields } from 'components/MaintenanceGenius/config/fields'
import {
  addCubeEmployees,
  geniusOperatorToCubeOperator,
} from 'lib/cubeJs/cubeHelpers'

export type AppSort<T> = {
  field: T
  order: 'ASC' | 'DESC'
}

export type EmployeeSort = AppSort<CubeEmployeesAccessor>

export type EmployeeFilter = {
  [k in CubeEmployeesAccessor]: {
    value: string
    operator: string
    field: CubeKey
  }
}

export type AllEmployeesQueryConfig = {
  offset: number | undefined
  limit: number | undefined
  sort: EmployeeSort | undefined
  filter: EmployeeFilter | undefined
}

export const employeesDimensions: CubeKey[] = [
  'SMART_ANALYTICS_EMPLOYEES.companyId',
  'SMART_ANALYTICS_EMPLOYEES.employeeId',
  'SMART_ANALYTICS_EMPLOYEES.fullName',
  'SMART_ANALYTICS_EMPLOYEES.membershipStatus',
  'SMART_ANALYTICS_EMPLOYEES.externalId',
  'SMART_ANALYTICS_EMPLOYEES.nationalInsuranceNumber',
  'SMART_ANALYTICS_EMPLOYEES.total1DaysLateMissingContributions',
  'SMART_ANALYTICS_EMPLOYEES.total30DaysLateMissingContributions',
  'SMART_ANALYTICS_EMPLOYEES.total60DaysLateMissingContributions',
  'SMART_ANALYTICS_EMPLOYEES.total90DaysLateMissingContributions',
  'SMART_ANALYTICS_EMPLOYEES.lastContributionEndDate',
  'SMART_ANALYTICS_EMPLOYEES.lastSignInDate',
  'SMART_ANALYTICS_EMPLOYEES.lastBeneficiariesUpdatedDate',
  'SMART_ANALYTICS_EMPLOYEES.groupName',
  'SMART_ANALYTICS_EMPLOYEES.startDate',
  'SMART_ANALYTICS_EMPLOYEES.exitDate',
  'SMART_ANALYTICS_EMPLOYEES.exitReason',
  'SMART_ANALYTICS_EMPLOYEES.emailAddress',
  'SMART_ANALYTICS_EMPLOYEES.telephone',
  'SMART_ANALYTICS_EMPLOYEES.homeAddress',
  'SMART_ANALYTICS_EMPLOYEES.gender',
  'SMART_ANALYTICS_EMPLOYEES.isGoneAway',
  'SMART_ANALYTICS_EMPLOYEES.hasValidHomeAddress',
]

const subtractDays = (days: number) =>
  dayjs().subtract(days, 'days').format('DD MMM YYYY')

export const getDBMembershipValue = (searchString: string) => {
  const foundEntry = Object.entries(membershipValues).find(
    ([_k, v]) => v === searchString,
  )
  return foundEntry ? foundEntry[0] : 'Unknown status'
}

const mapOptions = (fieldName: string, value: string): Filter => {
  switch (`${fieldName} - ${value}`) {
    case 'lastBeneficiariesUpdatedDate - within 30 days':
      return {
        member: 'SMART_ANALYTICS_EMPLOYEES.lastBeneficiariesUpdatedDate',
        operator: 'afterDate',
        values: [subtractDays(30)],
      }
    case 'lastBeneficiariesUpdatedDate - within 60 days':
      return {
        member: 'SMART_ANALYTICS_EMPLOYEES.lastBeneficiariesUpdatedDate',
        operator: 'afterDate',
        values: [subtractDays(60)],
      }
    case 'lastBeneficiariesUpdatedDate - within 90 days':
      return {
        member: 'SMART_ANALYTICS_EMPLOYEES.lastBeneficiariesUpdatedDate',
        operator: 'afterDate',
        values: [subtractDays(90)],
      }
    case 'lastBeneficiariesUpdatedDate - never':
      return {
        member: 'SMART_ANALYTICS_EMPLOYEES.lastBeneficiariesUpdatedDate',
        operator: 'notSet',
      }
    case 'lastSignInDate - within 30 days':
      return {
        member: 'SMART_ANALYTICS_EMPLOYEES.lastSignInDate',
        operator: 'afterDate',
        values: [subtractDays(30)],
      }
    case 'lastSignInDate - within 60 days':
      return {
        member: 'SMART_ANALYTICS_EMPLOYEES.lastSignInDate',
        operator: 'afterDate',
        values: [subtractDays(60)],
      }
    case 'lastSignInDate - within 90 days':
      return {
        member: 'SMART_ANALYTICS_EMPLOYEES.lastSignInDate',
        operator: 'afterDate',
        values: [subtractDays(90)],
      }
    case 'lastSignInDate - never':
      return {
        member: 'SMART_ANALYTICS_EMPLOYEES.lastSignInDate',
        operator: 'notSet',
      }
    case 'membershipStatus - Paid up / ceased':
      return {
        member: 'SMART_ANALYTICS_EMPLOYEES.membershipStatus',
        operator: 'equals',
        values: [getDBMembershipValue(value)],
      }
    case 'membershipStatus - Pre-joiner':
      return {
        member: 'SMART_ANALYTICS_EMPLOYEES.membershipStatus',
        operator: 'equals',
        values: [getDBMembershipValue(value)],
      }
    case 'membershipStatus - Opted out':
      return {
        member: 'SMART_ANALYTICS_EMPLOYEES.membershipStatus',
        operator: 'equals',
        values: [getDBMembershipValue(value)],
      }
    case 'membershipStatus - Active':
      return {
        member: 'SMART_ANALYTICS_EMPLOYEES.membershipStatus',
        operator: 'equals',
        values: [getDBMembershipValue(value)],
      }
    case 'membershipStatus - Deferred':
      return {
        member: 'SMART_ANALYTICS_EMPLOYEES.membershipStatus',
        operator: 'equals',
        values: [getDBMembershipValue(value)],
      }
    case 'membershipStatus - Missing':
      return {
        member: 'SMART_ANALYTICS_EMPLOYEES.membershipStatus',
        operator: 'notSet',
      }
    case 'lateContributions - 1-29 days':
      return {
        member: 'SMART_ANALYTICS_EMPLOYEES.total1DaysLateMissingContributions',
        operator: 'gt',
        values: ['0'],
      }
    case 'lateContributions - 30-59 days':
      return {
        member: 'SMART_ANALYTICS_EMPLOYEES.total30DaysLateMissingContributions',
        operator: 'gt',
        values: ['0'],
      }
    case 'lateContributions - 60-89 days':
      return {
        member: 'SMART_ANALYTICS_EMPLOYEES.total60DaysLateMissingContributions',
        operator: 'gt',
        values: ['0'],
      }
    case 'lateContributions - 90+ days':
      return {
        member: 'SMART_ANALYTICS_EMPLOYEES.total90DaysLateMissingContributions',
        operator: 'gt',
        values: ['0'],
      }
    case 'nationalInsuranceNumber - missing':
      return {
        member: 'SMART_ANALYTICS_EMPLOYEES.nationalInsuranceNumber',
        operator: 'notSet',
      }
    case 'nationalInsuranceNumber - complete':
      return {
        member: 'SMART_ANALYTICS_EMPLOYEES.nationalInsuranceNumber',
        operator: 'set',
      }
    case 'hasMissingEmailAddress - true':
      return {
        member: 'SMART_ANALYTICS_EMPLOYEES.emailAddress',
        operator: 'notSet',
      }
    case 'hasMissingEmailAddress - false':
      return {
        member: 'SMART_ANALYTICS_EMPLOYEES.emailAddress',
        operator: 'set',
      }
    case 'hasMissingHomeAddress - true':
      return {
        member: 'SMART_ANALYTICS_EMPLOYEES.homeAddress',
        operator: 'notSet',
      }
    case 'hasMissingHomeAddress - false':
      return {
        member: 'SMART_ANALYTICS_EMPLOYEES.homeAddress',
        operator: 'set',
      }
    case 'hasMissingTelephone - true':
      return {
        member: 'SMART_ANALYTICS_EMPLOYEES.telephone',
        operator: 'notSet',
      }
    case 'hasMissingTelephone - false':
      return {
        member: 'SMART_ANALYTICS_EMPLOYEES.telephone',
        operator: 'notSet',
      }

    case 'isGoneAway - No':
      return {
        member: 'SMART_ANALYTICS_EMPLOYEES.isGoneAway',
        operator: 'equals',
        values: ['0'],
      }

    case 'isGoneAway - Yes':
      return {
        member: 'SMART_ANALYTICS_EMPLOYEES.isGoneAway',
        operator: 'equals',
        values: ['1'],
      }

    default:
      // any days late
      return {} as BinaryFilter
  }
}

export const employeeFilter = (
  filter: EmployeeFilter | undefined,
): Filter[] => {
  if (!filter) return []

  return Object.values(filter)
    .map(({ operator, value, field }): Filter => {
      const config = fields.find(f => f.fieldName === field)

      if (config?.type === 'option') {
        return mapOptions(field, value)
      }

      return {
        member: addCubeEmployees(field),
        operator: geniusOperatorToCubeOperator(operator as BinaryOperator),
        values: [value],
      }
    })
    .filter(n => Object.keys(n).length !== 0)
}

// TODO -> refactor this using a generic and a call back
// OR REMOVE THE CUBE ACCESSOR SPLIT IN THE WHOLE APPLICATION
export const orderEmployees = (sort: EmployeeSort | undefined) => {
  if (sort) {
    const { field, order } = sort
    return {
      [addCubeEmployees(field)]: order.toLowerCase(),
    } as AppQuery['order']
  }
  return {}
}
