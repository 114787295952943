import { useToken } from '@chakra-ui/react'

type UseGetWidth = (characters: number | undefined, padding: string, borderWidth: string) => string

export const useGetWidth: UseGetWidth = (characters, padding, borderWidth) => {
  const paddingToken = useToken('space', padding)
  const borderWidthToken = useToken('borderWidths', borderWidth)

  return `calc(${characters}ch + (2 * ${paddingToken}) + (2 * ${borderWidthToken}))`
}
