import { Column, Columns } from '_BRIGHT_/components'
import type { BaseInputFieldProps } from '_BRIGHT_/components/Forms/BaseInputField'
import { BaseInputField } from '_BRIGHT_/components/Forms/BaseInputField'
import type { FormErrors } from '_BRIGHT_/components/Forms/common'
import { FormControl } from '_BRIGHT_/components/Forms/FormControl'
import type { FC, ChangeEvent, ReactElement } from 'react'
import React from 'react'

export type BaseInputProps = BaseInputFieldProps & {
  id?: string
  label?: string
  ariaLabel?: string
  name?: string
  description?: string
  errors?: FormErrors
  rightButton?: ReactElement
  leftButton?: ReactElement
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
}

const BaseInput: FC<BaseInputProps> = ({
  id,
  label,
  name,
  borderless,
  color,
  description,
  errors,
  isRequired,
  characters,
  rightButton,
  leftButton,
  value,
  type = 'text',
  leftAddon,
  rightAddon,
  textAlign,
  onChange,
  onBlur,
  onKeyDown,
  pattern,
  inputMode,
  role,
  minLength,
  maxLength,
  width = '100%',
  height,
  placeholder,
  _placeholder,
  ...inputFieldProps
}) => {
  const hasSideButton = leftButton || rightButton
  return (
    <FormControl
      id={id}
      label={label}
      description={description}
      isRequired={isRequired}
      errors={errors}
    >
      <Columns space={hasSideButton ? 'xs' : undefined}>
        {leftButton && (
          <Column width="content" alignY="bottom">
            {leftButton}
          </Column>
        )}
        <Column width={characters ? 'content' : undefined}>
          <BaseInputField
            isRequired={false}
            id="JJJONNNN"
            name={name}
            value={value}
            borderless={borderless}
            color={color}
            type={type}
            characters={characters}
            leftAddon={leftAddon}
            rightAddon={rightAddon}
            onKeyDown={onKeyDown}
            onChange={onChange}
            onBlur={onBlur}
            pattern={pattern}
            inputMode={inputMode}
            role={role}
            minLength={minLength}
            maxLength={maxLength}
            textAlign={textAlign}
            width={width}
            height={height}
            placeholder={placeholder}
            _placeholder={_placeholder}
            {...inputFieldProps}
          />
        </Column>
        {rightButton && (
          <Column width="content" alignY="bottom">
            {rightButton}
          </Column>
        )}
      </Columns>
    </FormControl>
  )
}

export { BaseInput }
