import { Link, Stack } from '_BRIGHT_'
import { CenteredBlock } from 'components/CenteredBlock'
import { PageHeadingBlock } from 'components/PageHeadingBlock'
import { useScrollToTop } from 'lib/hooks'
import { SelectClientGenius } from 'components/SelectClientGenius/SelectClientGenius'
import { selectClientOverviewQuery } from './selectClientOverviewQuery'
import { Overview } from 'components/Overview'

export const SelectClientPage = () => {
  useScrollToTop()

  return (
    <>
      <PageHeadingBlock
        pageHeading="Select a client"
        subheading="Select a client to view detailed charts and tables."
      >
        <Link href="/glossary-of-terms">Glossary of terms</Link>
      </PageHeadingBlock>
      <CenteredBlock>
        <Stack space="md">
          <Overview query={selectClientOverviewQuery} />
          <SelectClientGenius />
        </Stack>
      </CenteredBlock>
    </>
  )
}
