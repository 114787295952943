import { ErrorFallback } from 'components/ErrorFallback'
import { ErrorBoundary } from 'react-error-boundary'
import { AdviserNavigation, EmployerNavigation, NotAuthorisedPage } from 'pages'
import { PageLoading } from '_BRIGHT_'
import { PageWrapper } from 'components/PageWrapper'
import { useStartApp } from 'lib/hooks'

export function App() {
  const { scope, status } = useStartApp()

  if (scope && status === 'authorised') {
    return (
      <PageWrapper>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          {scope === 'user' && <AdviserNavigation />}
          {scope === 'customer' && <EmployerNavigation />}
        </ErrorBoundary>
      </PageWrapper>
    )
  }

  if (status === 'signout') {
    return (
      <PageWrapper>
        <PageLoading />
      </PageWrapper>
    )
  }

  if (status === 'unauthorised') {
    return <NotAuthorisedPage />
  }

  return null
}
