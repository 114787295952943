import type { FC } from 'react'
import React from 'react'
import { Link, Block, Stack, Text, Divider, Heading } from '_BRIGHT_/components'

export type PageHeadingBlockProps = {
  backLink: string
  backLinkText: string
  pageHeading: string
  subheading?: string
  showDivider?: boolean
}

const PageHeadingBlock: FC<PageHeadingBlockProps> = ({
  backLink,
  backLinkText,
  pageHeading,
  subheading,
  showDivider = true,
}) => {
  return (
    <>
      <Block palette="baseTwo" paddingTop="lg">
        <Link leftIcon="FunctionalArrowLeft" href={backLink}>
          {backLinkText}
        </Link>
      </Block>
      <Block palette="baseTwo" paddingTop="5xl">
        <Stack space="5xl">
          <Stack space="md">
            <Heading>{pageHeading}</Heading>
            {subheading && (
              <Text level="md" variant="secondary">
                {subheading}
              </Text>
            )}
          </Stack>
          {showDivider && <Divider />}
        </Stack>
      </Block>
    </>
  )
}

export { PageHeadingBlock }
