import type { FC } from 'react'
import React from 'react'
import type { HiFiIconPathProps } from '_BRIGHT_/components/Icons'

const Options: FC<HiFiIconPathProps> = ({
  primaryColor,
  secondaryColor,
  baseColor,
  strokeWidth,
  hasShadow,
}) => {
  return (
    <>
      {hasShadow && (
        <path
          opacity="0.2"
          d="M30 14.1111C30 14.602 29.5709 15 29.0417 15L11.6367 15C11.1694 15 10.7169 14.8364 10.3577 14.5375L7.51654 12.1739C7.35176 12.0184 7.22184 11.8346 7.13355 11.6335C7.04525 11.4324 7 11.2173 7 11.0005C7 10.7837 7.04524 10.5686 7.13355 10.3675C7.22182 10.1665 7.35161 9.98278 7.51632 9.82735L10.3576 7.46273C10.7169 7.16372 11.1695 7 11.637 7L29.0417 7C29.5709 7 30 7.39797 30 7.88889L30 14.1111Z"
          fill={primaryColor}
        />
      )}
      <path
        d="M16 2V4"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 11V14"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 21L16 29"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.3429 19.7382C25.1586 19.9066 24.918 20 24.6683 20H7V14H24.6685C24.918 14 25.1586 14.0933 25.3429 14.2617L27.7594 16.469C27.8357 16.5387 27.8962 16.6214 27.9375 16.7124C27.9788 16.8034 28 16.901 28 16.9995C28 17.098 27.9788 17.1956 27.9375 17.2866C27.8962 17.3776 27.8357 17.4603 27.7594 17.53L25.3429 19.7382Z"
        fill={baseColor}
        stroke={secondaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.65615 4.26178C6.84044 4.09338 7.08106 4 7.3307 4H25V10H7.33082C7.0811 10 6.84042 9.90657 6.65611 9.73809L4.24059 7.53C4.16432 7.46035 4.10381 7.37765 4.06253 7.28662C4.02125 7.1956 4 7.09803 4 6.9995C4 6.90097 4.02125 6.8034 4.06253 6.71238C4.10381 6.62135 4.16432 6.53865 4.24059 6.469L6.65615 4.26178Z"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  )
}

export { Options }
