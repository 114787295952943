import { FC } from 'react'
import { Box } from '@chakra-ui/react'
import { StandardCard } from 'components/StandardCard'
import { FilterQuery } from 'lib/cubeJs'
import { CubeTablePivot, formatTablePivotToCubeValues } from 'lib/cubeJs'
import { useCompanyId } from 'lib/hooks'
import { PageLoading } from '_BRIGHT_'
import { HifiIconVariant } from '_BRIGHT_/components/Icons'
import { useAnyCubeQueryQuery } from 'redux/query/'
import { DataRow } from 'components/DataRow/DataRow'

type CardProps = {
  path?: string
  title: string
  icon: HifiIconVariant
  query: FilterQuery
}

export const CardContent = ({ query }: { query: FilterQuery }) => {
  const { companyId } = useCompanyId()

  const {
    data: cardData,
    error,
    isLoading,
  } = useAnyCubeQueryQuery(query([companyId]))

  if (error) {
    throw error
  }

  if (isLoading) {
    return <PageLoading />
  }

  if (cardData) {
    const tables = cardData.tablePivot() as CubeTablePivot[]
    const data = formatTablePivotToCubeValues(tables[0])

    return (
      <Box width="100%" pt="sm">
        {data.map((props, i) => (
          <DataRow
            key={i}
            py={{ base: '2xs' }}
            textVariant="secondary"
            {...props}
          />
        ))}
      </Box>
    )
  }

  return null
}

export const JourneyCard: FC<CardProps> = ({
  path = '',
  icon,
  title,
  query,
}) => (
  <StandardCard to={path} iconVariant={icon} title={title}>
    <CardContent query={query} />
  </StandardCard>
)
