import type { FC } from 'react'
import React from 'react'
import type { HiFiIconPathProps } from '_BRIGHT_/components/Icons'

const Info: FC<HiFiIconPathProps> = ({
  primaryColor,
  secondaryColor,
  baseColor,
  strokeWidth,
  hasShadow,
}) => {
  return (
    <>
      {hasShadow && (
        <path
          opacity="0.2"
          d="M7.46565 7.97492C11.3252 6.66527 15.5136 6.6774 19.3555 7.99741C19.7464 8.13174 20 8.50507 19.9999 8.91844L19.9976 29.5386C19.9975 30.2418 19.2892 30.7272 18.6153 30.5262C15.372 29.5592 11.9053 29.5179 8.62273 30.4231C8.31174 30.5017 7.98662 30.5089 7.67233 30.4444C7.35804 30.3799 7.06294 30.2453 6.8097 30.051C6.55645 29.8567 6.35179 29.6078 6.21144 29.3234C6.07108 29.039 5.99875 28.7268 6.00002 28.4106V9.95011C5.99939 9.5102 6.14114 9.08154 6.40469 8.72636C6.66825 8.37117 7.03988 8.10796 7.46565 7.97492Z"
          fill={primaryColor}
        />
      )}
      <path
        d="M16 6.5V26"
        stroke={primaryColor}
        strokeWidth={+strokeWidth * 2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 6.12973C12.2723 4.70936 8.1463 4.62568 4.36096 5.89367C3.9656 6.01563 3.62051 6.25691 3.37579 6.58249C3.13106 6.90808 2.99943 7.30102 3.00002 7.70427V24.6264C2.99884 24.9162 3.066 25.2025 3.19633 25.4631C3.32667 25.7238 3.51671 25.9519 3.75186 26.1301C3.98702 26.3082 4.26104 26.4316 4.55288 26.4907C4.84471 26.5499 5.14661 26.5432 5.43539 26.4712C8.92115 25.5223 12.6305 25.708 15.9976 27"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 6.12973C19.7277 4.70936 23.8537 4.62568 27.639 5.89367C28.0344 6.01563 28.3795 6.25691 28.6242 6.58249C28.8689 6.90808 29.0006 7.30102 29 7.70427V24.6264C29.0012 24.9162 28.934 25.2025 28.8037 25.4631C28.6733 25.7238 28.4833 25.9519 28.2481 26.1301C28.013 26.3082 27.739 26.4316 27.4471 26.4907C27.1553 26.5499 26.8534 26.5432 26.5646 26.4712C23.0789 25.5223 19.3695 25.708 16.0024 27"
        fill={baseColor}
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 14L10.5 11.5L8 14V5.66667C8 5.22464 8.17559 4.80072 8.48816 4.48816C8.80072 4.17559 9.22464 4 9.66667 4H11.3333C11.7754 4 12.1993 4.17559 12.5118 4.48816C12.8244 4.80072 13 5.22464 13 5.66667V14Z"
        fill={baseColor}
        stroke={secondaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  )
}

export { Info }
