import { useColor } from '_BRIGHT_/components'
import { Divider as ChakraDivider } from '@chakra-ui/react'
import type { FC } from 'react'
import React from 'react'
import type { Size } from '_BRIGHT_/components/Typography/common'
import type { BorderColor } from '_BRIGHT_/components/PaletteProvider'

type HeightSizes = 'default' | 'custom'
type HeightValues = Extract<Size, '9'> | '100%'

export type DividerProps = {
  orientation?: 'horizontal' | 'vertical'
  borderWidth?: '2xs' | 'xs'
  color?: BorderColor
  height?: 'default' | 'custom'
}

const heightMap: Record<HeightSizes, HeightValues> = {
  default: '100%',
  custom: '9',
}

const Divider: FC<DividerProps> = ({
  orientation = 'horizontal',
  borderWidth = '2xs',
  color = 'borderSecondary',
  height = 'default',
}) => {
  const borderColor = useColor(color)
  const hasHeight = orientation === 'horizontal' ? '0' : heightMap[height]

  return (
    <ChakraDivider
      orientation={orientation}
      borderColor={borderColor}
      borderLeftWidth={orientation === 'vertical' ? borderWidth : '0'}
      borderBottomWidth={orientation === 'horizontal' ? borderWidth : '0'}
      opacity="1"
      height={hasHeight}
    />
  )
}

export { Divider }
