import type { FC } from 'react'
import React from 'react'
import type { HiFiIconPathProps } from '_BRIGHT_/components/Icons'

const Percentages: FC<HiFiIconPathProps> = ({
  primaryColor,
  secondaryColor,
  baseColor,
  strokeWidth,
  hasShadow,
}) => {
  return (
    <>
      {hasShadow && (
        <path
          opacity="0.2"
          d="M4 18C4 21.713 5.475 25.274 8.10051 27.8995C10.726 30.525 14.287 32 18 32C21.713 32 25.274 30.525 27.8995 27.8995C30.525 25.274 32 21.713 32 18C32 14.287 30.525 10.726 27.8995 8.10051C25.274 5.475 21.713 4 18 4C14.287 4 10.726 5.475 8.10051 8.10051C5.475 10.726 4 14.287 4 18Z"
          fill={primaryColor}
        />
      )}
      <path
        d="M4.80761 23.1924C2.36964 20.7544 1 17.4478 1 14C1 10.5522 2.36964 7.24558 4.80761 4.80761C7.24558 2.36964 10.5522 1 14 1C17.4478 1 20.7544 2.36964 23.1924 4.80761C25.6304 7.24558 27 10.5522 27 14C27 17.4478 25.6304 20.7544 23.1924 23.1924C20.7544 25.6304 17.4478 27 14 27C10.5522 27 7.24558 25.6304 4.80761 23.1924Z"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 19L19 9"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 9.5C7 10.163 7.26339 10.7989 7.73223 11.2678C8.20107 11.7366 8.83696 12 9.5 12C10.163 12 10.7989 11.7366 11.2678 11.2678C11.7366 10.7989 12 10.163 12 9.5C12 8.83696 11.7366 8.20107 11.2678 7.73223C10.7989 7.26339 10.163 7 9.5 7C8.83696 7 8.20107 7.26339 7.73223 7.73223C7.26339 8.20107 7 8.83696 7 9.5Z"
        fill={baseColor}
        stroke={secondaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 18.5C16 19.163 16.2634 19.7989 16.7322 20.2678C17.2011 20.7366 17.837 21 18.5 21C19.163 21 19.7989 20.7366 20.2678 20.2678C20.7366 19.7989 21 19.163 21 18.5C21 17.837 20.7366 17.2011 20.2678 16.7322C19.7989 16.2634 19.163 16 18.5 16C17.837 16 17.2011 16.2634 16.7322 16.7322C16.2634 17.2011 16 17.837 16 18.5Z"
        fill={baseColor}
        stroke={secondaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  )
}

export { Percentages }
