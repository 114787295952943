import type { FC } from 'react'
import React from 'react'
import { Box } from '@chakra-ui/react'
import { Text } from '_BRIGHT_/components'
import { Header } from '_BRIGHT_/components/Accordion/Header'

const Button: FC = ({ children }) => (
  <Header>
    <Box flex="1" textAlign="left" py="2">
      <Text level="md" variant="primary">
        {children}
      </Text>
    </Box>
  </Header>
)

export { Button }
