import { useSessionstorageState } from 'rooks'
import { Block, Column, Columns, Stack, IconButton, Link } from '_BRIGHT_'
import { useLocation } from 'react-router-dom'
import { Text } from '@chakra-ui/react'
import { useAppScope } from 'redux/selectors'

export const EmployerWelcomeHeader = () => {
  const { pathname } = useLocation()
  const scope = useAppScope()

  const [showWelcomeAlert, setShowWelcomeAlert] = useSessionstorageState(
    'showWelcomeAlert',
    true,
  )

  const isAnalyticsPage = /^\/analytics(\/*)?$/g.test(pathname)

  return isAnalyticsPage && scope === 'customer' && showWelcomeAlert ? (
    <Block palette="baseFour" paddingY="md">
      <Columns>
        <Column width="fluid">
          <Stack space="2xs" alignX="center">
            <Text fontWeight="bold" textAlign="center">
              Welcome to the new employer analytics page
            </Text>
            <Link href="/welcome">Find out more</Link>
          </Stack>
        </Column>
        <Column width="content">
          <IconButton
            variant="ghostButton"
            icon="FunctionalClose"
            onClick={() => setShowWelcomeAlert(false)}
            ariaLabel="Close Banner"
          />
        </Column>
      </Columns>
    </Block>
  ) : null
}
