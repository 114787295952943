import {
  AnalyticsPage,
  SelectClientPage,
  HomePage,
  MaintenancePage,
  NotFoundPage,
  DisclaimerPage,
  GlossaryOfTermsPage,
} from 'pages'
import { Routes, Route, Navigate } from 'react-router-dom'

export const AdviserNavigation = () => (
  <Routes>
    <Route path="/" element={<Navigate to="/analytics" replace />} />
    <Route path="/analytics" element={<HomePage />} />
    <Route path="/analytics/clients" element={<SelectClientPage />} />
    <Route path="/analytics/clients/:id" element={<AnalyticsPage />} />
    <Route
      path="/analytics/clients/:id/maintenance"
      element={<MaintenancePage />}
    />
    <Route path="/disclaimer" element={<DisclaimerPage />} />
    <Route path="/glossary-of-terms" element={<GlossaryOfTermsPage />} />
    <Route path="*" element={<NotFoundPage />} />
  </Routes>
)
