import { FunctionalIcon } from '_BRIGHT_/components'
import type { BaseButtonProps } from '_BRIGHT_/components/Buttons/BaseButton'
import { BaseButton } from '_BRIGHT_/components/Buttons/BaseButton'
import type { ButtonVariant } from '_BRIGHT_/components/Buttons/common'
import { iconSizeMap } from '_BRIGHT_/components/Buttons/common'
import type { FunctionalIconVariant } from '_BRIGHT_/components/Icons'
import type { FC } from 'react'
import React from 'react'

export type IconButtonProps = Pick<
  BaseButtonProps,
  'onClick' | 'size' | 'ariaDisabled' | 'isLoading' | 'type'
> &
  Required<Pick<BaseButtonProps, 'ariaLabel'>> & {
    icon: FunctionalIconVariant
    variant?: ButtonVariant
    isRound?: boolean
  }

const IconButton: FC<IconButtonProps> = ({
  ariaLabel,
  icon,
  onClick,
  variant = 'primaryButton',
  size = 'lg',
  ariaDisabled,
  isLoading,
  type = 'button',
  isRound = false,
}) => {
  const iconSize = iconSizeMap[size]

  return (
    <BaseButton
      ariaLabel={ariaLabel}
      onClick={onClick}
      variant={variant}
      size={size}
      ariaDisabled={ariaDisabled}
      hasPaddingX={false}
      isFullWidthForMobile={false}
      isLoading={isLoading}
      type={type}
      borderRadius={isRound ? 'full' : undefined}
    >
      <FunctionalIcon variant={icon} size={iconSize} />
    </BaseButton>
  )
}

export { IconButton }
