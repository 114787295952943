import * as R from 'ramda'
import { useCallback, useEffect, useMemo } from 'react'
import { sortDirections } from '_BRIGHT_/constants'
import type { GeniusFieldsConfig } from '_BRIGHT_/components/Genius'
import type {
  FieldName,
  FiltersMap,
  FilterItem,
  Sort,
} from '_BRIGHT_/components/Genius/hooks'
import { useQueryParams } from '_BRIGHT_/utils/useQueryParams'
import type { QueryParams } from '_BRIGHT_/utils/queryParams'
import type { UrlParamValidationResult } from './utils/validateQueryParams'
import { validateQueryParams } from './utils/validateQueryParams'

type GeniusQueryParams = Record<string, string>

export type GeniusUrlState = {
  currentPage: number
  filters: FiltersMap | null
  sort: Sort | null
}

type GeniusUrlStateUpdate = {
  currentPage: number | null
  filters: FiltersMap | null
  sort: Sort | null
}

type UpdateGeniusUrlParamsFn = (
  updateState: (state: GeniusUrlState) => GeniusUrlState,
  shouldReplace?: boolean,
) => void
type UseGeniusUrlStateHook = (
  id: string,
  fieldsConfig: GeniusFieldsConfig,
) => {
  urlState: GeniusUrlState
  addFilter(filter: FilterItem): void
  removeFilter(field: FieldName): void
  setSort(sort: Sort): void
  resetSort(): void
  setPage(page: number): void
}

/**
 * Given an object of the query params, return the GeniusUrlState representation.
 * eg. { sort: '-slug', page: 2 } => { sort: { field: 'slug', direction: 'DESC' }, currentPage: 2 }
 */
const parseQueryParams = (
  queryParams: QueryParams,
  paramValidationResult: UrlParamValidationResult,
): GeniusUrlState => {
  let state: GeniusUrlState = {
    currentPage: 1,
    filters: null,
    sort: null,
  }

  // Note that a query param can only be invalid if it actually exists.
  // So it's necessary to also check the existence of each param before attempting to parse it.
  if (queryParams.sort && paramValidationResult.sort) {
    if (queryParams.sort.charAt(0) === '-') {
      state = R.assocPath(['sort', 'field'], queryParams.sort.slice(1), state)
      state = R.assocPath(['sort', 'direction'], sortDirections.DESC, state)
    } else {
      state = R.assocPath(['sort', 'field'], queryParams.sort, state)
      state = R.assocPath(['sort', 'direction'], sortDirections.ASC, state)
    }
  }

  if (queryParams.page && paramValidationResult.page) {
    state.currentPage = parseInt(queryParams.page, 10)
  }

  if (queryParams.filters && paramValidationResult.filters) {
    state.filters = JSON.parse(queryParams.filters)
  }

  return state
}

/**
 * Given the GeniusUrlState, convert into a query params object allowing the state to be encoded in the URL.
 */
const encodeStateAsQueryParams = (
  urlState: GeniusUrlStateUpdate,
): QueryParams => {
  const params: GeniusQueryParams = {}

  if (urlState.sort) {
    params.sort =
      urlState.sort.direction === sortDirections.ASC
        ? urlState.sort.field
        : `-${urlState.sort.field}`
  }

  if (urlState.filters) {
    params.filters = JSON.stringify(urlState.filters)
  }

  if (urlState.currentPage) {
    params.page = `${urlState.currentPage}`
  }

  return params
}

/**
 * Manages Genius query params.
 */
export const useGeniusUrlState: UseGeniusUrlStateHook = (id, fieldsConfig) => {
  const { queryParams, updateQueryParams } = useQueryParams(
    ['sort', 'filters', 'page'],
    id,
  )

  const paramValidationResult = useMemo(
    () => validateQueryParams(queryParams, fieldsConfig),
    [fieldsConfig, queryParams],
  )

  const urlState = useMemo(
    () => parseQueryParams(queryParams, paramValidationResult),
    [queryParams, paramValidationResult],
  )

  const updateGeniusUrlParams = useCallback<UpdateGeniusUrlParamsFn>(
    updateState => {
      const nextState: GeniusUrlStateUpdate = updateState(urlState)

      if (nextState.currentPage === 1) {
        nextState.currentPage = null
      }

      if (
        !R.equals(urlState.sort, nextState.sort) ||
        !R.equals(urlState.filters, nextState.filters)
      ) {
        nextState.currentPage = null
      }

      updateQueryParams(encodeStateAsQueryParams(nextState))
    },
    [urlState, updateQueryParams],
  )

  // If invalid URL state is found, drop those query params from the URL
  useEffect(() => {
    const urlStateToUpdate: Partial<GeniusUrlState> = {}

    if (!paramValidationResult.sort) {
      urlStateToUpdate.sort = null
    }

    if (!paramValidationResult.filters) {
      urlStateToUpdate.filters = null
    }

    if (!paramValidationResult.page) {
      urlStateToUpdate.currentPage = 1
    }

    if (Object.keys(urlStateToUpdate).length > 0) {
      updateGeniusUrlParams(state => ({ ...state, ...urlStateToUpdate }))
    }
  }, [paramValidationResult, updateGeniusUrlParams])

  const addFilter = useCallback(
    (filter: FilterItem) => {
      updateGeniusUrlParams(state => ({
        ...state,
        filters: {
          ...state.filters,
          [filter.field]: filter.filterValue,
        },
      }))
    },
    [updateGeniusUrlParams],
  )

  const removeFilter = useCallback(
    (field: FieldName) => {
      updateGeniusUrlParams(state => {
        const updatedFilters = { ...state.filters }
        delete updatedFilters[field]

        return {
          ...state,
          filters:
            Object.keys(updatedFilters).length === 0 ? null : updatedFilters,
        }
      })
    },
    [updateGeniusUrlParams],
  )

  const resetSort = useCallback(() => {
    updateGeniusUrlParams(state => ({ ...state, sort: null }))
  }, [updateGeniusUrlParams])

  const setSort = useCallback(
    (sort: Sort) => {
      updateGeniusUrlParams(state => ({ ...state, sort }))
    },
    [updateGeniusUrlParams],
  )

  const setPage = useCallback(
    (currentPage: number) => {
      updateGeniusUrlParams(state => ({ ...state, currentPage }))
    },
    [updateGeniusUrlParams],
  )

  return useMemo<ReturnType<UseGeniusUrlStateHook>>(
    () => ({
      urlState,
      addFilter,
      removeFilter,
      setSort,
      resetSort,
      setPage,
    }),
    [urlState, addFilter, removeFilter, setSort, resetSort, setPage],
  )
}
