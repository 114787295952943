import React from 'react'
import type { PropsWithChildren } from 'react'
import { Accordion, AccordionItem, Stack, useColor } from '_BRIGHT_/components'

import type { GeniusListViewProps } from '_BRIGHT_/components/Genius'
import { ItemsCounter, ListViewItem } from './sections'
import { Box } from '@chakra-ui/react'
import {
  useDrawerContext,
  useGeniusStateContext,
  useGeniusConfigContext,
} from '_BRIGHT_/components/Genius/hooks'

// @TODO Genius - add GeniusListText context to handle the fontSize (level) and text wrap (details in the 2 comments below) - https://smartpension.atlassian.net/browse/T2-1176
// @TODO Genius - meta data TEXT has always to be visible (text wrap) (this should be handled via a GeniusListText context) - https://smartpension.atlassian.net/browse/T2-1176
// @TODO Genius - Summary section text has to wrap only when expanded (this should be handled via a GeniusListText context) - https://smartpension.atlassian.net/browse/T2-1176
// @TODO Genius - Text alignment in summary and details items - https://smartpension.atlassian.net/browse/T2-1177

export const ListView = <SummaryProps, ButtonsProps>({
  hasCollapsedGutters,
  dataset,
  SummaryRenderer,
  MetadataItemRenderer,
  buttonsRendererList,
  totalResults,
}: PropsWithChildren<
  GeniusListViewProps<SummaryProps, ButtonsProps>
>): JSX.Element => {
  const { actions } = useDrawerContext()
  const {
    body: { itemsCounterLabels },
  } = useGeniusConfigContext()
  const bulkSelectionAction = actions.get('selectAll')
  const { settings, dispatch, selectedItems } = useGeniusStateContext()
  const hoverColor = useColor('active')

  return (
    <Stack space="sm" data-testid="genius-list-view">
      <Box width="full">
        {dataset.map(({ id, summaryProps, metadata }, index) => (
          <Accordion
            /**
             * Modified unique Key
             */
            key={id + String(index)}
            borderColor="borderSecondary"
          >
            <AccordionItem _hover={{ bg: hoverColor }} borderWidth={0}>
              {({ isExpanded }) => (
                <ListViewItem
                  hasCollapsedGutters={hasCollapsedGutters}
                  isExpanded={isExpanded}
                  hasBulkEdit={Boolean(bulkSelectionAction)}
                  isChecked={Boolean(selectedItems[id])}
                  metadata={metadata}
                  buttonsRendererList={buttonsRendererList}
                  id={id}
                  summaryProps={summaryProps}
                  SummaryRenderer={SummaryRenderer}
                  hoverColor={hoverColor}
                  MetadataItemRenderer={MetadataItemRenderer}
                  settings={settings}
                  dispatch={dispatch}
                />
              )}
            </AccordionItem>
          </Accordion>
        ))}
      </Box>

      {itemsCounterLabels && (
        <Box width="full" px={{ base: 'none', sm: 'sm' }}>
          <ItemsCounter
            count={totalResults}
            singularLabel={itemsCounterLabels.singular}
            pluralLabel={itemsCounterLabels.plural}
          />
        </Box>
      )}
    </Stack>
  )
}
