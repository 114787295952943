import type { BaseInputFieldProps } from '_BRIGHT_/components/Forms/BaseInputField'
import { BaseInputField } from '_BRIGHT_/components/Forms/BaseInputField'
import type { FormErrors } from '_BRIGHT_/components/Forms/common'
import type { FormControlLabelProps } from '_BRIGHT_/components/Forms/FormControl/FormControl'
import {
  FormControl,
  FormControlLabel,
} from '_BRIGHT_/components/Forms/FormControl/FormControl'
import { Adjacent } from '_BRIGHT_/components'
import type { Optional } from '_BRIGHT_/utils'
import { Box, FormControl as ChakraFormControl } from '@chakra-ui/react'
import * as R from 'ramda'
import type { FC } from 'react'
import React from 'react'
import {
  DAY_DIGITS,
  MONTH_DIGITS,
  YEAR_DIGITS,
  useDateInputs,
} from './useDateInputs'
import type { UseDateInputsArgs } from './useDateInputs'

export type DateInputProps = Partial<UseDateInputsArgs> & {
  dayLabel: string
  description?: string
  errors?: FormErrors
  id: string
  isRequired?: boolean
  label?: string
  monthLabel: string
  yearLabel: string
}

export const DAY_TEST_ID = 'day-container'
export const MONTH_TEST_ID = 'month-container'
export const YEAR_TEST_ID = 'year-container'

type DateInputField = Pick<BaseInputFieldProps, 'isInvalid'> &
  Pick<DateInputProps, 'id' | 'isRequired'> &
  Optional<FormControlLabelProps, 'label'> &
  Pick<BaseInputFieldProps, 'value' | 'onChange'> & {
    characters: number
  }

const DateInputField: FC<DateInputField> = ({
  isInvalid,
  id,
  label,
  value,
  onChange,
  characters,
  isRequired,
}) => (
  <ChakraFormControl id={id}>
    <Box mb="2xs">
      <FormControlLabel
        label={label}
        labelVariant="small"
        useOptionalSuffix={false}
        isRequired={isRequired}
      />
    </Box>
    <BaseInputField
      isInvalid={isInvalid}
      value={value}
      type="text"
      inputMode="numeric"
      pattern="[0-9]*"
      onChange={onChange}
      maxLength={characters}
      characters={characters}
      isRequired={isRequired}
    />
  </ChakraFormControl>
)

const DateInput: FC<DateInputProps> = ({
  dateFormat = 'DD-MM-YYYY',
  dayLabel,
  errors,
  description,
  id,
  isRequired,
  label,
  monthLabel,
  onChange = () => null,
  yearLabel,
  value = '',
}) => {
  const {
    day,
    month,
    year,
    dayPosition,
    monthPosition,
    yearPosition,
    isFilled,
    isValid,
    handleChange,
  } = useDateInputs({
    dateFormat,
    value,
    onChange,
  })

  const isInvalid = (isFilled && !isValid) || (errors && !R.isEmpty(errors))

  const orderedFields = []

  orderedFields[dayPosition] = (
    <Box data-testid={DAY_TEST_ID} key={`${id}-day`}>
      <DateInputField
        characters={DAY_DIGITS}
        label={dayLabel}
        isInvalid={isInvalid}
        isRequired={isRequired}
        value={day?.toString() || ''}
        onChange={e => handleChange(e, 'day')}
        id={`${id}-day`}
      />
    </Box>
  )

  orderedFields[monthPosition] = (
    <Box data-testid={MONTH_TEST_ID} key={`${id}-month`}>
      <DateInputField
        characters={MONTH_DIGITS}
        label={monthLabel}
        isInvalid={isInvalid}
        isRequired={isRequired}
        value={month?.toString() || ''}
        onChange={e => handleChange(e, 'month')}
        id={`${id}-month`}
      />
    </Box>
  )

  orderedFields[yearPosition] = (
    <Box data-testid={YEAR_TEST_ID} key={`${id}-year`}>
      <DateInputField
        characters={YEAR_DIGITS}
        label={yearLabel}
        isInvalid={isInvalid}
        isRequired={isRequired}
        value={year?.toString() || ''}
        onChange={e => handleChange(e, 'year')}
        id={`${id}-year`}
      />
    </Box>
  )

  return (
    <FormControl
      isInvalid={isInvalid}
      isRequired={isRequired}
      id={id}
      as="fieldset"
      label={label}
      description={description}
      errors={errors}
    >
      <Adjacent space="sm">{orderedFields}</Adjacent>
    </FormControl>
  )
}

export { DateInput }
