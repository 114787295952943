import { useColor } from '_BRIGHT_/components'
import type { SystemProps } from '@chakra-ui/system'

export type UseOutlineCardStyles = () => {
  bg: SystemProps['color']
  border: SystemProps['border']
  borderRadius: SystemProps['borderRadius']
  overflow: SystemProps['overflow']
  boxSize: SystemProps['boxSize']
  borderColor: SystemProps['color']
}

export const useOutlineCardStyles: UseOutlineCardStyles = () => {
  const borderColor = useColor('borderSecondary')
  const bg = useColor('core')

  return {
    bg,
    border: '1px',
    borderRadius: 'lg',
    overflow: 'hidden',
    boxSize: '100%',
    borderColor,
  }
}
