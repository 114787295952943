import React from 'react'
import type { FC } from 'react'
import { Icon } from '@chakra-ui/react'
import type { FunctionalIconSvgProps } from '_BRIGHT_/components/Icons/FunctionalIcon/FunctionalIcon'

export const FunctionalVisible: FC<FunctionalIconSvgProps> = ({
  color,
  size,
  height,
  strokeWidth,
  ariaHidden,
}) => (
  <Icon
    viewBox="0 0 24 24"
    fill="none"
    color={color}
    width={size}
    height={height || size}
    aria-hidden={ariaHidden}
  >
    <path
      d="M11.9841 5.25012C8.33067 5.25012 4.58301 7.36465 1.6327 11.5937C1.54849 11.7158 1.50234 11.86 1.50009 12.0083C1.49783 12.1565 1.53958 12.3021 1.62004 12.4267C3.88692 15.9751 7.58442 18.7501 11.9841 18.7501C16.336 18.7501 20.1094 15.9667 22.3805 12.4103C22.4591 12.2882 22.5009 12.146 22.5009 12.0008C22.5009 11.8556 22.4591 11.7135 22.3805 11.5914C20.1043 8.07575 16.3032 5.25012 11.9841 5.25012Z"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 15.7499C14.0711 15.7499 15.75 14.0709 15.75 11.9999C15.75 9.92881 14.0711 8.24988 12 8.24988C9.92893 8.24988 8.25 9.92881 8.25 11.9999C8.25 14.0709 9.92893 15.7499 12 15.7499Z"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      strokeMiterlimit={10}
    />
  </Icon>
)
