import { Box, Link as ChakraLink } from '@chakra-ui/react'
import { withPalette, useColor, Text } from '_BRIGHT_/components'
import { Grid, GridItem } from '_BRIGHT_/components/Layout/Grid'
import React from 'react'
import type { FC } from 'react'
import { useShadow, isExternalLink, isHashLink } from '_BRIGHT_/utils'
import { Link as RouterLink } from 'react-router-dom'

type LinksItemProps = {
  content: string
  href: string
  isActive?: boolean
}

const BaseLinksItem: FC<LinksItemProps> = ({
  content,
  href,
  isActive = false,
}) => {
  const bg = useColor('core')
  const hoverBg = useColor('active')
  const activeBg = useColor('core', 'highlight')
  const focusShadow = useShadow('focus')
  const isHash = isHashLink(href)
  const isExternal = isExternalLink(href)
  const as = isExternal || isHash ? undefined : RouterLink

  return (
    <GridItem width="full">
      <ChakraLink
        as={as}
        to={href}
        width="full"
        display="block"
        verticalAlign="top"
        bg={bg}
        _focus={{ boxShadow: `inset ${focusShadow}` }}
        _hover={{ bg: isActive ? activeBg : hoverBg }}
        _active={{ bg: activeBg }}
        textDecoration="none"
        href={href}
        py={'sm'}
        px={{ base: 'md', sm: 'lg' }}
        aria-current={isActive ? 'page' : undefined}
      >
        <Text as="b" level="sm" variant={isActive ? 'primary' : 'secondary'}>
          {content}
        </Text>
      </ChakraLink>
    </GridItem>
  )
}

const BaseLinksItemWithPalette = withPalette<LinksItemProps>(BaseLinksItem)

export const LinksItem: FC<LinksItemProps> = props => {
  return props.isActive ? (
    <BaseLinksItemWithPalette {...props} palette="highlight" />
  ) : (
    <BaseLinksItem {...props} />
  )
}

export const Links: FC = ({ children }) => {
  return (
    <Box flexGrow={1} py={{ base: '3xl', sm: '4xl' }}>
      <Grid as="ul" wrap>
        {children}
      </Grid>
    </Box>
  )
}
