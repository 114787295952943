import { FullMetadata, Genius, GeniusFieldsConfig } from '_BRIGHT_/components'
import {
  buttonsPropsMunger,
  buttonsRendererList,
  summaryPropsMunger,
  SummaryRenderer,
} from './renderers'
import { useGeniusVariables } from './hooks/useGeniusVariables'
import { useCompaniesCountQuery, useCompaniesQuery } from 'redux/query'
import { CompanyFilter, CompanySort } from 'redux/query/companies/companies'
import { geniusConfig } from './config'
import { MetadataItemRenderer } from 'components/GeniusRenderers'
import { GeniusMetadataItemWithId } from 'lib/cubeJs'

export const SelectClientGenius = () => {
  const {
    variables: { filter, offset, sort, limit },
    onGeniusStateChange,
    skip,
  } = useGeniusVariables()

  const {
    isLoading: isLoadingCount,
    isFetching: isFetchingCount,
    data: total,
  } = useCompaniesCountQuery(filter as CompanyFilter)

  const {
    isLoading,
    data: dataset,
    isFetching,
    isError,
  } = useCompaniesQuery(
    {
      offset,
      limit,
      filter: filter as CompanyFilter,
      sort: sort as CompanySort,
    },
    {
      skip: isLoadingCount || skip,
    },
  )

  return (
    <Genius
      id="companies"
      summaryPropsMunger={summaryPropsMunger}
      buttonsPropsMunger={buttonsPropsMunger}
      SummaryRenderer={SummaryRenderer}
      MetadataItemRenderer={MetadataItemRenderer}
      metadataListMunger={metadataListMunger}
      buttonsRendererList={buttonsRendererList}
      isLoadingData={skip || isFetching || isLoading}
      dataset={dataset}
      datasetError={isError}
      onStateChange={onGeniusStateChange}
      limit={limit}
      totalResults={total}
      isLoadingGeniusConfig={isLoadingCount || isFetchingCount}
      geniusConfigError={null}
      geniusConfigFromQuery={geniusConfig}
    />
  )
}

/**
 * metadataListMunger allows us to tag an invalid value
 * before it reaches the MetadataItemRenderer
 * this needs to happen upstream since some values are
 * marked and not visible in the fields config
 *
 * in the cube value map
 * if hasMissingPaymentMethod is true it will resolve as 'invalid--'
 * else will resolve as ''
 */
const metadataListMunger: (
  metaData: FullMetadata<GeniusMetadataItemWithId>,
  fields: GeniusFieldsConfig,
) => FullMetadata<GeniusMetadataItemWithId> = m => {
  return {
    ...m,
    directDebitState: `${m.hasMissingPaymentMethod}${m.directDebitState}`,
  }
}
