import type { FC } from 'react'
import React from 'react'
import type { HiFiIconPathProps } from '_BRIGHT_/components/Icons'

const Users: FC<HiFiIconPathProps> = ({
  primaryColor,
  secondaryColor,
  baseColor,
  strokeWidth,
  hasShadow,
}) => {
  return (
    <>
      {hasShadow && (
        <>
          <path
            opacity="0.2"
            d="M14.5 18C8.33909 18 4.42414 22.0427 4.03244 27.9994C3.99621 28.5505 4.44772 29 5 29H24C24.5523 29 25.0038 28.5505 24.9676 27.9994C24.5759 22.0427 20.6609 18 14.5 18Z"
            fill={primaryColor}
          />
          <path
            opacity="0.2"
            d="M14.5 5C12.7761 5 11.1228 5.68482 9.90381 6.90381C8.68482 8.12279 8 9.77609 8 11.5C8 13.2239 8.68482 14.8772 9.90381 16.0962C11.1228 17.3152 12.7761 18 14.5 18C16.2239 18 17.8772 17.3152 19.0962 16.0962C20.3152 14.8772 21 13.2239 21 11.5C21 9.77609 20.3152 8.12279 19.0962 6.90381C17.8772 5.68482 16.2239 5 14.5 5Z"
            fill={primaryColor}
          />
        </>
      )}
      <path
        d="M6 8.5C6 9.95869 6.57946 11.3576 7.61091 12.3891C8.64236 13.4205 10.0413 14 11.5 14C12.9587 14 14.3576 13.4205 15.3891 12.3891C16.4205 11.3576 17 9.95869 17 8.5C17 7.04131 16.4205 5.64236 15.3891 4.61091C14.3576 3.57946 12.9587 3 11.5 3C10.0413 3 8.64236 3.57946 7.61091 4.61091C6.57946 5.64236 6 7.04131 6 8.5V8.5Z"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 26C2 23.6131 3.00089 21.3239 4.78249 19.636C6.56408 17.9482 8.98044 17 11.5 17C12.7101 17 13.8964 17.2187 15 17.6331"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 14.5C19 15.6935 19.4741 16.8381 20.318 17.682C21.1619 18.5259 22.3065 19 23.5 19C24.6935 19 25.8381 18.5259 26.682 17.682C27.5259 16.8381 28 15.6935 28 14.5C28 13.3065 27.5259 12.1619 26.682 11.318C25.8381 10.4741 24.6935 10 23.5 10C22.3065 10 21.1619 10.4741 20.318 11.318C19.4741 12.1619 19 13.3065 19 14.5Z"
        fill={baseColor}
        stroke={secondaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.9433 27H16.0567C16.2582 25.2559 16.9872 23.7786 18.1234 22.7332C19.2779 21.6712 20.9124 21 23 21C25.0876 21 26.7221 21.6712 27.8766 22.7332C29.0128 23.7786 29.7418 25.2559 29.9433 27Z"
        fill={baseColor}
        stroke={secondaryColor}
        strokeWidth={strokeWidth}
      />
    </>
  )
}

export { Users }
