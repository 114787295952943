import { useDropdownStyles } from '_BRIGHT_/components/Overlays/Dropdown'
import { Text } from '_BRIGHT_/components/Typography'
import { Box, List } from '@chakra-ui/react'
import { useListBox, mergeProps } from 'react-aria'
import type { SelectState } from '@react-stately/select'
import type { FocusStrategy } from '@react-types/shared'
import type {
  FC,
  ReactChild,
  ReactFragment,
  ReactPortal,
  RefObject,
} from 'react'
import React from 'react'
import type {
  Option,
  OptionListContentProps,
} from '_BRIGHT_/components/Forms/common'
import { PhoneListItem } from './PhoneListItem'
import {
  EmptyOptionListItem,
  LoadingOptionListItem,
  OptionListItem,
} from './OptionListItem'

export type OptionListProps<T> = OptionListContentProps & {
  listBoxRef: RefObject<HTMLUListElement>
  state: SelectState<T>
  label?: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined
  isClearable?: boolean
  autoFocus?: boolean | FocusStrategy
  type?: 'select' | 'phone'
  shouldUseVirtualFocus?: boolean
  isLoading?: boolean
  isEmpty?: boolean
}

const OptionListLabel: FC = ({ children, ...labelProps }) => (
  <Box py="sm" px="md" {...labelProps}>
    <Text level="xs" as="strong">
      {children}
    </Text>
  </Box>
)

export const OptionList: FC<OptionListProps<Option>> = ({
  listBoxRef,
  state,
  label,
  isClearable,
  type = 'select',
  autoFocus = 'first',
  isLoading = false,
  isEmpty = false,
  shouldUseVirtualFocus = false,
  noResultsLabel = 'No results found',
  loadingLabel = 'Loading...',
  ...menuProps
}) => {
  const { maxHeight } = useDropdownStyles()
  const { listBoxProps, labelProps } = useListBox(
    {
      autoFocus,
      disallowEmptySelection: !isClearable,
      isLoading,
      shouldUseVirtualFocus,
      ...menuProps,
    } as any,
    state,
    listBoxRef,
  )

  const ListItem = type === 'phone' ? PhoneListItem : OptionListItem

  return (
    <>
      <List
        {...mergeProps(listBoxProps, menuProps)}
        outline="none"
        ref={listBoxRef}
        maxHeight={maxHeight}
        width="100%"
        paddingY="sm"
        overflowY="auto"
        onBlur={e => e.preventDefault()}
      >
        {label && <OptionListLabel {...labelProps}>{label}</OptionListLabel>}
        {isEmpty && <EmptyOptionListItem>{noResultsLabel}</EmptyOptionListItem>}
        {isLoading ? (
          <LoadingOptionListItem>{loadingLabel}</LoadingOptionListItem>
        ) : (
          [...state.collection].map(item => (
            <ListItem
              key={item.key}
              item={item}
              state={state}
              shouldUseVirtualFocus={shouldUseVirtualFocus}
            />
          ))
        )}
      </List>
    </>
  )
}
