import { BaseSpinner } from '_BRIGHT_/components/Spinners'
import type { Size, ButtonVariant } from '_BRIGHT_/components/Buttons/common'
import { spinnerSizeMap } from '_BRIGHT_/components/Buttons/common'
import type { OnClick } from '_BRIGHT_/utils'
import { Button as ChakraButton } from '@chakra-ui/react'
import type { FC, ReactElement, AriaAttributes } from 'react'
import React from 'react'
import { useButtonStyles } from '_BRIGHT_/components/Buttons/BaseButton/useButtonStyles'
import { useLinkStyles } from '_BRIGHT_/components/Typography/Link/useLinkStyles'
import type {
  LinkLevel,
  LinkVariant,
} from '_BRIGHT_/components/Typography/Link/Link'
import {
  isLinkVariant,
  withLinkButtonPalette,
} from '_BRIGHT_/components/Typography/Link/Link'
import { withPalette } from '_BRIGHT_/components'

export type BaseButtonProps = {
  type?: 'button' | 'submit'
  onClick?: OnClick
  size?: Size
  hasPaddingX?: boolean
  leftIcon?: ReactElement
  rightIcon?: ReactElement
  ariaLabel?: string
  ariaDisabled?: AriaAttributes['aria-disabled']
  ariaCurrent?: AriaAttributes['aria-current']
  buttonRef?: React.LegacyRef<HTMLButtonElement>
  ariaExpanded?: AriaAttributes['aria-expanded']
  isLoading?: boolean
  variant: ButtonVariant | LinkVariant
  isFullWidthForMobile?: boolean
  isFullWidthForDesktop?: boolean
  level?: LinkLevel
  borderRadius?: 'full' | 'md'
}

const ProtoButton: FC<BaseButtonProps> = ({
  type = 'button',
  onClick,
  size = 'md',
  children,
  leftIcon,
  rightIcon,
  ariaLabel,
  ariaDisabled,
  ariaCurrent,
  buttonRef,
  ariaExpanded,
  variant,
  hasPaddingX = true,
  isLoading = false,
  isFullWidthForMobile = true,
  isFullWidthForDesktop = false,
  level = 'sm',
  borderRadius = 'md',
}) => {
  const spinnerSize = spinnerSizeMap[size]
  const buttonStyles = useButtonStyles({
    size,
    isFullWidthForMobile,
    isFullWidthForDesktop,
    hasPaddingX,
    variant,
    borderRadius,
  })
  const linkStyles = useLinkStyles(variant, level)
  const {
    borderWidth,
    themeSize,
    backgroundColor,
    color,
    hoverColor,
    hoverBackgroundColor,
    focusBackgroundColor,
    activeColor,
    boxShadow,
    iconSize,
    iconLineHeight,
    ...restStyles
  } = isLinkVariant(variant) ? linkStyles : buttonStyles

  return (
    <ChakraButton
      type={type}
      color={color}
      backgroundColor={backgroundColor}
      border={borderWidth}
      height={themeSize}
      minWidth={themeSize}
      _hover={{ color: hoverColor, bg: hoverBackgroundColor }}
      _active={{ bg: activeColor }}
      _focus={{ bg: focusBackgroundColor, boxShadow }}
      leftIcon={leftIcon}
      rightIcon={rightIcon}
      onClick={onClick}
      aria-label={ariaLabel}
      aria-disabled={ariaDisabled}
      aria-current={ariaCurrent}
      ref={buttonRef}
      aria-expanded={ariaExpanded}
      variant={variant}
      isLoading={isLoading && !isLinkVariant(variant)}
      {...(isLoading && {
        _disabled: {
          bg: backgroundColor,
          _hover: { bg: backgroundColor },
          cursor: 'not-allowed',
        },
      })}
      spinner={
        <BaseSpinner
          size={spinnerSize}
          color={color}
          emptyColor={backgroundColor}
        />
      }
      {...restStyles}
    >
      {children}
    </ChakraButton>
  )
}

const ProtoButtonWithPalette = withPalette<BaseButtonProps>(ProtoButton)

export const BaseButton: FC<BaseButtonProps> = props =>
  withLinkButtonPalette({
    linkComponent: ProtoButton,
    buttonComponent: ProtoButtonWithPalette,
    props,
  })
