import { Block, Link } from '_BRIGHT_/components'
import type { FC } from 'react'
import React from 'react'
import { Link as ReactRouterLink, useNavigate } from 'react-router-dom'

export type BackBlockProps = {
  text: string
  href?: string
  to?: string
}

const BackBlock: FC<BackBlockProps> = ({ href = '', text, to }) => {
  const navigate = useNavigate()

  return (
    <Block palette="baseTwo" paddingTop="lg">
      <Link
        leftIcon="FunctionalArrowLeft"
        href={href}
        {...(to &&
          text !== 'Back' && {
            to,
            as: ReactRouterLink,
          })}
        {...(text === 'Back' && {
          onClick: () => navigate(-1),
        })}
      >
        {text}
      </Link>
    </Block>
  )
}

export { BackBlock }
