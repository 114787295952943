import type { GeniusConfig } from '_BRIGHT_/generated/generated-bright-components'

export const actions: GeniusConfig['actions'] = [
  {
    actionType: 'sort',
    title: 'Sort',
    sortByLabel: 'Sort By',
    sortDirectionLabel: 'Sort order',
    confirmButtonLabel: 'Save',
    cancelButtonLabel: 'Cancel',
    sortDirectionOptions: [
      {
        label: 'Ascending (A-Z)',
        value: 'ASC',
      },
      {
        label: 'Descending (Z-A)',
        value: 'DESC',
      },
    ],
  },
  {
    actionType: 'settings',
    title: 'Settings',
    description: 'Choose which details you want to be displayed at all times.',
    confirmButtonLabel: 'Save',
    cancelButtonLabel: 'Cancel',
    dragCancelMessagePrefix:
      'Movement cancelled. The item has returned to its starting position of',
    dragEndMessagePrefix:
      'You have dropped the item. You have moved the item from position',
    dragHandleTitlePrefix: 'Drag to re-order',
    dragHandleUsageInstructions:
      'Press space bar to start a drag. When dragging you can use the arrow keys to move the item around and escape to cancel. Some screen readers may require you to be in focus mode or to use your pass through key',
    dragStartMessagePrefix: 'You have lifted an item in position',
    dragUpdateMessagePrefix: 'You have moved the item from position',
  },
  {
    actionType: 'filter',
    title: 'Filter',
    cancelButtonLabel: 'Clear',
    confirmButtonLabel: 'Filter',
  },

  // {
  //   actionType: 'customAction',
  //   title: 'Custom Action',
  //   // itemPrefix: 'Filter By',
  // },
  // {
  //   actionType: 'download',
  //   title: 'Download',
  //   description: 'Do you want to download a CSV file?',
  //   cancelButtonLabel: 'Cancel',
  //   confirmButtonLabel: 'Filter',
  // },
  // {
  //   actionType: 'bulkEdit',
  //   title: 'Edit',
  //   itemPrefix: 'Change',
  //   confirmButtonLabel: 'Save',
  //   cancelButtonLabel: 'Cancel',
  // },
  // {
  //   actionType: 'selectAll',
  //   title: 'Select All',
  // },
]
