import { GeniusBodyConfig } from '_BRIGHT_/generated/generated-bright-components'

export const body: GeniusBodyConfig = {
  bulkEdit: {
    checkboxLabelPrefix: 'Select',
    checkboxLabelSuffix: 'Clients',
  },
  itemButtons: [
    {
      label: 'Sign in',
    },
  ],
  itemsCounterLabels: {
    singular: 'Item',
    plural: 'Items',
  },
  appliedActionTags: {
    clearButtonLabel: 'Clear',
  },
  emptyDatasetContent: '',
}
