import type { PropsWithChildren } from 'react'
import React, { useMemo, memo } from 'react'
import { Box, Grid, GridItem } from '@chakra-ui/react'
import {
  MetadataSection,
  BulkSelectionCheckbox,
  ButtonsSection,
  ListViewItemAccordionButton,
  ListViewItemAccordionIcon,
  ListViewItemAccordionPanel,
} from './'
import { Frame, useColor } from '_BRIGHT_/components'
import { getCollapsedMetadataItems } from '_BRIGHT_/components/Genius/components/ListView/util'
import type { GeniusListViewDataItem } from '_BRIGHT_/components/Genius/types'
import type { GeniusStateContextValue } from '_BRIGHT_/components/Genius/context'
import type { GeniusListViewProps } from '_BRIGHT_/components/Genius/types'
import type { UseColor } from '_BRIGHT_/components/PaletteProvider'
import type { UseGeniusStateReturnedValue } from '_BRIGHT_/components/Genius/hooks'
import { dispatchToggleSelectedItem } from '_BRIGHT_/components/Genius/hooks'
import { useShadow } from '_BRIGHT_/utils'

type ListViewItemProps<SummaryProps, ButtonsProps> = Pick<
  GeniusListViewProps<SummaryProps, ButtonsProps>,
  'SummaryRenderer' | 'MetadataItemRenderer' | 'buttonsRendererList'
> &
  Pick<
    GeniusListViewDataItem<SummaryProps, ButtonsProps>,
    'id' | 'summaryProps' | 'metadata'
  > & {
    isChecked: boolean
    isExpanded: boolean
    hasCollapsedGutters: boolean
    settings: GeniusStateContextValue['settings']
    hasBulkEdit: boolean
    hoverColor: ReturnType<UseColor>
    dispatch: UseGeniusStateReturnedValue['dispatch']
  }

// @TODO Genius - refactor all prefix and suffix dynamic translations - https://smartpension.atlassian.net/browse/T2-1175

const SimpleListViewItem = <SummaryProps, ButtonsProps>({
  isChecked,
  isExpanded,
  hasCollapsedGutters,
  hasBulkEdit,
  metadata: { itemsProps, buttonsProps },
  buttonsRendererList,
  id,
  summaryProps,
  SummaryRenderer,
  MetadataItemRenderer,
  settings,
  dispatch,
}: PropsWithChildren<
  ListViewItemProps<SummaryProps, ButtonsProps>
>): JSX.Element => {
  const collapsedMetadataItems = useMemo(
    () => getCollapsedMetadataItems(itemsProps, settings),
    [itemsProps, settings],
  )
  const buttonsComputedList = useMemo(
    () =>
      buttonsProps
        ? typeof buttonsRendererList === 'function'
          ? buttonsRendererList(buttonsProps)
          : buttonsRendererList
        : null,
    [buttonsRendererList, buttonsProps],
  )

  const shadow = useShadow('focus')
  const boxShadow = `${shadow} inset`
  const backgroundColor = useColor('core')
  const gutterPadding = hasCollapsedGutters ? 'none' : 'sm'

  const { templateRows, templateColumns, rightGridColumnPaddingProps } =
    useMemo(() => {
      const templateRows = hasBulkEdit ? 'auto auto' : 'auto'
      const templateColumns = hasBulkEdit ? 'auto 1fr' : 'auto'
      const rightGridColumnPaddingProps = {
        paddingLeft: hasBulkEdit ? 'none' : gutterPadding,
        paddingRight: gutterPadding,
      }

      return {
        templateRows,
        templateColumns,
        rightGridColumnPaddingProps,
      }
    }, [hasBulkEdit, gutterPadding])

  return (
    <Grid templateRows={templateRows} templateColumns={templateColumns}>
      {hasBulkEdit && (
        <GridItem rowSpan={1} colSpan={1}>
          <Box paddingLeft={gutterPadding} paddingTop="md">
            <BulkSelectionCheckbox
              id={id}
              primaryLabel={summaryProps.primaryLabel}
              isChecked={isChecked}
              onChange={() => dispatchToggleSelectedItem(dispatch, id)}
            />
          </Box>
        </GridItem>
      )}
      <GridItem rowSpan={1} colSpan={1}>
        <ListViewItemAccordionButton
          boxShadow={boxShadow}
          {...rightGridColumnPaddingProps}
        >
          <SummaryRenderer {...summaryProps} />
          <ListViewItemAccordionIcon isExpanded={isExpanded} />
        </ListViewItemAccordionButton>
      </GridItem>
      {hasBulkEdit && <GridItem rowSpan={1} colSpan={1} bg={backgroundColor} />}
      <GridItem rowSpan={1} colSpan={1}>
        <Frame palette="baseTwo">
          <Box {...rightGridColumnPaddingProps} width="full">
            {!isExpanded && !!collapsedMetadataItems.length && (
              <MetadataSection
                metadataItemsProps={collapsedMetadataItems}
                MetadataItemRenderer={MetadataItemRenderer}
              />
            )}
            <ListViewItemAccordionPanel>
              <MetadataSection
                metadataItemsProps={itemsProps}
                MetadataItemRenderer={MetadataItemRenderer}
              />

              {!!buttonsComputedList?.length && (
                <ButtonsSection
                  buttonsRendererList={buttonsComputedList}
                  buttonsProps={buttonsProps}
                />
              )}
            </ListViewItemAccordionPanel>
          </Box>
        </Frame>
      </GridItem>
    </Grid>
  )
}

export const ListViewItem = memo(
  SimpleListViewItem,
) as typeof SimpleListViewItem
