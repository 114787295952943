import type { FC } from 'react'
import React from 'react'
import type { HiFiIconPathProps } from '_BRIGHT_/components/Icons'

const Cash: FC<HiFiIconPathProps> = ({
  primaryColor,
  secondaryColor,
  baseColor,
  strokeWidth,
  hasShadow,
}) => {
  return (
    <>
      {hasShadow && (
        <path
          opacity="0.2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.0603 4C12.839 4 11.9029 5.08501 12.0819 6.2931L13.6683 17.0014C12.6488 16.9703 11.6283 17.4562 11.035 18.399C10.9915 18.468 10.9602 18.5441 10.9378 18.6226L9.10637 25.0492C9.0191 25.3555 8.79122 25.6018 8.49271 25.7126L7.65225 26.0247C6.93764 26.29 6.77073 27.2252 7.34944 27.7214L11.8344 31.5663C12.0894 31.7849 12.4371 31.8609 12.76 31.7686L13.8962 31.4439C14.3985 31.25 14.9325 31.1506 15.4712 31.1507H22.6186C22.8607 31.1507 23.0945 31.0629 23.2768 30.9036L24.3332 29.9802L25.8987 28.6852C26.0058 28.5966 26.0933 28.4866 26.1556 28.3622L26.7268 27.2207C26.8745 26.9254 26.9513 26.5997 26.9513 26.2695C26.9513 25.9393 26.8745 25.6137 26.7268 25.3183L26.3785 24.6216C26.2313 24.3273 26.2384 23.9793 26.3974 23.6911L26.4994 23.5065L26.5013 23.5036C26.7339 23.1543 26.858 22.7441 26.858 22.3245C26.858 21.9147 26.7397 21.5139 26.5175 21.1701L25.979 20.2646C25.89 20.1149 25.7653 19.9938 25.6193 19.9096C25.5601 19.8754 25.499 19.8426 25.4376 19.8098C25.2049 19.6852 24.9691 19.5589 24.8212 19.3457L22.9019 16.5791C22.7473 16.3563 22.5135 16.2113 22.2581 16.1651L23.7206 6.2931C23.8996 5.08501 22.9635 4 21.7422 4H14.0603Z"
          fill={primaryColor}
        />
      )}
      <path
        d="M15.4388 16L14.4518 6.18952C14.4431 6.10788 14.4517 6.02533 14.4771 5.94726C14.5025 5.86919 14.5441 5.79735 14.5991 5.73645C14.6542 5.67556 14.7215 5.62697 14.7966 5.59387C14.8717 5.56076 14.953 5.54389 15.0351 5.54435H23.0851C23.1699 5.54334 23.2539 5.56082 23.3312 5.59559C23.4086 5.63035 23.4774 5.68155 23.5329 5.74563C23.5884 5.80971 23.6293 5.88513 23.6527 5.96662C23.6761 6.04812 23.6815 6.13374 23.6684 6.21752L22.2008 16"
        fill={baseColor}
      />
      <path
        d="M15.4388 16L14.4518 6.18952C14.4431 6.10788 14.4517 6.02533 14.4771 5.94726C14.5025 5.86919 14.5441 5.79735 14.5991 5.73645C14.6542 5.67556 14.7215 5.62697 14.7966 5.59387C14.8717 5.56076 14.953 5.54389 15.0351 5.54435H23.0851C23.1699 5.54334 23.2539 5.56082 23.3312 5.59559C23.4086 5.63035 23.4774 5.68155 23.5329 5.74563C23.5884 5.80971 23.6293 5.88513 23.6527 5.96662C23.6761 6.04812 23.6815 6.13374 23.6684 6.21752L22.2008 16"
        stroke={secondaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.125 16H23L23.5495 16.924C23.6728 17.109 23.7385 17.3264 23.7385 17.5488C23.7385 17.7711 23.6728 17.9885 23.5495 18.1735L23.1995 18.695C23.0894 18.8602 23.025 19.0515 23.0127 19.2497C23.0004 19.4478 23.0407 19.6456 23.1295 19.8232L23.7129 20.9898C23.7911 21.1463 23.8318 21.3189 23.8318 21.4938C23.8318 21.6688 23.7911 21.8413 23.7129 21.9978L23.0467 23.329C22.9135 23.5963 22.8916 23.9055 22.986 24.1888L23.1809 24.7722C23.2474 24.9706 23.2572 25.1836 23.2092 25.3872C23.1611 25.5909 23.0572 25.7771 22.909 25.9248L21.25 27.375H13.3517C12.9221 27.3749 12.4964 27.4569 12.0975 27.6165L9.88086 28.25"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 21.4915L5.78233 20.0728C6.25164 19.8957 6.64858 19.5673 6.9105 19.1395L9.343 13.5138C9.59839 13.0986 9.94068 12.7436 10.3463 12.4732L11.1933 11.9073"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6869 15.3642L9.88319 2.72683C9.86995 2.63659 9.87631 2.54455 9.90184 2.45699C9.92738 2.36942 9.97149 2.28839 10.0312 2.21941C10.0908 2.15044 10.1647 2.09513 10.2477 2.05727C10.3306 2.0194 10.4208 1.99987 10.512 2H19.3227"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.8525 11.1829C18.9389 11.1829 19.0233 11.2084 19.0951 11.2563C19.167 11.3042 19.223 11.3722 19.2563 11.4519C19.2895 11.5315 19.2984 11.6193 19.2819 11.704C19.2654 11.7887 19.2242 11.8667 19.1635 11.9281C19.1028 11.9894 19.0253 12.0315 18.9407 12.0489C18.8562 12.0663 18.7683 12.0583 18.6883 12.0259C18.6083 11.9935 18.5397 11.9381 18.491 11.8668C18.4424 11.7955 18.416 11.7114 18.415 11.625C18.415 11.509 18.4611 11.3977 18.5432 11.3157C18.6252 11.2336 18.7365 11.1875 18.8525 11.1875"
        stroke={secondaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  )
}

export { Cash }
