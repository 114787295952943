import type { FC } from 'react'
import React from 'react'
import type { HiFiIconPathProps } from '_BRIGHT_/components/Icons'

const Enter: FC<HiFiIconPathProps> = ({
  primaryColor,
  secondaryColor,
  baseColor,
  strokeWidth,
  hasShadow,
}) => {
  return (
    <>
      <path
        d="M14 27C14.2529 27 14.596 27 14.9537 27H19"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 5H14.9537C14.596 5 14.2529 5 14 5"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      {hasShadow && (
        <path
          d="M21.4731 21.3669L15.0375 27.6571C14.8417 27.8482 14.609 27.9645 14.3654 27.9931C14.1217 28.0217 13.8767 27.9614 13.6579 27.819C13.4391 27.6766 13.2551 27.4578 13.1265 27.187C12.9978 26.9162 12.9297 26.6041 12.9297 26.2857L12.9297 23.4286L6.63467 23.4286C5.93591 23.4286 5.26578 23.0673 4.77168 22.4244C4.27758 21.7814 4 20.9093 4 20C4 19.0907 4.27758 18.2186 4.77168 17.5756C5.26578 16.9326 5.93592 16.5714 6.63467 16.5714L12.9297 16.5714L12.9297 13.7143C12.9297 13.3959 12.9978 13.0838 13.1265 12.813C13.2551 12.5422 13.4391 12.3234 13.6579 12.181C13.8767 12.0386 14.1217 11.9783 14.3654 12.0069C14.609 12.0355 14.8417 12.1518 15.0375 12.3429L21.4731 18.624C21.6367 18.7837 21.7695 18.9907 21.8609 19.2288C21.9524 19.4668 22 19.7293 22 19.9954C22 20.2616 21.9524 20.524 21.8609 20.7621C21.7695 21.0001 21.6367 21.2072 21.4731 21.3669Z"
          opacity="0.2"
          fill={primaryColor}
          fillRule="evenodd"
          clipRule="evenodd"
        />
      )}
      <path
        d="M20.8152 28.9913L29.3867 27.5053C29.5568 27.4758 29.7125 27.3729 29.8252 27.2154C29.9378 27.0579 29.9999 26.8564 30 26.6478V6.43385C30.0001 6.23538 29.9441 6.04287 29.8413 5.88849C29.7385 5.73411 29.5952 5.62722 29.4352 5.58567L20.8638 3.0192C20.7596 2.99214 20.6518 2.9937 20.5482 3.02377C20.4445 3.05383 20.3478 3.11165 20.2649 3.19297C20.1821 3.27429 20.1153 3.37706 20.0695 3.49375C20.0237 3.61044 19.9999 3.7381 20 3.86737V28.1339C20.0001 28.258 20.0221 28.3807 20.0646 28.4935C20.1071 28.6064 20.1691 28.7069 20.2464 28.7882C20.3237 28.8695 20.4145 28.9297 20.5126 28.9648C20.6108 28.9998 20.714 29.0088 20.8152 28.9913Z"
        fill={baseColor}
        stroke={secondaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.2144 16.5554C23.2852 16.5554 23.3544 16.577 23.4132 16.6174C23.472 16.6578 23.5178 16.7152 23.5447 16.7823C23.5716 16.8494 23.5785 16.9232 23.5644 16.9943C23.5503 17.0654 23.5159 17.1306 23.4656 17.1816C23.4152 17.2326 23.3512 17.2672 23.2817 17.2808C23.2122 17.2945 23.1403 17.2867 23.0751 17.2584C23.0099 17.2301 22.9544 17.1826 22.9156 17.1219C22.8768 17.0612 22.8565 16.9901 22.8573 16.9175C22.8571 16.8694 22.8663 16.8218 22.8842 16.7773C22.9021 16.7328 22.9284 16.6924 22.9616 16.6584C22.9948 16.6244 23.0342 16.5975 23.0776 16.5791C23.121 16.5608 23.1675 16.5514 23.2144 16.5515"
        stroke={secondaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.44433 13.6092L3 13.6092C1.89543 13.6092 1 14.5046 1 15.6092V15.6697V15.7301C1 16.8347 1.89543 17.7301 3 17.7301H9.44434C9.72048 17.7301 9.94434 17.954 9.94434 18.2301V21.7601C9.94433 22.2101 10.4923 22.431 10.8045 22.1069L16.6736 16.0128C16.857 15.8224 16.8605 15.5222 16.6815 15.3276L10.8124 8.94414C10.504 8.60872 9.94433 8.82691 9.94433 9.28255V13.1092C9.94433 13.3854 9.72048 13.6092 9.44433 13.6092Z"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
      />
    </>
  )
}

export { Enter }
