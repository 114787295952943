import React, { useState, useRef } from 'react'
import type { FormEvent, FC } from 'react'
import { Divider, Frame, Button } from '_BRIGHT_/components'
import { BaseCheckbox } from '_BRIGHT_/components/Forms/Checkbox'
import { useShadow } from '_BRIGHT_/utils'
import type { Settings, SettingsItem } from '_BRIGHT_/components/Genius/hooks'
import {
  useGeniusStateContext,
  useGeniusConfigContext,
  dispatchSetSettings,
  useDrawerContext,
} from '_BRIGHT_/components/Genius/hooks'
import { useColor, withPalette } from '_BRIGHT_/components/PaletteProvider'
import { Box } from '@chakra-ui/layout'
import { DragHandle } from './DragHandle'
import type {
  DraggingStyle,
  DragStart,
  DragUpdate,
  DropResult,
  NotDraggingStyle,
  ResponderProvided,
} from 'react-beautiful-dnd'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { Column, Columns } from '_BRIGHT_/components/Layout'
import {
  ActionDrawerButtonsWrapper,
  ActionDrawerView,
} from '_BRIGHT_/components/Genius/components'
import { extractSettingsConfig } from './extractSettingsConfig'
import * as R from 'ramda'

const reorder = (settings: Settings, startIndex: number, endIndex: number) => {
  const reorderedSettings = Array.from(settings)
  const [removed] = reorderedSettings.splice(startIndex, 1)
  reorderedSettings.splice(endIndex, 0, removed)

  return reorderedSettings
}

// @TODO Genius - refactor all prefix and suffix dynamic translations - https://smartpension.atlassian.net/browse/T2-1175
export type SettingsViewBodyProps = {
  confirmButtonLabel: string
  cancelButtonLabel: string
  dragCancelMessagePrefix: string
  dragEndMessagePrefix: string
  dragHandleTitlePrefix: string
  dragHandleUsageInstructions: string
  dragStartMessagePrefix: string
  dragUpdateMessagePrefix: string
  labelsMap: Record<string, string>
  defaultSettings: Settings
}

// @TODO Genius - refactor all prefix and suffix dynamic translations - https://smartpension.atlassian.net/browse/T2-1175
export const BaseSettingsViewBody = ({
  confirmButtonLabel,
  cancelButtonLabel,
  dragCancelMessagePrefix,
  dragEndMessagePrefix,
  dragHandleTitlePrefix,
  dragHandleUsageInstructions,
  dragStartMessagePrefix,
  dragUpdateMessagePrefix,
  labelsMap,
  defaultSettings,
}: SettingsViewBodyProps): JSX.Element => {
  const { settings: settingsContext, dispatch } = useGeniusStateContext()
  const { close } = useDrawerContext()
  const bg = useColor('core')
  const contrastBg = useColor('contrast')
  const draggingShadow = useShadow('md')
  const focusShadow = useShadow('focus')
  const wrapperRef = useRef<HTMLFormElement>(null)

  const [settings, setSettings] = useState<Settings>(
    settingsContext || R.clone(defaultSettings),
  )

  const getSettingStyle = (
    isDragging: boolean,
    draggableStyle: DraggingStyle | NotDraggingStyle | undefined,
  ) => {
    return {
      boxShadow: isDragging ? draggingShadow : undefined,
      ...draggableStyle,
      transform:
        isDragging && draggableStyle?.transform
          ? `${draggableStyle.transform} rotate(2deg)`
          : draggableStyle?.transform,
    }
  }

  const onSelectSetting = (setting: SettingsItem) => () => {
    setSettings((prevSettings: Settings) => {
      const newSettings = R.clone(prevSettings)
      const newSetting = newSettings.find(
        ({ field }: SettingsItem) => field === setting.field,
      ) as SettingsItem
      newSetting.showInSummary = !newSetting.showInSummary
      return newSettings
    })
  }

  const onDragEnd = (
    { destination, source, reason }: DropResult,
    provided: ResponderProvided,
  ) => {
    // @TODO Genius - refactor all prefix and suffix dynamic translations - https://smartpension.atlassian.net/browse/T2-1175
    reason === 'CANCEL' &&
      provided.announce(`${dragCancelMessagePrefix} ${source.index + 1}`)
    if (!destination) return

    setSettings(reorder(settings, source.index, destination.index))

    // @TODO Genius - refactor all prefix and suffix dynamic translations - https://smartpension.atlassian.net/browse/T2-1175
    provided.announce(
      `${dragEndMessagePrefix} ${source.index + 1} to position ${
        destination?.index + 1
      }`,
    )
  }

  const onDragStart = ({ source }: DragStart, provided: ResponderProvided) => {
    provided.announce(`${dragStartMessagePrefix} ${source.index + 1}`)
    /**
     * "transform: translateX(0%)" needs to be removed from Chakra UI Drawer - otherwise it would become a containing
     * block for the dragged item with "position: fixed" calculated internally by react-beautiful-dnd relatively
     * to the viewport. See https://developer.mozilla.org/en-US/docs/Web/CSS/Containing_block for more info
     * */
    wrapperRef.current
      ?.closest<HTMLElement>('[role="dialog"]')
      ?.style.removeProperty('transform')
  }

  const onDragUpdate = (
    { destination, source }: DragUpdate,
    provided: ResponderProvided,
  ) => {
    if (!destination) return
    // @TODO Genius - refactor all prefix and suffix dynamic translations - https://smartpension.atlassian.net/browse/T2-1175
    provided.announce(
      `${dragUpdateMessagePrefix} ${source.index + 1} to position ${
        destination?.index + 1
      }`,
    )
  }

  const onSave = (e: FormEvent) => {
    e.preventDefault()
    dispatchSetSettings(dispatch, settings)
    // @TODO Genius - Refactor to triggerthe close action when the GeniusState gets updated - https://smartpension.atlassian.net/browse/T2-1096
    close()
  }

  return (
    <form onSubmit={onSave} style={{ width: '100%' }} ref={wrapperRef}>
      <DragDropContext
        onDragEnd={onDragEnd}
        onDragStart={onDragStart}
        onDragUpdate={onDragUpdate}
        dragHandleUsageInstructions={dragHandleUsageInstructions}
      >
        <Droppable droppableId="droppable">
          {provided => (
            <Box
              {...provided.droppableProps}
              ref={provided.innerRef}
              data-testid="drop-settings"
              as="ol"
              listStyleType="none"
              w="100%"
            >
              {settings?.map((setting: SettingsItem, i: number) => (
                <Draggable
                  key={setting.field}
                  draggableId={setting.field}
                  index={i}
                >
                  {(provided, snapshot) => (
                    <Box
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={getSettingStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style,
                      )}
                      data-testid={setting.field}
                      marginLeft="-md"
                      as="li"
                    >
                      <Box
                        bg={snapshot.isDragging ? contrastBg : bg}
                        paddingX="md"
                      >
                        <Columns alignY="center">
                          {/* @TODO Genius - how do we handle strings with dynamic values? - https://smartpension.atlassian.net/browse/T2-1175 */}
                          <Column width="content">
                            <Box
                              {...provided.dragHandleProps}
                              title={`${dragHandleTitlePrefix} ${
                                labelsMap[setting.field]
                              }`}
                              padding="md"
                              marginLeft="-md"
                              borderRadius="md"
                              _focus={{
                                boxShadow: focusShadow,
                                outline: 'none',
                              }}
                            >
                              <DragHandle />
                            </Box>
                          </Column>
                          <Column width="fluid">
                            <BaseCheckbox
                              onChange={onSelectSetting(setting)}
                              isChecked={setting.showInSummary}
                              label={labelsMap[setting.field]}
                              space="md"
                            />
                          </Column>
                        </Columns>
                      </Box>
                      {i !== settings.length - 1 && (
                        <Box paddingLeft="md">
                          <Divider />
                        </Box>
                      )}
                    </Box>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>

      <Frame palette="baseTwo" paddingTop="4xl">
        <ActionDrawerButtonsWrapper>
          {/* @TODO Genius - refactor to trigger the close action when the GeniusState gets updated - https://smartpension.atlassian.net/browse/T2-1096 */}
          <Button
            variant="secondaryButton"
            isFullWidthForMobile
            onClick={close}
          >
            {cancelButtonLabel}
          </Button>
          <Button isFullWidthForMobile type="submit">
            {confirmButtonLabel}
          </Button>
        </ActionDrawerButtonsWrapper>
      </Frame>
    </form>
  )
}

const BaseSettingsViewBodyWithPalette =
  withPalette<SettingsViewBodyProps>(BaseSettingsViewBody)

export const SettingsViewBody: FC<SettingsViewBodyProps> = ({ ...props }) => {
  return <BaseSettingsViewBodyWithPalette {...props} palette="baseTwo" />
}

export const SettingsView: FC = () => {
  const { actions, fields } = useGeniusConfigContext()

  if (!actions.settings) return null

  const { title, description, ...settingsContent } = actions.settings
  const { labelsMap, defaultSettings } = extractSettingsConfig(fields)

  return (
    <ActionDrawerView title={title} description={description}>
      <SettingsViewBody
        {...settingsContent}
        defaultSettings={defaultSettings}
        labelsMap={labelsMap}
      />
    </ActionDrawerView>
  )
}
