import { Spinner as ChakraSpinner } from '@chakra-ui/react'
import type { FC } from 'react'
import React from 'react'
import type { FontSize } from '_BRIGHT_/components/Typography/common'

export type SpinnerProps = {
  size?: SpinnerSize
  color: string
  emptyColor: string
  spinnerLabel?: string
}

export type SpinnerSize = Extract<FontSize, 'xl' | 'lg' | 'md' | 'sm' | 'xs'>
type TSpinnerThickness = '2px' | '3px' | '4px' | '5px'
type TSpinnerBoxSize = '47px' | '32px' | '21px' | '14px' | '10px'

const thicknessMap: Record<SpinnerSize, TSpinnerThickness> = {
  xs: '2px',
  sm: '2px',
  md: '3px',
  lg: '4px',
  xl: '5px',
}

const boxSizeMap: Record<SpinnerSize, TSpinnerBoxSize> = {
  xs: '10px',
  sm: '14px',
  md: '21px',
  lg: '32px',
  xl: '47px',
}

export const BaseSpinner: FC<SpinnerProps> = ({
  size = 'md',
  spinnerLabel = 'Loading...',
  color,
  emptyColor,
}) => {
  const thickness = thicknessMap[size]
  const boxSize = boxSizeMap[size]

  return (
    <ChakraSpinner
      thickness={thickness}
      speed="0.8s"
      emptyColor={emptyColor}
      color={color}
      boxSize={boxSize}
      label={spinnerLabel}
      data-testid="spinner"
    />
  )
}
