import type { RawLocation } from './types'

export type DeserializedItem<Item> = Record<Uncapitalize<Extract<keyof Item, string>>, Item[keyof Item]>

export function deserializeItem<T>(item: T): DeserializedItem<T> {
  return Object.entries(item).reduce((result, [key, value]) => {
    const deserializedKey = key.charAt(0).toLowerCase() + key.slice(1)
    return { ...result, [deserializedKey]: value }
  }, {} as DeserializedItem<T>)
}

export type DeserializedSuggestion = DeserializedItem<Omit<RawLocation, 'Text'>> & {
  label: string
}

export function deserializeSuggestion({ Text, ...item }: RawLocation): DeserializedSuggestion {
  return {
    ...deserializeItem(item),
    label: Text,
  }
}

export class AddressApiError extends Error {
  data: Record<number, string>

  constructor({ id, message }: Record<string, string>) {
    super(message)
    this.data = { [Number(id)]: message }
  }
}
