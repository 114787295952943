import { Button } from '_BRIGHT_/components'
import { DateInput, Select, withController } from '_BRIGHT_/components/Forms'
import { ActionDrawerButtonsWrapper } from '_BRIGHT_/components/Genius/components'
import {
  dispatchAddFilter,
  dispatchRemoveFilter,
  useGeniusStateContext,
  useDrawerContext,
} from '_BRIGHT_/components/Genius/hooks'
import type { FilterValueWithoutOperator } from '_BRIGHT_/components/Genius/hooks'
import { Stack } from '_BRIGHT_/components/Layout'
import type { FC } from 'react'
import React from 'react'
import { useForm } from 'react-hook-form'
import type {
  GeniusDateInputConfig,
  SharedFilterComponentProps,
} from '_BRIGHT_/components/Genius'

export const FILTER_BY_DATE_TEST_ID = 'filter-by-date'

export type FilterByDateProps = SharedFilterComponentProps &
  Pick<GeniusDateInputConfig, 'operatorOptions'> &
  GeniusDateInputConfig['dateLabels']

type FilterByDateFormValues = FilterValueWithoutOperator & {
  operator?: string | null
}

const ControlledSelectInput = withController<FilterByDateFormValues>()(Select)
const ControlledDateInput = withController<FilterByDateFormValues>()(DateInput)

export const FilterByDate: FC<FilterByDateProps> = ({
  operatorOptions,
  fieldName,
  confirmButtonLabel,
  cancelButtonLabel,
  selectInputDescription,
  dayInputLabel,
  monthInputLabel,
  yearInputLabel,
}) => {
  const { filters, dispatch } = useGeniusStateContext()
  const { close } = useDrawerContext()
  const initialValue = filters?.[fieldName] || { operator: null, value: '' }
  const { control, setValue, handleSubmit, reset } =
    useForm<FilterByDateFormValues>({
      mode: 'onSubmit',
      criteriaMode: 'all',
      defaultValues: initialValue,
    })

  const applyFilter = (formData: FilterByDateFormValues): void => {
    dispatchAddFilter(dispatch, fieldName, formData)
    // @TODO Genius - Refactor to triggerthe close action when the GeniusState gets updated - https://smartpension.atlassian.net/browse/T2-1096
    close()
  }

  const onClear = () => {
    dispatchRemoveFilter(dispatch, fieldName)
    reset({ value: '', operator: null })
    // @TODO Genius - Refactor to triggerthe close action when the GeniusState gets updated - https://smartpension.atlassian.net/browse/T2-1096
    close()
  }
  const setDate = (value: string) => setValue('value', value)

  return (
    <form
      data-testid={FILTER_BY_DATE_TEST_ID}
      onSubmit={handleSubmit(applyFilter)}
    >
      <Stack space="4xl">
        <ControlledSelectInput
          control={control}
          name="operator"
          description={selectInputDescription}
          items={operatorOptions}
          ariaLabel={selectInputDescription}
        />
        <ControlledDateInput
          control={control}
          name="value"
          dateFormat="DD-MM-YYYY"
          dayLabel={dayInputLabel}
          monthLabel={monthInputLabel}
          yearLabel={yearInputLabel}
          id="value"
          onChange={setDate}
          isRequired
        />
        <ActionDrawerButtonsWrapper>
          <Button
            isFullWidthForMobile
            variant="secondaryButton"
            onClick={onClear}
          >
            {cancelButtonLabel}
          </Button>
          <Button isFullWidthForMobile type="submit">
            {confirmButtonLabel}
          </Button>
        </ActionDrawerButtonsWrapper>
      </Stack>
    </form>
  )
}
