import type { FC } from 'react'
import React from 'react'
import type { HiFiIconPathProps } from '_BRIGHT_/components/Icons'

const Exit: FC<HiFiIconPathProps> = ({
  primaryColor,
  secondaryColor,
  strokeWidth,
  hasShadow,
}) => {
  return (
    <>
      {hasShadow && (
        <path
          opacity="0.2"
          d="M15.8974 31.9807L5.58175 30.4405C5.143 30.3751 4.74143 30.1465 4.45091 29.7967C4.16037 29.4469 4.00028 28.9994 4 28.5363V7.58576C3.99984 7.14528 4.14427 6.71776 4.4092 6.37512C4.67051 6.03717 5.03342 5.80168 5.43879 5.70664L15.7426 3.0498C15.7524 3.04725 15.7623 3.04487 15.7723 3.04264C16.041 2.98255 16.3191 2.98601 16.5863 3.05278C16.8536 3.11956 17.1031 3.24795 17.3167 3.42856C17.5303 3.60917 17.7025 3.83741 17.8208 4.09657C17.9389 4.35548 18.0001 4.6387 18 4.92551V30.0758C17.9998 30.3515 17.943 30.6245 17.8334 30.8752C17.7237 31.1259 17.5638 31.3491 17.3645 31.5297C17.1651 31.7102 16.931 31.8439 16.6779 31.9217C16.4248 31.9995 16.1586 32.0196 15.8974 31.9807Z"
          fill={primaryColor}
        />
      )}
      <path
        d="M24.4874 25.5828C24.1592 25.9191 23.7141 26.108 23.25 26.108H18"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 4.58752H23.25C23.7141 4.58752 24.1592 4.77647 24.4874 5.11279C24.8156 5.44912 25 5.90527 25 6.3809"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0217 27.991L2.736 26.4478C2.53182 26.4172 2.345 26.3103 2.20981 26.1467C2.07462 25.9832 2.00013 25.7739 2 25.5574V4.56592C1.99984 4.35982 2.06705 4.15991 2.1904 3.99959C2.31376 3.83927 2.48578 3.72827 2.67771 3.68512L12.9634 1.01994C13.0884 0.991838 13.2179 0.993458 13.3422 1.02468C13.4666 1.0559 13.5827 1.11594 13.6821 1.20039C13.7814 1.28484 13.8616 1.39156 13.9166 1.51274C13.9716 1.63392 14.0001 1.76649 14 1.90073V27.1006C13.9999 27.2295 13.9735 27.3568 13.9225 27.4741C13.8714 27.5913 13.797 27.6956 13.7043 27.7801C13.6115 27.8645 13.5026 27.927 13.3848 27.9634C13.2671 27.9998 13.1432 28.0092 13.0217 27.991V27.991Z"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 14.0032C10.4009 14.0032 10.304 14.0325 10.2216 14.0874C10.1393 14.1423 10.0752 14.2203 10.0375 14.3115C9.99986 14.4027 9.99028 14.503 10.01 14.5996C10.0297 14.6962 10.0779 14.7848 10.1483 14.8541C10.2188 14.9235 10.3084 14.9704 10.4057 14.989C10.5031 15.0076 10.6038 14.997 10.695 14.9585C10.7863 14.9201 10.864 14.8555 10.9183 14.773C10.9726 14.6905 11.001 14.5939 11 14.4953C11.0001 14.4299 10.9873 14.3652 10.9623 14.3047C10.9372 14.2443 10.9004 14.1894 10.8539 14.1432C10.8075 14.097 10.7523 14.0604 10.6915 14.0354C10.6308 14.0105 10.5657 13.9978 10.5 13.9979"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.3263 13.8613L18.7857 13.8613C17.7995 13.8613 17 14.6608 17 15.647C17 16.6333 17.7995 17.4328 18.7857 17.4328L24.3263 17.4328C24.6024 17.4328 24.8263 17.6566 24.8263 17.9328L24.8263 20.7686C24.8263 21.2174 25.3719 21.4389 25.6848 21.1171L30.6688 15.9913C30.8544 15.8005 30.8579 15.4977 30.6768 15.3026L25.6927 9.9334C25.3836 9.60032 24.8263 9.8191 24.8263 10.2736V13.3613C24.8263 13.6375 24.6024 13.8613 24.3263 13.8613Z"
        stroke={secondaryColor}
        strokeWidth={strokeWidth}
      />
    </>
  )
}

export { Exit }
