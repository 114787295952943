import { Adjacent, Stack, Tag } from '_BRIGHT_/components'
import { useGeniusConfigContext } from '_BRIGHT_/components/Genius/hooks'
import { useIsMobileAndTabletViewport } from '_BRIGHT_/utils'
import type { FC } from 'react'
import React from 'react'
import type { AppliedActionTagsContent } from '..'

export type AppliedActionTagsUIProps = {
  appliedActionTagList: AppliedActionTagsContent[]
  onClear: (tag: AppliedActionTagsContent) => void
  clearButtonLabel: string
}

export const AppliedActionTagsUI: FC<AppliedActionTagsUIProps> = ({
  appliedActionTagList,
  onClear,
  clearButtonLabel,
}) => {
  const { fields, actions } = useGeniusConfigContext()
  const isMobile = useIsMobileAndTabletViewport()
  const AppliedActionTagsWrapper = isMobile ? Stack : Adjacent

  const formatContent = ({
    value,
    operator,
    fieldName,
    actionType,
  }: AppliedActionTagsContent) => {
    const fieldLabel = `${fields[fieldName].label}`
    const withOperator = operator ? `${operator} ` : ''

    if (actionType === 'sort') {
      const sortLabel = actions.sort?.sortDirectionOptions.find(option => {
        return option.value === value
      })?.label

      return `${fieldLabel}: ${sortLabel}`
    }

    return `${fieldLabel}: ${withOperator}${value}`
  }

  return (
    <AppliedActionTagsWrapper space="xs" alignX="start">
      {appliedActionTagList.map((tag: AppliedActionTagsContent) => {
        return (
          <Tag
            onClear={() => onClear(tag)}
            clearButtonLabel={clearButtonLabel}
            key={`${tag.fieldName}-${tag.actionType}`}
          >
            {formatContent(tag)}
          </Tag>
        )
      })}
    </AppliedActionTagsWrapper>
  )
}
