import type { FC } from 'react'
import React from 'react'
import { IconButton } from '_BRIGHT_/components'

type ArrowDirection = 'forward' | 'back'

export type PaginationArrowProps = {
  direction: ArrowDirection
  isDisabled?: boolean
  label: string
  onClick: (direction: ArrowDirection) => void
}

export const PaginationArrow: FC<PaginationArrowProps> = ({
  direction,
  label,
  isDisabled,
  onClick,
}) => {
  return (
    <IconButton
      size="sm"
      variant="ghostButton"
      icon={
        direction === 'forward'
          ? 'FunctionalChevronRight'
          : 'FunctionalChevronLeft'
      }
      ariaLabel={label}
      ariaDisabled={isDisabled}
      onClick={() => onClick(direction)}
    />
  )
}
