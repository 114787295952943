import type { Control, Path } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import type { FC } from 'react'
import React from 'react'

type ControllerProps<FieldValues> = {
  name: Path<FieldValues>
  control: Control<FieldValues>
}

export const withController =
  <FieldValues,>() =>
  <Props,>(Component: FC<Props>): FC<Props & ControllerProps<FieldValues>> =>
  ({ name, control, ...props }) =>
    (
      <Controller
        name={name}
        control={control}
        render={({ field: { ref: _, ...fieldProps }, fieldState: { error } }) => {
          return <Component {...(props as Props)} {...fieldProps} errors={error?.types} />
        }}
      />
    )
