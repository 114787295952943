import { Divider } from '_BRIGHT_/components'
import { alignXMap, spaceMap } from '_BRIGHT_/components/Layout/Common'
import type { AlignX, Space } from '_BRIGHT_/components/Layout/Common'
import { useResponsiveValue } from '_BRIGHT_/utils'
import type { ResponsiveStyle } from '_BRIGHT_/utils'
import type { FC } from 'react'
import React, { Children, isValidElement } from 'react'
import { Flex } from '@chakra-ui/react'
import type { DividerProps } from '_BRIGHT_/components/Divider/Divider'

export type StackProps = {
  hasDivider?: ResponsiveStyle<boolean>
  dividerColor?: DividerProps['color']
  alignX?: AlignX
  space?: Space
  ['data-testid']?: string
}

const Stack: FC<StackProps> = ({
  children,
  hasDivider = false,
  dividerColor,
  space = 'none',
  alignX = 'stretch',
  ['data-testid']: dataTestid,
}) => {
  const gap = spaceMap[space]
  const alignItems = alignXMap[alignX]
  const showDivider = useResponsiveValue(hasDivider)

  return (
    <Flex
      width="100%"
      flexDirection="column"
      gridGap={gap}
      alignItems={alignItems}
      data-testid={dataTestid}
    >
      {Children.map(children, (child, index) => {
        if (!isValidElement(child)) {
          return null
        }

        return (
          <>
            {index > 0 && showDivider && <Divider color={dividerColor} />}
            {child}
          </>
        )
      })}
    </Flex>
  )
}

export { Stack }
