import type { FC } from 'react'
import React from 'react'
import type { HiFiIconPathProps } from '_BRIGHT_/components/Icons'

const OrganisationlDetailsEdit: FC<HiFiIconPathProps> = ({
  primaryColor,
  secondaryColor,
  baseColor,
  strokeWidth,
  hasShadow,
}) => {
  return (
    <>
      {hasShadow && (
        <path
          opacity="0.2"
          d="M6 9C6 6.23858 8.23858 4 11 4H26C27.1046 4 28 4.89543 28 6V27C28 29.7614 25.7614 32 23 32H11C8.23858 32 6 29.7614 6 27V9Z"
          fill={primaryColor}
        />
      )}
      <path
        d="M29.2767 20.2228L20.375 29.1256L16 30.0006L16.875 25.6256L25.7767 16.7228C26.2396 16.26 26.8674 16 27.522 16C28.1766 16 28.8044 16.26 29.2673 16.7228L29.2767 16.7333C29.5061 16.9623 29.6881 17.2342 29.8122 17.5336C29.9364 17.833 30.0003 18.1539 30.0003 18.478C30.0003 18.8021 29.9364 19.1231 29.8122 19.4225C29.6881 19.7218 29.5061 19.9938 29.2767 20.2228Z"
        fill={baseColor}
        stroke={secondaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.7477 28.25H5.62383C4.92784 28.2497 4.26046 27.973 3.76843 27.4807C3.2764 26.9885 3 26.321 3 25.625"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23 11.625V2.875C23 2.76 22.9773 2.64612 22.9333 2.53988C22.8892 2.43365 22.8247 2.33714 22.7433 2.25587C22.6619 2.1746 22.5653 2.11018 22.459 2.06627C22.3527 2.02237 22.2388 1.99985 22.1238 2H6.5C5.57174 2 4.6815 2.36875 4.02513 3.02513C3.36875 3.6815 3 4.57174 3 5.5V25.625C3 24.929 3.2764 24.2615 3.76843 23.7693C4.26046 23.277 4.92784 23.0003 5.62383 23H12.6238"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 8L14 8"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 12L18 12"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  )
}

export { OrganisationlDetailsEdit }
