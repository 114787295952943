import { Tooltip } from 'components/Tooltip'
import { colors } from '_BRIGHT_/theme/default/colors'
import { FunctionalIcon } from '_BRIGHT_/components'

export const AppTooltip = ({ tooltip }: { tooltip: string }) => (
  <Tooltip label={tooltip} placement="bottom-start">
    <FunctionalIcon
      color={colors.baseOne.baseOne.contentTertiary}
      size="xs"
      variant="FunctionalInfoOutline"
    />
  </Tooltip>
)
