import { Divider, Frame, Heading, Stack, Text } from '_BRIGHT_/components'
import type { GeniusAction } from '_BRIGHT_/generated/generated-adviser-components'
import type { FC } from 'react'
import React from 'react'

export type ActionDrawerViewProps = Pick<GeniusAction, 'title' | 'description'>

export const ActionDrawerView: FC<ActionDrawerViewProps> = ({
  title,
  description,
  children,
}) => {
  return (
    <Frame palette="baseTwo" paddingX="lg">
      <Stack>
        <Frame palette="baseTwo" paddingY="xl">
          <Stack space="md">
            <Heading as="h2">{title}</Heading>
            {description && <Text level="md">{description}</Text>}
          </Stack>
        </Frame>

        <Divider />

        <Frame palette="baseTwo" paddingTop="4xl">
          {children}
        </Frame>
      </Stack>
    </Frame>
  )
}
