import { configureStore } from '@reduxjs/toolkit'
import { authReducer } from './slice/authSlice'
import { rtkQueryErrorHandler } from './middleware/rtkQueryErrorHandler'
import { employeeApi } from './query/employees/employeesApi'
import { companiesApi } from './query/companies/companiesApi'
import { genericApi } from './query/generic/genericApi'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    [genericApi.reducerPath]: genericApi.reducer,
    [employeeApi.reducerPath]: employeeApi.reducer,
    [companiesApi.reducerPath]: companiesApi.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false }).concat([
      genericApi.middleware,
      employeeApi.middleware,
      companiesApi.middleware,
      rtkQueryErrorHandler,
    ]),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type RootStateSlices = Omit<
  RootState,
  'genericApi' | 'employeeApi' | 'comapniesApi'
>
