import type { Color, FontFamily, FontWeight, Level, ResponsiveFontSize, ResponsiveLineHeight, Variant } from './types'

export const colorMap: Record<Variant, Color> = {
  primary: 'contentPrimary',
  secondary: 'contentSecondary',
  tertiary: 'contentTertiary',
  error: 'error',
  accentPrimary: 'accentPrimary',
}

const fontMap: Record<Level, FontFamily> = {
  '2xs': 'body',
  xs: 'body',
  sm: 'body',
  md: 'heading',
  lg: 'heading',
  xl: 'heading',
  '2xl': 'heading',
  '3xl': 'heading',
}

const fontSizeMap: Record<Level, ResponsiveFontSize> = {
  '2xs': '2xs',
  xs: 'xs',
  sm: 'sm',
  md: { base: 'md', sm: 'lg' },
  lg: { base: 'lg', sm: 'xl' },
  xl: { base: 'xl', sm: '2xl' },
  '2xl': { base: '2xl', sm: '3xl' },
  '3xl': { base: '3xl', sm: '4xl' },
}

const fontWeightMap: Record<Level, FontWeight> = {
  '2xs': 'normal',
  xs: 'normal',
  sm: 'normal',
  md: 'medium',
  lg: 'medium',
  xl: 'medium',
  '2xl': 'medium',
  '3xl': 'medium',
}

const lineHeightMap: Record<Level, ResponsiveLineHeight> = {
  '2xs': 'taller',
  xs: 'taller',
  sm: 'taller',
  md: { base: 'tall', sm: 'base' },
  lg: { base: 'base', sm: 'base' },
  xl: { base: 'base', sm: 'short' },
  '2xl': { base: 'short', sm: 'short' },
  '3xl': { base: 'short', sm: 'shorter' },
}

type ResponsiveTypography = {
  fontFamily: FontFamily
  fontSize: ResponsiveFontSize
  fontWeight: FontWeight
  lineHeight: ResponsiveLineHeight
}

type GetResponsiveValue = <ResponsiveThemeValue>(
  value: string,
  valueMap: Record<string, string>,
  themeValueMap: Record<string, ResponsiveThemeValue>
) => ResponsiveThemeValue

const getResponsiveValue: GetResponsiveValue = (value, valueMap, themeValueMap) => {
  const themeValue = valueMap[value]

  return themeValueMap[themeValue]
}

type GetResponsiveTypography = (value: string, valueMap: Record<string, Level>) => ResponsiveTypography

export const getResponsiveTypography: GetResponsiveTypography = (value, map) => {
  const fontFamily = getResponsiveValue(value, map, fontMap)
  const fontSize = getResponsiveValue(value, map, fontSizeMap)
  const fontWeight = getResponsiveValue(value, map, fontWeightMap)
  const lineHeight = getResponsiveValue(value, map, lineHeightMap)

  return { fontFamily, fontSize, fontWeight, lineHeight }
}
