const sizes = {
  'block-children-max-width': '47.5rem',
  'icon-2xs': '0.75rem',
  'icon-xs': '1rem',
  'icon-sm': '1.5rem',
  'icon-md': '1.75rem',
  'icon-lg': '2.25rem',
  'icon-xl': '3rem',
  'icon-2xl': '4rem',
  'icon-3xl': '5rem',
  'icon-4xl': '6rem',
  'icon-5xl': '8rem',
  'button-sm': '2rem',
  'button-md': '2.5rem',
  'button-lg': '3rem',
  // chakra sizes
  '0': '0',
  '1': '0.25rem',
  '2': '0.5rem',
  '2xl': '42rem',
  '2xs': '16rem',
  '3': '0.75rem',
  '3xl': '48rem',
  '3xs': '14rem',
  '4': '1rem',
  '4xl': '56rem',
  '5': '1.25rem',
  '5xl': '64rem',
  '6': '1.5rem',
  '6xl': '72rem',
  '7': '1.75rem',
  '8': '2rem',
  '9': '2.25rem', // custom size added for Greeting Block
  '10': '2.5rem',
  '12': '3rem',
  '14': '3.5rem',
  '16': '4rem',
  '18': '4.5rem',
  '20': '5rem',
  '24': '6rem',
  '26': '6.5rem',
  '28': '7rem',
  '30': '7.5rem',
  '32': '8rem',
  '40': '10rem',
  '48': '12rem',
  '56': '14rem',
  '64': '16rem',
  containers: { sm: '640px', md: '768px', lg: '1024px', xl: '1280px' },
  full: '100%',
  lg: '32rem',
  md: '28rem',
  px: '1px',
  sm: '24rem',
  xl: '36rem',
  xs: '20rem',
}

export { sizes }
