import {
  withPalette,
  IconButton,
  Frame,
  Column,
  Columns,
} from '_BRIGHT_/components'
import type { IconButtonProps } from '_BRIGHT_/components/Buttons/IconButton/IconButton'
import type {
  DrawerContentProps as ChakraDrawerContentProps,
  DrawerProps as ChakraDrawerProps,
} from '@chakra-ui/react'
import type { FunctionalIconVariant } from '_BRIGHT_/components/Icons'
import {
  Drawer as ChakraDrawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  VisuallyHidden,
} from '@chakra-ui/react'
import type { FC, ReactElement } from 'react'
import React from 'react'

export type DrawerProps = Pick<
  ChakraDrawerProps,
  | 'isOpen'
  | 'onClose'
  | 'closeOnOverlayClick'
  | 'initialFocusRef'
  | 'finalFocusRef'
  | 'placement'
  | 'isFullHeight'
  | 'closeOnEsc'
> &
  Pick<ChakraDrawerContentProps, 'maxWidth' | 'height'> & {
    ariaLabelDrawer: string
    hasCloseButton?: boolean
    ariaLabelClose?: string
    backButton?: ReactElement<DrawerIconButtonProps>
  }

const ProtoDrawer: FC<DrawerProps> = ({
  isOpen,
  onClose,
  ariaLabelClose = 'Close Modal',
  ariaLabelDrawer,
  closeOnOverlayClick,
  initialFocusRef,
  children,
  finalFocusRef,
  backButton,
  placement = 'bottom',
  height,
  maxWidth,
  isFullHeight = true,
  hasCloseButton = true,
  closeOnEsc = true,
}) => {
  return (
    <ChakraDrawer
      isOpen={isOpen}
      placement={placement}
      onClose={onClose}
      isFullHeight={isFullHeight}
      initialFocusRef={initialFocusRef}
      closeOnOverlayClick={closeOnOverlayClick}
      finalFocusRef={finalFocusRef}
      closeOnEsc={closeOnEsc}
    >
      <DrawerOverlay />
      <DrawerContent maxWidth={maxWidth} height={height}>
        {(hasCloseButton || backButton) && (
          <Frame paddingX="md" paddingY="xs" palette="baseTwo">
            <Columns space="sm">
              {backButton && <Column width="content">{backButton}</Column>}
              {hasCloseButton && (
                <Column width="full">
                  <Frame alignX="end" palette="baseTwo">
                    <DrawerIconButton
                      icon="FunctionalClose"
                      onClick={onClose}
                      ariaLabel={ariaLabelClose}
                    />
                  </Frame>
                </Column>
              )}
            </Columns>
          </Frame>
        )}
        <DrawerHeader p="none">
          <VisuallyHidden>{ariaLabelDrawer}</VisuallyHidden>
        </DrawerHeader>
        <DrawerBody p="none">{children}</DrawerBody>
      </DrawerContent>
    </ChakraDrawer>
  )
}

const ProtoDrawerWithPalette = withPalette<DrawerProps>(ProtoDrawer)

export const Drawer: FC<DrawerProps> = props => (
  <ProtoDrawerWithPalette {...props} palette="baseTwo" />
)

export type DrawerIconButtonProps = Pick<
  IconButtonProps,
  'onClick' | 'ariaLabel'
> & {
  icon: Extract<
    FunctionalIconVariant,
    'FunctionalArrowLeft' | 'FunctionalClose'
  >
}

export const DrawerIconButton: FC<DrawerIconButtonProps> = ({
  icon,
  onClick,
  ariaLabel,
}) => (
  <IconButton
    size="md"
    variant="ghostButton"
    icon={icon}
    onClick={onClick}
    ariaLabel={ariaLabel}
  />
)
