import type { FC } from 'react'
import type { PaginationOptions } from '_BRIGHT_/components/Pagination/common/getPagination'

import type {
  DataSetID,
  Settings,
  FieldName,
  FilterValue,
  Sort,
  SortDirection,
  GeniusReducerState,
  GeniusViewType,
  GeniusState,
} from './hooks'
import type {
  GeniusBodyConfig as GeniusBodyConfigQueryData,
  GeniusBulkEditActionContent,
  GeniusDownloadActionContent,
  GeniusFilterActionContent,
  GeniusSelectAllActionContent,
  GeniusSettingActionContent,
  GeniusSortActionContent,
  Maybe,
  GeniusConfig as GeniusConfigFromQuery,
  AppliedActionTags,
  GeniusDateFieldConfig,
  GeniusNumberFieldConfig,
  GeniusOptionFieldConfig,
  GeniusTextFieldConfig,
  GeniusCustomActionContent,
} from '_BRIGHT_/generated/generated-bright-components'
export type {
  GeniusDateFieldConfig,
  GeniusNumberFieldConfig,
  GeniusOptionFieldConfig,
  GeniusTextFieldConfig,
} from '_BRIGHT_/generated/generated-bright-components'
import type { GeniusDataMungerArgs } from './geniusDataMungers'
// import type { ApolloError } from '@apollo/client'

export type { ActionBarProps } from './components'

// @TODO Genius - apply generated types across genius components - https://smartpension.atlassian.net/browse/T2-1174
export type GeniusMetadataItemProps = {
  label: string | number
  value: string | number | null
}

export type GeniusMetadataListViewItemProps = GeniusMetadataItemProps & {
  fieldName: FieldConfig['fieldName']
  showInSummary: boolean
}

export type SummaryFullProps<SummaryProps> = SummaryProps & {
  primaryLabel: string
}

export type ButtonsPropsDefault = GeniusBodyConfigQueryData['itemButtons']

export type GeniusListViewDataItem<
  SummaryProps,
  ButtonsProps = ButtonsPropsDefault,
> = {
  id: number | string
  summaryProps: SummaryFullProps<SummaryProps>
  metadata: {
    itemsProps: GeniusMetadataListViewItemProps[]
    buttonsProps?: ButtonsRenderListFullProps<ButtonsProps>
  }
}

export type ButtonsRenderListFullProps<ButtonsProps> = ButtonsProps & {
  itemButtons: GeniusBodyConfig['itemButtons']
}

export type ButtonsSections<ButtonsProps> = Required<
  Pick<
    GeniusListViewDataItem<unknown, ButtonsProps>['metadata'],
    'buttonsProps'
  >
> & {
  buttonsRendererList:
    | FC<ButtonsRenderListFullProps<ButtonsProps>>[]
    | ((
        ButtonsRenderListFullProps: ButtonsRenderListFullProps<ButtonsProps>,
      ) => FC<ButtonsRenderListFullProps<ButtonsProps>>[])
}

export type MetadataItemsSectionProps = {
  MetadataItemRenderer: FC<GeniusMetadataItemProps>
  metadataItemsProps: GeniusMetadataItemProps[]
}

export type GeniusItemsCounterProps = {
  count: number
  singularLabel: string
  pluralLabel: string
}

export type GeniusActionBarConfig = {
  actionDrawerMenuLabel: string
  actionDrawerAriaLabelClose: string
  actionDrawerAriaLabelBack: string
  switchToListLabel: string
  switchToTableLabel: string
  primaryActionButtonLink?: Maybe<string>
  primaryActionButtonLabel?: Maybe<string>
  bulkButtonLabel?: Maybe<string>
  bulkMessageLabel?: Maybe<string>
}

export type OnStateChangeReturnValue = GeniusState &
  Pick<PaginationOptions, 'currentPage'>

export type GeniusUIProps<
  SummaryProps,
  ButtonsProps = ButtonsPropsDefault,
> = GeniusListViewRenderers<SummaryProps, ButtonsProps> & {
  id: string
  isLoadingData: boolean
  onStateChange: (stateObject: OnStateChangeReturnValue) => void
  onBulkEditSubmit?: OnBulkEditSubmit
  limit: number
  totalResults?: PaginationOptions['totalResults']
  dataset: GeniusListViewDataItem<SummaryProps, ButtonsProps>[] | null
  config: GeniusConfig
  resultsPerPage: PaginationOptions['resultsPerPage']
  availableViews?: GeniusViewType[]
  customActionRenderers?: GeniusCustomActionRenderers
}

export type GeniusProps<
  SummaryProps,
  DatasetItem,
  ButtonsProps = ButtonsPropsDefault,
> = Omit<
  GeniusUIProps<SummaryProps, ButtonsProps>,
  'dataset' | 'resultsPerPage' | 'config'
> & {
  dataset?: DatasetItem[]
  summaryPropsMunger: GeniusDataMungerArgs<
    SummaryProps,
    DatasetItem,
    ButtonsProps
  >['summaryPropsMunger']
  buttonsPropsMunger?: GeniusDataMungerArgs<
    SummaryProps,
    DatasetItem,
    ButtonsProps
  >['buttonsPropsMunger']
  metadataListMunger?: GeniusDataMungerArgs<
    SummaryProps,
    DatasetItem,
    ButtonsProps
  >['metadataListMunger']
  isLoadingGeniusConfig: boolean
  geniusConfigFromQuery?: GeniusConfigFromQuery
  geniusConfigError: any
  datasetError: any
  customActionRenderers?: GeniusCustomActionRenderers
}

type GeniusListViewRenderers<
  SummaryProps,
  ButtonsProps = ButtonsPropsDefault,
> = {
  SummaryRenderer: FC<SummaryFullProps<SummaryProps>>
  MetadataItemRenderer: MetadataItemsSectionProps['MetadataItemRenderer']
  /**
   * @prop an array of buttons {buttonsRendererList} or a function returning and array of buttons (if you need to apply some logic before deciding which buttons will be rendered depending on the button props)
   */
  buttonsRendererList?: ButtonsSections<ButtonsProps>['buttonsRendererList']
}

export type GeniusItemsCounter = Pick<
  GeniusBodyConfigQueryData,
  'itemsCounterLabels'
>

export type GeniusListViewProps<
  SummaryProps,
  ButtonsProps = ButtonsPropsDefault,
> = GeniusListViewRenderers<SummaryProps, ButtonsProps> &
  GeniusItemsCounter & {
    hasCollapsedGutters: boolean
    dataset: GeniusListViewDataItem<SummaryProps, ButtonsProps>[]
    totalResults: PaginationOptions['totalResults']
  }

export type CommonActionViewContent = {
  title: string
  description?: string
}

export type SortOptionsItem<V = string> = {
  label: string
  value: V
}

type SortViewContent = {
  sortByLabel: string
  sortDirectionLabel: string
  confirmButtonLabel: string
  cancelButtonLabel: string
  sortDirectionOptions: SortOptionsItem<SortDirection>[]
}

// @TODO Genius - refactor all prefix and suffix dynamic translations - https://smartpension.atlassian.net/browse/T2-1175
type SettingsViewContent = {
  confirmButtonLabel: string
  cancelButtonLabel: string
  dragCancelMessagePrefix: string
  dragEndMessagePrefix: string
  dragHandleTitlePrefix: string
  dragHandleUsageInstructions: string
  dragStartMessagePrefix: string
  dragUpdateMessagePrefix: string
}

export type FilterViewContent = {
  confirmButtonLabel: string
  cancelButtonLabel: string
}

export type DownloadContent = CommonActionViewContent
export type FilterContent = CommonActionViewContent & FilterViewContent
export type SortContent = CommonActionViewContent & SortViewContent
export type SettingsContent = CommonActionViewContent & SettingsViewContent
export type SelectAllContent = CommonActionViewContent
export type BulkEditContent = CommonActionViewContent & BulkEditViewContent

export type BulkEditViewContent = {
  confirmButtonLabel: string
  cancelButtonLabel: string
}

export type EnabledActionContent =
  | DownloadContent
  | SortContent
  | SettingsContent
  | FilterContent
  | SelectAllContent

type CustomActionConfig = {
  [key: string]: Omit<GeniusCustomActionContent, 'actionType'>
}

export type GeniusActionsConfig = {
  sort?: Omit<GeniusSortActionContent, 'actionType'>
  download?: Omit<GeniusDownloadActionContent, 'actionType'>
  settings?: Omit<GeniusSettingActionContent, 'actionType'>
  filter?: Omit<GeniusFilterActionContent, 'actionType'>
  selectAll?: Omit<GeniusSelectAllActionContent, 'actionType'>
  bulkEdit?: Omit<GeniusBulkEditActionContent, 'actionType'>
} & CustomActionConfig

export type GeniusConfig = {
  fields: GeniusFieldsConfig
  actions: GeniusActionsConfig
  actionBar: GeniusActionBarConfig
  body: GeniusBodyConfig
}

export type GeniusFieldsConfig = Record<string, FieldConfig>

export type FieldConfig =
  | GeniusDateFieldConfig
  | GeniusNumberFieldConfig
  | GeniusOptionFieldConfig
  | GeniusTextFieldConfig

type BulkEdit = {
  // @TODO Genius - refactor all prefix and suffix dynamic translations - https://smartpension.atlassian.net/browse/T2-1175
  checkboxLabelPrefix: string
  checkboxLabelSuffix: string
}

type GeniusBodyConfig = GeniusItemsCounter & {
  bulkEdit?: Maybe<BulkEdit>
  itemButtons?: GeniusBodyConfigQueryData['itemButtons']
  appliedActionTags: AppliedActionTags
  emptyDatasetContent: string
}

export type GeniusEnabledAction = keyof GeniusActionsConfig
export type GeniusAction = {
  label: string
  name: GeniusEnabledAction
  isVisible: boolean
  onClick: () => void
  customRenderer?: FC | null
}
export type GeniusActionsMap = Map<GeniusEnabledAction, GeniusAction>
export type GeniusCustomActionRenderers = Record<GeniusCustomActionType, FC>

export type GeniusInputType = 'text' | 'option' | 'date' | 'number'

export type GeniusOptionInput = { label: string; id: string }

export type GeniusInputConfigBase<T extends GeniusInputType> = {
  type: T
}

export type GeniusTextInputConfig = GeniusInputConfigBase<'text'>

export type GeniusNumberInputConfig = GeniusInputConfigBase<'number'>

export type GeniusDateInputConfig = GeniusInputConfigBase<'date'> & {
  operatorOptions: Iterable<GeniusOptionInput>
  dateLabels: {
    selectInputDescription: string
    dayInputLabel: string
    monthInputLabel: string
    yearInputLabel: string
  }
}

export type GeniusOptionInputConfig = GeniusInputConfigBase<'option'> & {
  options: Iterable<GeniusOptionInput>
}

export type GeniusInputConfig =
  | GeniusDateInputConfig
  | GeniusOptionInputConfig
  | GeniusTextInputConfig
  | GeniusNumberInputConfig

export type SharedFilterComponentProps = Pick<
  FilterViewContent,
  'cancelButtonLabel' | 'confirmButtonLabel'
> &
  Pick<FieldConfig, 'label' | 'fieldName'>

export type BulkEditSubmitValues = {
  data: Record<FieldConfig['fieldName'], string | boolean>
  selectedIds: (keyof GeniusReducerState['selectedItems'])[]
}

export type OnBulkEditSubmit = (values: BulkEditSubmitValues) => void

export type MungerResult<
  Metadata,
  Keys extends keyof Metadata = keyof Metadata,
  AdditionalArgs = Record<string, unknown>,
> = Pick<Metadata, Keys> & AdditionalArgs

export type GeniusCustomActionType = string

// useGeniusState() actions
export type AddFilterAction = {
  type: GeniusStateActionType.addFilter
  data: { field: FieldName; filterValue: FilterValue }
}
export type RemoveFilterAction = {
  type: GeniusStateActionType.removeFilter
  data: { field: FieldName }
}
export type SetSortAction = { type: GeniusStateActionType.setSort; data: Sort }
export type ResetSortAction = { type: GeniusStateActionType.resetSort }
export type SetSettingsAction = {
  type: GeniusStateActionType.setSettings
  data: Settings
}
export type ResetSettingsAction = { type: GeniusStateActionType.resetSettings }
export type ToggleSelectedItemAction = {
  type: GeniusStateActionType.toggleSelectedItem
  data: { id: DataSetID }
}
export type SelectMultipleItemsAction = {
  type: GeniusStateActionType.selectMultipleItems
  data: { ids: DataSetID[] }
}
export type DeselectAllItemsAction = {
  type: GeniusStateActionType.deselectAllItems
}
export type SetViewTypeAction = {
  type: GeniusStateActionType.setViewType
  data: GeniusViewType
}
export type Action =
  | AddFilterAction
  | RemoveFilterAction
  | SetSortAction
  | ResetSortAction
  | SetSettingsAction
  | ResetSettingsAction
  | ToggleSelectedItemAction
  | SelectMultipleItemsAction
  | DeselectAllItemsAction
  | SetViewTypeAction

export enum GeniusStateActionType {
  addFilter = 'addFilter',
  removeFilter = 'removeFilter',
  setSort = 'setSort',
  resetSort = 'resetSort',
  setSettings = 'setSettings',
  resetSettings = 'resetSettings',
  toggleSelectedItem = 'toggleSelectedItem',
  selectMultipleItems = 'selectMultipleItems',
  deselectAllItems = 'deselectAllItems',
  setViewType = 'setViewType',
}
