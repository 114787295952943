import type { ToggleOption } from '_BRIGHT_/components'
import {
  Adjacent,
  Button,
  Column,
  Columns,
  Divider,
  Link,
  Toggle,
  useColor,
} from '_BRIGHT_/components'
import { BaseCheckbox } from '_BRIGHT_/components/Forms/Checkbox'
import type { BaseCheckboxProps } from '_BRIGHT_/components/Forms/Checkbox/Checkbox'
import type { GeniusActionBarConfig } from '_BRIGHT_/components/Genius'
import type { BulkEditBarProps } from '_BRIGHT_/components/Genius/components'
import { BulkEditBar } from '_BRIGHT_/components/Genius/components'
import type { GeniusViewType } from '_BRIGHT_/components/Genius/hooks'
import {
  dispatchSetViewType,
  LIST_ID,
  TABLE_ID,
  useDrawerContext,
  useGeniusConfigContext,
  useGeniusStateContext,
} from '_BRIGHT_/components/Genius/hooks'
import { useIsMobileViewport, useWindowSize } from '_BRIGHT_/utils'
import { Box, Collapse, Flex } from '@chakra-ui/react'
import type { FC } from 'react'
import React, { useEffect, useRef } from 'react'
import { calculateOverflow, DesktopView, MobileView } from './utils'

export type ActionBarWrapperProps = {
  bulkItemsAmount: BulkEditBarProps['amount']
  onBulkButtonClick: BulkEditBarProps['onButtonClick']
  isCondensed: boolean
  isDividerFullWidth: boolean
}

export const ActionBarWrapper: FC<ActionBarWrapperProps> = ({
  bulkItemsAmount,
  onBulkButtonClick,
  isCondensed,
  isDividerFullWidth,
  children,
}) => {
  const { actionBar } = useGeniusConfigContext()
  const { bulkButtonLabel, bulkMessageLabel } = actionBar

  const borderRadius = isDividerFullWidth ? 'none' : 'lg'
  const bg = useColor('core', 'baseOne')
  const padding = { base: 'md', sm: 'sm' }
  const isBulkEditBarOpen = bulkItemsAmount > 0

  return (
    <Box
      py={padding}
      px={!isDividerFullWidth ? padding : 'none'}
      borderRadius={borderRadius}
      bg={bg}
      w="100%"
      data-testid="genius-action-bar"
    >
      <Flex
        px={isDividerFullWidth ? padding : 'none'}
        flexWrap={isCondensed ? 'wrap' : 'wrap-reverse'}
      >
        {children}
      </Flex>
      {bulkButtonLabel && bulkMessageLabel && (
        <Collapse
          in={isBulkEditBarOpen}
          unmountOnExit={true}
          data-testid="bulk-edit-bar"
        >
          <Box py={padding}>
            <Divider color="borderTertiary" />
          </Box>
          <Box px={isDividerFullWidth ? padding : 'none'}>
            <BulkEditBar
              amount={bulkItemsAmount}
              buttonLabel={bulkButtonLabel}
              messageLabel={bulkMessageLabel}
              onButtonClick={onBulkButtonClick}
            />
          </Box>
        </Collapse>
      )}
    </Box>
  )
}

export type ActionBarProps = Omit<
  ActionBarWrapperProps,
  'isCondensed' | 'isDividerFullWidth' | 'onBulkButtonClick'
> & {
  isSelectAllChecked: BaseCheckboxProps['isChecked']
  isCondensed: boolean
  isDatasetEmpty: boolean
  availableViews: GeniusViewType[]
  onOverflowChange?: (isOverflowing: boolean) => void
}

export const ActionBar: FC<ActionBarProps> = ({
  isSelectAllChecked,
  bulkItemsAmount,
  availableViews,
  isCondensed,
  isDatasetEmpty,
  onOverflowChange,
}) => {
  const { actionBar } = useGeniusConfigContext()
  const { viewType: activeToggleOptionId, dispatch } = useGeniusStateContext()

  const {
    actionDrawerMenuLabel,
    switchToListLabel,
    switchToTableLabel,
    primaryActionButtonLink,
    primaryActionButtonLabel,
  } = actionBar
  const { actions, open } = useDrawerContext()
  const toggleOptionsMap = {
    [TABLE_ID]: { label: switchToListLabel, id: LIST_ID },
    [LIST_ID]: { label: switchToTableLabel, id: TABLE_ID },
  }

  const toggleOptions: ToggleOption[] = availableViews.map(
    view => toggleOptionsMap[view],
  )

  const bulkSelectionAction = actions.get('selectAll')

  const onToggleViewChange = (id: string) =>
    id !== activeToggleOptionId &&
    dispatchSetViewType(dispatch, id as GeniusViewType)

  const defaultToggleOption = toggleOptions.find(
    option => option.id === activeToggleOptionId,
  )

  const isMobile = Boolean(useIsMobileViewport())
  const { width } = useWindowSize()
  const desktopWrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    onOverflowChange?.(isMobile || calculateOverflow(desktopWrapperRef))
  }, [isMobile, onOverflowChange, width])

  const hasEnabledActions = actions.size > 0
  const hasPrimaryActionButton = Boolean(
    primaryActionButtonLink && primaryActionButtonLabel,
  )
  const hasMultipleViews = availableViews.length > 1

  if (
    !hasEnabledActions &&
    !hasPrimaryActionButton &&
    (isCondensed || !hasMultipleViews)
  )
    return null

  return (
    <ActionBarWrapper
      bulkItemsAmount={bulkItemsAmount}
      onBulkButtonClick={() => open('bulkEdit')}
      isCondensed={isCondensed}
      isDividerFullWidth={!isCondensed && activeToggleOptionId === TABLE_ID}
    >
      <MobileView show={isCondensed}>
        <Columns space="md">
          {hasEnabledActions && (
            <Column>
              <Button
                variant="secondaryButton"
                size="sm"
                onClick={() => open('options')}
                isFullWidthForDesktop
              >
                {actionDrawerMenuLabel}
              </Button>
            </Column>
          )}
          {hasPrimaryActionButton && (
            <Column>
              <PrimaryActionButton
                link={primaryActionButtonLink}
                label={primaryActionButtonLabel}
              />
            </Column>
          )}
        </Columns>
      </MobileView>
      <DesktopView
        ref={desktopWrapperRef}
        show={!isCondensed}
        hasSelectAll={Boolean(bulkSelectionAction) && !isDatasetEmpty}
      >
        {bulkSelectionAction && !isDatasetEmpty && (
          <Box justifySelf="flex-start">
            <BaseCheckbox
              label={bulkSelectionAction.label}
              isLabelVisuallyHidden={true}
              onChange={bulkSelectionAction.onClick}
              isChecked={isSelectAllChecked}
            />
          </Box>
        )}
        <Box flexShrink={0}>
          <Adjacent space="md">
            <Adjacent space="xs">
              {Array.from(actions).map(([key, { label, onClick, isVisible }]) =>
                isVisible ? (
                  <ActionButton key={key} onClick={onClick} label={label} />
                ) : undefined,
              )}
            </Adjacent>

            {hasMultipleViews && (
              <Toggle
                options={toggleOptions}
                onChange={onToggleViewChange}
                defaultOption={defaultToggleOption}
              />
            )}

            {hasPrimaryActionButton && (
              <PrimaryActionButton
                link={primaryActionButtonLink}
                label={primaryActionButtonLabel}
              />
            )}
          </Adjacent>
        </Box>
      </DesktopView>
    </ActionBarWrapper>
  )
}

type ActionButtonProps = {
  onClick: () => void
  label: string
}

const ActionButton: FC<ActionButtonProps> = ({ label, onClick }) => (
  <Button variant="ghostButton" size="sm" onClick={onClick}>
    {label}
  </Button>
)

type PrimaryActionButtonProps = {
  link?: GeniusActionBarConfig['primaryActionButtonLink']
  label?: GeniusActionBarConfig['primaryActionButtonLabel']
}

const PrimaryActionButton: FC<PrimaryActionButtonProps> = ({ link, label }) => {
  if (!link || !label) return null

  return (
    <Link variant="primaryButton" size="sm" href={link} isFullWidthForDesktop>
      {label}
    </Link>
  )
}
