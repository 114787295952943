import React from 'react'
import type { FC } from 'react'
import { Icon } from '@chakra-ui/react'
import type { FunctionalIconSvgProps } from '_BRIGHT_/components/Icons/FunctionalIcon/FunctionalIcon'

export const FunctionalAccordionArrow: FC<FunctionalIconSvgProps> = ({
  color,
  size,
  height,
  ariaHidden,
}) => (
  <Icon
    viewBox="0 0 24 24"
    fill="none"
    color={color}
    width={size}
    height={height || size}
    aria-hidden={ariaHidden}
  >
    <rect width={24} height={24} fill="transparent" />
    <path
      d="M15.8778 11.3267C16.3861 11.6317 16.3861 12.3683 15.8778 12.6733L8.18916 17.2865C7.66582 17.6005 7 17.2235 7 16.6132L7 7.38679C7 6.77647 7.66581 6.39949 8.18916 6.7135L15.8778 11.3267Z"
      fill="currentColor"
    />
  </Icon>
)
