import { useState } from 'react'
import type { OnStateChangeReturnValue } from '_BRIGHT_/components/Genius/types'
import type {
  Maybe,
  Scalars,
  SortDirection,
} from '_BRIGHT_/generated/generated-bright-components'
import { GENIUS_INIT_STATE } from './useGeniusStateReducer'
import type { GeniusState } from './useGeniusState'

export type QueryVariables = {
  limit: number
  offset: number
  sort?: Maybe<{
    field: Scalars['String']
    order: SortDirection
  }>
  filter?: Maybe<Record<string, unknown>>
}

type UseGeniusStateForOperationsArgs = {
  limit?: number
}

type GeniusStateWithPagination = GeniusState & { currentPage: number }

export type UseGeniusStateForOperationsReturnedValue = {
  variables: QueryVariables
  skip: boolean
  onGeniusStateChange: (state: GeniusStateWithPagination) => void
}

const adaptFieldNameForApi = (fieldName: string) =>
  fieldName.replace(/__/g, '.')

export const useGeniusStateForQuery = (
  { limit = 100 }: UseGeniusStateForOperationsArgs = { limit: 100 },
): UseGeniusStateForOperationsReturnedValue => {
  const [geniusState, setGeniusState] = useState<
    OnStateChangeReturnValue & { skip: boolean }
  >({
    ...GENIUS_INIT_STATE,
    filters: null,
    sort: null,
    currentPage: 1,
    skip: true,
  })

  const onGeniusStateChange = (state: GeniusStateWithPagination) => {
    setGeniusState({
      ...state,
      skip: false,
    })
  }

  const sortVariable: QueryVariables['sort'] = geniusState.sort
    ? {
        field: adaptFieldNameForApi(geniusState.sort.field),
        order: geniusState.sort.direction,
      }
    : undefined

  const filterVariable: QueryVariables['filter'] = geniusState.filters
    ? Object.keys(geniusState.filters).reduce((transformedFilters, key) => {
        if (geniusState.filters === null || geniusState.filters[key] === null) {
          return transformedFilters
        }

        let value = geniusState.filters[key].value
        // @TODO Genius - Use correct type for filter values - https://smartpension.atlassian.net/browse/T2-1188
        if (key === 'id') {
          value = Number(value)
        }

        return {
          ...transformedFilters,
          [key]: {
            field: key,
            ...geniusState.filters[key],
            value,
          },
        }
      }, {})
    : undefined

  const variables: QueryVariables = {
    limit,
    offset: limit * (geniusState.currentPage - 1),
    sort: sortVariable,
    filter: filterVariable,
  }

  return {
    variables,
    skip: geniusState.skip,
    onGeniusStateChange,
  }
}
