import {
  Account,
  AddUser,
  AddUsers,
  AnalyticsCharts,
  Calculator,
  Calendar,
  Cash,
  ChangePassword,
  Clients,
  Downloads,
  Uploads,
  OrganisationlDetailsEdit,
  PersonalDetailsEdit,
  Enter,
  Exit,
  Flexible,
  Guarantee,
  Help,
  Info,
  Message,
  MessageEmployer,
  Mix,
  Money,
  Options,
  Pause,
  Percentages,
  PersonChosen,
  Proportions,
  Start,
  SuperUser,
  Todo,
  Transfer,
  Users,
} from './icons'
import { useColor } from '_BRIGHT_/components/PaletteProvider'
import type { FC } from 'react'
import React from 'react'

export type HifiIconVariant = keyof typeof hifiIconComponentMap
type StrokeWidthSize = 'sm' | 'md' | 'lg'
export type StrokeWidth = '1' | '1.5' | '2'

export type HiFiIconProps = {
  variant: HifiIconVariant
  hasShadow?: boolean
  strokeWidth?: StrokeWidthSize
  isMultiColor?: boolean
}
export type HiFiIconPathProps = {
  primaryColor: string
  secondaryColor: string
  baseColor: string
  strokeWidth: StrokeWidth
  hasShadow: boolean
}

export const hifiIconComponentMap = {
  Account,
  AddUser,
  AddUsers,
  AnalyticsCharts,
  Calculator,
  Calendar,
  Cash,
  ChangePassword,
  Clients,
  Downloads,
  Uploads,
  OrganisationlDetailsEdit,
  PersonalDetailsEdit,
  Enter,
  Exit,
  Flexible,
  Guarantee,
  Help,
  Info,
  Message,
  MessageEmployer,
  Mix,
  Money,
  Options,
  Pause,
  Percentages,
  PersonChosen,
  Proportions,
  Start,
  SuperUser,
  Todo,
  Transfer,
  Users,
}

const strokeWidthMap: Record<StrokeWidthSize, StrokeWidth> = {
  sm: '1',
  md: '1.5',
  lg: '2',
}

const HiFiIcon: FC<HiFiIconProps> = ({
  strokeWidth = 'md',
  variant,
  isMultiColor = true,
  hasShadow = true,
}) => {
  const Icon = hifiIconComponentMap[variant]
  const baseColor = useColor('core')
  const primaryColor = useColor('accentPrimary')
  const secondaryColor = useColor(
    isMultiColor ? 'accentSecondary' : 'accentPrimary',
  )
  const iconStrokeWidth = strokeWidthMap[strokeWidth]

  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Icon
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
        baseColor={baseColor}
        strokeWidth={iconStrokeWidth}
        hasShadow={hasShadow}
      />
    </svg>
  )
}

export { HiFiIcon }
