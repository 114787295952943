import React, { createContext } from 'react'
import type { FC } from 'react'
import type { GeniusConfig } from '_BRIGHT_/components/Genius'

export const GeniusConfigContext = createContext<GeniusConfig | null>(null)

export const GeniusConfigProvider: FC<{ value: GeniusConfig }> = ({
  value,
  children,
}) => (
  <GeniusConfigContext.Provider value={value}>
    {children}
  </GeniusConfigContext.Provider>
)
