import { useColor } from '_BRIGHT_/components'
import type { ResponsiveStyle } from '_BRIGHT_/utils'
import { mapResponsiveStyle, useShadow } from '_BRIGHT_/utils'
import {
  getPaddingX,
  getThemeSize,
  responsiveSizeMap,
  textLevelMap,
} from '_BRIGHT_/components/Buttons/common'
import type {
  Size,
  ButtonVariant,
  ButtonThemeSize,
  PaddingX,
} from '_BRIGHT_/components/Buttons/common'
import type {
  FontFamily,
  ResponsiveFontSize,
  ResponsiveLineHeight,
} from '_BRIGHT_/components/Typography/common'
import { getResponsiveTypography } from '_BRIGHT_/components/Typography/common'
import type {
  LinkVariant,
  LinkIconSize,
} from '_BRIGHT_/components/Typography/Link/Link'
import type { SystemProps } from '@chakra-ui/system'
import type { FunctionalIconLineHeight } from '_BRIGHT_/components/Icons'

export type ButtonLinkStyles = {
  color: string
  backgroundColor: string
  hoverBackgroundColor: SystemProps['backgroundColor']
  focusBackgroundColor: SystemProps['backgroundColor']
  hoverColor: SystemProps['color']
  activeColor: SystemProps['color']
  boxShadow: SystemProps['boxShadow']
  width: SystemProps['width']
  borderColor: SystemProps['borderColor']
  display: SystemProps['display']
  alignItems: SystemProps['alignItems']
  justifyContent: SystemProps['justifyContent']
  borderWidth: SystemProps['borderWidth']
  borderRadius: SystemProps['borderRadius']
  fontWeight: SystemProps['fontWeight']
  textDecoration: SystemProps['textDecoration']
  paddingX: ResponsiveStyle<PaddingX> | string
  fontFamily: FontFamily
  fontSize: ResponsiveFontSize
  lineHeight: ResponsiveLineHeight
  themeSize: ResponsiveStyle<ButtonThemeSize> | string
  iconSize: LinkIconSize
  iconLineHeight: FunctionalIconLineHeight
}

const iconSizeMap: Record<Size, LinkIconSize> = {
  sm: 'xs',
  md: 'sm',
  lg: 'sm',
}

export type ButtonStylesOptions = {
  size: Size
  isFullWidthForMobile: boolean
  isFullWidthForDesktop: boolean
  hasPaddingX: boolean
  variant: ButtonVariant | LinkVariant
  borderRadius?: 'full' | 'md'
}

export const useButtonStyles = ({
  size,
  isFullWidthForMobile,
  isFullWidthForDesktop,
  hasPaddingX,
  variant,
  borderRadius = 'md',
}: ButtonStylesOptions): ButtonLinkStyles => {
  const color = useColor('contentPrimary')
  const backgroundColor = useColor('core')
  const themeBorderColor = useColor('borderPrimary')
  const hoverBackgroundColor = useColor('hover', undefined, false)
  const activeColor = useColor('active')
  const focusBackgroundColor =
    variant === 'primaryButton' || variant === 'negativeButton'
      ? activeColor
      : undefined
  const boxShadow = useShadow('focus')
  const responsiveSize = responsiveSizeMap[size]
  const width = {
    base: isFullWidthForMobile ? '100%' : 'fit-content',
    sm: isFullWidthForDesktop ? '100%' : 'fit-content',
  }
  const paddingX = hasPaddingX
    ? mapResponsiveStyle(getPaddingX, responsiveSize)
    : '0'
  const themeSize = mapResponsiveStyle(getThemeSize, responsiveSize)
  const borderColor =
    variant === 'ghostButton' ? 'transparent' : themeBorderColor
  const { fontFamily, fontSize, lineHeight } = getResponsiveTypography(
    size,
    textLevelMap,
  )
  const iconSize = iconSizeMap[size]

  return {
    color,
    backgroundColor,
    hoverBackgroundColor,
    hoverColor: color,
    focusBackgroundColor,
    activeColor,
    boxShadow,
    width,
    paddingX,
    themeSize,
    borderColor,
    borderRadius,
    fontFamily,
    fontSize,
    lineHeight,
    iconSize,
    iconLineHeight: iconSize,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: '1px',
    fontWeight: 'bold',
    textDecoration: 'none',
  }
}
