import { Stack } from '_BRIGHT_'
import { StandardCard } from 'components/StandardCard'

export const HomeNavigationCards = () => {
  return (
    <Stack space="md">
      <StandardCard
        title="Select a client"
        iconVariant="Clients"
        description="View your client list, apply filters and select a client for detailed analytics."
        to="/analytics/clients"
      />
    </Stack>
  )
}
