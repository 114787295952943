import { FunctionalIcon, Link, Text } from '_BRIGHT_/components'
import type { Space } from '_BRIGHT_/components/Layout/Common'
import { Grid, GridItem } from '_BRIGHT_/components/Layout/Grid'
import type { LinkProps, TextProps } from '_BRIGHT_/components/Typography'
import type { FontSize } from '_BRIGHT_/components/Typography/common'
import type {
  FunctionalIconLineHeight,
  FunctionalIconSize,
  FunctionalIconProps,
} from '_BRIGHT_/components/Icons'
import { Box, Flex } from '@chakra-ui/react'
import type { FC, ReactElement, ReactNode } from 'react'
import React, {
  createContext,
  Children,
  isValidElement,
  useContext,
} from 'react'
import { Adjacent } from '_BRIGHT_/components'
import type { TextVariant } from '_BRIGHT_/components/Typography/Text'

type ListLevel = 'xs' | 'sm'
type ListFontSize = Extract<FontSize, 'xs' | 'sm'>
type ListIconSize = Extract<FunctionalIconSize, 'xs' | 'sm'>
type ListIconLineHeight = Extract<FunctionalIconLineHeight, 'xs' | 'sm'>

const spaceMap: Record<ListLevel, Space> = {
  xs: 'xs',
  sm: 'sm',
}

const itemSpaceMap: Record<ListLevel, Space> = {
  xs: '2xs',
  sm: 'xs',
}

const contentSizeMap: Record<ListLevel, ListFontSize> = {
  xs: 'xs',
  sm: 'sm',
}

const iconSizeMap: Record<ListLevel, ListIconSize> = {
  xs: 'xs',
  sm: 'sm',
}

const ListItemContext = createContext<{ level: ListLevel }>({ level: 'sm' })
const ListIconContext = createContext<{ lineHeight: ListIconLineHeight }>({
  lineHeight: 'sm',
})

type ListMarkerProps = Pick<TextProps, 'variant'> & {
  level?: ListFontSize
}

const Marker: FC<ListMarkerProps> = ({ level, variant }) => {
  return (
    <Box as="span" userSelect="none" aria-hidden>
      <Text level={level} variant={variant}>
        •
      </Text>
    </Box>
  )
}

type ListTextItemProps = Omit<TextProps, 'level'> & {
  marker?: ReactElement
}

export const ListTextItem: FC<ListTextItemProps> = ({
  children,
  as,
  variant,
  marker: customMarker,
}) => {
  const { level } = useContext(ListItemContext)
  const textLevel = contentSizeMap[level]

  const marker = customMarker || <Marker variant={variant} level={textLevel} />

  return (
    <ListIconContext.Provider value={{ lineHeight: textLevel }}>
      <Adjacent space={itemSpaceMap[level]}>
        {marker}
        <Text as={as} variant={variant} level={textLevel}>
          {children}
        </Text>
      </Adjacent>
    </ListIconContext.Provider>
  )
}

type ListDoubleTextItemProps = Omit<
  TextProps,
  'level' | 'children' | 'variant'
> &
  (
    | { children?: never; primaryContent: ReactNode }
    | { primaryContent?: never; children: ReactNode }
  ) &
  (
    | { variant?: never; primaryVariant?: TextProps['variant'] }
    | { primaryVariant?: never; variant?: TextProps['variant'] }
  ) & {
    marker?: ReactElement
    secondaryVariant?: TextVariant
    secondaryContent?: ReactNode
  }

export const ListDoubleTextItem: FC<ListDoubleTextItemProps> = ({
  children,
  primaryContent = children,
  secondaryContent = null,
  variant,
  primaryVariant = variant,
  secondaryVariant = primaryVariant,
  marker: customMarker,
}) => {
  const { level } = useContext(ListItemContext)
  const textLevel = contentSizeMap[level]

  const marker = customMarker || (
    <Marker variant={primaryVariant} level={textLevel} />
  )

  return (
    <ListIconContext.Provider value={{ lineHeight: textLevel }}>
      <Adjacent space={itemSpaceMap[level]}>
        {marker}
        <Flex direction="column">
          <Text as="b" variant={primaryVariant} level={textLevel}>
            {primaryContent}
          </Text>
          {secondaryContent && (
            <Text variant={secondaryVariant} level={textLevel}>
              {secondaryContent}
            </Text>
          )}
        </Flex>
      </Adjacent>
    </ListIconContext.Provider>
  )
}

type ListIconProps = Omit<FunctionalIconProps, 'size'> & {
  level?: ListLevel
}

export const ListIcon: FC<ListIconProps> = ({
  variant,
  color,
  level: overrideLevel,
}) => {
  const { level } = useContext(ListItemContext)
  const { lineHeight } = useContext(ListIconContext)
  const iconSize = iconSizeMap[overrideLevel ?? level]

  return (
    <FunctionalIcon
      variant={variant}
      color={color}
      size={iconSize}
      lineHeight={lineHeight}
    />
  )
}

type ListLinkItemProps = Omit<LinkProps, 'level'>

export const ListLinkItem: FC<ListLinkItemProps> = ({
  children,
  variant,
  leftIcon,
  rightIcon,
  isExternal,
  href,
  onClick,
}) => {
  const { level } = useContext(ListItemContext)
  const textLevel = contentSizeMap[level]

  return (
    <Link
      variant={variant}
      level={textLevel}
      leftIcon={leftIcon}
      rightIcon={rightIcon}
      isExternal={isExternal}
      href={href}
      onClick={onClick}
    >
      {children}
    </Link>
  )
}

export type ListProps = {
  level?: ListLevel
}

const List: FC<ListProps> = ({ children, level = 'sm' }) => {
  const space = spaceMap[level]

  return (
    <ListItemContext.Provider value={{ level }}>
      <Grid as="ul" wrap space={space}>
        {Children.map(children, child => {
          if (!isValidElement(child)) {
            return null
          }

          return <GridItem width="full">{child}</GridItem>
        })}
      </Grid>
    </ListItemContext.Provider>
  )
}

export { List }
