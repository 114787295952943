import { useColor } from '_BRIGHT_/components'
import { useGetWidth } from '_BRIGHT_/components/Forms/common'
import { useShadow } from '_BRIGHT_/utils/useShadow'
import type { BaseInputFieldProps } from './BaseInputField'
import type { SystemProps } from '@chakra-ui/system'

export type UseBaseInputFieldStyles = ({
  characters,
  width,
  height,
}: Pick<BaseInputFieldProps, 'characters' | 'width' | 'height'>) => {
  size:
    | string
    | {
        base: string
        sm: string
      }
  fontSize: SystemProps['fontSize']
  borderColor: SystemProps['color']
  width?: SystemProps['width']
  paddingX: SystemProps['padding']
  borderWidth: SystemProps['borderWidth']
  height: SystemProps['height']
  _focus: {
    boxShadow: SystemProps['boxShadow']
    borderColor: SystemProps['borderColor']
  }
  _hover: {
    borderColor: SystemProps['borderColor']
  }
  _invalid: {
    boxShadow: SystemProps['boxShadow']
    borderColor: SystemProps['borderColor']
  }
}

export const useBaseInputFieldStyles: UseBaseInputFieldStyles = ({
  characters,
  width,
  height,
}) => {
  const size = height ? height : { base: '10', sm: '12' }
  const paddingX = 'sm'
  const borderWidth = '2xs'
  const calculatedWidth = useGetWidth(characters, paddingX, borderWidth)
  const fieldWidth = characters ? calculatedWidth : width
  const borderColor = useColor('borderPrimary')
  const negativeColor = useColor('core', 'negativePrimary')
  const boxShadowFocus = useShadow('focus')
  const boxShadowInvalid = useShadow('invalid')
  const fontSize = 'sm'

  return {
    size,
    fontSize,
    borderColor,
    width: fieldWidth,
    paddingX,
    borderWidth,
    height: size,
    _focus: {
      boxShadow: boxShadowFocus,
      borderColor: 'transparent',
    },
    _hover: {
      borderColor,
    },
    _invalid: {
      boxShadow: boxShadowInvalid,
      borderColor: negativeColor,
    },
  }
}
