import { Skeleton } from '@chakra-ui/react'
import { BackBlock } from 'components/BackBlock'
import { Breadcrumb } from 'components/Breadcrumb'
import { FC, ReactNode } from 'react'
import {
  Block,
  Stack,
  Text,
  Divider,
  Heading,
  useColor,
} from '_BRIGHT_/components'

export type PageHeadingBlockProps = {
  pageHeading: string
  subheading?: string | ReactNode
  hasDivider?: boolean
} & (
  | { backLink: string; backLinkText: string; href?: never }
  | { href: string; backLinkText: string; backLink?: never }
  | { href?: never; backLinkText?: never; backLink?: never }
)

/**
 * MODIFIED:
 * render Breadcrumb if no href or backLink
 * extra content can be passed through {children}
 * added skeleton loader
 */

const PageHeadingBlock: FC<PageHeadingBlockProps> = ({
  backLink,
  href,
  backLinkText,
  pageHeading,
  subheading,
  hasDivider = true,
  children,
}) => {
  const endColor = useColor('contrast', 'baseTwo')

  return (
    <>
      {(href || backLink) && backLinkText ? (
        <BackBlock href={href} to={backLink} text={backLinkText} />
      ) : (
        <Breadcrumb />
      )}
      <Block palette="baseTwo" paddingTop="5xl">
        <Stack space="5xl">
          <Stack space="md">
            {pageHeading ? (
              <Heading>{pageHeading}</Heading>
            ) : (
              <Skeleton maxW="200px" minH="49px" endColor={endColor} />
            )}
            {subheading && (
              <Text level="md" variant="secondary">
                {subheading}
              </Text>
            )}
            {children}
          </Stack>
          {hasDivider && <Divider />}
        </Stack>
      </Block>
    </>
  )
}

export { PageHeadingBlock }
