import { Query, ResultSet } from '@cubejs-client/core'
import { createApi, retry } from '@reduxjs/toolkit/query/react'
import { CubeTablePivot } from 'lib/cubeJs'
import { formatTablePivotToCubeValues } from 'lib/cubeJs/cubeValueFormats'
import { cubeBaseQuery } from '../cubeBaseQuery'

const staggeredBaseQuery = retry(cubeBaseQuery(), {
  maxRetries: 3,
})

export const genericApi = createApi({
  reducerPath: 'genericApi',
  baseQuery: staggeredBaseQuery,
  keepUnusedDataFor: 180,

  endpoints: build => ({
    anyCubeQuery: build.query<ResultSet<any>, Query>({
      query: cubeQuery => ({ cubeQuery }),
    }),

    latestUpdate: build.query<string, void>({
      query: () => ({
        cubeQuery: {
          measures: ['SMART_ANALYTICS_DATASETS_INFO.latestUpdate'],
        },
      }),
      transformResponse: (cubeResponse: ResultSet<any>) => {
        return formatTablePivotToCubeValues(
          cubeResponse.tablePivot()[0] as CubeTablePivot,
        )[0].value
      },
    }),
  }),
})

export const { useAnyCubeQueryQuery, useLatestUpdateQuery } = genericApi
