import { ChakraProvider } from '@chakra-ui/react'
import type { FC } from 'react'
import React from 'react'

type BrightProvider = {
  theme: Record<string, unknown>
}

const BrightProvider: FC<BrightProvider> = ({ children, theme }) => {
  return <ChakraProvider theme={theme}>{children}</ChakraProvider>
}

export { BrightProvider }
