import {
  FunctionalAccordionArrow,
  FunctionalArrowLeft,
  FunctionalBurger,
  FunctionalChevronDown,
  FunctionalChevronLeft,
  FunctionalChevronRight,
  FunctionalChevronUp,
  FunctionalClose,
  FunctionalDownload,
  FunctionalExclamation,
  FunctionalExclamationOutline,
  FunctionalExternal,
  FunctionalHidden,
  FunctionalInfinity,
  FunctionalInfo,
  FunctionalInfoOutline,
  FunctionalMinus,
  FunctionalPlus,
  FunctionalPdf,
  FunctionalQuestion,
  FunctionalRefresh,
  FunctionalSearch,
  FunctionalTick,
  FunctionalTickCircle,
  FunctionalUpload,
  FunctionalVisible,
  FunctionalWarningContrast,
} from './icons'

export const functionalIconMap = {
  FunctionalAccordionArrow,
  FunctionalArrowLeft,
  FunctionalBurger,
  FunctionalChevronDown,
  FunctionalChevronLeft,
  FunctionalChevronRight,
  FunctionalChevronUp,
  FunctionalClose,
  FunctionalDownload,
  FunctionalExclamation,
  FunctionalExclamationOutline,
  FunctionalExternal,
  FunctionalHidden,
  FunctionalInfinity,
  FunctionalInfo,
  FunctionalInfoOutline,
  FunctionalMinus,
  FunctionalPlus,
  FunctionalPdf,
  FunctionalQuestion,
  FunctionalRefresh,
  FunctionalSearch,
  FunctionalTick,
  FunctionalTickCircle,
  FunctionalUpload,
  FunctionalVisible,
  FunctionalWarningContrast,
}

export type FunctionalIconVariant = keyof typeof functionalIconMap
