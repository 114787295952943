import type { FC } from 'react'
import React from 'react'
import { Button } from '_BRIGHT_/components'
import type { OnClick } from '_BRIGHT_/utils'

export type PaginationItemProps = {
  page: number
  isCurrentPage?: boolean
  labelGoToPage: string
  labelCurrentPage: string
  onClick: OnClick
}

export const PaginationItem: FC<PaginationItemProps> = ({
  page,
  isCurrentPage = false,
  labelGoToPage,
  labelCurrentPage,
  onClick,
}) => {
  return (
    <Button
      size="sm"
      hasPaddingX={false}
      isFullWidthForMobile={false}
      variant={isCurrentPage ? 'primaryButton' : 'ghostButton'}
      onClick={onClick}
      ariaCurrent={isCurrentPage ? 'page' : undefined}
      ariaLabel={`${isCurrentPage ? labelCurrentPage : labelGoToPage} ${page}`}
    >
      {page}
    </Button>
  )
}
