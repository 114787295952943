import React, { Component } from 'react'
import { useResetGeniusUrlState } from '_BRIGHT_/components/Genius/hooks/useResetGeniusUrlState'

export type GeniusErrorBoundaryBaseProps = {
  children: JSX.Element
  onDidCatch: () => void
}

class GeniusErrorBoundaryBase extends Component<GeniusErrorBoundaryBaseProps> {
  componentDidCatch(): void {
    this.props.onDidCatch()
  }

  render(): JSX.Element {
    return this.props.children
  }
}

export type GeniusErrorBoundaryProps = {
  id: string
  children: JSX.Element
}

/**
 * Clears Genius URL state when component crashes.
 *
 * Note that errors aren't actually caught here, theystill bubble up to any error boundaries higher up in
 * the component tree.
 */
export const GeniusErrorBoundary = ({
  id,
  children,
}: GeniusErrorBoundaryProps): JSX.Element => {
  const resetUrlState = useResetGeniusUrlState(id)
  return (
    <GeniusErrorBoundaryBase onDidCatch={resetUrlState}>
      {children}
    </GeniusErrorBoundaryBase>
  )
}
