import { Frame } from '_BRIGHT_/components'
import type { PaletteName } from '_BRIGHT_/components/PaletteProvider'
import type { FC } from 'react'
import React from 'react'

export type Palettes = Extract<PaletteName, 'baseOne' | 'baseTwo'>
type CardFrameProps = {
  palette?: Palettes
}

const CardFrame: FC<CardFrameProps> = ({ palette = 'baseTwo', children }) => {
  return (
    <Frame paddingY="lg" paddingX="lg" palette={palette}>
      {children}
    </Frame>
  )
}

export { CardFrame }
