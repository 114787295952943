import type { FC } from 'react'
import React from 'react'
import { Icon } from '@chakra-ui/react'
import type { FunctionalIconSvgProps } from '_BRIGHT_/components/Icons/FunctionalIcon/FunctionalIcon'

export const FunctionalTickCircle: FC<FunctionalIconSvgProps> = ({
  color,
  size,
  height,
  strokeWidth,
  ariaHidden,
}) => (
  <Icon
    viewBox="0 0 24 24"
    fill="none"
    color={color}
    width={size}
    height={height || size}
    aria-hidden={ariaHidden}
  >
    <path
      d="M11.9877 3.00201C9.58308 3.04137 7.29056 4.02543 5.60571 5.74145C4.76268 6.58074 4.09754 7.58138 3.65003 8.68356C3.20252 9.78574 2.98184 10.9668 3.00117 12.1562C2.99979 13.3184 3.22777 14.4695 3.67207 15.5434C4.11636 16.6174 4.76822 17.5931 5.5903 18.4146C6.41237 19.2362 7.3885 19.8875 8.46274 20.3311C9.53697 20.7748 10.6882 21.0021 11.8504 21H12.0109C14.4163 20.9752 16.7135 19.9973 18.3987 18.2809C20.0839 16.5644 21.0193 14.2496 20.9997 11.8443C21.0025 10.668 20.77 9.50297 20.3159 8.41782C19.8619 7.33266 19.1955 6.34925 18.3558 5.5254C17.5161 4.70156 16.5202 4.05392 15.4266 3.62057C14.333 3.18722 13.1638 2.97691 11.9877 3.00201ZM17.7725 8.83333L12.0683 16.5742C12.001 16.6635 11.9165 16.7386 11.8199 16.7949C11.7232 16.8513 11.6163 16.8878 11.5054 16.9024C11.3944 16.917 11.2817 16.9093 11.1738 16.8799C11.0658 16.8504 10.9648 16.7997 10.8767 16.7308L6.80334 13.4742C6.71787 13.4058 6.64671 13.3212 6.59392 13.2253C6.54114 13.1294 6.50775 13.0241 6.49568 12.9153C6.4713 12.6955 6.53521 12.4751 6.67334 12.3025C6.81147 12.1299 7.01252 12.0192 7.23225 11.9948C7.45198 11.9705 7.6724 12.0344 7.84501 12.1725L11.2417 14.89L16.4308 7.8475C16.4933 7.75376 16.5741 7.67365 16.6684 7.61203C16.7627 7.5504 16.8686 7.50854 16.9795 7.48896C17.0904 7.46939 17.2042 7.47251 17.3139 7.49814C17.4236 7.52377 17.527 7.57138 17.6177 7.63808C17.7085 7.70479 17.7848 7.7892 17.8421 7.88623C17.8993 7.98326 17.9363 8.09089 17.9507 8.20261C17.9652 8.31433 17.9569 8.42782 17.9262 8.53623C17.8956 8.64464 17.8433 8.74571 17.7725 8.83333Z"
      fill="currentColor"
    />
    <path
      d="M5.60571 5.74145C7.29056 4.02543 9.58308 3.04137 11.9877 3.00201C13.1638 2.97691 14.333 3.18722 15.4266 3.62057C16.5202 4.05392 17.5161 4.70156 18.3558 5.5254C19.1955 6.34925 19.8619 7.33266 20.3159 8.41782C20.77 9.50297 21.0025 10.668 20.9997 11.8443C21.0193 14.2496 20.0839 16.5644 18.3987 18.2809C16.7135 19.9973 14.4163 20.9752 12.0109 21H11.8504C10.6882 21.0021 9.53697 20.7748 8.46274 20.3311C7.3885 19.8875 6.41237 19.2362 5.5903 18.4147C4.76822 17.5931 4.11636 16.6174 3.67207 15.5434C3.22777 14.4695 2.99979 13.3184 3.00117 12.1562C2.98184 10.9668 3.20252 9.78574 3.65003 8.68356C4.09754 7.58138 4.76268 6.58074 5.60571 5.74145Z"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </Icon>
)
