import { useScopeBaseURL } from 'lib/hooks'
import { useAuthData } from 'redux/selectors'

export const usePageWrapper = () => {
  const { baseURL } = useScopeBaseURL()
  const { organisationName, scope, organisationId, title, forename, surname } =
    useAuthData()

  const navigationItems =
    scope === 'user'
      ? [
          {
            content: 'Home',
            href: `${baseURL}/`,
            isActive: false,
          },
          {
            content: 'Clients',
            href: `${baseURL}/companies`,
            isActive: false,
          },
          {
            content: 'Users',
            href: `${baseURL}/advisers/${organisationId}/users`,
            isActive: false,
          },
          {
            content: 'Help',
            href: `${baseURL}/get-help`,
            isActive: false,
          },
          {
            content: 'Downloads',
            href: `${baseURL}/exports`,
            isActive: false,
          },
        ]
      : []

  return {
    baseURL,
    navigationItems,
    name: organisationName,
    signedInAsContent: [title, forename, surname].filter(Boolean).join(' '),
    scope,
  }
}
