import type { FieldConfig } from '_BRIGHT_/components'
import { Checkbox, Stack } from '_BRIGHT_/components'
import type { FC } from 'react'
import React, { useState } from 'react'

type TogglableFieldProps = Pick<FieldConfig, 'label'>

export const TogglableField: FC<TogglableFieldProps> = ({
  label,
  children,
}) => {
  const [isChecked, setIsChecked] = useState(false)
  return (
    <Stack space="xl">
      <Checkbox
        label={label}
        isChecked={isChecked}
        onChange={() => setIsChecked(!isChecked)}
      />
      {isChecked && children}
    </Stack>
  )
}
