import { isValid, format as dateFnsFormat } from 'date-fns'
export const FALLBACK_VALUE = 'invalid date format'
import enGB from 'date-fns/locale/en-GB'

export const isValidDate = (rawDate: RawDate): boolean => (rawDate ? isValid(Date.parse(rawDate)) : false)

type RawDate = string | null
type FormatDateReturnedValue<FallbackValue> = string | FallbackValue
type FormatDateOptions<FallbackValue> = {
  format?: string
  locale?: Locale
  fallbackValue?: FormatDateReturnedValue<FallbackValue>
}

export const formatDate = <FallbackValue = string>(
  rawDate: RawDate,
  { format = 'PPP', locale = enGB, fallbackValue = FALLBACK_VALUE }: FormatDateOptions<FallbackValue> = {
    format: 'PPP',
    locale: enGB,
    fallbackValue: FALLBACK_VALUE,
  }
): FormatDateReturnedValue<FallbackValue> =>
  rawDate && isValidDate(rawDate) ? dateFnsFormat(new Date(rawDate), format, { locale }) : fallbackValue
