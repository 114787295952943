import type { brightTheme } from '_BRIGHT_/theme'
import { useTheme } from '@chakra-ui/react'
import type { FC } from 'react'
import React, { createContext, useContext } from 'react'

export type BlockPaletteName = 'baseOne' | 'baseTwo' | 'baseThree' | 'baseFour'

export type PaletteName =
  | BlockPaletteName
  | 'highlight'
  | 'highlightOutline'
  | 'positivePrimary'
  | 'positiveSecondary'
  | 'negativePrimary'
  | 'negativeSecondary'
  | 'negativeOutline'
  | 'warningPrimary'
  | 'warningSecondary'
  | 'infoPrimary'
  | 'infoSecondary'

export type ColorName =
  | 'accentPrimary'
  | 'accentSecondary'
  | 'active'
  | 'core'
  | 'contrast'
  | 'hover'
  | 'active'
  | 'contrast'
  | 'contentPrimary'
  | 'contentSecondary'
  | 'contentTertiary'
  | 'error'
  | 'link'
  | 'linkHover'
  | 'border'
  | 'borderPrimary'
  | 'borderSecondary'
  | 'borderTertiary'
  | 'accentPrimary'
  | 'accentSecondary'
  | 'focus'

export type BorderColor = Extract<
  ColorName,
  'borderPrimary' | 'borderSecondary' | 'accentSecondary' | 'borderTertiary'
>

export type UseColor = (
  colorName: ColorName,
  customPaletteName?: PaletteName,
  throwErrorIfNotFound?: boolean,
) => string

// Temporary until we move to Chakra 1.0 and can pass our theme type in using generics
type Palettes = Record<
  PaletteName,
  Record<PaletteName | 'default', Record<ColorName, string>>
>

type WithPalette = <P>(
  WrappedComponent: FC<Omit<P, 'palette'>>,
) => FC<P & { palette?: PaletteName }>

export type WithBlockPaletteProps<P> = P & Record<'palette', BlockPaletteName>

export type PaletteProviderProps = {
  palette: PaletteName
  parentPalette?: PaletteName
}

export const PaletteContext = createContext<PaletteProviderProps>({
  palette: 'baseOne',
})

export const useColor: UseColor = (
  colorName,
  customPaletteName,
  throwErrorIfNotFound = true,
) => {
  const theme = useTheme<typeof brightTheme>()
  const paletteContext = useContext(PaletteContext)
  const parentPalette = paletteContext.parentPalette || paletteContext.palette
  const paletteName = customPaletteName || paletteContext.palette
  const palettes = theme.colors as Palettes

  const palette = palettes[paletteName][parentPalette]

  if (!palette && throwErrorIfNotFound) {
    throw new Error(`An entry for the parentPalette: ${parentPalette} cannot be found in the palette: ${paletteName}.

    This is most likely you are trying to use a child palette on an incorrect parent palette. For example:
    - a "baseThree" palette on a "baseTwo" palette
    - a "negativePrimary palette on a "baseThree" or "baseFour" palette

    Please check that the palette combination you are trying to use is valid
    `)
  }

  if (!palette[colorName] && throwErrorIfNotFound) {
    throw new Error(`
      The color ${colorName} cannot be found in palette: ${paletteName}.
      Make sure it's present on the palette you are trying to use.
    `)
  }

  return palette[colorName]
}

const PaletteProvider: FC<PaletteProviderProps> = ({
  palette,
  parentPalette,
  children,
}) => {
  return (
    <PaletteContext.Provider value={{ palette, parentPalette }}>
      {children}
    </PaletteContext.Provider>
  )
}

export const withPalette: WithPalette = WrappedComponent => {
  return ({ palette = 'baseTwo', ...props }) => {
    const paletteContext = useContext(PaletteContext)

    return (
      <PaletteProvider palette={palette} parentPalette={paletteContext.palette}>
        <WrappedComponent {...props} />
      </PaletteProvider>
    )
  }
}

export { PaletteProvider }
