import React from 'react'
import type { FC } from 'react'
import { Icon } from '@chakra-ui/react'
import type { FunctionalIconSvgProps } from '_BRIGHT_/components/Icons/FunctionalIcon/FunctionalIcon'

export const FunctionalExclamation: FC<FunctionalIconSvgProps> = ({
  color,
  size,
  height,
  strokeWidth,
  ariaHidden,
}) => (
  <Icon
    viewBox="0 0 24 24"
    fill="none"
    color={color}
    width={size}
    height={height || size}
    aria-hidden={ariaHidden}
  >
    <path
      d="M11.9877 3.00201C9.58308 3.04137 7.29056 4.02543 5.60571 5.74145C4.76268 6.58074 4.09754 7.58138 3.65003 8.68356C3.20252 9.78574 2.98184 10.9668 3.00117 12.1562C2.99979 13.3184 3.22777 14.4695 3.67207 15.5434C4.11636 16.6174 4.76822 17.5931 5.5903 18.4147C6.41237 19.2362 7.3885 19.8875 8.46274 20.3311C9.53697 20.7748 10.6882 21.0021 11.8504 21H12.0109C14.4163 20.9752 16.7135 19.9973 18.3987 18.2809C20.0839 16.5644 21.0193 14.2496 20.9997 11.8443C21.0025 10.668 20.77 9.50297 20.3159 8.41782C19.8619 7.33266 19.1955 6.34925 18.3558 5.5254C17.5161 4.70156 16.5202 4.05392 15.4266 3.62057C14.333 3.18722 13.1638 2.97691 11.9877 3.00201ZM11.1672 12.4177V7.41829C11.1672 7.1973 11.255 6.98537 11.4113 6.8291C11.5675 6.67284 11.7795 6.58505 12.0005 6.58505C12.2215 6.58505 12.4334 6.67284 12.5897 6.8291C12.746 6.98537 12.8337 7.1973 12.8337 7.41829V12.4177C12.8337 12.6387 12.746 12.8507 12.5897 13.0069C12.4334 13.1632 12.2215 13.251 12.0005 13.251C11.7795 13.251 11.5675 13.1632 11.4113 13.0069C11.255 12.8507 11.1672 12.6387 11.1672 12.4177ZM10.7506 15.7857C10.7446 15.6223 10.7712 15.4594 10.8289 15.3064C10.8866 15.1534 10.9742 15.0135 11.0867 14.8948C11.1991 14.7761 11.3341 14.681 11.4837 14.6151C11.6333 14.5492 11.7945 14.5137 11.958 14.5108H11.9805C12.3093 14.5115 12.6251 14.6394 12.8617 14.8677C13.0983 15.0961 13.2373 15.4071 13.2495 15.7357C13.2557 15.8991 13.2291 16.0621 13.1715 16.2151C13.1138 16.3681 13.0262 16.5081 12.9137 16.6268C12.8013 16.7455 12.6663 16.8406 12.5166 16.9065C12.367 16.9724 12.2056 17.0078 12.0421 17.0105H12.0196C11.691 17.0095 11.3754 16.8815 11.1389 16.6532C10.9024 16.4249 10.7633 16.1141 10.7506 15.7857Z"
      fill="currentColor"
    />
    <path
      d="M5.60571 5.74145C7.29056 4.02543 9.58308 3.04137 11.9877 3.00201C13.1638 2.97691 14.333 3.18722 15.4266 3.62057C16.5202 4.05392 17.5161 4.70156 18.3558 5.5254C19.1955 6.34925 19.8619 7.33266 20.3159 8.41782C20.77 9.50297 21.0025 10.668 20.9997 11.8443C21.0193 14.2496 20.0839 16.5644 18.3987 18.2809C16.7135 19.9973 14.4163 20.9752 12.0109 21H11.8504C10.6882 21.0021 9.53697 20.7748 8.46274 20.3311C7.3885 19.8875 6.41237 19.2362 5.5903 18.4147C4.76822 17.5931 4.11636 16.6174 3.67207 15.5434C3.22777 14.4695 2.99979 13.3184 3.00117 12.1562C2.98184 10.9668 3.20252 9.78574 3.65003 8.68356C4.09754 7.58138 4.76268 6.58074 5.60571 5.74145Z"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </Icon>
)
