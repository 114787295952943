import type { FC } from 'react'
import React from 'react'
import { Icon } from '@chakra-ui/react'
import type { FunctionalIconSvgProps } from '_BRIGHT_/components/Icons/FunctionalIcon/FunctionalIcon'

export const FunctionalExclamationOutline: FC<FunctionalIconSvgProps> = ({
  color,
  size,
  height,
  strokeWidth,
  ariaHidden,
}) => (
  <Icon
    viewBox="0 0 24 24"
    fill="none"
    color={color}
    width={size}
    height={height || size}
    aria-hidden={ariaHidden}
  >
    <path
      d="M21 12C21 7.03125 16.9687 3 12 3C7.03125 3 3 7.03125 3 12C3 16.9687 7.03125 21 12 21C16.9687 21 21 16.9687 21 12Z"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      strokeMiterlimit={10}
    />
    <path
      d="M11.7307 7.28126L11.9998 12.9977L12.2684 7.28126C12.2701 7.24472 12.2642 7.20822 12.2513 7.174C12.2384 7.13978 12.2186 7.10855 12.1932 7.08222C12.1678 7.0559 12.1373 7.03503 12.1036 7.0209C12.0698 7.00676 12.0336 6.99966 11.997 7.00001V7.00001C11.9608 7.00035 11.9251 7.00797 11.892 7.02242C11.8589 7.03686 11.829 7.05784 11.8041 7.08409C11.7793 7.11034 11.76 7.14133 11.7474 7.17521C11.7348 7.20908 11.7291 7.24515 11.7307 7.28126V7.28126Z"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 15C11.7033 15 11.4133 15.088 11.1666 15.2528C10.92 15.4176 10.7277 15.6519 10.6142 15.926C10.5006 16.2001 10.4709 16.5017 10.5288 16.7926C10.5867 17.0836 10.7296 17.3509 10.9393 17.5607C11.1491 17.7704 11.4164 17.9133 11.7074 17.9712C11.9983 18.0291 12.2999 17.9994 12.574 17.8858C12.8481 17.7723 13.0824 17.58 13.2472 17.3334C13.412 17.0867 13.5 16.7967 13.5 16.5C13.5 16.1022 13.342 15.7206 13.0607 15.4393C12.7794 15.158 12.3978 15 12 15Z"
      fill="currentColor"
    />
  </Icon>
)
