const space = {
  none: 0,
  '2xs': '0.25rem',
  xs: '0.5rem',
  sm: '0.75rem',
  md: '1rem',
  lg: '1.25rem',
  xl: '1.5rem',
  '2xl': '1.75rem',
  '3xl': '2rem',
  '4xl': '2.25rem',
  '5xl': '2.5rem',
  '6xl': '3rem',
  '7xl': '4rem',
}

export { space }
