import { usePageWrapper } from './usePageWrapper'
import {
  Navigation,
  NavigationDrawerLinksItem,
  SignOutButton,
} from '_BRIGHT_/components'
import { useAppDispatch } from 'redux/hooks'
import { signout } from 'redux/slice/authSlice'
import { Box } from '@chakra-ui/react'

export const Menu = () => {
  const { baseURL, name, navigationItems, signedInAsContent, scope } =
    usePageWrapper()
  const dispatch = useAppDispatch()
  const onSignOut = () => dispatch(signout())
  const signOutButtonLabel = 'Sign out'

  return (
    <Navigation
      accountDetails={[
        {
          label: name,
          content: signedInAsContent,
        },
      ]}
      menuButtonLabel="Menu"
      menuButtonAriaLabel="Open menu"
      navigationAriaLabel="Main"
      onSignOut={onSignOut}
      imageUrl="/assets/smart-logo-contrast.svg"
      imageAltText="Dashboard"
      imageLink={`${baseURL}/`}
      closeButtonLabel="Close"
      signOutButtonLabel={signOutButtonLabel}
      variant="contrast"
      {...(scope === 'customer' && {
        topBarContent: (
          <Box paddingY="1">
            <SignOutButton label={signOutButtonLabel} onClick={onSignOut} />
          </Box>
        ),
      })}
    >
      {navigationItems.map((props, index) => (
        <NavigationDrawerLinksItem key={index} {...props} />
      ))}
    </Navigation>
  )
}
