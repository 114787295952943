import React, { useRef, useEffect } from 'react'
import type { FC, LegacyRef } from 'react'
import gsap from 'gsap'
import { useColor } from '_BRIGHT_/components'
import type { Icon } from './types'

export const Upload: FC<Icon> = ({ size, autoPlay }) => {
  const speed = 1.6
  const color = useColor('core', 'warningPrimary')
  const strokeWidth = 16
  const loaderSVG = useRef()
  const whole = useRef()
  const arrow1 = useRef()
  const arrow1Line = useRef()
  const arrow2 = useRef()
  const mainMask = useRef()

  useEffect(() => {
    gsap.set(loaderSVG.current as unknown as gsap.TweenTarget, {
      visibility: 'visible',
    })
    gsap.set(mainMask.current as unknown as gsap.TweenTarget, {
      attr: { y: 60 + strokeWidth / 2 },
    })
    const mainTl = gsap.timeline({
      repeat: -1,
      defaults: { duration: autoPlay ? 1 : 0 },
    })

    mainTl
      .add('arrow1Out')
      .to(
        arrow1.current as unknown as gsap.TweenTarget,
        { y: -220, ease: 'back.in' },
        'arrow1Out',
      )
      .to(
        arrow1Line.current as unknown as gsap.TweenTarget,
        { drawSVG: '0% 60%', ease: 'sine.inOut', duration: 0.4 },
        'arrow1Out',
      )
      .to(
        arrow1Line.current as unknown as gsap.TweenTarget,
        { drawSVG: '0% 100%', ease: 'sine.in', duration: 0.3 },
        'arrow1Out+=0.45',
      )
      .fromTo(
        arrow2.current as unknown as gsap.TweenTarget,
        { opacity: 0, y: 180 + strokeWidth / 2 },
        { opacity: 1, y: 0, ease: 'elastic(0.1, 0.25)' },
        0.8,
      )

    mainTl.timeScale(speed)
  }, [autoPlay])

  return (
    <svg
      viewBox="0 0 300 300"
      ref={loaderSVG as unknown as LegacyRef<SVGSVGElement>}
      width={size}
      height={size}
    >
      <defs>
        <clipPath id="mainMask">
          <rect
            ref={mainMask as unknown as LegacyRef<SVGRectElement>}
            width={300}
            height={230}
            y={0}
          />
        </clipPath>
      </defs>
      <g ref={whole as unknown as LegacyRef<SVGSVGElement>}>
        <g
          id="outline"
          fill="none"
          stroke={color}
          strokeOpacity={0.4}
          strokeLinecap="round"
          strokeMiterlimit={10}
          strokeWidth={strokeWidth}
        >
          <path d="M46.07,210.42v23.1a11.54,11.54,0,0,0,11.54,11.54h23.1" />
          <path d="M46.07,94.94V71.84A11.54,11.54,0,0,1,57.61,60.29h23.1" />
          <path d="M46.07,164.23v-23.1" />
          <path d="M253.93,210.42v23.1a11.54,11.54,0,0,1-11.54,11.54h-23.1" />
          <path d="M253.93,94.94V71.84a11.54,11.54,0,0,0-11.54-11.55h-23.1" />
          <path d="M253.93,164.23v-23.1" />
          <path d="M173.1,60.29H126.9" />
        </g>
        <g clipPath="url(#mainMask)">
          <g
            ref={arrow1 as unknown as LegacyRef<SVGSVGElement>}
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={strokeWidth}
          >
            <polyline points="106.69 172.89 150 129.58 193.31 172.89" />
            <line
              ref={arrow1Line as unknown as LegacyRef<SVGLineElement>}
              x1={150}
              y1={129.58}
              x2={150}
              y2={279.71}
            />
          </g>
          <g
            ref={arrow2 as unknown as LegacyRef<SVGSVGElement>}
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={strokeWidth}
          >
            <polyline points="106.69 172.89 150 129.58 193.31 172.89" />
            <line x1={150} y1={129.58} x2={150} y2={279.71} />
          </g>
        </g>
      </g>
    </svg>
  )
}
