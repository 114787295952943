import { Stack } from '_BRIGHT_/components'
import type { FormErrors } from '_BRIGHT_/components/Forms/common'
import { FormControl } from '_BRIGHT_/components/Forms/FormControl'
import type { RadioGroupProps as ChakraRadioGroupProps } from '@chakra-ui/react'
import { useRadioGroup } from '@chakra-ui/react'
import type { FC } from 'react'
import React, { Children, isValidElement, cloneElement } from 'react'

export type RadioGroupProps = Pick<
  ChakraRadioGroupProps,
  'onChange' | 'value' | 'name'
> & {
  label?: string
  description?: string
  errors?: FormErrors
  defaultValue?: string | number
  isRequired?: boolean
}

const RadioGroup: FC<RadioGroupProps> = ({
  children,
  defaultValue,
  description,
  errors,
  isRequired,
  label,
  onChange,
  value,
  name,
}) => {
  const { getRadioProps } = useRadioGroup({
    defaultValue,
    value,
    onChange,
    name,
  })

  return (
    <FormControl
      role="radiogroup"
      label={label}
      description={description}
      isRequired={isRequired}
      errors={errors}
      as="fieldset"
    >
      <Stack space="xl">
        {Children.map(children, child => {
          if (!isValidElement(child)) {
            return null
          }

          const radio = getRadioProps({ value: child.props.value })

          return cloneElement(child, { ...child.props, ...radio })
        })}
      </Stack>
    </FormControl>
  )
}

export { RadioGroup }
