import type { FC } from 'react'
import React from 'react'
import { Text } from '_BRIGHT_/components'
import { Flex } from '@chakra-ui/react'

export const PaginationEllipsis: FC = () => {
  return (
    <Flex boxSize="8" alignItems="center" justifyContent="center">
      <Text as="span" level="md" variant="accentPrimary">
        …
      </Text>
    </Flex>
  )
}
