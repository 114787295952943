import { useColor } from '_BRIGHT_/components'
import { convertRemToPx } from '_BRIGHT_/utils'
import { Box, useToken } from '@chakra-ui/react'
import {
  mergeProps,
  FocusScope,
  DismissButton,
  useOverlay,
  useOverlayPosition,
} from 'react-aria'
import type { FC, RefObject } from 'react'
import React, { useContext, createContext } from 'react'

type MaxHeight = number | string

const DropdownStylesContext = createContext<{ maxHeight?: MaxHeight }>({
  maxHeight: undefined,
})

type UseDropdownStyles = () => {
  maxHeight?: MaxHeight
}

export const useDropdownStyles: UseDropdownStyles = () => {
  const context = useContext(DropdownStylesContext)

  if (!context)
    throw new Error(
      'useOverlayStyles must be used within OverlayContext.Provider',
    )

  return context
}

export type DropdownProps = {
  isOpen: boolean
  onClose: () => void
  overlayRef: RefObject<HTMLDivElement>
  boundingRef?: RefObject<HTMLDivElement>
  triggerRef: RefObject<HTMLElement>
}

export const Dropdown: FC<DropdownProps> = ({
  isOpen,
  onClose,
  overlayRef,
  triggerRef,
  children,
  boundingRef,
}) => {
  const offset = convertRemToPx(useToken('space', '2xs'))
  const bgColor = useColor('core')

  const {
    overlayProps: { style: overlayStyles },
  } = useOverlayPosition({
    targetRef: triggerRef,
    overlayRef,
    offset,
    containerPadding: 0,
    isOpen,
  })

  const { overlayProps } = useOverlay(
    {
      onClose,
      shouldCloseOnBlur: true,
      isOpen,
      isDismissable: true,
    },
    overlayRef,
  )

  const width = boundingRef?.current?.getBoundingClientRect().width ?? '100%'

  return (
    <FocusScope restoreFocus>
      <Box
        {...mergeProps(overlayProps, { style: overlayStyles })}
        width={width}
        ref={overlayRef}
        boxShadow="md"
        borderRadius="lg"
        overflow="hidden"
        bg={bgColor}
        paddingY="2xs"
        data-testid="dropdown"
      >
        <DropdownStylesContext.Provider
          value={{ maxHeight: overlayStyles?.maxHeight }}
        >
          {children}
        </DropdownStylesContext.Provider>
        <DismissButton onDismiss={onClose} />
      </Box>
    </FocusScope>
  )
}
