import { AppQuery } from 'lib/cubeJs'

export const selectClientOverviewQuery: AppQuery = {
  measures: [
    'SMART_ANALYTICS_COMPANIES.count',
    'SMART_ANALYTICS_COMPANIES.totalMissingDirectDebitState',
    'SMART_ANALYTICS_COMPANIES.sumInvalidEmail',
    'SMART_ANALYTICS_COMPANIES.sumInvalidFields',
    'SMART_ANALYTICS_COMPANIES.totalCompaniesMissingMemberContributions',
    'SMART_ANALYTICS_COMPANIES.sumInvalidNationalInsuranceNumber',
    'SMART_ANALYTICS_COMPANIES.sumInvalidAddresses',
  ],
}
