const baseOneAndTwoDefault = {
  contentPrimary: '#003357',
  contentSecondary: '#222222',
  contentTertiary: '#666666',
  link: '#007793',
  linkHover: '#003357',
  accentPrimary: '#00819D',
  accentSecondary: '#F07C91',
  focus: '#588DF3',
  error: '#BC074C',
}

const baseOneDefault = {
  core: '#F5F7FB',
  contrast: '#FFFFFF',
  active: '#EBEDF1',
  borderPrimary: '#999999',
  borderSecondary: '#D6D6D6',
  borderTertiary: '#E8E8E8',
  ...baseOneAndTwoDefault,
}

const baseOne = {
  baseOne: {
    ...baseOneDefault,
  },

  baseTwo: {
    ...baseOneDefault,
  },
}

const baseTwoDefault = {
  core: '#FFFFFF',
  contrast: '#F5F7FB',
  active: '#F8F8F8',
  borderPrimary: '#999999',
  borderSecondary: '#E0E0E0',
  borderTertiary: '#EFEFEF',
  ...baseOneAndTwoDefault,
}

const baseTwo = {
  baseOne: {
    ...baseTwoDefault,
  },

  baseTwo: {
    ...baseTwoDefault,
  },
}

const baseThreeDefault = {
  core: '#103354',
  contrast: '#0C2943',
  active: '#1E3F5E',
  borderPrimary: '#587EA5',
  borderSecondary: '#294F75',
  borderTertiary: '#183E64',
  contentPrimary: '#7BE0C2',
  contentSecondary: '#FFFFFF',
  contentTertiary: '#CCCCCC',
  focus: '#7FB3F6',
  link: '#7BE0C2',
  linkHover: '#FFFFFF',
  accentPrimary: '#FFFFFF',
  accentSecondary: '#F07C91',
}

const baseThree = {
  baseThree: {
    ...baseThreeDefault,
  },

  baseFour: {
    ...baseThreeDefault,
  },
}

const baseFourDefault = {
  core: '#7BE0C2',
  contrast: '#67CCAE',
  active: '#74D3B6',
  borderPrimary: '#49AE90',
  borderSecondary: '#67CCAE',
  borderTertiary: '#73D8BA',
  contentPrimary: '#003357',
  contentSecondary: '#222222',
  contentTertiary: '#525252',
  focus: '#003D85',
  link: '#222222',
  linkHover: '#005975',
  accentPrimary: '#FFFFFF',
  accentSecondary: '#0C2943',
}

const baseFour = {
  baseThree: {
    ...baseFourDefault,
  },

  baseFour: {
    ...baseFourDefault,
  },
}

const highlightDefault = {
  core: '#00819D',
  hover: '#0A7793',
  active: '#005975',
  contentPrimary: '#FFFFFF',
  borderPrimary: 'transparent',
}

const highlight = {
  baseOne: {
    ...highlightDefault,
  },
  baseTwo: {
    ...highlightDefault,
  },
  baseThree: {
    ...highlightDefault,
  },
  baseFour: {
    ...highlightDefault,
    core: '#264C73',
    hover: '#12385F',
    active: '#00244B',
  },
}

const outlineDefault = {
  core: 'transparent',
}

const highlightOutlinebaseOneAndTwoDefault = {
  ...outlineDefault,
  hover: '#F0F0F0',
  active: '#E6E6E6',
  contentPrimary: '#007793',
  borderPrimary: '#007793',
}

const highlightOutline = {
  baseOne: {
    ...highlightOutlinebaseOneAndTwoDefault,
  },
  baseTwo: {
    ...highlightOutlinebaseOneAndTwoDefault,
  },
  baseThree: {
    ...outlineDefault,
    hover: '#3A6087',
    active: '#264C73',
    contentPrimary: '#FFFFFF',
    borderPrimary: '#FFFFFF',
  },
  baseFour: {
    ...outlineDefault,
    hover: '#67CCAE',
    active: '#49AE90',
    contentPrimary: '#103354',
    borderPrimary: '#103354',
  },
}

const positivePrimaryDefault = {
  core: '#0B8718',
  contentPrimary: '#FFFFFF',
}

const positivePrimary = {
  baseOne: {
    ...positivePrimaryDefault,
  },
  baseTwo: {
    ...positivePrimaryDefault,
  },
}

const positiveSecondaryDefault = {
  core: '#A4DD91',
  contentPrimary: '#222222',
}

const positiveSecondary = {
  baseOne: {
    ...positiveSecondaryDefault,
  },
  baseTwo: {
    ...positiveSecondaryDefault,
  },
}

const negativePrimaryBaseOneAndTwoDefault = {
  core: '#BC074C',
  hover: '#9A2049',
  active: '#860C35',
  contentPrimary: '#FFFFFF',
  borderPrimary: 'transparent',
}

const negativePrimary = {
  baseOne: {
    ...negativePrimaryBaseOneAndTwoDefault,
  },
  baseTwo: {
    ...negativePrimaryBaseOneAndTwoDefault,
  },
}

const negativeSecondaryBaseOneAndTwoDefault = {
  core: '#F3AC88',
  contentPrimary: '#222222',
}

const negativeSecondary = {
  baseOne: {
    ...negativeSecondaryBaseOneAndTwoDefault,
  },
  baseTwo: {
    ...negativeSecondaryBaseOneAndTwoDefault,
  },
}

const negativeOutlineDefault = {
  ...outlineDefault,
  hover: '#F0F0F0',
  active: '#E6E6E6',
  contentPrimary: '#BC074C',
  borderPrimary: '#BC074C',
}

const negativeOutline = {
  baseOne: {
    ...negativeOutlineDefault,
  },
  baseTwo: {
    ...negativeOutlineDefault,
  },
}

const warningPrimaryBaseOneAndTwoDefault = {
  core: '#FF9C36',
  contentPrimary: '#0C2943',
}

const warningPrimary = {
  baseOne: {
    ...warningPrimaryBaseOneAndTwoDefault,
  },
  baseTwo: {
    ...warningPrimaryBaseOneAndTwoDefault,
  },
}

const warningSecondaryBaseOneAndTwoDefault = {
  core: '#FBE38E',
  contentPrimary: '#222222',
}

const warningSecondary = {
  baseOne: {
    ...warningSecondaryBaseOneAndTwoDefault,
  },
  baseTwo: {
    ...warningSecondaryBaseOneAndTwoDefault,
  },
}

const infoPrimaryBaseOneAndTwoDefault = {
  core: '#2E67C8',
  contentPrimary: '#FFFFFF',
}

const infoPrimary = {
  baseOne: {
    ...infoPrimaryBaseOneAndTwoDefault,
  },
  baseTwo: {
    ...infoPrimaryBaseOneAndTwoDefault,
  },
}

const infoSecondaryBaseOneAndTwoDefault = {
  contentPrimary: '#222222',
}

const infoSecondary = {
  baseOne: {
    core: '#DFEAF4',
    ...infoSecondaryBaseOneAndTwoDefault,
  },
  baseTwo: {
    core: '#ECF2FA',
    ...infoSecondaryBaseOneAndTwoDefault,
  },
}

const colors = {
  baseOne,
  baseTwo,
  baseThree,
  baseFour,
  highlight,
  highlightOutline,
  positivePrimary,
  positiveSecondary,
  negativePrimary,
  negativeSecondary,
  negativeOutline,
  warningPrimary,
  warningSecondary,
  infoPrimary,
  infoSecondary,
}

export { colors }
