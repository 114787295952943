import {
  MiddlewareAPI,
  isRejectedWithValue,
  Middleware,
  AnyAction,
} from '@reduxjs/toolkit'
import { CustomCubeError } from 'redux/query/cubeBaseQuery'
import { signout } from 'redux/slice/authSlice'

export const rtkQueryErrorHandler: Middleware =
  (middlewareApi: MiddlewareAPI) => next => action => {
    if (isRejectedWithValue(action)) {
      const payload = action.payload

      // filter the cutom cube error
      if (payload instanceof CustomCubeError) {
        switch (payload.status) {
          case 403:
            // let it through - won't happend
            break
          default:
            // any cube error will sign out
            middlewareApi.dispatch(signout() as unknown as AnyAction)
            break
        }
      }
    }

    return next(action)
  }

/**
  const acionExample = {
   type: 'cubeQueryApi/executeQuery/rejected',
    payload: {
      response: {
        error: {
          status: 500,
          message: 'Error: QueryRewrite invalid company id filter supplied',
        },
      },
    },
    meta: {
      arg: {
        type: 'query',
        subscribe: true,
        subscriptionOptions: { pollingInterval: 0 },
        endpointName: 'companyName',
        originalArgs: { companyId: '' },
        queryCacheKey: 'companyName({"companyId":""})',
      },
      requestId: 'JsU3wI0RFHQSUwTPzdGHr',
      rejectedWithValue: true,
      requestStatus: 'rejected',
      aborted: false,
      condition: false,
    },
    error: { message: 'Rejected' },
  }
*/
