import { useCallback } from 'react'
import { useQueryParams } from '_BRIGHT_/utils/useQueryParams'

export const useResetGeniusUrlState = (id: string): (() => void) => {
  const { updateQueryParams } = useQueryParams(['sort', 'filters', 'page'], id)

  return useCallback(() => {
    updateQueryParams({})
  }, [updateQueryParams])
}
