import { Text, useColor } from '_BRIGHT_/components'
import { GridItem } from '_BRIGHT_/components/Layout/Grid'
import { useShadow } from '_BRIGHT_/utils'
import type { ButtonProps } from '@chakra-ui/react'
import { Button as ChakraButton } from '@chakra-ui/react'
import type { FC } from 'react'
import React from 'react'

type OptionsItemProps = Pick<ButtonProps, 'onClick'> & {
  content: string
}

export const OptionsMenuItem: FC<OptionsItemProps> = ({ content, onClick }) => {
  const bg = useColor('core')
  const hoverBg = useColor('active')
  const focusShadow = useShadow('focus')

  return (
    <GridItem width="full">
      <ChakraButton
        width="full"
        bg={bg}
        _focus={{ boxShadow: `inset ${focusShadow}` }}
        _hover={{ bg: hoverBg }}
        _active={{ bg: hoverBg }}
        justifyContent="flex-start"
        onClick={onClick}
        height="button-lg"
        py="sm"
        px="md"
      >
        <Text level="sm" variant="secondary" align="left" isTruncated>
          {content}
        </Text>
      </ChakraButton>
    </GridItem>
  )
}
