import React from 'react'
import type { FC } from 'react'
import { Button, Link } from '_BRIGHT_/components'
import type { ButtonVariant } from '_BRIGHT_/components/Buttons/common'
import type { ButtonProps } from '_BRIGHT_/components/Buttons/Button/Button'
import type { LinkProps } from '_BRIGHT_/components/Typography'
import type { Optional } from '_BRIGHT_/utils'

export type GeniusListMetadataButtonProps = Omit<
  ButtonProps,
  'size' | 'isFullWidthForMobile' | 'variant'
> &
  Optional<LinkProps, 'href' | 'download'> & {
    variant: Extract<ButtonVariant, 'secondaryButton' | 'negativeOutlineButton'>
  }

export const GeniusListMetadataButton: FC<GeniusListMetadataButtonProps> = ({
  children,
  onClick,
  variant,
  href,
  download,
  ...props
}) =>
  href ? (
    <Link
      variant={variant}
      size="sm"
      href={href}
      download={download}
      isFullWidthForMobile={false}
    >
      {children}
    </Link>
  ) : (
    <Button
      {...props}
      size="sm"
      variant={variant}
      isFullWidthForMobile={false}
      onClick={onClick}
    >
      {children}
    </Button>
  )
