import type { FC } from 'react'
import React from 'react'
import type { HiFiIconPathProps } from '_BRIGHT_/components/Icons'

const Transfer: FC<HiFiIconPathProps> = ({
  primaryColor,
  secondaryColor,
  baseColor,
  strokeWidth,
  hasShadow,
}) => {
  return (
    <>
      <path
        d="M14.26 5.15833L4 5.15833C2.89543 5.15833 2 6.05376 2 7.15833V8.15833V9.15833C2 10.2629 2.89543 11.1583 4 11.1583L14.26 11.1583C14.5361 11.1583 14.76 11.3822 14.76 11.6583L14.76 13.8911C14.76 14.3218 15.2682 14.551 15.591 14.2658L22.0856 8.52845C22.3074 8.33249 22.3114 7.98778 22.0941 7.78675L15.5995 1.77692C15.2794 1.48068 14.76 1.70774 14.76 2.14391V4.65833C14.76 4.93447 14.5361 5.15833 14.26 5.15833Z"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
      />
      {hasShadow && (
        <path
          opacity="0.2"
          d="M16.76 5.50297C16.76 4.19445 18.3183 3.51329 19.2787 4.40201L25.7733 10.4118C26.4251 11.0149 26.4131 12.0491 25.7476 12.637L19.2531 18.3743C18.2847 19.2298 16.76 18.5423 16.76 17.2502L16.76 15.5174L7.00001 15.5174C5.34315 15.5174 4.00001 14.1742 4.00001 12.5174V10.5174C4.00001 8.86053 5.34315 7.51739 7.00001 7.51739L16.76 7.51739V5.50297Z"
          fill={primaryColor}
        />
      )}
      <path
        d="M17.74 26.8417L28 26.8417C29.1046 26.8417 30 25.9462 30 24.8417L30 23.8417L30 22.8417C30 21.7371 29.1046 20.8417 28 20.8417L17.74 20.8417C17.4639 20.8417 17.24 20.6178 17.24 20.3417L17.24 18.1089C17.24 17.6782 16.7318 17.449 16.409 17.7342L9.91444 23.4715C9.6926 23.6675 9.68863 24.0122 9.90588 24.2133L16.4005 30.2231C16.7206 30.5193 17.24 30.2923 17.24 29.8561L17.24 27.3417C17.24 27.0655 17.4639 26.8417 17.74 26.8417Z"
        fill={baseColor}
        stroke={secondaryColor}
        strokeWidth={strokeWidth}
      />
    </>
  )
}

export { Transfer }
