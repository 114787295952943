import type {
  GeniusAction,
  GeniusConfig as GeniusConfigQueryData,
  GeniusOptionInputConfig,
  Maybe,
  Scalars,
} from '_BRIGHT_/generated/generated-bright-components'
import { formatDate } from '_BRIGHT_/utils'
import * as R from 'ramda'
import type {
  ButtonsPropsDefault,
  ButtonsRenderListFullProps,
  FieldConfig,
  GeniusActionsConfig,
  GeniusConfig,
  GeniusFieldsConfig,
  GeniusListViewDataItem,
  GeniusMetadataListViewItemProps,
  SummaryFullProps,
} from './types'
import { isDateField, isOptionField } from './utils'

// @TODO Genius - Flatten nested objects - https://smartpension.atlassian.net/browse/T2-1120
type RecordValues =
  | string
  | number
  | boolean
  | Record<string, Maybe<string | number>>

export type FullMetadata<Metadata> = Record<string, Maybe<RecordValues>> & {
  id: Scalars['ID']
} & Omit<Metadata, 'id' | 'itemButtons'>

export type GeniusDataMungerArgs<
  SummaryProps,
  Metadata,
  ButtonsProps = void,
> = {
  summaryPropsMunger: (
    metaData: FullMetadata<Metadata>,
    config: GeniusConfig,
  ) => SummaryFullProps<SummaryProps>
  buttonsPropsMunger?: (
    metaData: FullMetadata<Metadata>,
    config: GeniusConfig,
  ) => ButtonsProps
  metadataListMunger?: (
    metaData: FullMetadata<Metadata>,
    fields: GeniusFieldsConfig,
  ) => FullMetadata<Metadata>
  itemsList?: FullMetadata<Metadata>[]
  config: GeniusConfig
}

// @TODO Genius - Remove this getter when flattening of the nested field objects on the graphQL layer is implemented, making it redundant.
// https://smartpension.atlassian.net/browse/T2-1120
const getMetadataValue = <Metadata>(
  metaDataItem: FullMetadata<Metadata>,
  fieldName: FieldConfig['fieldName'],
) => {
  // In case a metadata prop is a nested value (object), we're able to parse it if the 'fieldName' follows the '__' notation (no matter the depth of the object)
  const path = fieldName.split('__')
  const lensPath = R.lensPath(path)
  const value = R.view(lensPath, metaDataItem)

  return typeof value === 'boolean' ? value.toString() : value
}

const formatOption = (value: string, { options }: GeniusOptionInputConfig) =>
  options.find(({ id }) => id === value)?.label || value

export const formatByFieldType = (
  value: string | number | null,
  field: FieldConfig,
): string | number | null => {
  if (value == null) return value

  if (isDateField(field)) {
    return formatDate(value.toString(), {
      format: field.format ?? undefined,
      fallbackValue: null,
    })
  }
  if (isOptionField(field)) {
    return formatOption(value.toString(), field.optionInputConfig)
  }
  return value
}
const omitActionType = (action: GeniusAction) => R.omit(['actionType'], action)
export const geniusConfigMunger = (
  configQueryData: GeniusConfigQueryData,
): GeniusConfig => {
  const {
    body,
    actionBar,
    actions: actionsQueryData,
    fields: fieldsQueryData,
  } = configQueryData

  const actionsIndexed = R.indexBy(R.prop('actionType'), actionsQueryData)
  const actionsIndexedWithoutType = R.mapObjIndexed(
    omitActionType,
    actionsIndexed,
  ) as GeniusActionsConfig
  const fieldsIndexed = R.indexBy(
    R.prop('fieldName'),
    fieldsQueryData,
  ) as GeniusFieldsConfig

  return {
    body,
    actionBar,
    actions: actionsIndexedWithoutType,
    fields: fieldsIndexed,
  }
}

export const geniusDatasetMunger = <
  SummaryProps,
  Metadata,
  ButtonsProps = ButtonsPropsDefault,
>({
  summaryPropsMunger,
  buttonsPropsMunger,
  metadataListMunger,
  itemsList,
  config,
}: GeniusDataMungerArgs<SummaryProps, Metadata, ButtonsProps>):
  | GeniusListViewDataItem<SummaryProps, ButtonsProps>[]
  | null => {
  if (!itemsList) return null

  const {
    fields,
    body: { itemButtons },
  } = config

  const dataset = itemsList.map(item => {
    const { id } = item
    const mungedItem = metadataListMunger?.(item, fields) || item

    return {
      id,
      summaryProps: summaryPropsMunger(item, config),
      metadata: {
        itemsProps: Object.keys(fields).reduce<
          GeniusMetadataListViewItemProps[]
        >((acc, key: string) => {
          const field = fields[key]
          const {
            label,
            fieldName,
            isShownInMetadataList,
            settings: { showInSummary },
          } = field

          if (!isShownInMetadataList) return acc

          const value = getMetadataValue(mungedItem, fieldName)

          value !== undefined &&
            acc.push({
              fieldName,
              label,
              value: formatByFieldType(value, field),
              showInSummary,
            })

          return acc
        }, []),
        buttonsProps: buttonsPropsMunger
          ? { ...buttonsPropsMunger(item, config), itemButtons }
          : ({ itemButtons } as ButtonsRenderListFullProps<ButtonsProps>),
      },
    }
  })

  return dataset
}
