import type { FC } from 'react'
import React from 'react'
import { AccordionButton, AccordionIcon } from '@chakra-ui/react'
import { useColor } from '_BRIGHT_/components'
import { useShadow } from '_BRIGHT_/utils'
import type { SpaceProps } from '@chakra-ui/styled-system'

type HeaderProps = {
  px?: SpaceProps['px']
  py?: SpaceProps['py']
  isShadowInset?: boolean
}

const Header: FC<HeaderProps> = ({
  children,
  px = '0',
  py = 'xs',
  isShadowInset = false,
}) => {
  const iconColor = useColor('accentSecondary')
  const hoverColor = useColor('active')
  const shadow = useShadow('focus')
  const boxShadow = isShadowInset ? `${shadow} inset` : shadow

  return (
    <AccordionButton
      px={px}
      py={py}
      _hover={{ bg: hoverColor }}
      _focus={{ boxShadow }}
      data-testid="accordion-button"
    >
      {children}
      <AccordionIcon color={iconColor} fontSize="2xl" />
    </AccordionButton>
  )
}

export { Header }
