import { Box } from '@chakra-ui/react'
import React from 'react'
import type { FC } from 'react'
import { useColor } from '_BRIGHT_/components'

export const EmptyDatasetView: FC = ({ children }) => {
  const contentSecondary = useColor('contentSecondary')

  return children ? (
    <Box color={contentSecondary} space="sm" alignItems="center" py="sm">
      {children}
    </Box>
  ) : null
}
