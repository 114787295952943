import type { BaseInputProps } from '_BRIGHT_/components/Forms/BaseInput'
import { BaseInput } from '_BRIGHT_/components/Forms/BaseInput'
import type { FC } from 'react'
import React from 'react'

export type TextInputProps = Omit<
  BaseInputProps,
  'leftAddon' | 'rightAddon' | 'button'
>

const TextInput: FC<TextInputProps> = props => <BaseInput {...props} />

export { TextInput }
