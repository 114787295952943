import React from 'react'
import type { FC } from 'react'
import { Icon } from '@chakra-ui/react'
import type { FunctionalIconSvgProps } from '_BRIGHT_/components/Icons/FunctionalIcon/FunctionalIcon'

export const FunctionalSearch: FC<FunctionalIconSvgProps> = ({
  color,
  size,
  height,
  strokeWidth,
  ariaHidden,
}) => (
  <Icon
    viewBox="0 0 24 24"
    fill="none"
    color={color}
    width={size}
    height={height || size}
    aria-hidden={ariaHidden}
  >
    <rect width={24} height={24} fill="transparent" />
    <circle
      cx={12}
      cy={12}
      r={8}
      stroke="currentColor"
      strokeWidth={strokeWidth}
    />
    <path
      d="M18 18L22.5 22.5"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
)
