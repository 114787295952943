import type { DrawerContentProps, DrawerProps } from '@chakra-ui/react'
import { useTheme } from '@chakra-ui/react'

type SideDrawerStyles = Pick<DrawerProps, 'placement'> & Pick<DrawerContentProps, 'maxWidth' | 'height'>

export const useSideDrawerStyles = (): SideDrawerStyles => {
  const theme = useTheme()

  const maxWidth = {
    base: '100%',
    sm: '45%',
    md: `calc(${theme.sizes['block-children-max-width']}/2)`,
  }

  return {
    height: '100%',
    maxWidth,
    placement: 'right',
  }
}
