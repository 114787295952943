import { useCompanyId } from 'lib/hooks'
import { useCompanies } from 'redux/selectors'

export const useFindCompany = () => {
  const companies = useCompanies()

  const { companyId } = useCompanyId()

  const company = companies.find(({ id }) => companyId === String(id))

  return {
    company,
    companyId,
  }
}
