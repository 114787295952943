import { Button, Stack } from '_BRIGHT_/components'
import { TextInput, withController } from '_BRIGHT_/components/Forms'
import { ActionDrawerButtonsWrapper } from '_BRIGHT_/components/Genius/components'
import {
  useGeniusStateContext,
  dispatchAddFilter,
  dispatchRemoveFilter,
  useDrawerContext,
} from '_BRIGHT_/components/Genius/hooks'
import type { FilterValueWithoutOperator } from '_BRIGHT_/components/Genius/hooks'
import React from 'react'
import type { FC } from 'react'
import { useForm } from 'react-hook-form'
import type { SharedFilterComponentProps } from '_BRIGHT_/components/Genius'

export const FILTER_BY_TEXT_TEST_ID = 'filter-by-text'

const ControlledTextInput =
  withController<FilterValueWithoutOperator>()(TextInput)

export type FilterByTextProps = SharedFilterComponentProps

export const FilterByText: FC<FilterByTextProps> = ({
  fieldName,
  label,
  confirmButtonLabel,
  cancelButtonLabel,
}) => {
  const { filters, dispatch } = useGeniusStateContext()
  const { close } = useDrawerContext()
  const initialValue = filters?.[fieldName] || { value: '' }
  const { control, handleSubmit, reset } = useForm<FilterValueWithoutOperator>({
    mode: 'onSubmit',
    criteriaMode: 'all',
    defaultValues: initialValue,
  })

  const applyFilter = (formData: FilterValueWithoutOperator): void => {
    dispatchAddFilter(dispatch, fieldName, formData)
    // @TODO Genius - Refactor to triggerthe close action when the GeniusState gets updated - https://smartpension.atlassian.net/browse/T2-1096
    close()
  }
  const onClear = () => {
    dispatchRemoveFilter(dispatch, fieldName)
    reset({ value: '' })
    // @TODO Genius - Refactor to triggerthe close action when the GeniusState gets updated - https://smartpension.atlassian.net/browse/T2-1096
    close()
  }

  return (
    <form
      data-testid={FILTER_BY_TEXT_TEST_ID}
      onSubmit={handleSubmit(applyFilter)}
    >
      <Stack space="4xl">
        <ControlledTextInput
          control={control}
          name="value"
          isRequired
          ariaLabel={label}
        />
        <ActionDrawerButtonsWrapper>
          <Button
            isFullWidthForMobile
            variant="secondaryButton"
            onClick={onClear}
          >
            {cancelButtonLabel}
          </Button>
          <Button isFullWidthForMobile type="submit">
            {confirmButtonLabel}
          </Button>
        </ActionDrawerButtonsWrapper>
      </Stack>
    </form>
  )
}
