import type { FC } from 'react'
import React from 'react'
import type { HiFiIconPathProps } from '_BRIGHT_/components/Icons'

const SuperUser: FC<HiFiIconPathProps> = ({
  primaryColor,
  secondaryColor,
  strokeWidth,
  hasShadow,
}) => {
  return (
    <>
      {hasShadow && (
        <path
          opacity="0.2"
          d="M19 9C17.9391 9 16.9217 9.42143 16.1716 10.1716C15.4214 10.9217 15 11.9391 15 13C15 14.0609 15.4214 15.0783 16.1716 15.8284C16.9217 16.5786 17.9391 17 19 17C17.1435 17 15.363 17.7375 14.0503 19.0503C12.7375 20.363 12 22.1435 12 24C12 24.5523 12.4477 25 13 25H25C25.5523 25 26 24.5523 26 24C26 22.1435 25.2625 20.363 23.9497 19.0503C22.637 17.7375 20.8565 17 19 17C20.0609 17 21.0783 16.5786 21.8284 15.8284C22.5786 15.0783 23 14.0609 23 13C23 11.9391 22.5786 10.9217 21.8284 10.1716C21.0783 9.42143 20.0609 9 19 9Z"
          fill={primaryColor}
        />
      )}
      <path
        d="M2 4.8125C2 5.18184 2.07275 5.54757 2.21409 5.8888C2.35543 6.23003 2.5626 6.54007 2.82376 6.80124C3.08493 7.0624 3.39497 7.26957 3.7362 7.41091C4.07743 7.55225 4.44316 7.625 4.8125 7.625C5.18184 7.625 5.54757 7.55225 5.8888 7.41091C6.23003 7.26957 6.54007 7.0624 6.80124 6.80124C7.0624 6.54007 7.26957 6.23003 7.41091 5.8888C7.55225 5.54757 7.625 5.18184 7.625 4.8125C7.625 4.44316 7.55225 4.07743 7.41091 3.7362C7.26957 3.39497 7.0624 3.08493 6.80124 2.82376C6.54007 2.5626 6.23003 2.35543 5.8888 2.21409C5.54757 2.07275 5.18184 2 4.8125 2C4.44316 2 4.07743 2.07275 3.7362 2.21409C3.39497 2.35543 3.08493 2.5626 2.82376 2.82376C2.5626 3.08493 2.35543 3.39497 2.21409 3.7362C2.07275 4.07743 2 4.44316 2 4.8125V4.8125Z"
        stroke={secondaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.09009 6.46375L9.50009 8.21124"
        stroke={secondaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 27.3125C2 27.6818 2.07275 28.0476 2.21409 28.3888C2.35543 28.73 2.5626 29.0401 2.82376 29.3012C3.08493 29.5624 3.39497 29.7696 3.7362 29.9109C4.07743 30.0523 4.44316 30.125 4.8125 30.125C5.18184 30.125 5.54757 30.0523 5.8888 29.9109C6.23003 29.7696 6.54007 29.5624 6.80124 29.3012C7.0624 29.0401 7.26957 28.73 7.41091 28.3888C7.55225 28.0476 7.625 27.6818 7.625 27.3125C7.625 26.9432 7.55225 26.5774 7.41091 26.2362C7.26957 25.895 7.0624 25.5849 6.80124 25.3238C6.54007 25.0626 6.23003 24.8554 5.8888 24.7141C5.54757 24.5727 5.18184 24.5 4.8125 24.5C4.44316 24.5 4.07743 24.5727 3.7362 24.7141C3.39497 24.8554 3.08493 25.0626 2.82376 25.3238C2.5626 25.5849 2.35543 25.895 2.21409 26.2362C2.07275 26.5774 2 26.9432 2 27.3125V27.3125Z"
        stroke={secondaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.09009 25.6612L9.50009 23.9125"
        stroke={secondaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.5 4.8125C24.5 5.55842 24.7963 6.27379 25.3238 6.80124C25.8512 7.32868 26.5666 7.625 27.3125 7.625C28.0584 7.625 28.7738 7.32868 29.3012 6.80124C29.8287 6.27379 30.125 5.55842 30.125 4.8125C30.125 4.06658 29.8287 3.35121 29.3012 2.82376C28.7738 2.29632 28.0584 2 27.3125 2C26.5666 2 25.8512 2.29632 25.3238 2.82376C24.7963 3.35121 24.5 4.06658 24.5 4.8125V4.8125Z"
        stroke={secondaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.035 6.46375L22.625 8.21124"
        stroke={secondaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.5 27.3125C24.5 28.0584 24.7963 28.7738 25.3238 29.3012C25.8512 29.8287 26.5666 30.125 27.3125 30.125C28.0584 30.125 28.7738 29.8287 29.3012 29.3012C29.8287 28.7738 30.125 28.0584 30.125 27.3125C30.125 26.5666 29.8287 25.8512 29.3012 25.3238C28.7738 24.7963 28.0584 24.5 27.3125 24.5C26.5666 24.5 25.8512 24.7963 25.3238 25.3238C24.7963 25.8512 24.5 26.5666 24.5 27.3125V27.3125Z"
        stroke={secondaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.035 25.6612L22.625 23.9125"
        stroke={secondaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 10C13 10.7956 13.3161 11.5587 13.8787 12.1213C14.4413 12.6839 15.2044 13 16 13C16.7956 13 17.5587 12.6839 18.1213 12.1213C18.6839 11.5587 19 10.7956 19 10C19 9.20435 18.6839 8.44129 18.1213 7.87868C17.5587 7.31607 16.7956 7 16 7C15.2044 7 14.4413 7.31607 13.8787 7.87868C13.3161 8.44129 13 9.20435 13 10V10Z"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 21C10 19.4087 10.6321 17.8826 11.7574 16.7574C12.8826 15.6321 14.4087 15 16 15C17.5913 15 19.1174 15.6321 20.2426 16.7574C21.3679 17.8826 22 19.4087 22 21H10Z"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  )
}

export { SuperUser }
