import { useEffect } from 'react'
import { useOrganisationHelper } from '../../redux/selectors'
import { assignUserId } from '../../lib/utils'

export const useTracking = () => {
  const { scope, userId } = useOrganisationHelper()

  const userType = scope === 'user' ? 'adviser' : 'employer'

  useEffect(() => {
    if (userId) {
      assignUserId(userId.toString(), userType)
    }
  }, [userId, userType])
}
