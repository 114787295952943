import { useCompanyId } from 'lib/hooks'
import { useCompanyNameQuery, useLatestUpdateQuery } from 'redux/query'
import { useAppScope } from 'redux/selectors'

export const usePageHeadingData = () => {
  const { companyId } = useCompanyId()
  const { data: name } = useCompanyNameQuery({ companyId })
  const { data: date } = useLatestUpdateQuery()
  const scope = useAppScope()

  if (scope === 'customer') {
    return {
      name: 'Analytics',
      date,
    }
  }

  return {
    name,
    date,
  }
}
