import { FC } from 'react'
import { LinkProps } from '@chakra-ui/react'
import { Link as ChakraLink } from '@chakra-ui/react'
import { useLinkStyles } from '_BRIGHT_/components/Typography/Link/useLinkStyles'

export const ExternalLink: FC<LinkProps> = ({ children, href }) => {
  const {
    boxShadow,
    hoverColor,
    hoverBackgroundColor,
    textDecoration,
    color,
    fontSize,
  } = useLinkStyles('primaryLink', '2xs')

  return (
    <ChakraLink
      color={color}
      fontSize={fontSize}
      _focus={{ boxShadow }}
      _active={{ boxShadow }}
      _hover={{ color: hoverColor, bg: hoverBackgroundColor }}
      textDecoration={textDecoration}
      rel="noopener noreferrer"
      target="_blank"
      href={href}
    >
      {children}
    </ChakraLink>
  )
}
