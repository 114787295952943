export const SENSITIVE_DATA_PLACEHOLDER = 'Not enough data'

export const TOOLTIP_TEXT = {
  AUM: 'Total value of all investments for all active and deferred members. Calculated at the end of the last working day.',
  takeupRate: 'Percentage of current employees that are active members.',
  dataQuality:
    `Percentage of completed and valid entries for National Insurance number, ` +
    `email address and home address for all employees. A complete and valid home address ` +
    `is one where both Address Line 1 and Post Code are complete, the Post Code format is ` +
    `valid and the employee is not recorded as Gone Away.`,
  schemeStartDate:
    'Date the company started participating in the Smart Pension Master Trust.',
  totalMembersWithMissingContributions:
    'Number of members with a contribution which is 1 or more days late.',
  totalCompaniesMissingMemberContributions:
    'Number of employers with at least one member with a late contribution.',
  // MAINTENANCE
  maintenanceMissingNINumbers: 'Number of members with no NI number.',
  maintenanceMissingBeneficiaries:
    'Number of members who have not provided any beneficiary information.',
  maintenanceInvalidHomeAddress:
    'Number of members with an invalid home address. This is when Address Line 1 and Post Code are not complete and correct, or the employee is recorded as gone away.',
  // SELECT A CLIENT
  sacMissingDirectDebits:
    'Number of employers who have not set up a Direct Debit for the payment of contributions.',
  sacMissingNINumbers:
    'Number of employers with at least one member with a missing NI number.',
  sacInvalidHomeAddress:
    'Number of employers with at least one member with an invalid home address. This is when address line 1 and postcode are not complete and correct, or the employee is recorded as gone away.',
  sacMissingEmailAddresses:
    'Number of employers with at least one member with a missing email address.',
}
