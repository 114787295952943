import type { ReactNode, RefObject, AriaAttributes } from 'react'
import React, { forwardRef } from 'react'
import type { ResponsiveValue } from '@chakra-ui/react'
import { Box, Flex } from '@chakra-ui/react'

type ResponsiveProps = {
  'aria-hidden': AriaAttributes['aria-hidden']
  height: string
  overflow: string
  visibility: ResponsiveValue<VisibilityState>
}

type MobileWrapperProps = {
  show: boolean
  children: ReactNode
}

type DesktopWrapperProps = MobileWrapperProps & {
  hasSelectAll?: boolean
}

const showProps: ResponsiveProps = {
  'aria-hidden': false,
  height: 'auto',
  overflow: 'initial',
  visibility: 'visible',
}

const hideProps: ResponsiveProps = {
  'aria-hidden': true,
  height: '0px',
  overflow: 'hidden',
  visibility: 'hidden',
}

export const calculateOverflow = (ref: RefObject<HTMLElement>): boolean => {
  const wrapper = ref?.current

  if (wrapper?.childElementCount) {
    return (
      [...wrapper.children].reduce((sum, child) => sum + child.getBoundingClientRect().width, 0) >
      wrapper.getBoundingClientRect().width
    )
  }

  return false
}

export const DesktopView = forwardRef<HTMLDivElement, DesktopWrapperProps>(({ children, show, hasSelectAll }, ref) => (
  <Flex
    ref={ref}
    {...(show ? showProps : hideProps)}
    alignItems="center"
    justifyContent={hasSelectAll ? 'space-between' : 'flex-end'}
    width="full"
    data-testid="desktop"
  >
    {children}
  </Flex>
))

export const MobileView = ({ children, show }: MobileWrapperProps): JSX.Element => (
  <Box {...(show ? showProps : hideProps)} width="full" data-testid="mobile">
    {children}
  </Box>
)
