import type { FC } from 'react'
import React from 'react'
import type { HiFiIconPathProps } from '_BRIGHT_/components/Icons'

const Money: FC<HiFiIconPathProps> = ({
  primaryColor,
  secondaryColor,
  baseColor,
  strokeWidth,
  hasShadow,
}) => {
  return (
    <>
      {hasShadow && (
        <rect
          opacity="0.2"
          x="4"
          y="7"
          width="28"
          height="19"
          rx="2.5"
          fill={primaryColor}
        />
      )}
      <path
        d="M27.25 19.75V26.75C27.25 28.6832 24.1163 30.25 20.25 30.25C16.3837 30.25 13.25 28.6832 13.25 26.75V19.75"
        stroke={secondaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.25 22.5C13.25 22.5314 13.2508 23.3128 13.2525 23.344C13.3521 25.2337 16.4466 26.75 20.25 26.75C24.0482 26.75 27.1393 25.2379 27.2471 23.3518C27.249 23.318 27.25 22.5341 27.25 22.5"
        fill={baseColor}
      />
      <path
        d="M13.25 22.5C13.25 22.5314 13.2508 23.3128 13.2525 23.344C13.3521 25.2337 16.4466 26.75 20.25 26.75C24.0482 26.75 27.1393 25.2379 27.2471 23.3518C27.249 23.318 27.25 22.5341 27.25 22.5"
        stroke={secondaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.75 21.5H2.75C2.28587 21.5 1.84075 21.3156 1.51256 20.9874C1.18438 20.6592 1 20.2141 1 19.75V5.75C1 5.28587 1.18438 4.84075 1.51256 4.51256C1.84075 4.18437 2.28587 4 2.75 4H25.5C25.9641 4 26.4092 4.18437 26.7374 4.51256C27.0656 4.84075 27.25 5.28587 27.25 5.75V14.5082"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.25 19.75C27.25 20.6783 26.5125 21.5685 25.1997 22.2249C23.887 22.8813 22.1065 23.25 20.25 23.25C18.3935 23.25 16.613 22.8813 15.3003 22.2249C13.9875 21.5685 13.25 20.6783 13.25 19.75C13.25 18.8217 13.9875 17.9315 15.3003 17.2751C16.613 16.6187 18.3935 16.25 20.25 16.25C22.1065 16.25 23.887 16.6187 25.1997 17.2751C26.5125 17.9315 27.25 18.8217 27.25 19.75Z"
        fill={baseColor}
        stroke={secondaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.625 12.75C17.6249 12.1648 17.4781 11.589 17.1981 11.0752C16.918 10.5613 16.5135 10.126 16.0217 9.80885C15.5299 9.49172 14.9664 9.30298 14.3828 9.25987C13.7992 9.21677 13.2141 9.32069 12.681 9.56211C12.148 9.80354 11.6839 10.1748 11.3314 10.6419C10.9789 11.1089 10.7491 11.657 10.663 12.2358C10.577 12.8146 10.6375 13.4058 10.8389 13.9552C11.0404 14.5046 11.3764 14.9948 11.8162 15.3808"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.8125 7.9375C22.7136 7.9375 22.6169 7.96682 22.5347 8.02177C22.4525 8.07671 22.3884 8.1548 22.3506 8.24616C22.3127 8.33752 22.3028 8.43805 22.3221 8.53504C22.3414 8.63204 22.389 8.72113 22.4589 8.79105C22.5289 8.86098 22.618 8.9086 22.715 8.92789C22.8119 8.94719 22.9125 8.93728 23.0038 8.89944C23.0952 8.8616 23.1733 8.79751 23.2282 8.71528C23.2832 8.63306 23.3125 8.53639 23.3125 8.4375C23.3125 8.30489 23.2598 8.17772 23.1661 8.08395C23.0723 7.99018 22.9451 7.9375 22.8125 7.9375Z"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.3125 16.6875C5.21361 16.6875 5.11694 16.7168 5.03471 16.7718C4.95249 16.8267 4.8884 16.9048 4.85056 16.9962C4.81272 17.0875 4.80282 17.1881 4.82211 17.285C4.8414 17.382 4.88902 17.4711 4.95895 17.5411C5.02887 17.611 5.11796 17.6586 5.21496 17.6779C5.31195 17.6972 5.41248 17.6873 5.50384 17.6494C5.59521 17.6116 5.6733 17.5475 5.72824 17.4653C5.78318 17.3831 5.8125 17.2864 5.8125 17.1875C5.8125 17.0549 5.75982 16.9277 5.66605 16.8339C5.57229 16.7402 5.44511 16.6875 5.3125 16.6875Z"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  )
}

export { Money }
