import {
  DownloadView,
  FilterView,
  OptionsMenuView,
  SettingsView,
  SortView,
  BulkEditView,
} from '_BRIGHT_/components/Genius/components'
import type { DrawerView } from '_BRIGHT_/components/Genius/hooks'
import {
  useDrawerContext,
  useGeniusConfigContext,
} from '_BRIGHT_/components/Genius/hooks'
import { Drawer, DrawerIconButton } from '_BRIGHT_/components/Overlays'
import type { DrawerIconButtonProps } from '_BRIGHT_/components/Overlays/Drawer/Drawer'
import { useSideDrawerStyles } from '_BRIGHT_/components/Overlays/Drawer/useSideDrawerStyles'
import type { FC } from 'react'
import React from 'react'

export const ActionDrawer: FC = () => {
  const { isOpen, close, viewsHistory, goBack, customActionRenderers } =
    useDrawerContext()
  const { actionBar } = useGeniusConfigContext()
  const {
    actionDrawerAriaLabelBack,
    actionDrawerAriaLabelClose,
    actionDrawerMenuLabel,
  } = actionBar
  const { height, maxWidth, placement } = useSideDrawerStyles()

  const drawerViews: Record<DrawerView, FC> = {
    download: DownloadView,
    sort: SortView,
    settings: SettingsView,
    filter: FilterView,
    options: OptionsMenuView,
    bulkEdit: BulkEditView,
    ...customActionRenderers,
  }

  const currentView = viewsHistory[viewsHistory.length - 1]

  return (
    <Drawer
      isOpen={isOpen}
      onClose={close}
      ariaLabelDrawer={actionDrawerMenuLabel}
      ariaLabelClose={actionDrawerAriaLabelClose}
      placement={placement}
      height={height}
      maxWidth={maxWidth}
      backButton={
        <BackButton
          isVisible={viewsHistory.length > 1}
          onClick={goBack}
          ariaLabel={actionDrawerAriaLabelBack}
        />
      }
    >
      {drawerViews[currentView]?.({})}
    </Drawer>
  )
}

type BackButtonProps = Pick<DrawerIconButtonProps, 'onClick' | 'ariaLabel'> & {
  isVisible: boolean
}

const BackButton: FC<BackButtonProps> = ({ isVisible, onClick, ariaLabel }) => (
  <>
    {isVisible && (
      <DrawerIconButton
        icon="FunctionalArrowLeft"
        onClick={onClick}
        ariaLabel={ariaLabel}
      />
    )}
  </>
)
