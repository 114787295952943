import { Adjacent, Frame, Stack } from '_BRIGHT_/components'
import { useIsMobileViewport } from '_BRIGHT_/utils'
import type { FC } from 'react'
import React from 'react'

export const ActionDrawerButtonsWrapper: FC = ({ children }) => {
  const ButtonsWrapper = useIsMobileViewport() ? Stack : Adjacent

  return (
    <Frame palette="baseTwo" paddingBottom="4xl">
      <ButtonsWrapper space="md">{children}</ButtonsWrapper>
    </Frame>
  )
}
