import React from 'react'
import type { FC } from 'react'
import { Icon } from '@chakra-ui/react'
import type { FunctionalIconSvgProps } from '_BRIGHT_/components/Icons/FunctionalIcon/FunctionalIcon'

export const FunctionalQuestion: FC<FunctionalIconSvgProps> = ({
  color,
  size,
  height,
  strokeWidth,
  ariaHidden,
}) => (
  <Icon
    viewBox="0 0 24 24"
    fill="none"
    color={color}
    width={size}
    height={height || size}
    aria-hidden={ariaHidden}
  >
    <path
      d="M12 3C10.22 3 8.47991 3.52784 6.99987 4.51677C5.51983 5.50571 4.36628 6.91131 3.68509 8.55585C3.0039 10.2004 2.82567 12.01 3.17294 13.7558C3.5202 15.5016 4.37737 17.1053 5.63604 18.364C6.89471 19.6226 8.49836 20.4798 10.2442 20.8271C11.99 21.1743 13.7996 20.9961 15.4442 20.3149C17.0887 19.6337 18.4943 18.4802 19.4832 17.0001C20.4722 15.5201 21 13.78 21 12C21 9.61305 20.0518 7.32387 18.364 5.63604C16.6761 3.94821 14.3869 3 12 3Z"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      strokeMiterlimit={10}
    />
    <path
      d="M9.60938 9.07645C9.60938 9.07645 9.64875 8.25614 10.5267 7.54973C11.0475 7.1302 11.6719 7.00879 12.2344 7.00036C12.7467 6.99379 13.2042 7.07864 13.478 7.20895C13.9467 7.43207 14.8594 7.97676 14.8594 9.13504C14.8594 10.3538 14.0625 10.9074 13.1545 11.5163C12.2466 12.1252 12 12.7861 12 13.4691"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M12 17.3754C12.5523 17.3754 13 16.9277 13 16.3754C13 15.8231 12.5523 15.3754 12 15.3754C11.4477 15.3754 11 15.8231 11 16.3754C11 16.9277 11.4477 17.3754 12 17.3754Z"
      fill="currentColor"
    />
  </Icon>
)
