import { ActionDrawerView } from '_BRIGHT_/components/Genius/components'
import { useGeniusConfigContext } from '_BRIGHT_/components/Genius/hooks'
import type { FC } from 'react'
import React from 'react'

// @TODO Genius - Dummy component - to be developed in a separate ticket - https://smartpension.atlassian.net/browse/T2-813
export const DownloadView: FC = () => {
  const { actions } = useGeniusConfigContext()
  const content = actions.download

  if (!content) return null
  return (
    <ActionDrawerView title={content.title}>
      Hardcoded: content of the Download View
    </ActionDrawerView>
  )
}
