import { Box } from '@chakra-ui/react'
import type { FC } from 'react'
import React from 'react'

import { CardFrame } from '_BRIGHT_/components/Cards/CardFrame'

const ContrastCard: FC = ({ children }) => {
  return (
    <Box border="none" borderRadius="lg" overflow="hidden" boxSize="100%">
      <CardFrame palette="baseOne">{children}</CardFrame>
    </Box>
  )
}

export { ContrastCard }
