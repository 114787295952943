import type { FC } from 'react'
import React from 'react'
import { Button } from './Button'
import { Panel } from './Panel'
import type { AccordionProps as ChakraAccordionProps } from '@chakra-ui/react'
import { Accordion as ChakraAccordion, AccordionItem } from '@chakra-ui/react'
import { Header } from './Header'
import type { DividerProps } from '_BRIGHT_/components/Divider/Divider'
import { useColor } from '_BRIGHT_/components'

export type AccordionProps = Pick<ChakraAccordionProps, 'allowMultiple'> & {
  borderColor?: DividerProps['color']
}

export const Accordion: FC<AccordionProps> = ({ children, allowMultiple = true, borderColor = 'borderSecondary' }) => {
  const border = useColor(borderColor)
  return (
    <ChakraAccordion allowMultiple={allowMultiple} border={border} w="100%">
      {children}
    </ChakraAccordion>
  )
}

export { AccordionItem, Button as AccordionButton, Panel as AccordionPanel, Header as AccordionHeader }
