import type { FC } from 'react'
import React from 'react'
import type { HiFiIconPathProps } from '_BRIGHT_/components/Icons'

const AnalyticsCharts: FC<HiFiIconPathProps> = ({
  primaryColor,
  secondaryColor,
  baseColor,
  strokeWidth,
  hasShadow,
}) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {hasShadow && (
        <path
          opacity="0.2"
          d="M16 12C15.4696 12 14.9609 12.2107 14.5858 12.5858C14.2107 12.9609 14 13.4696 14 14V30C14 30.5523 14.4477 31 15 31H21C21.5523 31 22 30.5523 22 30V14C22 13.4696 21.7893 12.9609 21.4142 12.5858C21.0391 12.2107 20.5304 12 20 12H16Z"
          fill={primaryColor}
        />
      )}
      <path
        opacity="0.2"
        d="M7 21C6.46957 21 5.96086 21.2107 5.58579 21.5858C5.21071 21.9609 5 22.4696 5 23V30C5 30.5523 5.44772 31 6 31H12C12.5523 31 13 30.5523 13 30V23C13 22.4696 12.7893 21.9609 12.4142 21.5858C12.0391 21.2107 11.5304 21 11 21H7Z"
        fill={primaryColor}
      />
      <path
        opacity="0.2"
        d="M25 17C24.4696 17 23.9609 17.2107 23.5858 17.5858C23.2107 17.9609 23 18.4696 23 19V30C23 30.5523 23.4477 31 24 31H30C30.5523 31 31 30.5523 31 30V19C31 18.4696 30.7893 17.9609 30.4142 17.5858C30.0391 17.2107 29.5304 17 29 17H25Z"
        fill={primaryColor}
      />
      <path
        d="M9 20H5C4.73478 20 4.48043 20.1054 4.29289 20.2929C4.10536 20.4804 4 20.7348 4 21V28H10V21C10 20.7348 9.89464 20.4804 9.70711 20.2929C9.51957 20.1054 9.26522 20 9 20Z"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 11H14C13.7348 11 13.4804 11.1054 13.2929 11.2929C13.1054 11.4804 13 11.7348 13 12V28H19V12C19 11.7348 18.8946 11.4804 18.7071 11.2929C18.5196 11.1054 18.2652 11 18 11Z"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27 16H23C22.7348 16 22.4804 16.1054 22.2929 16.2929C22.1054 16.4804 22 16.7348 22 17V28H28V17C28 16.7348 27.8946 16.4804 27.7071 16.2929C27.5196 16.1054 27.2652 16 27 16Z"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M7 10L11 6" stroke={secondaryColor} strokeWidth={strokeWidth} />
      <path
        d="M16 4.5L23 7"
        stroke={secondaryColor}
        strokeWidth={strokeWidth}
      />
      <circle
        cx="13.5"
        cy="4.5"
        r="2.5"
        fill="white"
        stroke={secondaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="5.5"
        cy="11.5"
        r="2.5"
        fill="white"
        stroke={secondaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="24.5"
        cy="7.5"
        r="2.5"
        fill={baseColor}
        stroke={secondaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export { AnalyticsCharts }
