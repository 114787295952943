import {
  Adjacent,
  Spinner,
  Stack,
  Text,
  useColor,
  withPalette,
} from '_BRIGHT_/components'
import { Box, chakra } from '@chakra-ui/react'
import { useOption } from 'react-aria'
import * as R from 'ramda'
import type { PaletteName } from '_BRIGHT_/components/PaletteProvider'
import type { ListState } from '@react-stately/list'
import type { Node } from '@react-types/shared'
import type { FC, ReactNode } from 'react'
import React, { useRef } from 'react'
import type { Option } from '_BRIGHT_/components/Forms/common'

export type OptionListItemProps<T> = {
  item: Node<T>
  state: ListState<T>
  shouldUseVirtualFocus: boolean
}

type BaseOptionListItemProps = OptionListItemProps<Option> & {
  isSelected: boolean
}

const BaseOptionListItem: FC<BaseOptionListItemProps> = ({
  item,
  state,
  isSelected,
  shouldUseVirtualFocus,
}) => {
  const {
    key,
    value: { label, icon, description },
  } = item

  const optionRef = useRef<HTMLLIElement>(null)

  const { optionProps } = useOption(
    {
      key,
      isSelected,
      shouldFocusOnHover: true,
      shouldUseVirtualFocus,
    },
    state,
    optionRef,
  )

  const isFocused = state.selectionManager.focusedKey === item.key
  const bgColor = useColor('core')
  const hoverBgColor = useColor('core', 'baseOne')
  const focusColor = useColor('focus', 'baseOne')

  const getBackgroundColor = R.cond([
    [R.always(isSelected), R.always(bgColor)],
    [R.always(isFocused), R.always(hoverBgColor)],
    [R.T, R.always(bgColor)],
  ])

  const getBoxShadow = R.cond([
    [R.always(isSelected), R.always('none')],
    [R.always(isFocused), R.always(`4px 0 0 inset ${focusColor}`)],
  ])

  return (
    <chakra.li
      {...optionProps}
      ref={optionRef}
      py="sm"
      px="md"
      outline="none"
      background={getBackgroundColor()}
      boxShadow={getBoxShadow()}
      _hover={{ boxShadow: 'none' }}
    >
      <Adjacent alignY="center" space={icon ? 'lg' : undefined}>
        {icon && (
          <Text variant={isSelected ? 'primary' : 'secondary'}>{icon}</Text>
        )}
        <Stack>
          <Text variant={isSelected ? 'primary' : 'secondary'}>{label}</Text>
          {description && (
            <Text level="xs" variant={isSelected ? 'primary' : 'tertiary'}>
              {description}
            </Text>
          )}
        </Stack>
      </Adjacent>
    </chakra.li>
  )
}

const OptionListItemWithPalette =
  withPalette<BaseOptionListItemProps>(BaseOptionListItem)

export const OptionListItem: FC<OptionListItemProps<Option>> = ({
  item,
  state,
  shouldUseVirtualFocus,
}) => {
  const isSelected = state.selectionManager.isSelected(item.key)
  const palette: PaletteName = isSelected ? 'highlight' : 'baseTwo'

  return (
    <OptionListItemWithPalette
      isSelected={isSelected}
      state={state}
      item={item}
      shouldUseVirtualFocus={shouldUseVirtualFocus}
      palette={palette}
    />
  )
}

const BasicListItem: React.FC<{ icon?: ReactNode }> = ({ icon, children }) => {
  const bgColor = useColor('core')

  return (
    <Box as="li" background={bgColor} py="sm" px="md" outline="none">
      <Adjacent alignY="center" space={icon ? 'lg' : undefined}>
        {icon}
        <Text variant="secondary">{children}</Text>
      </Adjacent>
    </Box>
  )
}

export const LoadingOptionListItem: React.FC = ({ children }) => (
  <BasicListItem icon={<Spinner variant="positive" />}>
    {children}
  </BasicListItem>
)

export const EmptyOptionListItem: React.FC = ({ children }) => (
  <BasicListItem>{children}</BasicListItem>
)
