import type { FC } from 'react'
import React from 'react'
import type { HiFiIconPathProps } from '_BRIGHT_/components/Icons'

const Todo: FC<HiFiIconPathProps> = ({
  primaryColor,
  secondaryColor,
  baseColor,
  strokeWidth,
  hasShadow,
}) => {
  return (
    <>
      {hasShadow && (
        <path
          opacity="0.2"
          d="M12.9932 8.21875C13.3961 5.82443 15.4843 4 18 4C20.5156 4 22.6039 5.82443 23.0067 8.21875H26C27.6569 8.21875 29 9.5619 29 11.2188V28C29 29.6569 27.6569 31 26 31H10C8.34315 31 7 29.6569 7 28V11.2187C7 9.5619 8.34315 8.21875 10 8.21875H12.9932Z"
          fill={primaryColor}
        />
      )}
      <path
        d="M9 11H15"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 15H12"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 27H5.66667C5.22464 27 4.80072 26.8174 4.48816 26.4923C4.17559 26.1673 4 25.7264 4 25.2667V7.06667C4 6.60696 4.17559 6.16608 4.48816 5.84101C4.80072 5.51595 5.22464 5.33333 5.66667 5.33333H9.83333C9.83333 4.18406 10.2723 3.08186 11.0537 2.2692C11.8351 1.45655 12.8949 1 14 1C15.1051 1 16.1649 1.45655 16.9463 2.2692C17.7277 3.08186 18.1667 4.18406 18.1667 5.33333H22.3333C22.7754 5.33333 23.1993 5.51595 23.5118 5.84101C23.8244 6.16608 24 6.60696 24 7.06667V9.66667"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 20C16 21.8565 16.7375 23.637 18.0503 24.9497C19.363 26.2625 21.1435 27 23 27C24.8565 27 26.637 26.2625 27.9497 24.9497C29.2625 23.637 30 21.8565 30 20C30 18.1435 29.2625 16.363 27.9497 15.0503C26.637 13.7375 24.8565 13 23 13C21.1435 13 19.363 13.7375 18.0503 15.0503C16.7375 16.363 16 18.1435 16 20Z"
        fill={baseColor}
        stroke={secondaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.3972 18L23.0403 22.4766C22.9656 22.5759 22.8705 22.658 22.7614 22.7174C22.6523 22.7767 22.5318 22.812 22.4079 22.8207C22.284 22.8295 22.1596 22.8116 22.0433 22.7682C21.9269 22.7247 21.8212 22.6568 21.7333 22.5691L20 20.8357"
        stroke={secondaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  )
}

export { Todo }
