import React from 'react'
import type { FC, ReactNode } from 'react'
import { Text } from '_BRIGHT_/components/Typography/Text'
import type {
  TextElement,
  TextLevel,
  TextVariant,
  TextProps,
} from '_BRIGHT_/components/Typography/Text'
import { Box } from '@chakra-ui/react'

export type GeniusListTextProps = Pick<TextProps, 'isTruncated'> & {
  textStyle: 'light' | 'regular' | 'strong'
  level?: Extract<TextLevel, 'sm' | 'xs'>
  children: ReactNode
  mask?: string
}

type GeniusListTextStyleKeys = GeniusListTextProps['textStyle']

type GeniuListTextStyleTypes = {
  variant: Extract<TextVariant, 'secondary' | 'tertiary'>
  as: Extract<TextElement, 'span' | 'b'>
}

const geniusListTextStyleMap: Record<
  GeniusListTextStyleKeys,
  GeniuListTextStyleTypes
> = {
  light: {
    variant: 'tertiary',
    as: 'span',
  },
  regular: {
    variant: 'secondary',
    as: 'span',
  },
  strong: {
    variant: 'secondary',
    as: 'b',
  },
}

export const GeniusListText: FC<GeniusListTextProps> = ({
  textStyle,
  level = 'sm',
  children,
  isTruncated = true,
  mask,
}) => {
  return (
    <Text
      {...geniusListTextStyleMap[textStyle]}
      level={level}
      isTruncated={isTruncated}
      align="start"
    >
      {mask && (
        <Box
          aria-hidden="true"
          as="span"
          display="block"
          opacity={0}
          height={0}
          overflow="hidden"
          whiteSpace="nowrap"
        >
          {mask}
        </Box>
      )}
      {children}
    </Text>
  )
}
