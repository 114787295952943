import type { FC } from 'react'
import { PageHeadingBlock } from 'components/PageHeadingBlock'
import { CenteredBlock } from 'components/CenteredBlock'
import { Glossary } from 'components/Glossary'

export const GlossaryOfTermsPage: FC = () => {
  return (
    <>
      <PageHeadingBlock
        pageHeading="Glossary of terms"
        backLink="/"
        backLinkText="Back"
      />
      <CenteredBlock>
        <Glossary />
      </CenteredBlock>
    </>
  )
}
