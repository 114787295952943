import React, { createContext, useContext } from 'react'
import type { FC } from 'react'
import { Box, Flex } from '@chakra-ui/react'
import { mapResponsiveStyle } from '_BRIGHT_/utils'
import type { ResponsiveStyle } from '_BRIGHT_/utils'
import {
  getAlignItems,
  getWidth,
  getJustifyContent,
  getFlexGrow,
  spaceMap,
} from '_BRIGHT_/components/Layout/Common'
import type {
  AlignX,
  AlignY,
  ResponsiveAlignX,
  ResponsiveAlignY,
  ResponsiveWidth,
  Space,
} from '_BRIGHT_/components/Layout/Common'

export type ColumnsProps = {
  space?: Space
  collapsedSpace?: Space
  alignX?: AlignX
  alignY?: AlignY
}

type ColumnProps = {
  alignX?: ResponsiveAlignX
  alignY?: ResponsiveAlignY
  alignSelf?: ResponsiveAlignY
  width?: ResponsiveWidth
}

const ColumnContext = createContext<{ collapse?: boolean }>({})

const Column: FC<ColumnProps> = ({
  children,
  alignX = 'start',
  alignY = 'top',
  alignSelf,
  width: uncollapsedWidth = 'fluid',
}) => {
  const { collapse } = useContext(ColumnContext)
  const columnWidth: ResponsiveWidth = collapse
    ? ({ base: 'full', sm: uncollapsedWidth } as ResponsiveWidth)
    : uncollapsedWidth
  const width = mapResponsiveStyle(getWidth, columnWidth)
  const flexGrow = mapResponsiveStyle(getFlexGrow, uncollapsedWidth)
  const justifyContent = mapResponsiveStyle(getJustifyContent, alignX)
  const alignItems = mapResponsiveStyle(getAlignItems, alignY)
  const optionalAlignSelf = alignSelf
    ? mapResponsiveStyle(getAlignItems, alignSelf)
    : undefined

  return (
    <Box
      minWidth="0"
      width={width}
      flexGrow={flexGrow}
      display="flex"
      justifyContent={justifyContent}
      alignItems={alignItems}
      alignSelf={optionalAlignSelf}
    >
      {children}
    </Box>
  )
}

const Columns: FC<ColumnsProps> = ({
  children,
  collapsedSpace,
  space = 'none',
  alignX = 'stretch',
  alignY = 'top',
}) => {
  const mappedSpace = spaceMap[space]
  const columnSpace = collapsedSpace
    ? { base: collapsedSpace, sm: space }
    : mappedSpace
  const collapse = Boolean(collapsedSpace)
  const justifyContent = mapResponsiveStyle(getJustifyContent, alignX)
  const alignItems = mapResponsiveStyle(getAlignItems, alignY)
  const flexWrap = (
    collapse ? { base: 'wrap', sm: 'nowrap' } : 'nowrap'
  ) as ResponsiveStyle<'wrap' | 'nowrap'>

  return (
    <ColumnContext.Provider value={{ collapse }}>
      <Flex
        width="100%"
        justifyContent={justifyContent}
        alignItems={alignItems}
        gridGap={columnSpace}
        flexWrap={flexWrap}
      >
        {children}
      </Flex>
    </ColumnContext.Provider>
  )
}

export { Columns, Column }
