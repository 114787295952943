import type { BoxProps } from '@chakra-ui/react'
import { Breadcrumb as ChakraBreadcrumb } from '@chakra-ui/react'
import type { FC } from 'react'
import React from 'react'

import { BreadcrumbItem } from './BreadcrumbItem'

type BreadcrumbProps = Pick<BoxProps, 'children'>

const Breadcrumb: FC<BreadcrumbProps> = ({ children }) => {
  return <ChakraBreadcrumb>{children}</ChakraBreadcrumb>
}

export { BreadcrumbItem, Breadcrumb }
