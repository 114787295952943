import type { GeniusCustomActionRenderers } from '_BRIGHT_/components'
import type { GeniusActionsMap } from '_BRIGHT_/components/Genius'
import type { DrawerView } from '_BRIGHT_/components/Genius/hooks'
import type { FC } from 'react'
import React, { createContext } from 'react'

export type DrawerContextValue = {
  actions: GeniusActionsMap
  isOpen: boolean
  open: (view: DrawerView) => void
  close: () => void
  goBack: () => void
  viewsHistory: DrawerView[]
  customActionRenderers: GeniusCustomActionRenderers
}

export const DrawerContext = createContext<DrawerContextValue | null>(null)

export const DrawerContextProvider: FC<{ value: DrawerContextValue }> = ({
  value,
  children,
}) => <DrawerContext.Provider value={value}>{children}</DrawerContext.Provider>
