import { useColor } from '_BRIGHT_/components'
import type { CheckboxProps as ChakraCheckboxProps } from '@chakra-ui/react'
import { VisuallyHidden } from '@chakra-ui/react'
import { Box, Checkbox as ChakraCheckbox } from '@chakra-ui/react'
import type { FormErrors } from '_BRIGHT_/components/Forms/common'
import { FormControl } from '_BRIGHT_/components/Forms/FormControl'
import type { FC } from 'react'
import React from 'react'
import { useShadow } from '_BRIGHT_/utils'
import type { Space } from '_BRIGHT_/components/Layout/Common'
import type { BorderColor } from '_BRIGHT_/components/PaletteProvider/PaletteProvider'

type CheckboxSpace = Extract<Space, 'sm' | 'md' | 'none'>

export type BaseCheckboxProps = Pick<
  ChakraCheckboxProps,
  'isInvalid' | 'isRequired' | 'isChecked' | 'onChange'
> & {
  value?: string | number
  label: string
  space?: CheckboxSpace
  borderColor?: BorderColor
  isLabelVisuallyHidden?: boolean
}

export const BaseCheckbox: FC<BaseCheckboxProps> = ({
  label,
  isChecked,
  onChange,
  isInvalid,
  isRequired,
  value,
  space = 'sm',
  isLabelVisuallyHidden = false,
  borderColor = 'borderPrimary',
}) => {
  const checkboxBorderColor = useColor(borderColor)
  const checkedFillColor = useColor('accentPrimary')
  const activeColor = useColor('active', 'highlight')
  const boxShadow = useShadow('focus')

  const labelBox = (
    <Box fontSize="sm" lineHeight="none" verticalAlign="baseline">
      {label}
    </Box>
  )

  return (
    <ChakraCheckbox
      // See bright/src/theme/default/components.ts for how this styling works
      colorScheme={checkedFillColor}
      variant="primary"
      borderColor={checkboxBorderColor}
      isInvalid={isInvalid}
      isRequired={isRequired}
      spacing={space}
      isChecked={isChecked}
      onChange={onChange}
      value={value}
      _checked={{
        _focus: {
          bg: activeColor,
        },
      }}
      _focus={{ boxShadow }}
    >
      {/* ChakraCheckbox wraps the content in a label as it stands,
    using a box here for ease of styling to match designs */}
      {isLabelVisuallyHidden ? (
        <VisuallyHidden>{labelBox}</VisuallyHidden>
      ) : (
        labelBox
      )}
    </ChakraCheckbox>
  )
}

export type CheckboxProps = BaseCheckboxProps &
  Pick<ChakraCheckboxProps, 'id'> & {
    errors?: FormErrors
    isRequired?: boolean
  }

export const Checkbox: FC<CheckboxProps> = ({
  id,
  label,
  isChecked,
  onChange,
  isInvalid,
  isRequired,
  errors,
  value,
  isLabelVisuallyHidden,
}) => (
  <FormControl id={id} isRequired={isRequired} errors={errors}>
    <BaseCheckbox
      onChange={onChange}
      label={label}
      isChecked={isChecked}
      isInvalid={isInvalid}
      isRequired={isRequired}
      value={value}
      isLabelVisuallyHidden={isLabelVisuallyHidden}
    />
  </FormControl>
)
