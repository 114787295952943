import type { InputProps as ChakraInputProps } from '@chakra-ui/input/dist/declarations/src'
import { Input as ChakraInput, InputGroup, InputLeftAddon, InputRightAddon } from '@chakra-ui/react'
import type { ChangeEvent } from 'react'
import React, { forwardRef } from 'react'
import { useBaseInputFieldStyles } from './useBaseInputFieldStyles'

export type BaseInputFieldProps = Pick<
  ChakraInputProps,
  | 'isInvalid'
  | 'isRequired'
  | 'textAlign'
  | 'role'
  | 'inputMode'
  | 'pattern'
  | 'onKeyDown'
  | 'onBlur'
  | 'minLength'
  | 'maxLength'
  | 'id'
  | 'name'
  | '_placeholder'
> & {
  value?: string
  type?: string
  characters?: number
  color?: string
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  width?: string
  height?: string
  leftAddon?: string
  rightAddon?: string
  ariaLabel?: string
  borderless?: boolean
  placeholder?: string
}

const BaseInputField = forwardRef<HTMLInputElement, BaseInputFieldProps>(
  ({ placeholder, borderless, leftAddon, rightAddon, characters, width, ariaLabel, height, ...inputProps }, ref) => {
    const { size, borderColor, borderWidth, ...styles } = useBaseInputFieldStyles({ characters, width, height })

    return (
      <InputGroup>
        {leftAddon && (
          <InputLeftAddon height={size} borderWidth={borderWidth} borderColor={borderColor} borderRightWidth="none">
            {leftAddon}
          </InputLeftAddon>
        )}
        <ChakraInput
          {...styles}
          borderWidth={borderless ? '0' : borderWidth}
          borderColor={borderColor}
          ref={ref}
          aria-label={ariaLabel}
          placeholder={placeholder}
          {...inputProps}
        />
        {rightAddon && (
          <InputRightAddon height={size} borderWidth={borderWidth} borderColor={borderColor} borderLeftWidth="none">
            {rightAddon}
          </InputRightAddon>
        )}
      </InputGroup>
    )
  }
)

export { BaseInputField }
