import type { FC } from 'react'
import React from 'react'
import { Flex } from '@chakra-ui/react'
import { useGeniusConfigContext } from '_BRIGHT_/components/Genius/hooks'
import { BaseCheckbox } from '_BRIGHT_/components/Forms/Checkbox'

type BulkSelectionCheckboxProps = {
  id: number | string
  primaryLabel: string
  isChecked: boolean
  onChange: () => void
}

export const BulkSelectionCheckbox: FC<BulkSelectionCheckboxProps> = ({
  primaryLabel,
  isChecked,
  onChange,
}) => {
  const {
    // @TODO Genius - refactor all prefix and suffix dynamic translations - https://smartpension.atlassian.net/browse/T2-1175
    body: { bulkEdit },
  } = useGeniusConfigContext()

  if (!bulkEdit) return null

  const { checkboxLabelPrefix, checkboxLabelSuffix } = bulkEdit

  // @TODO Genius - refactor all prefix and suffix dynamic translations - https://smartpension.atlassian.net/browse/T2-1175
  const checkboxLabel = `${checkboxLabelPrefix} ${primaryLabel} ${checkboxLabelSuffix}`

  return (
    <Flex alignContent="center" width="full">
      <BaseCheckbox
        label={checkboxLabel}
        isLabelVisuallyHidden={true}
        borderColor="borderSecondary"
        onChange={onChange}
        isChecked={isChecked}
      />
    </Flex>
  )
}
