import type { OnBulkEditSubmit } from '_BRIGHT_/components/Genius'
import type { FC } from 'react'
import React, { createContext } from 'react'

export type GeniusBulkEditContextValue = {
  onBulkEditSubmit: OnBulkEditSubmit
}

export const GeniusBulkEditContext =
  createContext<GeniusBulkEditContextValue | null>(null)

export const GeniusBulkEditContextProvider: FC<{
  value: GeniusBulkEditContextValue
}> = ({ value, children }) => (
  <GeniusBulkEditContext.Provider value={value}>
    {children}
  </GeniusBulkEditContext.Provider>
)
