import type { FC } from 'react'
import React from 'react'
import type { HiFiIconPathProps } from '_BRIGHT_/components/Icons'

const Flexible: FC<HiFiIconPathProps> = ({
  primaryColor,
  secondaryColor,
  baseColor,
  strokeWidth,
  hasShadow,
}) => {
  return (
    <>
      {hasShadow && (
        <path
          opacity="0.2"
          d="M12.0754 14.0754C14.0445 12.1062 16.7152 11 19.5 11C22.2848 11 24.9555 12.1062 26.9246 14.0754C28.8938 16.0445 30 18.7152 30 21.5C30 24.2848 28.8938 26.9555 26.9246 28.9246C24.9555 30.8938 22.2848 32 19.5 32C16.7152 32 14.0445 30.8938 12.0754 28.9246C10.1062 26.9555 9 24.2848 9 21.5C9 18.7152 10.1062 16.0445 12.0754 14.0754Z"
          fill={primaryColor}
        />
      )}
      <path
        d="M6 17.5C6 20.0196 7.00089 22.4359 8.78249 24.2175C10.5641 25.9991 12.9804 27 15.5 27C18.0196 27 20.4359 25.9991 22.2175 24.2175C23.9991 22.4359 25 20.0196 25 17.5C25 14.9804 23.9991 12.5641 22.2175 10.7825C20.4359 9.00089 18.0196 8 15.5 8C12.9804 8 10.5641 9.00089 8.78249 10.7825C7.00089 12.5641 6 14.9804 6 17.5V17.5Z"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="22.9016"
        y="11"
        width="3"
        height="8"
        rx="1"
        transform="rotate(59.6209 22.9016 11)"
        fill={baseColor}
        stroke={secondaryColor}
        strokeWidth={strokeWidth}
      />
      <path
        d="M2 10.7664C2 11.0316 2.10536 11.2859 2.29289 11.4735C2.48043 11.661 2.73478 11.7664 3 11.7664C3.26522 11.7664 3.51957 11.661 3.70711 11.4735C3.89464 11.2859 4 11.0316 4 10.7664C4 10.5011 3.89464 10.2468 3.70711 10.0593C3.51957 9.87171 3.26522 9.76636 3 9.76636C2.73478 9.76636 2.48043 9.87171 2.29289 10.0593C2.10536 10.2468 2 10.5011 2 10.7664V10.7664Z"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 25.3282C2 25.5935 2.10536 25.8478 2.29289 26.0354C2.48043 26.2229 2.73478 26.3282 3 26.3282C3.26522 26.3282 3.51957 26.2229 3.70711 26.0354C3.89464 25.8478 4 25.5935 4 25.3282C4 25.063 3.89464 24.8087 3.70711 24.6211C3.51957 24.4336 3.26522 24.3282 3 24.3282C2.73478 24.3282 2.48043 24.4336 2.29289 24.6211C2.10536 24.8087 2 25.063 2 25.3282V25.3282Z"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.2407 25.3282C27.2407 25.5935 27.3461 25.8478 27.5336 26.0354C27.7212 26.2229 27.9755 26.3282 28.2407 26.3282C28.5059 26.3282 28.7603 26.2229 28.9478 26.0354C29.1354 25.8478 29.2407 25.5935 29.2407 25.3282C29.2407 25.063 29.1354 24.8087 28.9478 24.6211C28.7603 24.4336 28.5059 24.3282 28.2407 24.3282C27.9755 24.3282 27.7212 24.4336 27.5336 24.6211C27.3461 24.8087 27.2407 25.063 27.2407 25.3282Z"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.2407 10.7664C27.2407 11.0316 27.3461 11.2859 27.5336 11.4735C27.7212 11.661 27.9755 11.7664 28.2407 11.7664C28.5059 11.7664 28.7603 11.661 28.9478 11.4735C29.1354 11.2859 29.2407 11.0316 29.2407 10.7664C29.2407 10.5011 29.1354 10.2468 28.9478 10.0593C28.7603 9.87171 28.5059 9.76636 28.2407 9.76636C27.9755 9.76636 27.7212 9.87171 27.5336 10.0593C27.3461 10.2468 27.2407 10.5011 27.2407 10.7664Z"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6204 3C14.6204 3.26522 14.7257 3.51957 14.9133 3.70711C15.1008 3.89464 15.3551 4 15.6204 4C15.8856 4 16.1399 3.89464 16.3275 3.70711C16.515 3.51957 16.6204 3.26522 16.6204 3C16.6204 2.73478 16.515 2.48043 16.3275 2.29289C16.1399 2.10536 15.8856 2 15.6204 2C15.3551 2 15.1008 2.10536 14.9133 2.29289C14.7257 2.48043 14.6204 2.73478 14.6204 3V3Z"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  )
}

export { Flexible }
