import React from 'react'
import { useWatch } from 'react-hook-form'
import { Box } from '@chakra-ui/react'
import { Button, Stack, Text, useColor } from '_BRIGHT_/components'
import type { AddressFieldProps } from '_BRIGHT_/components/Forms/AddressField'
import type { Control, FieldValues, Path } from 'react-hook-form'
import type { AddressFieldItem } from '_BRIGHT_/components/Forms/AddressField/common/types'
import type { ReactNode } from 'react'

export type AddressPreviewProps<PropsInput> = Pick<
  AddressFieldProps<FieldValues>,
  'id' | 'editAddressLabel' | 'enterAddressLabel'
> & {
  fields: AddressFieldItem[]
  control: Control<PropsInput>
  onEditClick: () => void
}

export const AddressPreview = <T,>({
  id,
  fields,
  control,
  editAddressLabel,
  enterAddressLabel,
  onEditClick,
}: AddressPreviewProps<T>): JSX.Element => {
  const addressFields = useWatch({
    control: control,
    name: fields.map(({ name }) => name) as Path<T>[],
  })

  const showPreview = addressFields.some(field => field)

  const bgColor = useColor('core', 'baseOne')

  return showPreview ? (
    <Box
      padding={{ base: 'md', sm: 'lg' }}
      borderRadius="lg"
      width="100%"
      backgroundColor={bgColor}
    >
      <Stack>
        <address data-testid={id} style={{ fontStyle: 'normal' }}>
          {addressFields.filter(Boolean).map((field, index) => (
            <Text as="div" key={fields[index].name}>
              {field as ReactNode}
            </Text>
          ))}
        </address>
        <Box paddingTop="sm">
          <Button variant="primaryLink" onClick={onEditClick}>
            {editAddressLabel}
          </Button>
        </Box>
      </Stack>
    </Box>
  ) : (
    <Button variant="primaryLink" onClick={onEditClick}>
      {enterAddressLabel}
    </Button>
  )
}
