import { useColor, withPalette } from '_BRIGHT_/components'
import type {
  AlignX,
  AlignY,
  Space,
  Width,
} from '_BRIGHT_/components/Layout/Common'
import {
  alignXMap,
  alignYMap,
  spaceMap,
  widthsMap,
} from '_BRIGHT_/components/Layout/Common'
import type { PaletteName } from '_BRIGHT_/components/PaletteProvider'
import type { ResponsiveStyle } from '_BRIGHT_/utils'
import { mapResponsiveStyle } from '_BRIGHT_/utils'
import { Flex } from '@chakra-ui/react'
import type { SystemProps } from '@chakra-ui/system'
import type { FC } from 'react'
import React from 'react'

type FrameWidth = Exclude<Width, 'width' | 'fluid' | 'content'>

export type FrameProps = {
  alignX?: AlignX
  alignY?: AlignY
  paddingX?: Space
  paddingY?: Space
  paddingTop?: Space
  paddingBottom?: Space
  width?: ResponsiveStyle<FrameWidth>
  flexGrow?: SystemProps['flexGrow']
  palette?: PaletteName
}

type ProtoFrameProps = Omit<FrameProps, 'palette'>

const getWidth = (width: FrameWidth): string => widthsMap[width]

const ProtoFrame: FC<ProtoFrameProps> = ({
  children,
  alignX = 'stretch',
  alignY = 'top',
  paddingX = 'none',
  paddingY = 'none',
  paddingTop = paddingY,
  paddingBottom = paddingY,
  width = 'full',
  flexGrow,
}) => {
  const backgroundColor = useColor('core')
  const frameWidth = mapResponsiveStyle(getWidth, width)

  return (
    <Flex
      backgroundColor={backgroundColor}
      justifyContent={alignXMap[alignX]}
      alignItems={alignYMap[alignY]}
      paddingX={spaceMap[paddingX]}
      paddingY={spaceMap[paddingY]}
      paddingTop={spaceMap[paddingTop]}
      paddingBottom={spaceMap[paddingBottom]}
      width={frameWidth}
      flex={flexGrow}
    >
      {children}
    </Flex>
  )
}

export const Frame = withPalette<FrameProps>(ProtoFrame)
