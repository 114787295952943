import * as R from 'ramda'
import type { GeniusFieldsConfig } from '_BRIGHT_/components/Genius'
import type { GeniusState, Settings } from '_BRIGHT_/components/Genius/hooks'

/**
 * Register Genius state keys in this config to persist them via local storage.
 */
const persistedStateConfig = {
  settings: {
    getDefaultValue(fieldsConfig: GeniusFieldsConfig): Settings {
      return Object.keys(fieldsConfig)
        .filter(key => fieldsConfig[key].isShownInMetadataList)
        .sort(
          (a, b) =>
            fieldsConfig[a].settings.position -
            fieldsConfig[b].settings.position,
        )
        .map(key => ({
          field: fieldsConfig[key].fieldName,
          showInSummary: fieldsConfig[key].settings.showInSummary,
        }))
    },
    getValidPersistedState(
      persistedSettings: unknown,
      fieldsConfig: GeniusFieldsConfig,
    ): Settings {
      const defaultValue =
        persistedStateConfig.settings.getDefaultValue(fieldsConfig)

      if (!Array.isArray(persistedSettings)) {
        return defaultValue
      }

      return defaultValue.map(defaultValue => {
        const matchingPersistedSetting = persistedSettings.find(
          persistedSetting =>
            persistedSetting.field === defaultValue.field &&
            typeof persistedSetting.showInSummary === 'boolean',
        )

        if (matchingPersistedSetting) {
          return {
            field: matchingPersistedSetting.field,
            showInSummary: matchingPersistedSetting.showInSummary,
          }
        }

        return defaultValue
      })
    },
  },
}

type PersistedStateKey = keyof typeof persistedStateConfig
type PersistedState = Pick<GeniusState, PersistedStateKey>

const getDefaultState = (fieldsConfig: GeniusFieldsConfig): PersistedState => {
  const defaultState = {} as PersistedState
  const validStateKeys = Object.keys(
    persistedStateConfig,
  ) as PersistedStateKey[]

  validStateKeys.forEach(key => {
    const { getDefaultValue } = persistedStateConfig[key]
    defaultState[key] = getDefaultValue(fieldsConfig)
  })

  return defaultState
}

const pickValidPersistedState = (
  persistedState: Record<string, unknown>,
  fieldsConfig: GeniusFieldsConfig,
): PersistedState => {
  const validState = {} as PersistedState
  const validStateKeys = Object.keys(
    persistedStateConfig,
  ) as PersistedStateKey[]

  validStateKeys.forEach(key => {
    const { getValidPersistedState } = persistedStateConfig[key]
    validState[key] = getValidPersistedState(persistedState[key], fieldsConfig)
  })

  return validState
}

export const getGeniusStatePersistedValues = (
  hookKey: string,
  fieldsConfig: GeniusFieldsConfig,
): PersistedState => {
  const defaultState = getDefaultState(fieldsConfig)
  const stateFromLocalStorage = window.localStorage.getItem(hookKey)

  if (stateFromLocalStorage === null) {
    return defaultState
  }

  let parsedState: Record<string, unknown>
  try {
    parsedState = JSON.parse(stateFromLocalStorage)
  } catch (e) {
    return defaultState
  }

  return {
    ...defaultState,
    ...pickValidPersistedState(parsedState, fieldsConfig),
  }
}

export const setGeniusStatePersistedValues = (
  values: GeniusState,
  hookKey: string,
): void => {
  const persistedValues = R.pick(Object.keys(persistedStateConfig), values)
  window.localStorage.setItem(hookKey, JSON.stringify(persistedValues))
}
