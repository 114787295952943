import { Button, Stack } from '_BRIGHT_/components'
import { Radio, RadioGroup, withController } from '_BRIGHT_/components/Forms'
import type { Option } from '_BRIGHT_/components/Forms'
import { ActionDrawerButtonsWrapper } from '_BRIGHT_/components/Genius/components'
import {
  dispatchAddFilter,
  dispatchRemoveFilter,
  useDrawerContext,
  useGeniusStateContext,
} from '_BRIGHT_/components/Genius/hooks'
import type { FilterValueWithoutOperator } from '_BRIGHT_/components/Genius/hooks'
import React from 'react'
import type { FC } from 'react'
import { useForm } from 'react-hook-form'
import type {
  GeniusOptionInputConfig,
  SharedFilterComponentProps,
} from '_BRIGHT_/components/Genius'

export const FILTER_BY_OPTION_TEST_ID = 'filter-by-option'

export type FilterByOptionProps = SharedFilterComponentProps &
  Pick<GeniusOptionInputConfig, 'options'>

const ControlledRadioGroup =
  withController<FilterValueWithoutOperator>()(RadioGroup)

export const FilterByOption: FC<FilterByOptionProps> = ({
  options,
  fieldName,
  confirmButtonLabel,
  cancelButtonLabel,
}) => {
  const { filters, dispatch } = useGeniusStateContext()
  const { close } = useDrawerContext()
  const initialValue = filters?.[fieldName]
  const { control, handleSubmit, setValue, reset } =
    useForm<FilterValueWithoutOperator>({
      mode: 'onSubmit',
      criteriaMode: 'all',
      defaultValues: initialValue,
    })

  const applyFilter = (formData: FilterValueWithoutOperator): void => {
    dispatchAddFilter(dispatch, fieldName, formData)
    // @TODO Genius - Refactor to triggerthe close action when the GeniusState gets updated - https://smartpension.atlassian.net/browse/T2-1096
    close()
  }
  const onClear = () => {
    dispatchRemoveFilter(dispatch, fieldName)
    reset({ value: '' })
    // @TODO Genius - Refactor to triggerthe close action when the GeniusState gets updated - https://smartpension.atlassian.net/browse/T2-1096
    close()
  }

  return (
    <form
      data-testid={FILTER_BY_OPTION_TEST_ID}
      onSubmit={handleSubmit(applyFilter)}
    >
      <Stack space="4xl">
        <ControlledRadioGroup
          onChange={value => setValue('value', value)}
          name="value"
          control={control}
          isRequired
        >
          {Object.values(options).map(({ label, id }: Option) => {
            return <Radio label={label} value={id} key={id} />
          })}
        </ControlledRadioGroup>
        <ActionDrawerButtonsWrapper>
          <Button
            isFullWidthForMobile
            variant="secondaryButton"
            onClick={onClear}
          >
            {cancelButtonLabel}
          </Button>
          <Button isFullWidthForMobile type="submit">
            {confirmButtonLabel}
          </Button>
        </ActionDrawerButtonsWrapper>
      </Stack>
    </form>
  )
}
