import { useContext } from 'react'
import type { DrawerContextValue } from '_BRIGHT_/components/Genius/context'
import { DrawerContext } from '_BRIGHT_/components/Genius/context'

export const useDrawerContext = (): DrawerContextValue => {
  const context = useContext(DrawerContext)

  if (!context)
    throw new Error(
      'useDrawerContext must be used within DrawerContextProvider',
    )

  return context
}
