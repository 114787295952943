import { FC } from 'react'
import { Link } from '_BRIGHT_'
import { ExternalLink } from 'components/ExternalLink'
import { Box } from '@chakra-ui/layout'

export const footerLinkPath = 'https://www.smart.co/footer'

export const FooterLinkWrapper: FC = ({ children }) => (
  <Box d="inline-flex" mr="3">
    {children}
  </Box>
)

export const FooterLinks = () => {
  return (
    <>
      <FooterLinkWrapper>
        <ExternalLink
          href={`${footerLinkPath}/terms-and-conditions`}
          rel="noopener noreferrer"
          target="_blank"
        >
          Terms and conditions
        </ExternalLink>
      </FooterLinkWrapper>
      <FooterLinkWrapper>
        <ExternalLink
          href={`${footerLinkPath}/cookies-information`}
          rel="noopener noreferrer"
          target="_blank"
        >
          Cookies
        </ExternalLink>
      </FooterLinkWrapper>
      <FooterLinkWrapper>
        <ExternalLink
          href={`${footerLinkPath}/privacy-policy`}
          rel="noopener noreferrer"
          target="_blank"
        >
          Privacy policy
        </ExternalLink>
      </FooterLinkWrapper>
      <FooterLinkWrapper>
        <ExternalLink
          href={`${footerLinkPath}/accessibility`}
          rel="noopener noreferrer"
          target="_blank"
        >
          Accessibility
        </ExternalLink>
      </FooterLinkWrapper>
      <FooterLinkWrapper>
        <Link href="/disclaimer" level="2xs">
          Software disclaimer
        </Link>
      </FooterLinkWrapper>
    </>
  )
}
