import {
  AnalyticsPage,
  NotFoundPage,
  DisclaimerPage,
  WelcomePage,
  MaintenancePage,
} from 'pages'
import { Routes, Route, Navigate } from 'react-router-dom'

export const EmployerNavigation = () => (
  <Routes>
    <Route path="/analytics/maintenance" element={<MaintenancePage />} />
    <Route path="/analytics" element={<AnalyticsPage />} />
    <Route path="/disclaimer" element={<DisclaimerPage />} />
    <Route path="/welcome" element={<WelcomePage />} />
    <Route path="/" element={<Navigate to="/analytics" replace />} />
    <Route path="*" element={<NotFoundPage />} />
  </Routes>
)
