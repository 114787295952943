import type { FC } from 'react'
import React from 'react'
import type { HiFiIconPathProps } from '_BRIGHT_/components/Icons'

const AddUser: FC<HiFiIconPathProps> = ({
  primaryColor,
  secondaryColor,
  baseColor,
  strokeWidth,
  hasShadow,
}) => {
  return (
    <>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.43713 8.06087C5.43713 9.4031 5.97033 10.6904 6.91943 11.6395C7.86853 12.5885 9.15578 13.1217 10.498 13.1217C11.8402 13.1217 13.1275 12.5885 14.0766 11.6395C15.0257 10.6904 15.5589 9.4031 15.5589 8.06087C15.5589 6.71865 15.0257 5.43139 14.0766 4.4823C13.1275 3.5332 11.8402 3 10.498 3C9.15578 3 7.86853 3.5332 6.91943 4.4823C5.97033 5.43139 5.43713 6.71865 5.43713 8.06087V8.06087Z"
          stroke={primaryColor}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.3292 16.6716C13.1546 16.1637 11.8592 15.8822 10.498 15.8822C6.82768 15.8822 3.63536 17.9288 2 20.9431"
          stroke={primaryColor}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
        />
        {hasShadow && (
          <>
            <path
              opacity="0.2"
              d="M12.6616 4C11.0754 4 9.55407 4.63014 8.43241 5.7518C7.31075 6.87347 6.6806 8.39476 6.6806 9.98103C6.6806 11.5673 7.31075 13.0886 8.43241 14.2103C9.55407 15.3319 11.0754 15.9621 12.6616 15.9621C14.2479 15.9621 15.7692 15.3319 16.8909 14.2103C18.0125 13.0886 18.6427 11.5673 18.6427 9.98103C18.6427 8.39477 18.0125 6.87347 16.8909 5.7518C15.7692 4.63014 14.2479 4 12.6616 4Z"
              fill={primaryColor}
            />
            <path
              opacity="0.2"
              d="M12.2016 16.8823C8.1792 16.8823 4.73466 19.5385 3.30671 23.3063C3.13099 23.77 3.49036 24.2436 3.98619 24.2436H20.417C20.9128 24.2436 21.2722 23.77 21.0965 23.3063C19.6685 19.5385 16.224 16.8823 12.2016 16.8823Z"
              fill={primaryColor}
            />
          </>
        )}
        <path
          d="M16 23C16 24.8565 16.7375 26.637 18.0503 27.9497C19.363 29.2625 21.1435 30 23 30C24.8565 30 26.637 29.2625 27.9497 27.9497C29.2625 26.637 30 24.8565 30 23C30 21.1435 29.2625 19.363 27.9497 18.0503C26.637 16.7375 24.8565 16 23 16C21.1435 16 19.363 16.7375 18.0503 18.0503C16.7375 19.363 16 21.1435 16 23Z"
          fill={baseColor}
          stroke={secondaryColor}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23 20V26M20 23H26"
          stroke={secondaryColor}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  )
}

export { AddUser }
