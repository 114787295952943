import type { TooltipProps as ChakraTooltipProps } from '@chakra-ui/react'
import { forwardRef, Tooltip as ChakraTooltip } from '@chakra-ui/react'
import type { FC } from 'react'
import React from 'react'

export type TooltipProps = Pick<ChakraTooltipProps, 'label' | 'aria-label' | 'children'>

const Tooltip: FC<TooltipProps> = forwardRef(({ children, label, 'aria-label': ariaLabel }, ref) => {
  return (
    <ChakraTooltip ref={ref} hasArrow placement="top" shouldWrapChildren label={label} aria-label={ariaLabel}>
      {children}
    </ChakraTooltip>
  )
})

export { Tooltip }
