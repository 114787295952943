import { useEffect, useState } from 'react'

export const useWip = () => {
  const [canSeeWip, setCanSeeWip] = useState<boolean>(
    window.sessionStorage.wip === 'true' ? true : false,
  )

  useEffect(() => {
    function handleSessionStorageEvent(event: StorageEvent) {
      if (event.storageArea === window.sessionStorage) {
        if (event.key === 'wip' && event.newValue === 'true') {
          setCanSeeWip(true)
        } else {
          setCanSeeWip(false)
        }
      }
    }

    window.addEventListener('storage', handleSessionStorageEvent)

    return () => {
      window.removeEventListener('storage', handleSessionStorageEvent)
    }
  }, [])

  return { canSeeWip }
}
