import type { Space } from '_BRIGHT_/components/Layout/Common'
import type { GridProps } from '_BRIGHT_/components/Layout/Grid'
import { Grid, GridItem } from '_BRIGHT_/components/Layout/Grid'
import type { FC } from 'react'
import React, { Children, isValidElement } from 'react'

export type AdjacentProps = Pick<GridProps, 'isInline' | 'alignY'> & {
  space?: Space
}

const Adjacent: FC<AdjacentProps> = ({
  children,
  space = 'none',
  alignY = 'top',
  isInline,
}) => {
  return (
    <Grid space={space} isInline={isInline} wrap={false} alignY={alignY}>
      {Children.map(children, child => {
        if (!isValidElement(child)) {
          return null
        }

        return <GridItem width="content">{child}</GridItem>
      })}
    </Grid>
  )
}

export { Adjacent }
