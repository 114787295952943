import type { FC } from 'react'
import React from 'react'
import type { HiFiIconPathProps } from '_BRIGHT_/components/Icons'

const AddUsers: FC<HiFiIconPathProps> = ({
  primaryColor,
  secondaryColor,
  baseColor,
  strokeWidth,
  hasShadow,
}) => {
  return (
    <>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M24.925 12.625C24.5073 11.902 23.9232 11.2891 23.2212 10.8371C22.5191 10.3851 21.7194 10.107 20.8884 10.026C20.0573 9.94503 19.219 10.0634 18.4428 10.3714C17.6667 10.6793 16.9753 11.1679 16.4258 11.7967M17.3125 6.0625C17.3125 6.87473 17.6352 7.65368 18.2095 8.22801C18.7838 8.80234 19.5628 9.125 20.375 9.125C21.1872 9.125 21.9662 8.80234 22.5405 8.22801C23.1149 7.65368 23.4375 6.87473 23.4375 6.0625C23.4375 5.25027 23.1149 4.47132 22.5405 3.89699C21.9662 3.32266 21.1872 3 20.375 3C19.5628 3 18.7838 3.32266 18.2095 3.89699C17.6352 4.47132 17.3125 5.25027 17.3125 6.0625Z"
          stroke={primaryColor}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.80151 7.125C4.80151 8.21902 5.23611 9.26823 6.0097 10.0418C6.78329 10.8154 7.8325 11.25 8.92652 11.25C10.0205 11.25 11.0697 10.8154 11.8433 10.0418C12.6169 9.26823 13.0515 8.21902 13.0515 7.125C13.0515 6.03098 12.6169 4.98177 11.8433 4.20818C11.0697 3.4346 10.0205 3 8.92652 3C7.8325 3 6.78329 3.4346 6.0097 4.20818C5.23611 4.98177 4.80151 6.03098 4.80151 7.125V7.125Z"
          stroke={primaryColor}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.0493 14.1434C11.0919 13.7294 10.036 13.5 8.92654 13.5C5.93493 13.5 3.33294 15.1681 2 17.625"
          stroke="#00819D"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M16 23C16 24.8565 16.7375 26.637 18.0503 27.9497C19.363 29.2625 21.1435 30 23 30C24.8565 30 26.637 29.2625 27.9497 27.9497C29.2625 26.637 30 24.8565 30 23C30 21.1435 29.2625 19.363 27.9497 18.0503C26.637 16.7375 24.8565 16 23 16C21.1435 16 19.363 16.7375 18.0503 18.0503C16.7375 19.363 16 21.1435 16 23Z"
          fill={baseColor}
          stroke={secondaryColor}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23 20V26M20 23H26"
          stroke={secondaryColor}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        {hasShadow && (
          <>
            <path
              opacity="0.2"
              d="M10.875 4C9.58207 4 8.3421 4.51361 7.42786 5.42786C6.51362 6.3421 6 7.58207 6 8.875C6 10.1679 6.51362 11.4079 7.42786 12.3221C8.3421 13.2364 9.58207 13.75 10.875 13.75C12.1679 13.75 13.4079 13.2364 14.3221 12.3221C15.2364 11.4079 15.75 10.1679 15.75 8.875C15.75 7.58207 15.2364 6.3421 14.3221 5.42786C13.4079 4.51361 12.1679 4 10.875 4Z"
              fill={primaryColor}
            />
            <path
              opacity="0.2"
              d="M10.5 14.5C7.28217 14.5 4.51804 16.5856 3.31634 19.5664C3.13095 20.0262 3.49036 20.5 3.98619 20.5H17.0138C17.5096 20.5 17.8691 20.0262 17.6837 19.5664C16.482 16.5856 13.7178 14.5 10.5 14.5Z"
              fill={primaryColor}
            />
          </>
        )}
      </svg>
    </>
  )
}

export { AddUsers }
