import { BinaryFilter, BinaryOperator } from '@cubejs-client/core'
import {
  CubeCompaniesAccessor,
  CubeKey,
  AppQuery,
  addCubeCompany,
  geniusOperatorToCubeOperator,
} from 'lib/cubeJs'
import { AppSort } from '../employees'

export type CompanySort = AppSort<CubeCompaniesAccessor>

export type CompanyFilter = {
  [k in CubeCompaniesAccessor]: {
    value: string
    operator: string
  }
}

export type AllClientsQueryConfig = {
  offset: number | undefined
  limit: number | undefined
  sort: CompanySort | undefined
  filter: CompanyFilter | undefined
}

export const companyDimensions: CubeKey[] = [
  'SMART_ANALYTICS_COMPANIES.companyId',
  'SMART_ANALYTICS_COMPANIES.companyName',
  'SMART_ANALYTICS_COMPANIES.companyState',
  'SMART_ANALYTICS_COMPANIES.totalAUM',
  'SMART_ANALYTICS_COMPANIES.dataQualityScoreRatio',
  'SMART_ANALYTICS_COMPANIES.directDebitState',
  'SMART_ANALYTICS_COMPANIES.totalEmployees',
  'SMART_ANALYTICS_COMPANIES.takeUpRatio',
  'SMART_ANALYTICS_COMPANIES.totalActiveEmployees',
  'SMART_ANALYTICS_COMPANIES.totalDeferredEmployees',
  'SMART_ANALYTICS_COMPANIES.totalMembersWithMissingContributions',
  'SMART_ANALYTICS_COMPANIES.totalInvalidNationalInsuranceNumber',
  'SMART_ANALYTICS_COMPANIES.totalInvalidAddresses',
  'SMART_ANALYTICS_COMPANIES.totalInvalidEmail',
  'SMART_ANALYTICS_COMPANIES.lastContributionCreationDate',
  'SMART_ANALYTICS_COMPANIES.schemeStartDate',
  'SMART_ANALYTICS_COMPANIES.dutyStartDate',
  'SMART_ANALYTICS_COMPANIES.nextReEnrolmentDate',
  'SMART_ANALYTICS_COMPANIES.averageActiveMemberPercentage',
  'SMART_ANALYTICS_COMPANIES.totalNewMembersThisMonth',
  'SMART_ANALYTICS_COMPANIES.totalLeaversThisMonth',
  'SMART_ANALYTICS_COMPANIES.totalClaims',
  'SMART_ANALYTICS_COMPANIES.hasMissingPaymentMethod',
]

const mapDateOperator = (fieldName: string, operator: string) => {
  if (fieldName === 'companyId') {
    return 'equals'
  }
  return geniusOperatorToCubeOperator(operator as BinaryOperator)
}

export const companyFilter = (
  filter: CompanyFilter | undefined,
): BinaryFilter[] =>
  (filter ? Object.entries(filter) : []).map(
    ([fieldName, { operator, value }]): BinaryFilter => ({
      member: addCubeCompany(fieldName),
      operator: mapDateOperator(fieldName, operator),
      values: [value],
    }),
  )

export const orderCompanies = (sort: CompanySort | undefined) => {
  if (sort) {
    const { field, order } = sort
    return {
      [addCubeCompany(field)]: order.toLowerCase(),
    } as AppQuery['order']
  }
  return {}
}
