import type { FC } from 'react'
import React from 'react'
import { BaseSpinner, useColor } from '_BRIGHT_/components'
import type { SpinnerSize } from '_BRIGHT_/components/Spinners/BaseSpinner/BaseSpinner'

export type SpinnerProps = {
  size?: SpinnerSize
  variant?: SpinnerVariants
  spinnerLabel?: string
}

type SpinnerVariants = 'primary' | 'positive'

const Spinner: FC<SpinnerProps> = ({
  size,
  variant = 'primary',
  spinnerLabel,
}) => {
  const colorName = variant === 'primary' ? 'accentSecondary' : 'core'
  const palette = variant === 'primary' ? undefined : 'positivePrimary'
  const color = useColor(colorName, palette)
  const emptyColor = useColor('contrast')

  return (
    <BaseSpinner
      size={size}
      emptyColor={emptyColor}
      color={color}
      spinnerLabel={spinnerLabel}
    />
  )
}

export { Spinner }
