import type { BulkEditViewContent, FieldConfig } from '_BRIGHT_/components'
import {
  Button,
  DateInput,
  Frame,
  Select,
  Stack,
  TextInput,
  useForm,
  withController,
} from '_BRIGHT_/components'
import {
  ActionDrawerButtonsWrapper,
  ActionDrawerView,
} from '_BRIGHT_/components/Genius/components'
import {
  dispatchDeselectAllItems,
  useDrawerContext,
  useGeniusConfigContext,
  useGeniusStateContext,
} from '_BRIGHT_/components/Genius/hooks'
import { useGeniusBulkEditContext } from '_BRIGHT_/components/Genius/hooks/useGeniusBulkEditContext'
import { Box } from '@chakra-ui/react'
import { isEmpty } from 'ramda'
import type { FC } from 'react'
import React from 'react'
import type { Control } from 'react-hook-form'
import { TogglableField } from './TogglableField'
import { ControlledFilterByNumberInput } from '_BRIGHT_/components/Genius/components/DrawerViews/FilterView'
import { isDateField, isOptionField } from '_BRIGHT_/components/Genius/utils'

// TODO: Refactor Genius component so that these 'anys' can be removed as part of https://smartpension.atlassian.net/browse/DEVEX-30
const ControlledDateInput = withController<any>()(DateInput)
const ControlledSelectInput = withController<any>()(Select)
const ControlledTextInput = withController<any>()(TextInput)

type BulkEditFieldProps = {
  control: Control
  fieldName: FieldConfig['fieldName']
}

type BulkEditFieldComponentProps = BulkEditFieldProps &
  Pick<FieldConfig, 'type' | 'label'>

const SelectField: FC<BulkEditFieldProps> = ({ control, fieldName }) => {
  const { fields } = useGeniusConfigContext()
  const field = fields[fieldName]
  if (!isOptionField(field)) return null

  const { optionInputConfig, label } = field

  return (
    <ControlledSelectInput
      ariaLabel={label}
      name={fieldName}
      control={control}
      items={optionInputConfig.options}
      isRequired
    />
  )
}

const DateField: FC<BulkEditFieldProps> = ({ control, fieldName }) => {
  const { fields } = useGeniusConfigContext()
  const field = fields[fieldName]
  if (!isDateField(field) || !field.dateInputConfig) return null
  const {
    dateLabels: { dayInputLabel, monthInputLabel, yearInputLabel },
  } = field.dateInputConfig

  return (
    <ControlledDateInput
      control={control}
      name={fieldName}
      dateFormat="DD-MM-YYYY"
      dayLabel={dayInputLabel}
      monthLabel={monthInputLabel}
      yearLabel={yearInputLabel}
      id={fieldName}
      isRequired
    />
  )
}

const BulkEditFieldComponent: FC<BulkEditFieldComponentProps> = ({
  type,
  fieldName,
  label,
  control,
}) => {
  const fieldComponents: Record<string, FC<BulkEditFieldProps>> = {
    option: SelectField,
    date: DateField,
    text: ({ control, fieldName }: BulkEditFieldProps) => (
      <ControlledTextInput
        control={control}
        name={fieldName}
        ariaLabel={label}
        isRequired
      />
    ),
    number: ({ control, fieldName }: BulkEditFieldProps) => (
      <ControlledFilterByNumberInput
        control={control}
        name={fieldName}
        label={label}
        isRequired
      />
    ),
  }

  const CurrentField = fieldComponents[type]

  if (!CurrentField) {
    console.warn(
      `Cannot render field component for type "${type}". Currently supported field types are: "text", "option", and "date".`,
    )

    return null
  }

  return <CurrentField control={control} fieldName={fieldName} />
}

const BulkEditViewBody: FC<BulkEditViewContent> = ({
  confirmButtonLabel,
  cancelButtonLabel,
}) => {
  const { fields } = useGeniusConfigContext()
  const { close } = useDrawerContext()
  const { selectedItems, dispatch } = useGeniusStateContext()
  const selectedIds = Object.keys(selectedItems).filter(
    key => !!selectedItems[key],
  ) // Filtering is needed since selectedItems includes deselected too
  const { onBulkEditSubmit } = useGeniusBulkEditContext()
  const bulkEditableFields = Object.values(fields).filter(
    ({ isBulkEditable }) => isBulkEditable,
  )
  const defaultValues = bulkEditableFields.reduce(
    (acc, { fieldName }) => ({ ...acc, [fieldName]: '' }),
    {},
  )
  const { control, onSubmit } = useForm(
    {
      mode: 'onSubmit',
      criteriaMode: 'all',
      defaultValues,
      shouldUnregister: true, // removes values from unchecked fields
    },
    async values => {
      await onBulkEditSubmit({ data: { ...values }, selectedIds })
      dispatchDeselectAllItems(dispatch)
      close() // @TODO Genius - invoke close() on genius state update not here  https://smartpension.atlassian.net/browse/T2-1096
    },
  )

  return (
    <Box as="form" onSubmit={onSubmit} width="100%">
      <Stack space="4xl">
        {bulkEditableFields.map((field: FieldConfig, i) => {
          return (
            <Frame palette="baseTwo" width="full" key={i}>
              <TogglableField {...field} label={field.label}>
                <BulkEditFieldComponent
                  type={field.type}
                  fieldName={field.fieldName}
                  label={field.label}
                  control={control}
                />
              </TogglableField>
            </Frame>
          )
        })}
      </Stack>
      <Frame palette="baseTwo" paddingTop="4xl">
        <ActionDrawerButtonsWrapper>
          <Button
            variant="secondaryButton"
            isFullWidthForMobile
            onClick={close}
          >
            {cancelButtonLabel}
          </Button>
          <Button type="submit" isFullWidthForMobile>
            {confirmButtonLabel}
          </Button>
        </ActionDrawerButtonsWrapper>
      </Frame>
    </Box>
  )
}

export const BulkEditView: FC = () => {
  const { actions } = useGeniusConfigContext()
  const { selectedItems } = useGeniusStateContext()

  if (!actions.bulkEdit) return null

  const { title, description, ...bulkEditContent } = actions.bulkEdit

  return (
    <ActionDrawerView
      title={title}
      description={
        description && `${description}: ${Object.keys(selectedItems).length}`
      }
    >
      {!isEmpty(selectedItems) && <BulkEditViewBody {...bulkEditContent} />}
    </ActionDrawerView>
  )
}
