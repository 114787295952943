import type { FC } from 'react'
import React from 'react'
import type { HiFiIconPathProps } from '_BRIGHT_/components/Icons'

const Downloads: FC<HiFiIconPathProps> = ({
  primaryColor,
  secondaryColor,
  baseColor,
  strokeWidth,
  hasShadow,
}) => {
  return (
    <>
      {hasShadow && (
        <path
          opacity="0.2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.3635 5.20192C19.0353 5.06851 18.6835 4.9999 18.3282 5H7.70586C6.98822 5 6.29997 5.28002 5.79252 5.77845C5.28507 6.27688 4.99998 6.95289 4.99998 7.65778V28.3422C4.99998 29.0471 5.28507 29.7231 5.79252 30.2216C6.29997 30.72 6.98822 31 7.70586 31H24.1765C25.7358 31 27 29.7583 27 28.2267V13.5174C26.9998 12.813 26.7149 12.1373 26.2079 11.6391L20.2418 5.77784C19.9904 5.53108 19.6919 5.33538 19.3635 5.20192Z"
          fill={primaryColor}
        />
      )}
      <path
        d="M15 22.5C15 24.4891 15.7902 26.3968 17.1967 27.8033C18.6032 29.2098 20.5109 30 22.5 30C24.4891 30 26.3968 29.2098 27.8033 27.8033C29.2098 26.3968 30 24.4891 30 22.5C30 20.5109 29.2098 18.6032 27.8033 17.1967C26.3968 15.7902 24.4891 15 22.5 15C20.5109 15 18.6032 15.7902 17.1967 17.1967C15.7902 18.6032 15 20.5109 15 22.5Z"
        fill={baseColor}
        stroke={secondaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.5 19V26"
        stroke={secondaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.5 26L25.5 23"
        stroke={secondaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.5 26L19.5 23"
        stroke={secondaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5 26H3.75439C3.28909 26 2.84286 25.8185 2.51385 25.4955C2.18484 25.1725 2 24.7343 2 24.2775V3.72249C2 3.26566 2.18484 2.82753 2.51385 2.50451C2.84286 2.18148 3.28909 2 3.75439 2H14.3146C14.545 1.9999 14.7732 2.04436 14.9862 2.13086C15.1991 2.21736 15.3926 2.34419 15.5556 2.50412L21.4866 8.32842C21.8152 8.65133 21.9999 9.08913 22 9.54565V11.6459"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 9H15.4C15.0287 9 14.6726 8.8525 14.4101 8.58995C14.1475 8.3274 14 7.9713 14 7.6V2"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  )
}

export { Downloads }
