import type { FormEvent } from 'react'
import { useEffect, useState } from 'react'
import type { DateUnits } from './utils'
import { formatDate, parseDate, isDateFilled, isDateValid } from './utils'

export const DAY_DIGITS = 2
export const MONTH_DIGITS = 2
export const YEAR_DIGITS = 4

export type DateFormat = 'DD-MM-YYYY' | 'MM-DD-YYYY' | 'YYYY-MM-DD'

type FieldsOrder = {
  dayPosition: number
  monthPosition: number
  yearPosition: number
}

type HandleChange = (e: FormEvent<HTMLInputElement>, input: keyof DateUnits) => void

export type UseDateInputsState = FieldsOrder &
  DateUnits & { handleChange: HandleChange; isValid: boolean; isFilled: boolean }

export type UseDateInputsArgs = {
  dateFormat: DateFormat
  value: string
  onChange: (value: string) => void
}

export const getFieldsPosition = (dateFormat: DateFormat): FieldsOrder => {
  const fieldsList = dateFormat.split('-')

  const dayPosition = fieldsList.indexOf('DD')
  const monthPosition = fieldsList.indexOf('MM')
  const yearPosition = fieldsList.indexOf('YYYY')

  return {
    dayPosition,
    monthPosition,
    yearPosition,
  }
}

export const useDateInputs = ({ dateFormat, value, onChange }: UseDateInputsArgs): UseDateInputsState => {
  const fieldPositions = getFieldsPosition(dateFormat)
  const [dateUnits, setDateUnits] = useState<DateUnits>(parseDate(value))
  const { day, month, year } = dateUnits
  const isFilled = isDateFilled(day, month, year)
  const isValid = isDateValid(day, month, year)
  const currentValue = isFilled && isValid ? formatDate(day, month, year) : ''

  const handleChange: HandleChange = (e, dateUnitKey) => {
    const val = parseInt(e.currentTarget.value, 10)
    const newUnit = val >= 0 ? val : undefined
    const newDateUnits: DateUnits = { ...dateUnits, [dateUnitKey]: newUnit }
    setDateUnits(newDateUnits)
  }

  useEffect(() => {
    const lastDate = parseDate(value)
    const hasChanged = day !== lastDate.day || month !== lastDate.month || year !== lastDate.year

    if (hasChanged) {
      onChange(currentValue)
    }
  }, [day, month, year])

  useEffect(() => {
    if (value !== currentValue) {
      setDateUnits(parseDate(value))
    }
  }, [value])

  return {
    ...dateUnits,
    ...fieldPositions,
    isFilled,
    isValid,
    handleChange,
  }
}
