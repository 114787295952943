import { membershipValues } from 'lib/cubeJs'
import { GeniusConfig } from '_BRIGHT_/generated/generated-bright-components'

export const fields: GeniusConfig['fields'] = [
  {
    fieldName: 'employeeId',
    type: 'text',
    isBulkEditable: false,
    isFilterable: true,
    isSortable: true,
    isShownInMetadataList: false,
    label: 'ID',
    settings: {
      position: 1,
      showInSummary: false,
    },
  },
  {
    fieldName: 'fullName',
    type: 'text',
    isBulkEditable: false,
    isFilterable: true,
    isSortable: true,
    isShownInMetadataList: false,
    label: 'Name',
    settings: {
      position: 2,
      showInSummary: false,
    },
  },
  {
    fieldName: 'membershipStatus',
    type: 'option',
    isBulkEditable: false,
    isFilterable: true,
    isSortable: true,
    isShownInMetadataList: true,
    label: 'Membership status',
    settings: {
      position: 3,
      showInSummary: false,
    },
    optionInputConfig: {
      options: Object.values(membershipValues).map(label => ({
        label,
        id: label,
      })),
    },
  },
  {
    fieldName: 'externalId',
    type: 'text',
    isBulkEditable: false,
    isFilterable: true,
    isSortable: true,
    isShownInMetadataList: true,
    label: 'External ID (Payroll ID)',
    settings: {
      position: 4,
      showInSummary: true,
    },
  },
  {
    fieldName: 'externalId',
    type: 'text',
    isBulkEditable: false,
    isFilterable: true,
    isSortable: true,
    isShownInMetadataList: true,
    label: 'External ID (Payroll ID)',
    settings: {
      position: 5,
      showInSummary: false,
    },
  },
  {
    fieldName: 'nationalInsuranceNumber',
    type: 'option',
    isBulkEditable: false,
    isFilterable: true,
    isSortable: true,
    isShownInMetadataList: true,
    label: 'NI number',
    settings: {
      position: 6,
      showInSummary: false,
    },
    optionInputConfig: {
      options: [
        {
          label: 'Present',
          id: 'present',
        },
        {
          label: 'Missing',
          id: 'missing',
        },
      ],
    },
  },
  {
    fieldName: 'lateContributions',
    type: 'option',
    isBulkEditable: false,
    isFilterable: true,
    isSortable: false,
    isShownInMetadataList: false,
    label: 'Late contributions',
    settings: {
      position: 7,
      showInSummary: false,
    },
    optionInputConfig: {
      options: [
        {
          label: '1-29 days late',
          id: '1-29 days',
        },
        {
          label: '30-59 days late',
          id: '30-59 days',
        },
        {
          label: '60-89 days late',
          id: '60-89 days',
        },
        {
          label: '90+ days late',
          id: '90+ days',
        },
      ],
    },
  },
  {
    fieldName: 'total1DaysLateMissingContributions',
    type: 'text',
    isBulkEditable: false,
    isFilterable: false,
    isSortable: true,
    isShownInMetadataList: true,
    label: 'Late contributions (1-29 days)',
    settings: {
      position: 7,
      showInSummary: false,
    },
  },
  {
    fieldName: 'total30DaysLateMissingContributions',
    type: 'text',
    isBulkEditable: false,
    isFilterable: false,
    isSortable: true,
    isShownInMetadataList: true,
    label: 'Late contributions (30-59 days)',
    settings: {
      position: 8,
      showInSummary: false,
    },
  },
  {
    fieldName: 'total60DaysLateMissingContributions',
    type: 'text',
    isBulkEditable: false,
    isFilterable: false,
    isSortable: true,
    isShownInMetadataList: true,
    label: 'Late contributions (60-89 days)',
    settings: {
      position: 9,
      showInSummary: false,
    },
  },
  {
    fieldName: 'total90DaysLateMissingContributions',
    type: 'text',
    isBulkEditable: false,
    isFilterable: false,
    isSortable: true,
    isShownInMetadataList: true,
    label: 'Late contributions (90+ days)',
    settings: {
      position: 10,
      showInSummary: false,
    },
  },
  {
    fieldName: 'lastContributionEndDate',
    type: 'text',
    isBulkEditable: false,
    isFilterable: false,
    isSortable: true,
    isShownInMetadataList: true,
    label: 'Last contribution date',
    settings: {
      position: 11,
      showInSummary: false,
    },
  },
  {
    fieldName: 'lastSignInDate',
    type: 'option',
    isBulkEditable: false,
    isFilterable: true,
    isSortable: true,
    isShownInMetadataList: true,
    label: 'Last sign-in',
    settings: {
      position: 12,
      showInSummary: false,
    },
    optionInputConfig: {
      options: [
        {
          label: 'Within 30 Days',
          id: 'within 30 days',
        },
        {
          label: 'Within 60 Days',
          id: 'within 60 days',
        },
        {
          label: 'Within 90 Days',
          id: 'within 90 days',
        },
        {
          label: 'Never',
          id: 'never',
        },
      ],
    },
  },
  {
    fieldName: 'lastBeneficiariesUpdatedDate',
    type: 'option',
    isBulkEditable: false,
    isFilterable: true,
    isSortable: true,
    isShownInMetadataList: true,
    label: 'Beneficiaries updated',
    settings: {
      position: 13,
      showInSummary: false,
    },
    optionInputConfig: {
      options: [
        {
          label: 'Within 30 Days',
          id: 'within 30 days',
        },
        {
          label: 'Within 60 Days',
          id: 'within 60 days',
        },
        {
          label: 'Within 90 Days',
          id: 'within 90 days',
        },
        {
          label: 'Never',
          id: 'never',
        },
      ],
    },
  },
  {
    fieldName: 'groupName',
    type: 'text',
    isBulkEditable: false,
    isFilterable: false,
    isSortable: true,
    isShownInMetadataList: true,
    label: 'Group',
    settings: {
      position: 15,
      showInSummary: false,
    },
  },
  {
    fieldName: 'startDate',
    type: 'text',
    isBulkEditable: false,
    isFilterable: false,
    isSortable: true,
    isShownInMetadataList: true,
    label: 'Start date',
    settings: {
      position: 16,
      showInSummary: false,
    },
  },
  {
    fieldName: 'exitDate',
    type: 'text',
    isBulkEditable: false,
    isFilterable: false,
    isSortable: true,
    isShownInMetadataList: true,
    label: 'Exit date',
    settings: {
      position: 17,
      showInSummary: false,
    },
  },
  {
    fieldName: 'exitReason',
    type: 'text',
    isBulkEditable: false,
    isFilterable: false,
    isSortable: true,
    isShownInMetadataList: true,
    label: 'Exit reason',
    settings: {
      position: 18,
      showInSummary: false,
    },
  },
  {
    fieldName: 'emailAddress',
    type: 'text',
    isBulkEditable: false,
    isFilterable: false,
    isSortable: true,
    isShownInMetadataList: true,
    label: 'Email address',
    settings: {
      position: 19,
      showInSummary: false,
    },
  },
  {
    fieldName: 'telephone',
    type: 'text',
    isBulkEditable: false,
    isFilterable: false,
    isSortable: true,
    isShownInMetadataList: true,
    label: 'Telephone',
    settings: {
      position: 20,
      showInSummary: false,
    },
  },
  {
    fieldName: 'homeAddress',
    type: 'text',
    isBulkEditable: false,
    isFilterable: false,
    isSortable: true,
    isShownInMetadataList: true,
    label: 'Home address',
    settings: {
      position: 21,
      showInSummary: false,
    },
  },
  {
    fieldName: 'hasValidHomeAddress',
    type: 'text',
    isBulkEditable: false,
    isFilterable: false,
    isSortable: false,
    isShownInMetadataList: false,
    label: 'Valid Home address',
    settings: {
      position: 22,
      showInSummary: false,
    },
  },
  {
    fieldName: 'gender',
    type: 'text',
    isBulkEditable: false,
    isFilterable: false,
    isSortable: true,
    isShownInMetadataList: true,
    label: 'Gender',
    settings: {
      position: 23,
      showInSummary: false,
    },
  },
  {
    fieldName: 'hasMissingEmailAddress',
    type: 'option',
    isBulkEditable: false,
    isFilterable: true,
    isSortable: false,
    isShownInMetadataList: false,
    label: 'Missing email address',
    settings: {
      position: 24,
      showInSummary: false,
    },
    optionInputConfig: {
      options: [
        {
          label: 'Present',
          id: 'false',
        },
        {
          label: 'Missing',
          id: 'true',
        },
      ],
    },
  },
  {
    fieldName: 'hasMissingTelephone',
    type: 'option',
    isBulkEditable: false,
    isFilterable: true,
    isSortable: false,
    isShownInMetadataList: false,
    label: 'Missing telephone',
    settings: {
      position: 25,
      showInSummary: false,
    },
    optionInputConfig: {
      options: [
        {
          label: 'Present',
          id: 'false',
        },
        {
          label: 'Missing',
          id: 'true',
        },
      ],
    },
  },
  {
    fieldName: 'hasMissingHomeAddress',
    type: 'option',
    isBulkEditable: false,
    isFilterable: true,
    isSortable: false,
    isShownInMetadataList: true,
    label: 'Missing home address',
    settings: {
      position: 26,
      showInSummary: false,
    },
    optionInputConfig: {
      options: [
        {
          label: 'Present',
          id: 'false',
        },
        {
          label: 'Missing',
          id: 'true',
        },
      ],
    },
  },
  {
    fieldName: 'isGoneAway',
    type: 'option',
    isBulkEditable: false,
    isFilterable: true,
    isSortable: false,
    isShownInMetadataList: true,
    label: 'Gone away',
    settings: {
      position: 27,
      showInSummary: false,
    },
    optionInputConfig: {
      options: [
        {
          label: 'Yes',
          id: 'Yes',
        },
        {
          label: 'No',
          id: 'No',
        },
      ],
    },
  },
]
