import { Box } from '@chakra-ui/react'
import { Text, Stack } from '_BRIGHT_/components'
import React from 'react'
import type { FC } from 'react'

type AccountDetailsItemProps = {
  label: string
  content: string
}

export const AccountDetailsItem: FC<AccountDetailsItemProps> = ({
  content,
  label,
}) => {
  return (
    <Box py="sm">
      <Stack>
        <Text as="b" variant="secondary" level="sm">
          {label}
        </Text>
        <Text as="span" variant="secondary" level="xs">
          {content}
        </Text>
      </Stack>
    </Box>
  )
}

export const AccountDetails: FC = ({ children }) => {
  return <Box py="md">{children}</Box>
}
