import { Box, Flex, Text } from '@chakra-ui/react'
import {
  Block,
  Breadcrumb as BrightBreadcumb,
  FunctionalIcon,
} from '_BRIGHT_/components'
import { useBreadCrumb } from './useBreadCrumb'
import { BreadcrumbItem } from './BreadCrumbItem'

export const Breadcrumb = () => {
  const breadCrumbLinks = useBreadCrumb()

  return (
    <Block palette="baseTwo" paddingTop="xl">
      <BrightBreadcumb>
        {breadCrumbLinks.map(({ to, href, text, icon }, index) => (
          <BreadcrumbItem
            key={index}
            isCurrentPage={false}
            isLastChild={false}
            {...(to && { to })}
            href={href ?? ''}
          >
            <Flex>
              {icon && (
                <Box mr="2" data-testid="breadcrumb-back-arrow">
                  <FunctionalIcon variant={icon} size="sm" />
                </Box>
              )}
              <Text>{text}</Text>
            </Flex>
          </BreadcrumbItem>
        ))}
      </BrightBreadcumb>
    </Block>
  )
}
