import {
  spaceMap,
  getAlignItems,
  getWidth,
  getFlexGrow,
  getJustifyContent,
} from '_BRIGHT_/components/Layout/Common'
import type {
  AlignY,
  ResponsiveAlignX,
  ResponsiveAlignY,
  ResponsiveBoolean,
  ResponsiveSpace,
  ResponsiveWidth,
  Space,
} from '_BRIGHT_/components/Layout/Common'
import type { SystemProps } from '@chakra-ui/system'
import { mapResponsiveStyle } from '_BRIGHT_/utils'
import { Flex, useTheme } from '@chakra-ui/react'
import type { FC, ReactNode } from 'react'
import React, { createContext, Children, useContext } from 'react'

const getFlexWrap = (wrap: boolean): 'wrap' | 'nowrap' =>
  wrap ? 'wrap' : 'nowrap'

export type GridItemProps = {
  alignX?: ResponsiveAlignX
  alignY?: ResponsiveAlignY
  width?: ResponsiveWidth
}

type GridItemContext = {
  itemElement?: 'div' | 'span' | 'li'
  space?: ResponsiveSpace
  overflow?: SystemProps['overflow']
}

const GridItemContext = createContext<GridItemContext>({})

export const GridItem: FC<GridItemProps> = ({
  children,
  width = 'fluid',
  alignX = 'start',
  alignY = 'top',
}) => {
  const flexBasis = mapResponsiveStyle(getWidth, width)
  const flexGrow = mapResponsiveStyle(getFlexGrow, width)
  const alignItems = mapResponsiveStyle(getAlignItems, alignY)
  const justifyContent = mapResponsiveStyle(getJustifyContent, alignX)
  const { itemElement, space, overflow } = useContext(GridItemContext)
  const itemFrameElement = itemElement === 'li' ? 'div' : itemElement
  const minWidth = overflow === 'hidden' ? '0' : 'auto'

  return (
    <Flex
      as={itemElement}
      flexBasis={flexBasis}
      flexGrow={flexGrow}
      maxWidth="100%"
      minWidth={minWidth}
    >
      <Flex
        as={itemFrameElement}
        flexBasis="100%"
        maxWidth="100%"
        justifyContent={justifyContent}
        alignItems={alignItems}
        paddingLeft={space}
        paddingTop={space}
      >
        {children}
      </Flex>
    </Flex>
  )
}

export type GridProps = {
  as?: 'div' | 'ul' | 'ol'
  divider?: ReactNode
  space?: ResponsiveSpace
  wrap?: ResponsiveBoolean
  isInline?: boolean
  alignY?: AlignY
  flexDir?: SystemProps['flexDirection']
  height?: SystemProps['height']
  minHeight?: SystemProps['minHeight']
  overflow?: SystemProps['overflow']
}

const Grid: FC<GridProps> = ({
  children,
  as = 'div',
  space = 'none',
  wrap = false,
  divider,
  isInline = false,
  alignY = 'top',
  flexDir = 'row',
  height = 'auto',
  minHeight,
  overflow,
}) => {
  const theme = useTheme() as unknown as Record<string, Record<Space, string>>
  const responsiveSpace = typeof space === 'string' ? spaceMap[space] : space
  const getSpace = (colSpace: Space): string => `-${theme.space[colSpace]}`
  const margin = mapResponsiveStyle(getSpace, responsiveSpace)
  const flexWrap = mapResponsiveStyle(getFlexWrap, wrap)
  const flexBasis = isInline ? 'auto' : '100%'
  const isList = as !== 'div'
  const element = isInline ? 'span' : as
  const itemElement = (isInline && 'span') || (isList && 'li') || 'div'

  return (
    <GridItemContext.Provider
      value={{ itemElement, space: responsiveSpace, overflow }}
    >
      <Flex
        as={element}
        marginLeft={margin}
        marginTop={margin}
        flexWrap={flexWrap}
        flexBasis={flexBasis}
        flexDir={flexDir}
        flexGrow={1}
        height={height}
        minHeight={minHeight}
        alignItems={mapResponsiveStyle(getAlignItems, alignY)}
        overflow={overflow}
      >
        {Children.map(children, (child, index) => {
          const hasDivider = divider && index > 0

          return (
            <>
              {hasDivider && divider}
              {child}
            </>
          )
        })}
      </Flex>
    </GridItemContext.Provider>
  )
}

export { Grid }
