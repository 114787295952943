import { useContext } from 'react'

import type { GeniusStateContextValue } from '_BRIGHT_/components/Genius/context'
import { GeniusStateContext } from '_BRIGHT_/components/Genius/context'

export const useGeniusStateContext = (): GeniusStateContextValue => {
  const context = useContext(GeniusStateContext)
  if (!context)
    throw new Error(
      'useGeniusStateContext must be used within GeniusStateContextProvider',
    )
  return context
}
