import type { FC } from 'react'
import React from 'react'
import { createPageRange } from './common/createPageRange'
import { PaginationArrow } from './PaginationArrow'
import { PaginationEllipsis } from './PaginationEllipsis'
import { PaginationItem } from './PaginationItem'
import { HStack } from '@chakra-ui/react'
import { useIsMobileViewport } from '_BRIGHT_/utils'

export type PaginationProps = {
  previousPage: number | null
  nextPage: number | null
  totalPages: number
  currentPage: number
  labelNextPage?: string
  labelPreviousPage?: string
  labelGoToPage?: string
  labelCurrentPage?: string
  onClick: (page: number) => void
}

export const Pagination: FC<PaginationProps> = ({
  previousPage,
  nextPage,
  totalPages,
  currentPage,
  labelNextPage = 'Next page',
  labelPreviousPage = 'Previous page',
  labelGoToPage = 'Go to page',
  labelCurrentPage = 'Current page',
  onClick,
}) => {
  const length = useIsMobileViewport() ? 5 : 7

  if (totalPages <= 1) {
    return null
  }

  const pageRange = createPageRange({
    currentPage,
    totalPages,
    length,
  })

  return (
    <HStack as="nav" aria-label="Pagination" spacing="xs">
      <PaginationArrow
        direction="back"
        label={labelPreviousPage}
        isDisabled={!previousPage}
        onClick={() => previousPage && onClick(previousPage)}
      />
      {pageRange.map((page: number | string, i) =>
        typeof page === 'number' && !isNaN(page) ? (
          <PaginationItem
            key={`item-${page}`}
            page={page}
            labelGoToPage={labelGoToPage}
            labelCurrentPage={labelCurrentPage}
            isCurrentPage={page === currentPage}
            onClick={() => page !== currentPage && onClick(page)}
          />
        ) : (
          <PaginationEllipsis key={`ellipsis-${i}`} />
        ),
      )}
      <PaginationArrow
        direction="forward"
        label={labelNextPage}
        isDisabled={!nextPage}
        onClick={() => nextPage && onClick(nextPage)}
      />
    </HStack>
  )
}
