declare global {
  interface Window {
    dataLayer: unknown[] | undefined
    [key: string]: any
  }
}

export const assignUserId = (userId: string, userType: string): void => {
  try {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'authenticated',
      userId,
      userType,
    })
  } catch (error) {
    console.error('[GA error]', error)
  }
}
