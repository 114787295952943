import { FunctionalIcon, Text, useColor } from '_BRIGHT_/components'
import { Dropdown } from '_BRIGHT_/components/Overlays/Dropdown'
import { Drawer } from '_BRIGHT_/components/Overlays/'
import { useIsMobileViewport } from '_BRIGHT_/utils'
import {
  Box,
  Button as ChakraButton,
  useFormControlContext,
  VisuallyHidden,
} from '@chakra-ui/react'
import { Item } from '@react-stately/collections'
import { useSelectState } from '@react-stately/select'
import type { SelectProps as ReactSelectProps } from '@react-types/select'
import * as R from 'ramda'
import type { FC, Key } from 'react'
import React, { useRef } from 'react'
import { HiddenSelect, useSelect, useButton } from 'react-aria'
import type { FormErrors, Option } from '_BRIGHT_/components/Forms/common'
import { FormControl } from '_BRIGHT_/components/Forms/FormControl'
import { OptionList } from '_BRIGHT_/components/Forms/OptionList'
import { useShadow, usePointerFocusStyles } from '_BRIGHT_/utils'

type BaseSelectProps<T> = Omit<ReactSelectProps<T>, 'items'> &
  Pick<
    SelectProps<T>,
    | 'items'
    | 'onChange'
    | 'listLabel'
    | 'ariaLabel'
    | 'placeholder'
    | 'isClearable'
  > & {
    isInvalid: boolean
    withBorder?: boolean
    dropdownBoundingRef?: React.RefObject<HTMLDivElement>
    type?: 'select' | 'phone'
    name?: string
  }

export const BaseSelect: FC<BaseSelectProps<Option>> = ({
  onChange,
  label,
  listLabel,
  ariaLabel,
  placeholder = 'Select an option',
  isInvalid,
  isClearable = true,
  withBorder = true,
  dropdownBoundingRef,
  type = 'select',
  name,
  children,
  items,
  selectedKey,
}) => {
  const triggerRef = useRef<HTMLButtonElement>(null)
  const listBoxRef = useRef<HTMLUListElement>(null)
  const overlayRef = useRef<HTMLDivElement>(null)

  const onSelectionChange = (value: Key) => {
    onChange && onChange(value)
  }

  const state = useSelectState<Option>({
    onSelectionChange,
    children,
    items,
    selectedKey,
  })

  const { labelId, id } = useFormControlContext()
  const hiddenLabelId = `${id}-hidden-label`
  const useSelectProps = label
    ? { id, 'aria-labelledby': labelId }
    : { 'aria-labelledby': hiddenLabelId }

  const { triggerProps, valueProps, menuProps } = useSelect(
    { children, items, selectedKey, ...useSelectProps },
    state,
    triggerRef,
  )

  const { buttonProps } = useButton(triggerProps, triggerRef)
  const { isOpen, selectedItem, close } = state

  const isMobile = useIsMobileViewport()

  const activeColor = useColor('core', 'highlight')
  const baseBorderColor = useColor('borderPrimary')
  const focusBgColor = useColor('core', 'highlightOutline')
  const negativeColor = useColor('core', 'negativePrimary')
  const iconColor = useColor('contentPrimary', 'highlightOutline')
  const focusBoxShadow = useShadow('focus')

  const getBorderColor = R.cond([
    [R.always(isOpen), R.always(activeColor)],
    [R.always(isInvalid), R.always(negativeColor)],
    [R.always(!withBorder), R.always('transparent')],
    [R.T, R.always(baseBorderColor)],
  ])

  const getBoxShadow = R.cond([
    [R.always(isOpen), R.always(`0px 0px 0px 1px ${activeColor}`)],
    [R.always(isInvalid), R.always(`0px 0px 0px 1px ${negativeColor}`)],
    [R.T, R.always('none')],
  ])

  const pointerFocusStyles = usePointerFocusStyles({
    boxShadow: 'none',
    borderColor: getBorderColor(),
  })

  return (
    <Box pos="relative" width="100%" maxWidth="100%">
      {!label && (
        <VisuallyHidden id={hiddenLabelId}>{ariaLabel}</VisuallyHidden>
      )}
      <HiddenSelect
        state={state}
        triggerRef={triggerRef}
        label={label || ariaLabel}
      />
      <ChakraButton
        {...buttonProps}
        ref={triggerRef}
        name={name}
        variant="outline"
        maxWidth="100%"
        height={{ base: 'button-md', sm: 'button-lg' }}
        isFullWidth
        p="sm"
        justifyContent="space-between"
        borderRadius="md"
        borderWidth="2xs"
        borderColor={getBorderColor()}
        boxShadow={getBoxShadow()}
        _hover={{ bg: focusBgColor }}
        _active={{ bg: focusBgColor }}
        _focus={{ boxShadow: focusBoxShadow, borderColor: 'transparent' }}
        {...pointerFocusStyles}
      >
        <Text level="sm" isTruncated>
          <span {...valueProps}>
            {selectedItem ? selectedItem.rendered : placeholder}
          </span>
        </Text>
        <FunctionalIcon
          color={iconColor}
          variant="FunctionalChevronDown"
          size="xs"
        />
      </ChakraButton>
      {isMobile ? (
        <Drawer
          isOpen={isOpen}
          onClose={close}
          ariaLabelDrawer="Select an option"
          closeOnOverlayClick={false}
        >
          <OptionList
            {...menuProps}
            listBoxRef={listBoxRef}
            state={state}
            isClearable={isClearable}
            type={type}
            label={listLabel}
          />
        </Drawer>
      ) : (
        isOpen && (
          <Dropdown
            isOpen={isOpen}
            onClose={close}
            overlayRef={overlayRef}
            triggerRef={triggerRef}
            boundingRef={dropdownBoundingRef}
          >
            <OptionList
              {...menuProps}
              listBoxRef={listBoxRef}
              state={state}
              isClearable={isClearable}
              type={type}
              label={listLabel}
            />
          </Dropdown>
        )
      )}
    </Box>
  )
}

export type SelectProps<T> = {
  items: Iterable<T>
  label?: string
  description?: string
  isRequired?: boolean
  errors?: FormErrors
  listLabel?: string
  ariaLabel?: string
  isClearable?: boolean
  placeholder?: string
  value?: string
  name?: string
  onChange?: (selectedItem: Key) => void
}

export const Select: FC<SelectProps<Option>> = ({
  items,
  label,
  description,
  isRequired,
  errors,
  listLabel,
  ariaLabel,
  isClearable,
  placeholder,
  onChange,
  value,
  name,
}) => {
  return (
    <FormControl
      label={label}
      errors={errors}
      isRequired={isRequired}
      description={description}
    >
      <BaseSelect
        isInvalid={Boolean(errors && !R.isEmpty(errors))}
        items={items}
        listLabel={listLabel}
        label={label}
        ariaLabel={ariaLabel}
        isClearable={isClearable}
        placeholder={placeholder}
        // NOTE: This should default to null to avoid flipping between controlled and uncontrolled
        // state and gettings warnings but the provided types don't support this.
        // <https://github.com/adobe/react-spectrum/issues/1363>
        selectedKey={
          Array.from(items).find(item => item.id === value)?.id || undefined
        }
        onChange={onChange}
        name={name}
      >
        {item => <Item key={item.id}>{item.label}</Item>}
      </BaseSelect>
    </FormControl>
  )
}
