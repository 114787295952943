import React from 'react'
import {
  BreadcrumbItem as ChakraBreadcrumbItem,
  BreadcrumbLink as ChakraBreadcrumbLink,
} from '@chakra-ui/react'
import type { FC } from 'react'

type BreadcrumbItemProps = {
  isCurrentPage?: boolean
  href: string
  isLastChild?: boolean
}

const BreadcrumbItem: FC<BreadcrumbItemProps> = ({
  children,
  isCurrentPage = false,
  href,
  isLastChild,
}) => {
  return (
    <ChakraBreadcrumbItem
      spacing="sm"
      separator="/"
      isCurrentPage={isCurrentPage}
      isLastChild={isLastChild}
    >
      <ChakraBreadcrumbLink href={href}>{children}</ChakraBreadcrumbLink>
    </ChakraBreadcrumbItem>
  )
}

export { BreadcrumbItem }
