import { useParams } from 'react-router-dom'
import { useOrganisationHelper } from 'redux/selectors'

export const useCompanyId = () => {
  const { scope, organisationId } = useOrganisationHelper()
  const params = useParams()

  return {
    companyId: scope === 'customer' ? String(organisationId) : params?.id ?? '',
  }
}
