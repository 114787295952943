import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { App } from './App'
import { reportWebVitals } from './reportWebVitals'
import { BrightProvider, brightTheme, PaletteProvider } from '_BRIGHT_'
import { store } from 'redux/store'
import { Provider as ReduxProvider } from 'react-redux'

ReactDOM.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <BrowserRouter>
        <BrightProvider theme={brightTheme}>
          <PaletteProvider palette="baseOne">
            <App />
          </PaletteProvider>
        </BrightProvider>
      </BrowserRouter>
    </ReduxProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)

reportWebVitals()
