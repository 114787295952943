import React from 'react'
import type { FC } from 'react'
import { Icon } from '@chakra-ui/react'
import type { FunctionalIconSvgProps } from '_BRIGHT_/components/Icons/FunctionalIcon/FunctionalIcon'

export const FunctionalHidden: FC<FunctionalIconSvgProps> = ({
  color,
  size,
  height,
  strokeWidth,
  ariaHidden,
}) => (
  <Icon
    viewBox="0 0 24 24"
    fill="none"
    color={color}
    width={size}
    height={height || size}
    aria-hidden={ariaHidden}
  >
    <path
      d="M19.7921 15.5C20.7867 14.5909 21.6593 13.5396 22.3805 12.4103C22.4591 12.2882 22.5009 12.146 22.5009 12.0008C22.5009 11.8556 22.4591 11.7135 22.3805 11.5914C20.1043 8.07575 16.3032 5.25012 11.9841 5.25012C11.4075 5.25012 10.8286 5.30279 10.2508 5.40813M13.5 18.637C13.0038 18.7113 12.498 18.7501 11.9841 18.7501C7.58442 18.7501 3.88692 15.9751 1.62004 12.4267C1.53958 12.3021 1.49783 12.1565 1.50009 12.0083C1.50234 11.86 1.54849 11.7158 1.6327 11.5937C2.4713 10.3916 3.37433 9.3604 4.3213 8.5"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.9997 7.49939C11.6627 7.49921 11.3267 7.53695 10.998 7.61189C10.9814 7.61534 10.9661 7.62322 10.9536 7.6347C10.9411 7.64618 10.932 7.66083 10.9272 7.67709C10.9224 7.69334 10.9221 7.7106 10.9263 7.72702C10.9305 7.74344 10.9391 7.7584 10.9512 7.77033L16.2288 13.0466C16.2407 13.0586 16.2557 13.0672 16.2721 13.0714C16.2885 13.0757 16.3058 13.0753 16.3221 13.0705C16.3383 13.0657 16.353 13.0566 16.3644 13.0441C16.3759 13.0317 16.3838 13.0163 16.3872 12.9997C16.5375 12.3407 16.5374 11.6562 16.3868 10.9972C16.2363 10.3383 15.9392 9.72167 15.5177 9.19324C15.0962 8.66481 14.5611 8.23813 13.952 7.94489C13.343 7.65165 12.6757 7.49938 11.9997 7.49939Z"
      fill="currentColor"
    />
    <path
      d="M7.77091 10.9534C7.75899 10.9413 7.74402 10.9327 7.7276 10.9285C7.71118 10.9243 7.69392 10.9246 7.67767 10.9294C7.66141 10.9342 7.64676 10.9433 7.63528 10.9558C7.6238 10.9683 7.61592 10.9836 7.61247 11.0002C7.44248 11.7429 7.46382 12.5166 7.67451 13.2487C7.88519 13.9809 8.27832 14.6476 8.81704 15.1863C9.35577 15.725 10.0224 16.1181 10.7546 16.3288C11.4868 16.5395 12.2604 16.5608 13.0031 16.3909C13.0197 16.3874 13.035 16.3795 13.0475 16.368C13.06 16.3566 13.0691 16.3419 13.0739 16.3257C13.0787 16.3094 13.0791 16.2921 13.0748 16.2757C13.0706 16.2593 13.062 16.2443 13.05 16.2324L7.77091 10.9534Z"
      fill="currentColor"
    />
    <line
      x1={4.41364}
      y1={4.04039}
      x2={19.5194}
      y2={20.0347}
      stroke="currentColor"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
)
