import { FC } from 'react'
import { BoxProps, Box, Skeleton } from '@chakra-ui/react'
import { Column, Columns, Text, useColor } from '_BRIGHT_'
import { AppValue } from 'lib/cubeJs'
import { AppTooltip } from 'components/Tooltip'

type DataRowProps = BoxProps &
  AppValue<string | number> & {
    textVariant: 'secondary' | 'tertiary'
    skeletonText?: boolean
  }

export const DataRow: FC<DataRowProps> = ({
  title,
  value,
  tooltip,
  textVariant,
  skeletonText = false,
  ...boxProps
}) => {
  const endColor = useColor('active', 'baseOne')

  return skeletonText ? (
    <Skeleton
      height="21px"
      data-testid="skeleton"
      startColor="transparent"
      endColor={endColor}
    />
  ) : (
    <Box {...boxProps} data-testid="card-line">
      <Columns>
        <Column width="fluid" alignY="center">
          <Text level="xs" variant={textVariant}>
            {title}
          </Text>
          {tooltip && (
            <Box lineHeight="1" tabIndex={-1}>
              <AppTooltip tooltip={tooltip} />
            </Box>
          )}
        </Column>
        <Column width="content">
          <Text as="span" level="xs" variant="primary">
            {value}
          </Text>
        </Column>
      </Columns>
    </Box>
  )
}
