import { AccordionPanel, FunctionalIcon, useColor } from '_BRIGHT_/components'
import type { SpaceProps } from '@chakra-ui/react'
import { AccordionButton, Box, usePrefersReducedMotion } from '@chakra-ui/react'
import type { FC } from 'react'
import React from 'react'

type ListViewItemAccordionButtonProps = Pick<
  SpaceProps,
  'paddingLeft' | 'paddingRight'
> & {
  boxShadow: string
}

export const ListViewItemAccordionButton: FC<ListViewItemAccordionButtonProps> =
  ({ boxShadow, children, ...paddingProps }) => {
    return (
      <AccordionButton
        paddingY="sm"
        _focus={{ boxShadow }}
        _hover={{ bg: 'none' }}
        textAlign="unset"
        alignItems="flex-start"
        gridGap="sm"
        {...paddingProps}
      >
        {children}
      </AccordionButton>
    )
  }

type ListViewItemAccordionIconProps = {
  isExpanded: boolean
}

export const ListViewItemAccordionIcon: FC<ListViewItemAccordionIconProps> = ({
  isExpanded,
}) => {
  const iconColor = useColor('accentSecondary')
  const prefersReducedMotion = usePrefersReducedMotion()
  const transform = isExpanded ? 'rotate(-180deg)' : undefined
  const transitionDuration = prefersReducedMotion ? undefined : 'normal'

  return (
    <Box
      transform={transform}
      transformOrigin="center"
      transitionProperty="transform"
      transitionDuration={transitionDuration}
    >
      <FunctionalIcon
        size="sm"
        lineHeight="sm"
        variant="FunctionalChevronDown"
        color={iconColor}
        ariaHidden={true}
      />
    </Box>
  )
}

export const ListViewItemAccordionPanel: FC = ({ children }) => (
  <AccordionPanel paddingBottom="none" paddingTop="none">
    {children}
  </AccordionPanel>
)
