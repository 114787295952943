import { Button } from '_BRIGHT_/components'
import type { FC } from 'react'
import React from 'react'
import type { OnClick } from '_BRIGHT_/utils'

type SignOutButtonProps = {
  label: string
  onClick: OnClick
}

export const SignOutButton: FC<SignOutButtonProps> = ({ label, onClick }) => {
  return (
    <Button
      onClick={onClick}
      size="sm"
      variant="secondaryButton"
      isFullWidthForMobile={false}
    >
      {label}
    </Button>
  )
}
