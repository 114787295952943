export function createRange(start: number, end: number): number[] {
  if (typeof start !== 'number' || typeof end !== 'number' || isNaN(start) || isNaN(end)) {
    throw TypeError(`Start and end arguments must be numbers, received: ${start}, ${end}`)
  }

  if (start > end) {
    throw Error(`Start number must be less than the end number, received: ${start}, ${end}`)
  }

  const length = end - start + 1

  return Array.from({ length }).map((_, i) => i + start)
}
