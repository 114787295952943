import gsap from 'gsap'

export const blendEases =
  (startEase: string, endEase: string) =>
  (v: number): number => {
    const { parseEase } = gsap
    const s = parseEase(startEase)
    const e = parseEase(endEase)
    const b = parseEase('power4.inOut')(v)
    return s(v) * (1 - b) + e(v) * b
  }
