import type { GeniusFieldsConfig } from '_BRIGHT_/components/Genius'
import {
  isDateField,
  isNumberField,
  isOptionField,
  isTextField,
  isFilterValueWithOperator,
  isFilterValueWithoutOperator,
} from '_BRIGHT_/components/Genius/utils'
import type { QueryParams } from '_BRIGHT_/utils/queryParams'

type ValidatorFn = (param: string, fieldsConfig: GeniusFieldsConfig) => boolean
export type UrlParamValidationResult = {
  sort: boolean
  filters: boolean
  page: boolean
}

const validateSortParam: ValidatorFn = (sortParam, fieldsConfig) => {
  const sortedField =
    sortParam.charAt(0) === '-' ? sortParam.slice(1) : sortParam
  return (
    Object.keys(fieldsConfig).includes(sortedField) &&
    fieldsConfig[sortedField].isSortable
  )
}

const validatePageParam: ValidatorFn = pageParam => {
  return Number.parseInt(pageParam, 10) >= 1
}

const validateFiltersParam: ValidatorFn = (filtersParam, fieldsConfig) => {
  let parsedFilters: Record<string, unknown>
  try {
    parsedFilters = JSON.parse(filtersParam)
  } catch (e) {
    return false
  }

  const validFields = Object.keys(fieldsConfig)
  return (
    Object.keys(parsedFilters).length > 0 &&
    Object.keys(parsedFilters).every(key => {
      const filter = parsedFilters[key]
      if (!validFields.includes(key) || !fieldsConfig[key].isFilterable) {
        return false
      }

      const field = fieldsConfig[key]

      if (isFilterValueWithOperator(filter) && isDateField(field)) {
        // Operator is optional, so it's still valid if not provided.
        // Otherwise it needs to match a valid operator from genius config.
        const isOperatorValid = filter.operator
          ? Array.from(field.dateInputConfig?.operatorOptions ?? []).some(
              option => option.id === filter.operator,
            )
          : true

        return typeof filter.value === 'string' && isOperatorValid
      }

      if (
        isFilterValueWithoutOperator(filter) &&
        (isNumberField(field) || isOptionField(field) || isTextField(field))
      ) {
        return typeof filter.value === 'string'
      }

      return false
    })
  )
}

export const validateQueryParams = (
  queryParams: QueryParams,
  fieldsConfig: GeniusFieldsConfig,
): UrlParamValidationResult => {
  // Default to true, because "not specified" is a completely valid state for a query param to be in.
  // eg. if sort param isn't present in the URL, that means we use the default sort, which is a totally valid
  // sort preference.
  const validationResult = { sort: true, filters: true, page: true }

  if (queryParams.sort) {
    validationResult.sort = validateSortParam(queryParams.sort, fieldsConfig)
  }

  if (queryParams.filters) {
    validationResult.filters = validateFiltersParam(
      queryParams.filters,
      fieldsConfig,
    )
  }

  if (queryParams.page) {
    validationResult.page = validatePageParam(queryParams.page, fieldsConfig)
  }

  return validationResult
}
