import type { FC } from 'react'
import React from 'react'
import type { HiFiIconPathProps } from '_BRIGHT_/components/Icons'

const Pause: FC<HiFiIconPathProps> = ({
  primaryColor,
  secondaryColor,
  strokeWidth,
  hasShadow,
}) => {
  return (
    <>
      {hasShadow && (
        <rect
          opacity="0.2"
          x="8"
          y="7"
          width="11"
          height="22"
          rx="1"
          fill={primaryColor}
        />
      )}
      <rect
        x="6"
        y="5"
        width="9"
        height="20"
        rx="0.5"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
      />
      <rect
        x="18"
        y="5"
        width="9"
        height="20"
        rx="0.5"
        stroke={secondaryColor}
        strokeWidth={strokeWidth}
      />
    </>
  )
}

export { Pause }
