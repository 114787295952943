import { borders } from './borders'
import { borderWidths } from './borderWidths'
import { breakpoints } from './breakpoints'
import { colors } from './colors'
import components from './components'
import { radii } from './radii'
import { shadows } from './shadows'
import { sizes } from './sizes'
import { space } from './space'
import { fonts, fontSizes, fontWeights, letterSpacings, lineHeights } from './typography'
import { zIndices } from './zIndices'

export const DefaultBrightTheme = {
  breakpoints,
  colors,
  components,
  space,
  zIndices,
  shadows,
  fonts,
  fontSizes,
  fontWeights,
  letterSpacings,
  lineHeights,
  borders,
  borderWidths,
  radii,
  sizes,
}
