import { IconButton, Text, useColor } from '_BRIGHT_/components'
import { toSentenceCase } from '_BRIGHT_/utils'
import { Tag as ChakraTag, TagLabel as ChakraTagLabel } from '@chakra-ui/react'
import type { FC } from 'react'
import React from 'react'

export type TagProps = {
  children: string
  onClear: () => void
  clearButtonLabel: string
}

const Tag: FC<TagProps> = ({ children, onClear, clearButtonLabel }) => {
  const bg = useColor('contrast', 'baseTwo')
  const color = useColor('contentPrimary', 'infoSecondary')
  const tagContent = toSentenceCase(children)

  return (
    <ChakraTag
      data-testid="tag"
      bg={bg}
      color={color}
      size="sm"
      variant="solid"
      borderRadius="full"
      px="none"
    >
      <ChakraTagLabel pl="sm" pr="2xs" py="2xs">
        <Text>{tagContent}</Text>
      </ChakraTagLabel>
      <IconButton
        ariaLabel={`${clearButtonLabel} ${tagContent}`}
        icon="FunctionalClose"
        onClick={onClear}
        size="sm"
        variant="ghostButton"
        isRound
      />
    </ChakraTag>
  )
}

export { Tag }
