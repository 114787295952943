import type { AppliedActionTagsContent } from '.'
import type { GeniusState } from '_BRIGHT_/components/Genius/hooks'
import { isFilterValueWithOperator } from '_BRIGHT_/components/Genius/utils'

export const getFilterTags = (
  filterList: GeniusState['filters'],
): AppliedActionTagsContent[] =>
  filterList
    ? Object.entries(filterList).map(
        ([fieldName, value]): AppliedActionTagsContent => {
          return {
            fieldName,
            value: value.value,
            operator: isFilterValueWithOperator(value)
              ? value.operator
              : undefined,
            actionType: 'filter',
          }
        },
      )
    : []

export const getSortTag = (
  sortItem: GeniusState['sort'],
): AppliedActionTagsContent[] =>
  sortItem
    ? [
        {
          fieldName: sortItem.field,
          value: sortItem.direction,
          actionType: 'sort',
        },
      ]
    : []
