import { useMemo } from 'react'
import { useAllAdviserCompanies } from 'redux/selectors'
import { useGeniusStateForQuery } from '_BRIGHT_'
import { QueryVariables } from '_BRIGHT_/components/Genius/hooks'

export const filterOutCompanyId = (
  variables: QueryVariables,
  allIds: string[],
) => {
  const filters = variables?.filter as { [k: string]: { value: string } }
  const companyId = filters?.companyId?.value

  if (!!companyId && allIds.includes(companyId)) {
    return variables.filter
  }

  const variablesCopy = { ...variables.filter }
  delete variablesCopy.companyId
  return variablesCopy
}

export const useGeniusVariables = () => {
  const allIds = useAllAdviserCompanies()

  const { variables, onGeniusStateChange, skip } = useGeniusStateForQuery({
    limit: 25,
  })

  const filter = useMemo(
    () => filterOutCompanyId(variables, allIds),
    [variables, allIds],
  )

  return {
    variables: {
      ...variables,
      filter,
    },
    skip,
    onGeniusStateChange,
  }
}
