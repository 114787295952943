import { Frame } from '_BRIGHT_/components'
import type { Space } from '_BRIGHT_/components/Layout/Common'
import type { WithBlockPaletteProps } from '_BRIGHT_/components/PaletteProvider'
import { PaletteProvider } from '_BRIGHT_/components/PaletteProvider'
import { Box } from '@chakra-ui/react'
import type { SystemProps } from '@chakra-ui/system'
import type { FC } from 'react'
import React from 'react'

export type BlockProps = WithBlockPaletteProps<{
  paddingY?: Space
  paddingTop?: Space
  paddingBottom?: Space
  flexGrow?: SystemProps['flexGrow']
}>

const Block: FC<BlockProps> = ({
  children,
  palette,
  paddingY,
  paddingTop,
  paddingBottom,
  flexGrow,
}) => {
  return (
    <PaletteProvider palette={palette}>
      <Frame
        alignX="center"
        paddingY={paddingY}
        paddingTop={paddingTop}
        paddingBottom={paddingBottom}
        palette={palette}
        flexGrow={flexGrow}
      >
        <Box
          maxWidth="block-children-max-width"
          width="100%"
          paddingX={{ base: 'md', sm: 'lg', md: 'none' }}
        >
          {children}
        </Box>
      </Frame>
    </PaletteProvider>
  )
}

export { Block }
