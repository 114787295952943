import React from 'react'
import type { FC } from 'react'
import {
  Button,
  Frame,
  Radio,
  RadioGroup,
  withController,
} from '_BRIGHT_/components'
import {
  ActionDrawerButtonsWrapper,
  ActionDrawerView,
} from '_BRIGHT_/components/Genius/components'
import { useForm } from 'react-hook-form'
import type { FieldConfig, SortOptionsItem } from '_BRIGHT_/components/Genius'
import type { Sort, SortDirection } from '_BRIGHT_/components/Genius/hooks'
import { useDrawerContext } from '_BRIGHT_/components/Genius/hooks'
import {
  useGeniusStateContext,
  useGeniusConfigContext,
  dispatchSetSort,
} from '_BRIGHT_/components/Genius/hooks'
import { Box } from '@chakra-ui/react'

export type SortViewBodyProps = {
  sortByLabel: string
  sortDirectionLabel: string
  sortDirectionOptions: SortOptionsItem<SortDirection>[]
  confirmButtonLabel: string
  cancelButtonLabel: string
  sortableFields: FieldConfig[]
}

export const MISSING_SORT_OPTIONS_MESSAGE = `Genius Sort view: the sortByOptions array shouldn't be empty`
export const MISSING_SORT_DIRECTION_MESSAGE = `Genius Sort view: the sortDirectionOptions array shouldn't be empty`

const ControlledRadioGroup = withController<Sort>()(RadioGroup)

export const SortViewBody: FC<SortViewBodyProps> = ({
  sortByLabel,
  sortDirectionLabel,
  sortDirectionOptions,
  confirmButtonLabel,
  cancelButtonLabel,
  sortableFields,
}) => {
  const { dispatch, sort } = useGeniusStateContext()
  const { close } = useDrawerContext()

  const { control, handleSubmit } = useForm<Sort>({
    mode: 'onSubmit',
    criteriaMode: 'all',
    defaultValues: {
      field: sort?.field || sortableFields[0]?.fieldName,
      direction: sort?.direction || sortDirectionOptions[0]?.value,
    },
  })

  if (!sortableFields.length) {
    console.error(MISSING_SORT_OPTIONS_MESSAGE)
    return null
  }

  if (!sortDirectionOptions.length) {
    console.error(MISSING_SORT_DIRECTION_MESSAGE)
    return null
  }

  const onFormSubmit = ({ field, direction }: Sort): void => {
    dispatchSetSort(dispatch, field, direction)
    // @TODO Genius - Refactor to triggerthe close action when the GeniusState gets updated - https://smartpension.atlassian.net/browse/T2-1096
    close()
  }

  return (
    <Box w="100%" as="form" onSubmit={handleSubmit(onFormSubmit)}>
      <ControlledRadioGroup
        control={control}
        name="field"
        label={sortByLabel}
        isRequired
      >
        {sortableFields.map(({ label, fieldName }) => (
          <Radio key={label} label={label} value={fieldName} />
        ))}
      </ControlledRadioGroup>

      <Frame palette="baseTwo" paddingTop="4xl">
        <ControlledRadioGroup
          control={control}
          name="direction"
          label={sortDirectionLabel}
          isRequired
        >
          {sortDirectionOptions.map(({ label, value }) => (
            <Radio key={label} label={label} value={value} />
          ))}
        </ControlledRadioGroup>
      </Frame>

      <Frame palette="baseTwo" paddingTop="4xl">
        <ActionDrawerButtonsWrapper>
          {/* @TODO Genius - refactor to trigger the close action when the GeniusState gets updated -https://smartpension.atlassian.net/browse/T2-1096 */}
          <Button
            variant="secondaryButton"
            isFullWidthForMobile
            onClick={close}
          >
            {cancelButtonLabel}
          </Button>
          <Button type="submit" isFullWidthForMobile>
            {confirmButtonLabel}
          </Button>
        </ActionDrawerButtonsWrapper>
      </Frame>
    </Box>
  )
}

export const SortView: FC = () => {
  const { actions, fields } = useGeniusConfigContext()
  if (!actions.sort) return null

  const { title, ...sortContent } = actions.sort

  const sortableFields = Object.values(fields).filter(
    ({ isSortable }) => isSortable,
  )

  return (
    <ActionDrawerView title={title}>
      <SortViewBody sortableFields={sortableFields} {...sortContent} />
    </ActionDrawerView>
  )
}
