import { AppQuery } from 'lib/cubeJs'

export const homePageQuery: AppQuery = {
  measures: [
    'SMART_ANALYTICS_COMPANIES.count',
    'SMART_ANALYTICS_COMPANIES.sumAUM',
    'SMART_ANALYTICS_COMPANIES.totalTakeUpRatio',
    'SMART_ANALYTICS_COMPANIES.sumEmployees',
    'SMART_ANALYTICS_COMPANIES.sumActiveEmployees',
    'SMART_ANALYTICS_COMPANIES.totalDataQualityScoreRatio',
  ],
}
