import type { Option } from '_BRIGHT_/components/Forms/common'
import type { DeserializedItem, DeserializedSuggestion } from '.'

export type GetSuggestionsArgs = {
  query: string
  abortSignal?: AbortSignal
  selectedLocation?: Location
}

export type AddressApiShape = {
  getSuggestions: (
    args: GetSuggestionsArgs,
  ) => Promise<DeserializedSuggestion[]>
  getAddressDetails: (
    address: Location,
  ) => Promise<DeserializedItem<RawAddress>>
}

export enum LOCATION_TYPE {
  ADDRESS = 'Address',
  LOCALITY = 'Locality',
  POSTCODE = 'Postcode',
  RESIDENTIAL = 'Residential',
}

export type RawLocation = {
  Id: string
  Description: string
  Type: LOCATION_TYPE
  Text: string
  Highlight: string
}

export type Location = Option & {
  type: LOCATION_TYPE
  highlight: string
}

export type AddressLookupResult<T> = { Items: T[] }

export type AddressLookupError = {
  Error: string
  Description: string
  Cause: string
  Resolution: string
}

export type RawAddress = {
  AdminAreaCode?: string
  AdminAreaName: string
  Barcode?: string
  Block?: string
  BuildingName?: string
  BuildingNumber: string
  City: string
  Company?: string
  CountryIso2: string
  CountryIso3: string
  CountryIsoNumber: number
  CountryName: string
  DataLevel?: string
  Department?: string
  District: string
  DomesticId?: string
  Field1?: string
  Field2?: string
  Field3?: string
  Field4?: string
  Field5?: string
  Id: string
  Label: string
  Language: string
  LanguageAlternatives: string
  Line1: string
  Line2?: string
  Line3?: string
  Line4?: string
  Line5?: string
  Neighbourhood?: string
  POBoxNumber?: string
  PostalCode: string
  Province?: string
  ProvinceCode?: string
  ProvinceName?: string
  SecondaryStreet?: string
  SortingNumber1?: string
  SortingNumber2?: string
  Street: string
  SubBuilding?: string
  Type: LOCATION_TYPE
}

export type Address = {
  adminAreaCode?: string
  adminAreaName: string
  barcode?: string
  block?: string
  buildingName?: string
  buildingNumber: string
  city: string
  company?: string
  countryIso2: string
  countryIso3: string
  countryIsoNumber: number
  countryName: string
  dataLevel?: string
  department?: string
  district: string
  domesticId?: string
  field1?: string
  field2?: string
  field3?: string
  field4?: string
  field5?: string
  id: string
  label: string
  language: string
  languageAlternatives: string
  line1: string
  line2?: string
  line3?: string
  line4?: string
  line5?: string
  neighbourhood?: string
  pOBoxNumber?: string
  postalCode: string
  province?: string
  provinceCode?: string
  provinceName?: string
  secondaryStreet?: string
  sortingNumber1?: string
  sortingNumber2?: string
  street: string
  subBuilding?: string
  type: LOCATION_TYPE
}
