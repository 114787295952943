import type { FC } from 'react'
import React from 'react'
import type { HiFiIconPathProps } from '_BRIGHT_/components/Icons'

const MessageEmployer: FC<HiFiIconPathProps> = ({
  primaryColor,
  secondaryColor,
  baseColor,
  strokeWidth,
  hasShadow,
}) => {
  return (
    <>
      <path
        d="M8.5 9C8.04013 9.23414 7.61548 9.54074 7.24377 9.91245C6.3139 10.8423 5.7915 12.1035 5.7915 13.4185C5.7915 14.7336 6.3139 15.9947 7.24377 16.9246C8.17363 17.8545 9.43481 18.3769 10.7498 18.3769C12.0649 18.3769 13.326 17.8545 14.2559 16.9246C14.833 16.3475 15.2531 15.6428 15.4889 14.8768"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.58325 28.1268C3.03097 28.1268 2.5768 27.6774 2.64564 27.1294C2.86601 25.3754 3.66315 23.7332 4.9264 22.47C6.42669 20.9697 8.46152 20.1268 10.5833 20.1268C12.705 20.1268 14.7398 20.9697 16.2401 22.47C17.5034 23.7332 18.3005 25.3754 18.5209 27.1294C18.5897 27.6774 18.1355 28.1268 17.5833 28.1268H3.58325Z"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      {hasShadow && (
        <>
          <circle
            opacity="0.2"
            cx="14"
            cy="15.8768"
            r="6"
            fill={primaryColor}
          />
          <path
            opacity="0.2"
            d="M7.63604 24.6445C9.32387 22.9536 11.6131 22.0037 14 22.0037C16.3869 22.0037 18.6761 22.9536 20.364 24.6445C22.0518 26.3354 23 28.6288 23 31.0201C23 31.2809 22.8966 31.5311 22.7124 31.7156C22.5283 31.9 22.2786 32.0037 22.0182 32.0037H5.98182C5.72142 32.0037 5.47169 31.9 5.28757 31.7156C5.10344 31.5311 5 31.2809 5 31.0201C5 28.6288 5.94821 26.3354 7.63604 24.6445Z"
            fill={primaryColor}
          />
        </>
      )}
      <path
        d="M17.8298 11.5H20.1667L18.5 16L24.9193 11.8236C25.2439 11.6124 25.6228 11.5 26.01 11.5H28.5C29.0523 11.5 29.5 11.0523 29.5 10.5V3C29.5 2.44772 29.0523 2 28.5 2H13C12.4477 2 12 2.44772 12 3V10.5"
        fill={baseColor}
      />
      <path
        d="M17.8298 11.5H20.1667L18.5 16L24.9193 11.8236C25.2439 11.6124 25.6228 11.5 26.01 11.5H28.5C29.0523 11.5 29.5 11.0523 29.5 10.5V3C29.5 2.44772 29.0523 2 28.5 2H13C12.4477 2 12 2.44772 12 3V10.5C12 11.0523 12.4477 11.5 13 11.5H17.8298Z"
        stroke={secondaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  )
}

export { MessageEmployer }
