import { FC } from 'react'
import { CubeKey } from 'lib/cubeJs'
import { Box } from '@chakra-ui/react'
import { Heading, Stack, Text } from '_BRIGHT_/'
import { formatCubeValueToAppValue } from 'lib/cubeJs/cubeValueMap'

export const glossaryKeys: CubeKey[] = [
  'SMART_ANALYTICS_COMPANIES.totalAUM',
  'SMART_ANALYTICS_COMPANIES.totalDataQualityScoreRatio',
  'SMART_ANALYTICS_COMPANIES.schemeStartDate',
  'SMART_ANALYTICS_COMPANIES.takeUpRatio',
]

export const Glossary = () => {
  return (
    <Stack space="xl">
      {glossaryKeys.map(cubeKey => (
        <Term key={cubeKey} cubeKey={cubeKey} />
      ))}
    </Stack>
  )
}

const Term: FC<{ cubeKey: CubeKey }> = ({ cubeKey }) => {
  const { title, tooltip: text } = formatCubeValueToAppValue(cubeKey, '_')

  return (
    <Box key={cubeKey}>
      <Stack space="xs">
        <Heading as="h3">{title}</Heading>
        <Text>{text}</Text>
      </Stack>
    </Box>
  )
}
