import { useColor } from '_BRIGHT_/components'
import { Button as ChakraButton } from '@chakra-ui/react'
import type { FC } from 'react'
import React, { useState } from 'react'
import { usePointerFocusStyles, useShadow } from '_BRIGHT_/utils'
import type { GeniusViewType } from '_BRIGHT_/components/Genius/hooks'

export type BorderSizes = 'md' | '2xs' | 'none'
export type Position = 'first' | 'middle' | 'last'
export type ToggleItemProps = {
  label: string
  borderLeftRadius?: BorderSizes
  borderRightRadius?: BorderSizes
  borderRightWidth?: BorderSizes
  onClick: () => void
  isActive: boolean
  position: Position
}

const ToggleItem: FC<ToggleItemProps> = ({
  label,
  borderLeftRadius = 'none',
  borderRightRadius = 'none',
  borderRightWidth = 'none',
  onClick,
  isActive = false,
  position,
}) => {
  const inActiveBgColor = useColor('core')
  const borderColor = useColor('borderSecondary')
  const inActiveColor = useColor('contentTertiary')
  const activeBgColor = useColor('contrast')
  const hoverBgColor = useColor('active')
  const focusColor = useColor('focus')
  const boxShadow = useShadow('inset-sm')
  const color = useColor('contentSecondary', 'baseTwo')

  const pointerFocusStyles = usePointerFocusStyles({ borderColor })

  const hoverStyles = {
    bg: !isActive ? hoverBgColor : undefined,
    color,
  }

  const activeStyles = {
    bg: activeBgColor,
    borderRightWidth: 'none',
    borderColor,
    color,
  }

  const keyboardFocusStyles = {
    boxShadow,
    borderColor: focusColor,
    bg: activeBgColor,
    color,
  }

  return (
    <ChakraButton
      bg={isActive ? activeBgColor : inActiveBgColor}
      borderRadius="md"
      borderColor={borderColor}
      borderWidth="2xs"
      borderTopLeftRadius={position === 'first' ? 'md' : borderLeftRadius}
      borderBottomLeftRadius={position === 'first' ? 'md' : borderLeftRadius}
      borderTopRightRadius={position === 'last' ? 'md' : borderRightRadius}
      borderBottomRightRadius={position === 'last' ? 'md' : borderRightRadius}
      borderRightWidth={position === 'last' ? '2xs' : borderRightWidth}
      height="8"
      aria-pressed={isActive}
      onClick={onClick}
      _active={activeStyles}
      _hover={hoverStyles}
      _focus={keyboardFocusStyles}
      color={isActive ? color : inActiveColor}
      cursor={isActive ? 'default' : 'pointer'}
      fontWeight="bold"
      fontSize="xs"
      role="button"
      {...pointerFocusStyles}
    >
      {label}
    </ChakraButton>
  )
}

export type ToggleOption = { label: string; id: GeniusViewType }
export type ToggleProps = {
  options: ToggleOption[]
  onChange: (value: string) => void
  defaultOption?: ToggleOption
}

export const Toggle: FC<ToggleProps> = ({
  options,
  onChange,
  defaultOption,
}) => {
  const [selectedId, _setSelectedId] = useState(
    defaultOption?.id ?? options[0]?.id,
  )

  const setSelectedId = (newId: GeniusViewType) => {
    onChange(newId)
    _setSelectedId(newId)
  }

  return !options.length ? null : (
    <div role="group" aria-label="Toggle buttons">
      {options.map(({ label, id }, i: number) => {
        const position =
          i === 0 ? 'first' : i === options.length - 1 ? 'last' : 'middle'
        return (
          <ToggleItem
            key={i}
            label={label}
            onClick={() => setSelectedId(id)}
            isActive={selectedId === id}
            position={position}
          />
        )
      })}
    </div>
  )
}
