import { useOrganisationHelper } from 'redux/selectors'

export const useScopeBaseURL = () => {
  const { scope, organisationSlug } = useOrganisationHelper()

  return {
    baseURL:
      scope === 'user'
        ? process.env.REACT_APP_ADVISER_APP_BASE_URL
        : createEmployerBaseUrl(organisationSlug),
  }
}

export const createEmployerBaseUrl = (organisationSlug: string | null) =>
  organisationSlug
    ? process.env.REACT_APP_EMPLOYER_APP_BASE_URL?.replace(
        '<account_name>',
        organisationSlug,
      )
    : ''
