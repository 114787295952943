import type { FC } from 'react'
import React from 'react'
import type { HiFiIconPathProps } from '_BRIGHT_/components/Icons'

const PersonChosen: FC<HiFiIconPathProps> = ({
  primaryColor,
  secondaryColor,
  baseColor,
  strokeWidth,
  hasShadow,
}) => {
  return (
    <>
      {hasShadow && (
        <path
          opacity="0.2"
          d="M14 18C9.70955 18 6.02405 20.7808 4.42179 24.7552C4.1746 25.3683 4.65382 26 5.31492 26H22.6851C23.3462 26 23.8254 25.3683 23.5782 24.7552C21.976 20.7808 18.2904 18 14 18Z"
          fill={primaryColor}
        />
      )}
      <path
        d="M6 7.5C6 8.95869 6.57946 10.3576 7.61091 11.3891C8.64236 12.4205 10.0413 13 11.5 13C12.9587 13 14.3576 12.4205 15.3891 11.3891C16.4205 10.3576 17 8.95869 17 7.5C17 6.04131 16.4205 4.64236 15.3891 3.61091C14.3576 2.57946 12.9587 2 11.5 2C10.0413 2 8.64236 2.57946 7.61091 3.61091C6.57946 4.64236 6 6.04131 6 7.5V7.5Z"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.0005 30L18.8863 24.919C18.4373 24.4915 18.1409 23.9402 18.0391 23.3433C17.9373 22.7464 18.0354 22.1342 18.3193 21.5933C18.5337 21.1849 18.8469 20.8308 19.2332 20.5603C19.6195 20.2897 20.0677 20.1105 20.541 20.0373C21.0143 19.9641 21.499 19.999 21.9553 20.1393C22.4116 20.2795 22.8264 20.521 23.1655 20.8438L24.0005 21.639L24.8356 20.8438C25.1746 20.5209 25.5893 20.2795 26.0455 20.1392C26.5018 19.999 26.9865 19.9641 27.4597 20.0373C27.9329 20.1105 28.381 20.2897 28.7672 20.5603C29.1534 20.8308 29.4665 21.1849 29.6808 21.5933C29.9645 22.1342 30.0626 22.7463 29.961 23.3431C29.8594 23.9399 29.5634 24.4912 29.1148 24.919L24.0005 30Z"
        fill={baseColor}
        stroke={secondaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6637 16.8579C14.3871 16.3059 12.9793 16 11.5 16C7.51122 16 4.04191 18.2242 2.26465 21.5"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
      {hasShadow && (
        <path
          opacity="0.2"
          d="M14.5 4C12.7761 4 11.1228 4.68482 9.90381 5.90381C8.68482 7.12279 8 8.77609 8 10.5C8 12.2239 8.68482 13.8772 9.90381 15.0962C11.1228 16.3152 12.7761 17 14.5 17C16.2239 17 17.8772 16.3152 19.0962 15.0962C20.3152 13.8772 21 12.2239 21 10.5C21 8.77609 20.3152 7.12279 19.0962 5.90381C17.8772 4.68482 16.2239 4 14.5 4Z"
          fill={primaryColor}
        />
      )}
    </>
  )
}

export { PersonChosen }
