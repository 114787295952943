import { useLocation, useParams } from 'react-router-dom'
import { formatTextAsSentence } from 'lib/utils/formatStringValues'
import { useScopeBaseURL } from 'lib/hooks'
import type { FunctionalIconVariant } from '_BRIGHT_/components/Icons'
import { useCompanyId } from 'lib/hooks'
import { useEffect } from 'react'
import { useOrganisationHelper } from 'redux/selectors'
import { useLazyCompanyNameQuery } from 'redux/query'

type BreadCrumbLinks = {
  to?: string
  href?: string
  text: string
  icon?: FunctionalIconVariant
}

export const useBreadCrumb = (): BreadCrumbLinks[] => {
  const { companyId } = useCompanyId()
  const { pathname } = useLocation()
  const { baseURL } = useScopeBaseURL()
  const [trigger, { data: name }] = useLazyCompanyNameQuery()
  const { scope } = useOrganisationHelper()
  const { id } = useParams()

  useEffect(() => {
    if (scope === 'user' && id) {
      trigger({ companyId })
    }
  }, [name, companyId, scope, trigger, id])

  if (pathname === '/analytics') {
    const homeCrumb = [
      {
        href: `${baseURL}/`,
        text: 'Home',
        icon: 'FunctionalArrowLeft' as FunctionalIconVariant,
      },
    ]

    return homeCrumb
  } else {
    const urlParts = pathname.replace(/^\/|\/$/g, '').split('/')

    const breadCrumbs = urlParts
      .slice(0, urlParts.length - 1)
      .map((urlPart, index) => {
        const to = urlParts.slice(0, index + 1).join('/')

        const text = isNaN(Number(urlPart))
          ? formatTextAsSentence(urlPart)
          : name ?? ''

        return {
          to: `/${to}`,
          text,
        }
      })

    return breadCrumbs
  }
}
