import * as R from 'ramda'
import { useForm as useFormOriginal } from 'react-hook-form'
import type { BaseSyntheticEvent, RefObject } from 'react'
import { useRef } from 'react'
import type { Path, UseFormProps, FieldValues, UseFormReturn } from 'react-hook-form'
import { withErrorHandler, GENERAL_FORM_ERROR } from './withErrorHandler'

type FormOnSubmit = { onSubmit: (event?: BaseSyntheticEvent) => void }
type FormRef = { ref: RefObject<HTMLFormElement> }
type BrightFormReturn<T> = UseFormReturn<T> & FormOnSubmit & FormRef

export function useForm<BrightFormFields extends FieldValues>(
  useFormArguments: UseFormProps<BrightFormFields>,
  mutationHandler: (values: BrightFormFields) => void
): BrightFormReturn<BrightFormFields> {
  const ref = useRef<HTMLFormElement>(null)

  const { handleSubmit, setError, clearErrors, getValues, ...rest } =
    useFormOriginal<BrightFormFields>(useFormArguments)

  const onSubmit = R.juxt([
    () => clearErrors(GENERAL_FORM_ERROR as Path<BrightFormFields>),
    handleSubmit(() => withErrorHandler(mutationHandler, setError)(getValues() as BrightFormFields)),
  ])
  return { ref, onSubmit, getValues, handleSubmit, setError, clearErrors, ...rest }
}
