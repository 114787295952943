export const myLog = (l: any) => console.log(JSON.stringify(l, null, 3))

export const isNumeric = (value: unknown): value is number =>
  !isNaN(parseFloat(value as string))

export const removeTrailingSlash = (pathname: string) => {
  if (pathname.endsWith('/')) {
    return pathname.slice(0, -1)
  } else {
    return pathname
  }
}
