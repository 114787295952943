import { GeniusConfig } from '_BRIGHT_/generated/generated-bright-components'

export const actionBar: GeniusConfig['actionBar'] = {
  actionDrawerAriaLabelBack: 'Back',
  actionDrawerAriaLabelClose: 'Close',
  actionDrawerMenuLabel: 'Options',
  // bulkButtonLabel: 'Edit selected',
  // bulkMessageLabel: 'Clients selected',
  // primaryActionButtonLabel: '',
  // primaryActionButtonLink: '',
  switchToListLabel: 'List',
  switchToTableLabel: 'Table',
}
