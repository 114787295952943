import type { FC } from 'react'
import React from 'react'
import type { HiFiIconPathProps } from '_BRIGHT_/components/Icons'

const Help: FC<HiFiIconPathProps> = ({
  primaryColor,
  secondaryColor,
  baseColor,
  strokeWidth,
  hasShadow,
}) => {
  return (
    <>
      {hasShadow && (
        <path
          opacity="0.2"
          d="M9.86486 4.4526C7.43243 5.5 5 7.73866 5 11.5C5 14.2145 6.15686 16.1748 7.7348 17.3642V23.6667C7.7348 24.0712 7.97844 24.4358 8.35212 24.5906C8.72579 24.7454 9.15591 24.6598 9.44191 24.3738L14.8296 18.9861C16.013 18.9556 17.1692 18.8582 18.1351 18.5474C20.5676 17.5 23 15.2613 23 11.5C23 7.56392 20.5676 5.21345 17.946 4.5C16.7509 4.17476 15.3517 4.02774 13.7568 4.00361C14.0822 4.0002 14.4076 4 14.7297 4H13.2703C13.4344 4 13.5966 4.00118 13.7568 4.00361C12.3731 4.0181 10.9899 4.09062 9.86486 4.4526Z"
          fill={primaryColor}
        />
      )}
      <path
        d="M30.0015 17.8666C30.0016 17.7442 30.0084 13.7886 30.0015 13.6667C29.9605 12.934 29.786 12.2126 29.5045 11.5329C29.1763 10.7402 28.695 10.0199 28.0883 9.4132C27.4816 8.8065 26.7613 8.32526 25.9686 7.99699C25.1759 7.66871 24.3262 7.49984 23.4682 7.5H19.7349C18.0021 7.5 16.3403 8.18833 15.1151 9.41357C13.9766 10.5521 13.2913 12.0676 13.2015 13.6667C13.1947 13.7885 13.2015 17.744 13.2015 17.8666C13.2015 19.5994 13.8899 21.2612 15.1151 22.4864C16.3403 23.7116 18.0021 24.4 19.7349 24.4H20.6682L26.2682 30V23.7479C27.3831 23.2212 28.3257 22.389 28.9866 21.3479C29.6474 20.3069 29.9994 19.0997 30.0015 17.8666Z"
        fill={baseColor}
        stroke={secondaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.46815 16.9334L5.73481 20.6667V14.4146C4.39441 13.7814 3.30983 12.7102 2.65992 11.3779C2.01002 10.0455 1.8336 8.53135 2.15976 7.08523C2.48592 5.6391 3.29518 4.34731 4.4541 3.42288C5.61301 2.49844 7.05237 1.99657 8.53481 2.00002H12.2681C13.6754 1.99992 15.0451 2.45404 16.1736 3.29484C16.7449 3.72052 17.2389 4.23381 17.6397 4.81187"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 14.504C18.9992 14.0094 19.1452 13.5256 19.4194 13.114C19.6936 12.7023 20.0838 12.3813 20.5405 12.1915C20.9973 12.0016 21.5001 11.9515 21.9853 12.0476C22.4705 12.1436 22.9163 12.3813 23.2664 12.7308C23.6164 13.0803 23.8549 13.5257 23.9517 14.0108C24.0485 14.4959 23.9992 14.9987 23.8101 15.4558C23.621 15.9128 23.3006 16.3035 22.8893 16.5784C22.4781 16.8533 21.9946 17 21.5 17"
        stroke={secondaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.4375 20C21.524 20 21.6086 20.0257 21.6806 20.0737C21.7525 20.1218 21.8086 20.1901 21.8417 20.2701C21.8748 20.35 21.8835 20.438 21.8666 20.5229C21.8497 20.6077 21.808 20.6857 21.7469 20.7469C21.6857 20.808 21.6077 20.8497 21.5229 20.8666C21.438 20.8835 21.35 20.8748 21.2701 20.8417C21.1901 20.8086 21.1218 20.7525 21.0737 20.6806C21.0257 20.6086 21 20.524 21 20.4375C21 20.3215 21.0461 20.2102 21.1281 20.1281C21.2102 20.0461 21.3215 20 21.4375 20Z"
        fill={baseColor}
      />
      <path
        d="M21.4375 20C21.524 20 21.6086 20.0257 21.6806 20.0737C21.7525 20.1218 21.8086 20.1901 21.8417 20.2701C21.8748 20.35 21.8835 20.438 21.8666 20.5229C21.8497 20.6077 21.808 20.6857 21.7469 20.7469C21.6857 20.808 21.6077 20.8497 21.5229 20.8666C21.438 20.8835 21.35 20.8748 21.2701 20.8417C21.1901 20.8086 21.1218 20.7525 21.0737 20.6806C21.0257 20.6086 21 20.524 21 20.4375C21 20.3215 21.0461 20.2102 21.1281 20.1281C21.2102 20.0461 21.3215 20 21.4375 20"
        stroke={secondaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  )
}

export { Help }
