import type { FC } from 'react'
import React from 'react'
import type { HiFiIconPathProps } from '_BRIGHT_/components/Icons'

const Calculator: FC<HiFiIconPathProps> = ({
  primaryColor,
  secondaryColor,
  baseColor,
  strokeWidth,
  hasShadow,
}) => {
  return (
    <>
      {hasShadow && (
        <path
          opacity="0.2"
          d="M5 7C5 5.89543 5.89543 5 7 5H23C24.1046 5 25 5.89543 25 7V18V29C25 30.1046 24.1046 31 23 31H7C5.89543 31 5 30.1046 5 29V7Z"
          fill={primaryColor}
        />
      )}
      <path
        d="M22 15V4C22 2.89543 21.1046 2 20 2H4C2.89543 2 2 2.89543 2 4V26C2 27.1046 2.89543 28 4 28H5.15789"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 6C5 5.44772 5.44772 5 6 5H18C18.5523 5 19 5.44772 19 6V9C19 9.55228 18.5523 10 18 10H6C5.44772 10 5 9.55228 5 9V6Z"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 15C6.59889 15 6.69556 15.0293 6.77779 15.0843C6.86001 15.1392 6.9241 15.2173 6.96194 15.3087C6.99978 15.4 7.00968 15.5006 6.99039 15.5975C6.9711 15.6945 6.92348 15.7836 6.85355 15.8536C6.78363 15.9235 6.69453 15.9711 6.59754 15.9904C6.50055 16.0097 6.40002 15.9998 6.30866 15.9619C6.2173 15.9241 6.13921 15.86 6.08427 15.7778C6.02932 15.6956 6 15.5989 6 15.5C6 15.3674 6.05268 15.2402 6.14645 15.1464C6.24021 15.0527 6.36739 15 6.5 15"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 20C6.59889 20 6.69556 20.0293 6.77779 20.0843C6.86001 20.1392 6.9241 20.2173 6.96194 20.3087C6.99978 20.4 7.00968 20.5006 6.99039 20.5975C6.9711 20.6945 6.92348 20.7836 6.85355 20.8536C6.78363 20.9235 6.69453 20.9711 6.59754 20.9904C6.50055 21.0097 6.40002 20.9998 6.30866 20.9619C6.2173 20.9241 6.13921 20.86 6.08427 20.7778C6.02932 20.6956 6 20.5989 6 20.5C6 20.3674 6.05268 20.2402 6.14645 20.1464C6.24021 20.0527 6.36739 20 6.5 20"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 15C12.0989 15 12.1956 15.0293 12.2778 15.0843C12.36 15.1392 12.4241 15.2173 12.4619 15.3087C12.4998 15.4 12.5097 15.5006 12.4904 15.5975C12.4711 15.6945 12.4235 15.7836 12.3536 15.8536C12.2836 15.9235 12.1945 15.9711 12.0975 15.9904C12.0006 16.0097 11.9 15.9998 11.8087 15.9619C11.7173 15.9241 11.6392 15.86 11.5843 15.7778C11.5293 15.6956 11.5 15.5989 11.5 15.5C11.5 15.3674 11.5527 15.2402 11.6464 15.1464C11.7402 15.0527 11.8674 15 12 15"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5 15C17.5989 15 17.6956 15.0293 17.7778 15.0843C17.86 15.1392 17.9241 15.2173 17.9619 15.3087C17.9998 15.4 18.0097 15.5006 17.9904 15.5975C17.9711 15.6945 17.9235 15.7836 17.8536 15.8536C17.7836 15.9235 17.6945 15.9711 17.5975 15.9904C17.5006 16.0097 17.4 15.9998 17.3087 15.9619C17.2173 15.9241 17.1392 15.86 17.0843 15.7778C17.0293 15.6956 17 15.5989 17 15.5C17 15.3674 17.0527 15.2402 17.1464 15.1464C17.2402 15.0527 17.3674 15 17.5 15"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="10"
        y="20"
        width="20"
        height="10"
        rx="3"
        fill={baseColor}
        stroke={secondaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 29L20 20"
        stroke={secondaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 25L13 25"
        stroke={secondaryColor}
        strokeWidth={+strokeWidth / 2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27 25L23 25"
        stroke={secondaryColor}
        strokeWidth={+strokeWidth / 2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25 27L25 23"
        stroke={secondaryColor}
        strokeWidth={+strokeWidth / 2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  )
}

export { Calculator }
