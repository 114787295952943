import {
  FullMetadata,
  Genius,
  GeniusFieldsConfig,
  useGeniusStateForQuery,
} from '_BRIGHT_/components'
import { summaryPropsMunger, SummaryRenderer } from './renderers'
import { useEmployeesCountQuery, useEmployeesQuery } from 'redux/query'
import { useCompanyId } from 'lib/hooks'
import { EmployeeFilter, EmployeeSort } from 'redux/query/employees'
import { geniusConfig } from './config'
import { MetadataItemRenderer } from 'components/GeniusRenderers'
import { GeniusMetadataItemWithId } from 'lib/cubeJs'

export const MaintenanceGenius = () => {
  const {
    variables: { offset, sort, limit, filter },
    onGeniusStateChange,
    skip,
  } = useGeniusStateForQuery({
    limit: 25,
  })

  const { companyId } = useCompanyId()

  const {
    isLoading: isLoadingCount,
    isFetching: isFetchingCount,
    data: totalEmployeesCount,
  } = useEmployeesCountQuery({
    filter,
    companyId,
  })

  const {
    isLoading,
    data: metaDataList,
    isFetching,
    isError,
  } = useEmployeesQuery(
    {
      config: {
        offset,
        limit,
        filter: filter as EmployeeFilter,
        sort: sort as EmployeeSort,
      },
      companyId: companyId,
    },
    {
      skip: skip || isLoadingCount || isFetchingCount,
    },
  )

  return (
    <Genius
      id="employees"
      summaryPropsMunger={summaryPropsMunger}
      SummaryRenderer={SummaryRenderer}
      MetadataItemRenderer={MetadataItemRenderer}
      metadataListMunger={metadataListMunger}
      isLoadingData={skip || isFetching || isLoading}
      dataset={metaDataList}
      datasetError={isError}
      onStateChange={onGeniusStateChange}
      limit={limit}
      totalResults={totalEmployeesCount}
      isLoadingGeniusConfig={isLoadingCount || isFetchingCount}
      geniusConfigError={null}
      geniusConfigFromQuery={geniusConfig}
    />
  )
}

/**
 * metadataListMunger allows us to tag an invalid value
 * before it reaches the MetadataItemRenderer
 * this needs to happen upstream since some values are
 * marked and not visible in the fields config
 *
 * in the cube value map
 * if hasValidHomeAddress is 0 it will resolve as 'invalid--'
 * else will resolve as ''
 */
const metadataListMunger: (
  metaData: FullMetadata<GeniusMetadataItemWithId>,
  fields: GeniusFieldsConfig,
) => FullMetadata<GeniusMetadataItemWithId> = m => {
  return {
    ...m,
    homeAddress: `${m.hasValidHomeAddress}${m.homeAddress}`,
  }
}
