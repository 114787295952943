import { FC } from 'react'
import { FallbackProps } from 'react-error-boundary'
import { Box } from '@chakra-ui/react'
import { CenteredBlock } from 'components/CenteredBlock'
import { BaseButton } from '_BRIGHT_/components/Buttons/BaseButton'
import { Stack } from '_BRIGHT_'

export const ErrorFallback: FC<FallbackProps> = ({
  error,
  resetErrorBoundary,
}) => (
  <CenteredBlock>
    <Stack space="4xl">
      <Box margin="10">
        <p>Something went wrong.</p>
        {process.env.NODE_ENV === 'development' && (
          <>
            <Box margin="10">
              <pre>{JSON.stringify(error.message, null, 3)}</pre>
            </Box>
            <BaseButton variant="primaryButton" onClick={resetErrorBoundary}>
              Try again
            </BaseButton>
          </>
        )}
      </Box>
    </Stack>
  </CenteredBlock>
)
