import { Adjacent, Text, Stack, useColor } from '_BRIGHT_/components'
import { Box, useRadio } from '@chakra-ui/react'
import type { UseRadioProps as ChakraUseRadioProps } from '@chakra-ui/react'
import { useShadow } from '_BRIGHT_/utils'
import type { FC } from 'react'
import React from 'react'

export type UseRadioProps = Omit<
  ChakraUseRadioProps,
  'isDisabled' | 'isReadOnly'
> & {
  label: string | number
  description?: string | number
  value: string | number
}

const Radio: FC<UseRadioProps> = ({ label, description, ...useRadioProps }) => {
  const borderColor = useColor('borderPrimary')
  const checkedFillColor = useColor('contentPrimary', 'highlight')
  const checkedBorderColor = useColor('core', 'highlight')
  const boxShadow = useShadow('focus')
  const activeColor = useColor('active', 'highlight')

  const { getInputProps, getCheckboxProps } = useRadio(useRadioProps)

  const input = getInputProps()
  const checkbox = getCheckboxProps()

  return (
    <Box as="label">
      <input {...input} />

      <Adjacent space="sm">
        <Box
          {...checkbox}
          width="20px"
          height="20px"
          border="2px solid"
          borderColor={borderColor}
          borderRadius="50%"
          marginTop="2px"
          _checked={{
            bg: checkedFillColor,
            color: checkedBorderColor,
            border: '5px',
          }}
          _focus={{ color: activeColor, boxShadow }}
        />
        <Stack>
          <Text>{label}</Text>
          {description && (
            <Text variant="tertiary" level="sm">
              <Box as="span" aria-hidden>
                {description}
              </Box>
            </Text>
          )}
        </Stack>
      </Adjacent>
    </Box>
  )
}

export { Radio }
