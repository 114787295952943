import React, { createContext } from 'react'
import type { FC } from 'react'
import type { UseGeniusStateReturnedValue } from '_BRIGHT_/components/Genius/hooks'

export type GeniusStateContextValue = UseGeniusStateReturnedValue

export const GeniusStateContext = createContext<GeniusStateContextValue | null>(
  null,
)

export const GeniusStateContextProvider: FC<{
  value: GeniusStateContextValue
}> = ({ value, children }) => (
  <GeniusStateContext.Provider value={value}>
    {children}
  </GeniusStateContext.Provider>
)
