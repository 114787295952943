import type { FC } from 'react'
import React from 'react'
import { Text, Button, Adjacent } from '_BRIGHT_/components'

export type BulkEditBarProps = {
  onButtonClick: (e: React.MouseEvent<HTMLButtonElement>) => void
  buttonLabel: string
  amount: number
  messageLabel: string
}

export const BulkEditBar: FC<BulkEditBarProps> = ({
  onButtonClick,
  amount,
  messageLabel,
  buttonLabel,
}) => {
  return (
    <Adjacent alignY="center" space="sm">
      <Text>
        {messageLabel}: <Text as="strong">{amount}</Text>
      </Text>
      <Button size="sm" onClick={onButtonClick} isFullWidthForMobile={false}>
        {buttonLabel}
      </Button>
    </Adjacent>
  )
}
