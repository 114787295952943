import { GeniusConfig } from '_BRIGHT_/generated/generated-bright-components'

export const fields: GeniusConfig['fields'] = [
  {
    fieldName: 'companyId',
    type: 'text',
    isBulkEditable: false,
    isFilterable: true,
    isSortable: true,
    isShownInMetadataList: false,
    label: 'ID',
    settings: {
      position: 1,
      showInSummary: false,
    },
  },
  {
    fieldName: 'companyName',
    type: 'text',
    isBulkEditable: false,
    isFilterable: true,
    isSortable: true,
    isShownInMetadataList: false,
    label: 'Name',
    settings: {
      position: 2,
      showInSummary: false,
    },
  },
  {
    fieldName: 'companyState',
    type: 'option',
    isBulkEditable: false,
    isFilterable: true,
    isSortable: true,
    isShownInMetadataList: true,
    label: 'Status',
    settings: {
      position: 3,
      showInSummary: false,
    },
    optionInputConfig: {
      options: [
        {
          label: 'Active',
          id: 'active',
        },
        {
          label: 'Ceased Trading',
          id: 'ceased_trading',
        },
        {
          label: 'Changed Provider',
          id: 'changed_provider',
        },
        {
          label: 'Closed',
          id: 'closed',
        },
        {
          label: 'Closed with Errors',
          id: 'closed_with_errors',
        },
        {
          label: 'Dissolved',
          id: 'dissolved',
        },
        {
          label: 'Duplicate or Opened in Error',
          id: 'duplicate_or_opened_in_error',
        },
        {
          label: 'In Build',
          id: 'in_build',
        },
        {
          label: 'Liquidated',
          id: 'liquidated',
        },
        {
          label: 'No further Duties',
          id: 'no_further_duties',
        },
        {
          label: 'Pending Dissolution',
          id: 'pending_dissolution',
        },
        {
          label: 'Pending Liquidation',
          id: 'pending_liquidation',
        },
        {
          label: 'Tuped out',
          id: 'tuped_out',
        },
      ],
    },
  },
  {
    fieldName: 'totalAUM',
    type: 'text',
    isBulkEditable: false,
    isFilterable: false,
    isSortable: true,
    isShownInMetadataList: true,
    label: 'AUM',
    settings: {
      position: 4,
      showInSummary: false,
    },
  },
  {
    fieldName: 'dataQualityScoreRatio',
    type: 'text',
    isBulkEditable: false,
    isFilterable: false,
    isSortable: true,
    isShownInMetadataList: true,
    label: 'Data quality',
    settings: {
      position: 5,
      showInSummary: false,
    },
  },
  {
    fieldName: 'directDebitState',
    type: 'text',
    isBulkEditable: false,
    isFilterable: false,
    isSortable: true,
    isShownInMetadataList: true,
    label: 'Direct Debit',
    settings: {
      position: 6,
      showInSummary: false,
    },
  },
  {
    fieldName: 'totalMembersWithMissingContributions',
    type: 'text',
    isBulkEditable: false,
    isFilterable: false,
    isSortable: true,
    isShownInMetadataList: true,
    label: 'Late contributions',
    settings: {
      position: 7,
      showInSummary: false,
    },
  },
  {
    fieldName: 'totalEmployees',
    type: 'text',
    isBulkEditable: false,
    isFilterable: false,
    isSortable: true,
    isShownInMetadataList: true,
    label: 'Total employees',
    settings: {
      position: 8,
      showInSummary: false,
    },
  },
  {
    fieldName: 'takeUpRatio',
    type: 'text',
    isBulkEditable: false,
    isFilterable: false,
    isSortable: true,
    isShownInMetadataList: true,
    label: 'Take-up rate',
    settings: {
      position: 9,
      showInSummary: false,
    },
  },
  {
    fieldName: 'totalActiveEmployees',
    type: 'text',
    isBulkEditable: false,
    isFilterable: false,
    isSortable: true,
    isShownInMetadataList: true,
    label: 'Active members',
    settings: {
      position: 10,
      showInSummary: false,
    },
  },
  {
    fieldName: 'totalDeferredEmployees',
    type: 'text',
    isBulkEditable: false,
    isFilterable: false,
    isSortable: true,
    isShownInMetadataList: true,
    label: 'Deferred members',
    settings: {
      position: 11,
      showInSummary: false,
    },
  },
  {
    fieldName: 'totalInvalidNationalInsuranceNumber',
    type: 'text',
    isBulkEditable: false,
    isFilterable: true,
    isSortable: true,
    isShownInMetadataList: true,
    label: 'Missing NI numbers',
    settings: {
      position: 12,
      showInSummary: false,
    },
  },
  {
    fieldName: 'totalInvalidAddresses',
    type: 'text',
    isBulkEditable: false,
    isFilterable: true,
    isSortable: true,
    isShownInMetadataList: true,
    label: 'Invalid home addresses',
    settings: {
      position: 13,
      showInSummary: false,
    },
  },
  {
    fieldName: 'totalInvalidEmail',
    type: 'text',
    isBulkEditable: false,
    isFilterable: false,
    isSortable: true,
    isShownInMetadataList: true,
    label: 'Missing email addresses',
    settings: {
      position: 14,
      showInSummary: false,
    },
  },
  {
    fieldName: 'lastContributionCreationDate',
    type: 'text',
    isBulkEditable: false,
    isFilterable: false,
    isSortable: true,
    isShownInMetadataList: true,
    label: 'Last payroll upload',
    settings: {
      position: 15,
      showInSummary: false,
    },
  },
  {
    fieldName: 'schemeStartDate',
    type: 'date',
    isBulkEditable: false,
    isFilterable: true,
    isSortable: true,
    isShownInMetadataList: true,
    label: 'Scheme start date',
    settings: {
      position: 16,
      showInSummary: false,
    },
    dateInputConfig: {
      operatorOptions: [
        {
          label: 'Equal to',
          id: 'equal',
        },
        {
          label: 'Before',
          id: 'before',
        },
        {
          label: 'After',
          id: 'after',
        },
      ],
      dateLabels: {
        selectInputDescription: 'Operator',
        dayInputLabel: 'Day',
        monthInputLabel: 'Month',
        yearInputLabel: 'Year',
      },
    },
  },
  {
    fieldName: 'dutyStartDate',
    type: 'date',
    isBulkEditable: false,
    isFilterable: true,
    isSortable: true,
    isShownInMetadataList: true,
    label: 'Duties start date',
    settings: {
      position: 17,
      showInSummary: false,
    },
    dateInputConfig: {
      operatorOptions: [
        {
          label: 'Equal to',
          id: 'equal',
        },
        {
          label: 'Before',
          id: 'before',
        },
        {
          label: 'After',
          id: 'after',
        },
      ],
      dateLabels: {
        selectInputDescription: 'Operator',
        dayInputLabel: 'Day',
        monthInputLabel: 'Month',
        yearInputLabel: 'Year',
      },
    },
  },
  {
    fieldName: 'nextReEnrolmentDate',
    type: 'date',
    isBulkEditable: false,
    isFilterable: true,
    isSortable: true,
    isShownInMetadataList: true,
    label: 'Re-enrolment date',
    settings: {
      position: 18,
      showInSummary: false,
    },
    dateInputConfig: {
      operatorOptions: [
        {
          label: 'Equal to',
          id: 'equal',
        },
        {
          label: 'Before',
          id: 'before',
        },
        {
          label: 'After',
          id: 'after',
        },
      ],
      dateLabels: {
        selectInputDescription: 'Operator',
        dayInputLabel: 'Day',
        monthInputLabel: 'Month',
        yearInputLabel: 'Year',
      },
    },
  },
  {
    fieldName: 'averageActiveMemberPercentage',
    type: 'text',
    isBulkEditable: false,
    isFilterable: false,
    isSortable: true,
    isShownInMetadataList: true,
    label: 'Average employee contribution',
    settings: {
      position: 19,
      showInSummary: false,
    },
  },
  {
    fieldName: 'totalNewMembersThisMonth',
    type: 'text',
    isBulkEditable: false,
    isFilterable: false,
    isSortable: false,
    isShownInMetadataList: true,
    label: 'New members this month',
    settings: {
      position: 20,
      showInSummary: false,
    },
  },
  {
    fieldName: 'totalLeaversThisMonth',
    type: 'text',
    isBulkEditable: false,
    isFilterable: false,
    isSortable: false,
    isShownInMetadataList: true,
    label: 'Leavers this month',
    settings: {
      position: 21,
      showInSummary: false,
    },
  },
  {
    fieldName: 'totalClaims',
    type: 'text',
    isBulkEditable: false,
    isFilterable: false,
    isSortable: false,
    isShownInMetadataList: true,
    label: 'Claims',
    settings: {
      position: 22,
      showInSummary: false,
    },
  },
  {
    fieldName: 'hasMissingPaymentMethod',
    type: 'text',
    isBulkEditable: false,
    isFilterable: false,
    isSortable: false,
    isShownInMetadataList: false,
    label: 'Has Missing Payment Method',
    settings: {
      position: 23,
      showInSummary: false,
    },
  },
]
