import { LoadMethodOptions, Query } from '@cubejs-client/core'
import { BaseQueryFn } from '@reduxjs/toolkit/query/react'
import { cubejsApi } from 'lib/cubeJs'

export class CustomCubeError {
  constructor(public status: number, public message: string) {}
}

export const cubeBaseQuery =
  (
    _config: { baseUrl: string } = { baseUrl: '' },
  ): BaseQueryFn<
    {
      cubeQuery: Query | Query[]
      options?: LoadMethodOptions | undefined
    },
    unknown,
    unknown
  > =>
  async ({ cubeQuery, options }) => {
    try {
      const result = await cubejsApi.load(cubeQuery, options)
      return { data: result }
    } catch (cubeJsError) {
      return customErrorHandler(cubeJsError as Error)
    }
  }

export const customErrorHandler = (
  error: Error,
): {
  error: CustomCubeError | Error
} => {
  const cubeError = JSON.parse(error.message)

  // verify and build cube custom error
  if (
    !!cubeError &&
    cubeError.hasOwnProperty('statusCode') &&
    cubeError.hasOwnProperty('error')
  ) {
    return {
      error: new CustomCubeError(cubeError.statusCode, cubeError.error),
    }
  }

  console.error('CubeJS Error incorrectly formatted:', { error })
  return { error }
}
