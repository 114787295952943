import type { FC } from 'react'
import React from 'react'
import type { HiFiIconPathProps } from '_BRIGHT_/components/Icons'

const Proportions: FC<HiFiIconPathProps> = ({
  primaryColor,
  secondaryColor,
  baseColor,
  strokeWidth,
  hasShadow,
}) => {
  return (
    <>
      {hasShadow && (
        <path
          opacity="0.2"
          d="M19.8134 18.7728C19.6736 18.5961 19.5975 18.3774 19.5975 18.1521V6.00009C19.5975 5.44777 19.1492 4.99624 18.5983 5.03658C16.7765 5.16998 14.9969 5.66672 13.3672 6.50084C11.4406 7.48691 9.77932 8.91591 8.52191 10.6687C7.2645 12.4215 6.44737 14.4473 6.13864 16.5772C5.82991 18.7071 6.03853 20.8794 6.7471 22.9131C7.45568 24.9467 8.64367 26.7827 10.2121 28.2681C11.7804 29.7534 13.6838 30.845 15.7633 31.4519C17.8429 32.0588 20.0385 32.1634 22.1671 31.757C23.9645 31.4138 25.6701 30.7147 27.1836 29.705C27.6454 29.3969 27.7194 28.761 27.3749 28.3258L19.8134 18.7728Z"
          fill={primaryColor}
        />
      )}
      <path
        d="M18 2V12H28C28 9.34783 26.9464 6.8043 25.0711 4.92893C23.1957 3.05357 20.6522 2 18 2Z"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.3624 15.5V3C12.3918 3.00017 10.4499 3.47663 8.69842 4.38967C6.94695 5.3027 5.43672 6.62584 4.29362 8.24879C3.15052 9.87175 2.40768 11.7475 2.12701 13.7196C1.84635 15.6918 2.036 17.7032 2.68016 19.5862C3.32432 21.4692 4.40431 23.1692 5.83011 24.5445C7.25592 25.9198 8.98621 26.9306 10.8767 27.4925C12.7673 28.0545 14.7633 28.1513 16.6983 27.775C18.6334 27.3987 20.4515 26.5601 22.001 25.3292L14.3624 15.5Z"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29 15H19L25.1789 23C26.3705 22.0487 27.3338 20.8341 27.9958 19.4481C28.6579 18.0621 29.0012 16.541 29 15Z"
        fill={baseColor}
        stroke={secondaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  )
}

export { Proportions }
