import React, { useRef, useEffect, useCallback } from 'react'
import type { FC, LegacyRef } from 'react'
import gsap from 'gsap'
import { useColor } from '_BRIGHT_/components'
import { blendEases } from './utils/blendEases'
import type { Icon } from './types'

export const Warning: FC<Icon> = ({ size, autoPlay }) => {
  const color = useColor('core', 'warningPrimary')
  const strokeWidth = 16
  const warningSVG = useRef()
  const exclamationLine = useRef()
  const exclamationDot = useRef()
  const whole = useRef()
  const bg = useRef()

  const playAnimation = useCallback(() => {
    const drawEase = blendEases('sine.in', 'expo')
    const mainTl = gsap.timeline({ defaults: { duration: autoPlay ? 1 : 0 } })

    mainTl
      .fromTo(
        bg.current as unknown as gsap.TweenTarget,
        { drawSVG: '-50% -50%' },
        { drawSVG: '0% 100%', ease: drawEase },
      )
      .from(
        bg.current as unknown as gsap.TweenTarget,
        { fillOpacity: 0, ease: 'expo.in' },
        0,
      )
      .add('drop', '-=0.28')
      .from(
        exclamationLine.current as unknown as gsap.TweenTarget,
        { opacity: 0, duration: 0.01 },
        'drop',
      )
      .from(
        exclamationLine.current as unknown as gsap.TweenTarget,
        { y: -85, duration: 0.6, ease: 'elastic(2.7, 0.96)' },
        'drop',
      )
      .from(
        exclamationLine.current as unknown as gsap.TweenTarget,
        { drawSVG: '0% 0%', duration: 0.26, ease: 'sine' },
        'drop',
      )
      .from(
        exclamationDot.current as unknown as gsap.TweenTarget,
        { scale: 0, transformOrigin: '50% 50%', duration: 0.26, ease: 'sine' },
        '-=0.5',
      )
  }, [autoPlay])

  useEffect(() => {
    gsap.set(warningSVG.current as unknown as gsap.TweenTarget, {
      visibility: 'visible',
    })
    playAnimation()
  }, [playAnimation])

  return (
    <svg
      viewBox="0 0 300 300"
      ref={warningSVG as unknown as LegacyRef<SVGSVGElement>}
      onClick={playAnimation}
      width={size}
      height={size}
    >
      <g ref={whole as unknown as LegacyRef<SVGSVGElement>}>
        <path
          ref={bg as unknown as LegacyRef<SVGPathElement>}
          d="M139,40,23.52,240.12a12.66,12.66,0,0,0,11,19h231a12.66,12.66,0,0,0,11-19L161,40A12.67,12.67,0,0,0,139,40Z"
          fill={color}
          fillOpacity={0.1}
          stroke={color}
          strokeOpacity={0.4}
          strokeMiterlimit={10}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle
          ref={exclamationDot as unknown as LegacyRef<SVGCircleElement>}
          cx={150}
          cy={210.43}
          r={1}
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={strokeWidth}
        />
        <line
          ref={exclamationLine as unknown as LegacyRef<SVGLineElement>}
          x1={150}
          y1={129.23}
          x2={150}
          y2={176.66}
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={strokeWidth}
        />
      </g>
    </svg>
  )
}
