import type { FC } from 'react'
import React from 'react'
import type { HiFiIconPathProps } from '_BRIGHT_/components/Icons'

const Start: FC<HiFiIconPathProps> = ({
  primaryColor,
  secondaryColor,
  baseColor,
  strokeWidth,
  hasShadow,
}) => {
  return (
    <>
      {hasShadow && (
        <path
          opacity="0.2"
          d="M4 18C4 21.713 5.475 25.274 8.10051 27.8995C10.726 30.525 14.287 32 18 32C21.713 32 25.274 30.525 27.8995 27.8995C30.525 25.274 32 21.713 32 18C32 14.287 30.525 10.726 27.8995 8.10051C25.274 5.475 21.713 4 18 4C14.287 4 10.726 5.475 8.10051 8.10051C5.475 10.726 4 14.287 4 18Z"
          fill={primaryColor}
        />
      )}
      <path
        d="M4.80761 23.1924C2.36964 20.7544 1 17.4478 1 14C1 10.5522 2.36964 7.24558 4.80761 4.80761C7.24558 2.36964 10.5522 1 14 1C17.4478 1 20.7544 2.36964 23.1924 4.80761C25.6304 7.24558 27 10.5522 27 14C27 17.4478 25.6304 20.7544 23.1924 23.1924C20.7544 25.6304 17.4478 27 14 27C10.5522 27 7.24558 25.6304 4.80761 23.1924Z"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.092 14L10 20.2104L10 7.78956L20.092 14Z"
        fill={baseColor}
        stroke={secondaryColor}
        strokeWidth={strokeWidth}
      />
    </>
  )
}

export { Start }
