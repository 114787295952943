import type { FC } from 'react'
import React from 'react'
import type { HiFiIconPathProps } from '_BRIGHT_/components/Icons'

const Calendar: FC<HiFiIconPathProps> = ({
  primaryColor,
  secondaryColor,
  baseColor,
  strokeWidth,
  hasShadow,
}) => {
  return (
    <>
      {hasShadow && (
        <path
          opacity="0.2"
          d="M6 10C6 8.89543 6.89543 8 8 8H30C31.1046 8 32 8.89543 32 10V30C32 31.1046 31.1046 32 30 32H8C6.89543 32 6 31.1046 6 30V10Z"
          fill={primaryColor}
        />
      )}
      <path
        d="M2 6C2 5.44772 2.44772 5 3 5H25C25.5523 5 26 5.44772 26 6V26C26 26.5523 25.5523 27 25 27H3C2.44771 27 2 26.5523 2 26V6Z"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 10L26 10"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 7V2"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 7V2"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5155 13.313C13.561 13.2189 13.6303 13.1398 13.7158 13.0845C13.8013 13.0292 13.8997 13 14 13C14.1003 13 14.1987 13.0292 14.2842 13.0845C14.3697 13.1398 14.439 13.2189 14.4845 13.313L15.609 15.7019C15.648 15.7837 15.7053 15.8543 15.776 15.9077C15.8466 15.961 15.9285 15.9956 16.0146 16.0084L18.5403 16.3932C18.6388 16.4079 18.7315 16.4511 18.8081 16.5181C18.8846 16.585 18.9419 16.673 18.9737 16.7723C19.0046 16.8715 19.0084 16.9778 18.9846 17.0792C18.9608 17.1805 18.9104 17.2728 18.8392 17.3455L17.0058 19.2172C16.9442 19.2801 16.8981 19.3578 16.8714 19.4436C16.8446 19.5294 16.8381 19.6207 16.8524 19.7097L17.2847 22.3477C17.3015 22.4509 17.2902 22.5571 17.2521 22.6538C17.2139 22.7505 17.1505 22.8338 17.0691 22.8941C16.9872 22.9552 16.8909 22.9914 16.7907 22.9987C16.6905 23.0059 16.5904 22.9839 16.5013 22.935L14.2522 21.6961C14.1743 21.6535 14.0878 21.6312 14 21.6312C13.9122 21.6312 13.8257 21.6535 13.7478 21.6961L11.4987 22.935C11.4096 22.9839 11.3095 23.0059 11.2093 22.9987C11.1091 22.9914 11.0128 22.9552 10.9309 22.8941C10.8495 22.8338 10.7861 22.7505 10.7479 22.6538C10.7098 22.5571 10.6985 22.4509 10.7153 22.3477L11.1476 19.7097C11.1619 19.6207 11.1554 19.5294 11.1286 19.4436C11.1019 19.3578 11.0558 19.2801 10.9942 19.2172L9.16076 17.3455C9.08956 17.2728 9.0392 17.1805 9.01542 17.0792C8.99165 16.9778 8.99542 16.8715 9.0263 16.7723C9.05796 16.6729 9.11528 16.5849 9.19182 16.5179C9.26837 16.451 9.36112 16.4078 9.45967 16.3932L11.9843 16.0084C12.0706 15.9958 12.1527 15.9613 12.2235 15.9079C12.2944 15.8545 12.3519 15.7838 12.391 15.7019L13.5155 13.313Z"
        fill={baseColor}
        stroke={secondaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  )
}

export { Calendar }
