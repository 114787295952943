import { useColor } from '_BRIGHT_/components'
import type { Level } from '_BRIGHT_/components/Typography/common'
import { getResponsiveTypography } from '_BRIGHT_/components/Typography/common'
import { Heading as ChakraHeading } from '@chakra-ui/react'
import type { FC } from 'react'
import React from 'react'
import type { ColorName } from '_BRIGHT_/components/PaletteProvider'
import type { HeadingProps as ChakraHeadingProps } from '@chakra-ui/react'

export type HeadingElement = 'h1' | 'h2' | 'h3' | 'h4' | 'h5'
export type HeadingColor = Extract<
  ColorName,
  'contentPrimary' | 'contentSecondary'
>

export type HeadingProps = Pick<ChakraHeadingProps, 'textAlign'> & {
  as?: HeadingElement
  color?: HeadingColor
}

const headingMap: Record<HeadingElement, Level> = {
  h1: '3xl',
  h2: '2xl',
  h3: 'xl',
  h4: 'lg',
  h5: 'md',
}

const Heading: FC<HeadingProps> = ({
  children,
  as = 'h1',
  color = 'contentPrimary',
  textAlign,
}) => {
  const customColor = useColor(color)
  const { fontFamily, fontSize, fontWeight, lineHeight } =
    getResponsiveTypography(as, headingMap)

  return (
    <ChakraHeading
      as={as}
      color={customColor}
      fontFamily={fontFamily}
      fontSize={fontSize}
      fontWeight={fontWeight}
      letterSpacing="normal"
      lineHeight={lineHeight}
      textAlign={textAlign}
      id={as === 'h1' ? 'content-start' : undefined}
    >
      {children}
    </ChakraHeading>
  )
}

export { Heading }
