import type { FC } from 'react'
import React from 'react'
import { Icon } from '@chakra-ui/react'
import type { FunctionalIconSvgProps } from '_BRIGHT_/components/Icons/FunctionalIcon/FunctionalIcon'

export const FunctionalWarningContrast: FC<FunctionalIconSvgProps> = ({
  color,
  size,
  height,
  strokeWidth,
  contrastColor,
  ariaHidden,
}) => (
  <Icon
    viewBox="0 0 24 24"
    fill="none"
    color={color}
    width={size}
    height={height || size}
    aria-hidden={ariaHidden}
  >
    <path
      d="M20.8189 18.0075L13.4054 3.84999C13.2711 3.5936 13.0691 3.37886 12.8215 3.22903C12.5738 3.0792 12.2899 3 12.0004 3C11.7109 3 11.427 3.0792 11.1793 3.22903C10.9317 3.37886 10.7297 3.5936 10.5954 3.84999L3.1811 18.0075C3.05445 18.2493 2.99233 18.5197 3.00076 18.7925C3.00918 19.0653 3.08785 19.3313 3.22917 19.5648C3.37049 19.7984 3.56968 19.9915 3.80746 20.1255C4.04525 20.2595 4.31359 20.3299 4.58654 20.3298H19.4135C19.6864 20.3299 19.9548 20.2595 20.1925 20.1255C20.4303 19.9915 20.6295 19.7984 20.7708 19.5648C20.9121 19.3313 20.9908 19.0653 20.9992 18.7925C21.0077 18.5197 20.9456 18.2493 20.8189 18.0075Z"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M20.8189 18.0075L13.4054 3.84999C13.2711 3.5936 13.0691 3.37886 12.8215 3.22903C12.5738 3.0792 12.2899 3 12.0004 3C11.7109 3 11.427 3.0792 11.1793 3.22903C10.9317 3.37886 10.7297 3.5936 10.5954 3.84999L3.1811 18.0075C3.05445 18.2493 2.99233 18.5197 3.00076 18.7925C3.00918 19.0653 3.08785 19.3313 3.22917 19.5648C3.37049 19.7984 3.56968 19.9915 3.80746 20.1255C4.04525 20.2595 4.31359 20.3299 4.58654 20.3298H19.4135C19.6864 20.3299 19.9548 20.2595 20.1925 20.1255C20.4303 19.9915 20.6295 19.7984 20.7708 19.5648C20.9121 19.3313 20.9908 19.0653 20.9992 18.7925C21.0077 18.5197 20.9456 18.2493 20.8189 18.0075ZM12.0194 18.616H12.0441C12.2173 18.6132 12.3882 18.5758 12.5468 18.506C12.7053 18.4361 12.8483 18.3354 12.9674 18.2095C13.0864 18.0836 13.1791 17.9352 13.24 17.773C13.3009 17.6108 13.3287 17.4381 13.3219 17.265C13.3082 16.9178 13.1608 16.5893 12.9105 16.3484C12.6602 16.1074 12.3263 15.9726 11.9788 15.9722H11.9542C11.7814 15.9753 11.6109 16.0129 11.4528 16.0827C11.2947 16.1525 11.152 16.2531 11.0332 16.3786C10.9144 16.5041 10.8218 16.6521 10.7609 16.8138C10.6999 16.9755 10.6718 17.1478 10.6781 17.3205C10.6913 17.6678 10.8382 17.9965 11.0882 18.238C11.3382 18.4794 11.6719 18.6148 12.0194 18.616ZM11.3769 7.84946C11.2116 8.01472 11.1187 8.23888 11.1187 8.4726V13.7602C11.1187 13.9939 11.2116 14.2181 11.3769 14.3833C11.5421 14.5486 11.7663 14.6415 12 14.6415C12.2337 14.6415 12.4579 14.5486 12.6231 14.3833C12.7884 14.2181 12.8813 13.9939 12.8813 13.7602V8.4726C12.8813 8.23888 12.7884 8.01472 12.6231 7.84946C12.4579 7.68419 12.2337 7.59134 12 7.59134C11.7663 7.59134 11.5421 7.68419 11.3769 7.84946Z"
      fill="currentColor"
    />
    <path
      d="M11.1187 8.4726C11.1187 8.23888 11.2116 8.01472 11.3769 7.84946C11.5421 7.68419 11.7663 7.59134 12 7.59134C12.2337 7.59134 12.4579 7.68419 12.6231 7.84946C12.7884 8.01472 12.8813 8.23888 12.8813 8.4726V13.7602C12.8813 13.9939 12.7884 14.2181 12.6231 14.3833C12.4579 14.5486 12.2337 14.6415 12 14.6415C11.7663 14.6415 11.5421 14.5486 11.3769 14.3833C11.2116 14.2181 11.1187 13.9939 11.1187 13.7602V8.4726Z"
      fill={contrastColor}
    />
    <path
      d="M12.0441 18.616H12.0194C11.6719 18.6148 11.3382 18.4794 11.0882 18.238C10.8382 17.9965 10.6913 17.6678 10.6781 17.3205C10.6717 17.1478 10.6999 16.9755 10.7609 16.8138C10.8218 16.6521 10.9144 16.5041 11.0332 16.3786C11.152 16.2531 11.2947 16.1525 11.4528 16.0827C11.6109 16.0129 11.7814 15.9753 11.9542 15.9722H11.9788C12.3263 15.9726 12.6602 16.1074 12.9105 16.3484C13.1608 16.5893 13.3082 16.9178 13.3219 17.265C13.3287 17.4381 13.3009 17.6108 13.24 17.773C13.1791 17.9352 13.0864 18.0836 12.9674 18.2095C12.8483 18.3354 12.7053 18.4361 12.5468 18.506C12.3882 18.5758 12.2173 18.6132 12.0441 18.616Z"
      fill={contrastColor}
    />
  </Icon>
)
