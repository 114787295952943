import { FC } from 'react'
import {
  CubeTablePivot,
  formatTablePivotToCubeValues,
  AppValue,
} from 'lib/cubeJs'
import { Query } from '@cubejs-client/core'
import { ContrastCard } from '_BRIGHT_'
import { useAnyCubeQueryQuery } from 'redux/query'
import { OverviewContent } from './OverviewContent'

export type OverviewProps = {
  data: AppValue<string | number>[]
  skeletonText?: boolean
}

export const Overview: FC<{
  query: Query
}> = ({ query }) => {
  const { data: resultSet, error, isLoading } = useAnyCubeQueryQuery(query)

  if (error) {
    throw error
  }

  if (isLoading) {
    const data = Array.from({
      length: query?.measures?.length || query?.dimensions?.length || 6,
    }).map(_ => ({
      title: '',
      value: '',
    }))

    return <OverviewCard data={data} skeletonText />
  }

  if (resultSet) {
    const tables = resultSet.tablePivot() as CubeTablePivot[]
    const data = formatTablePivotToCubeValues(tables[0])

    return <OverviewCard data={data} />
  }

  return null
}

export const OverviewCard: FC<OverviewProps> = props => (
  <ContrastCard>
    <OverviewContent {...props} />
  </ContrastCard>
)
