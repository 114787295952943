import { useColor } from '_BRIGHT_/components'
import type { Level, Variant } from '_BRIGHT_/components/Typography/common'
import {
  colorMap,
  getResponsiveTypography,
} from '_BRIGHT_/components/Typography/common'
import { Text as ChakraText } from '@chakra-ui/react'
import type { TextProps as ChakraTextProps } from '@chakra-ui/react'
import type { FC } from 'react'
import React from 'react'

export type TextLevel = Extract<Level, '2xs' | 'xs' | 'sm' | 'md' | 'lg'>
export type TextVariant = Extract<
  Variant,
  'primary' | 'secondary' | 'tertiary' | 'error' | 'accentPrimary'
>
export type TextElement = 'span' | 'div' | 'p' | 'strong' | 'b' | 'em' | 'i'

export type TextProps = Pick<ChakraTextProps, 'align'> & {
  as?: TextElement
  level?: Level
  variant?: TextVariant
  isTruncated?: boolean
}

const levelMap: Record<Level, Level> = {
  '2xs': '2xs',
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl',
  '2xl': '2xl',
  '3xl': '3xl',
}

const Text: FC<TextProps> = ({
  children,
  as = 'span',
  level = 'sm',
  variant = 'secondary',
  isTruncated = false,
  align,
}) => {
  const color = useColor(colorMap[variant])
  const {
    fontFamily,
    fontSize,
    fontWeight: defaultFontWeight,
    lineHeight,
  } = getResponsiveTypography(level, levelMap)
  const fontWeight = as === 'strong' || as === 'b' ? 'bold' : defaultFontWeight

  return (
    <ChakraText
      display={isTruncated ? 'block' : undefined}
      align={align}
      as={as}
      color={color}
      fontFamily={fontFamily}
      fontSize={fontSize}
      fontWeight={fontWeight}
      letterSpacing="normal"
      lineHeight={lineHeight}
      whiteSpace={isTruncated ? 'nowrap' : 'pre-wrap'}
      isTruncated={isTruncated}
      overflowWrap={isTruncated ? 'normal' : 'anywhere'}
    >
      {children}
    </ChakraText>
  )
}

export { Text }
