import {
  Column,
  Columns,
  Stack,
  useColor,
  HiFiIcon,
  Text,
} from '_BRIGHT_/components'
import type { HifiIconVariant } from '_BRIGHT_/components/Icons/HiFiIcon'
import { withPalette } from '_BRIGHT_/components/PaletteProvider'
import { useShadow, usePointerFocusStyles } from '_BRIGHT_/utils'
import { useOutlineCardStyles } from '_BRIGHT_/components/Cards'
import { Box } from '@chakra-ui/react'
import React from 'react'
import type { FC } from 'react'
import { spaceMap } from '_BRIGHT_/components/Layout/Common'
import { Link as ReactRouterLink } from 'react-router-dom'

export type StandardCardProps = {
  href?: string
  to?: string
  iconVariant?: HifiIconVariant
  title: string
  description?: string
}

const ProtoStandardCard: FC<StandardCardProps> = ({
  description,
  title,
  iconVariant,
  href,
  to,
  children,
}) => {
  const hoverColor = useColor('active')
  const boxShadow = useShadow('focus')
  const active = { bg: hoverColor }
  const pointerFocusStyles = usePointerFocusStyles({ boxShadow: 'sm' })
  const styles = useOutlineCardStyles()
  const isClickable = to || href
  return (
    <Box
      as={to ? ReactRouterLink : (href && 'a') || undefined}
      {...(!href && to && { to })}
      {...(!to && { href })}
      padding={spaceMap.lg}
      _hover={isClickable ? active : undefined}
      _active={isClickable ? active : undefined}
      _focus={{ boxShadow, outline: 'none' }}
      width={{ base: '100%' }}
      {...styles}
      {...pointerFocusStyles}
    >
      <Stack space="sm">
        <Columns space="sm">
          {iconVariant && (
            <Column width="content">
              <HiFiIcon variant={iconVariant} strokeWidth="lg" />
            </Column>
          )}

          <Column width="fluid">
            <Stack>
              <Box py="1">
                <Text variant="primary" level="md">
                  {title}
                  {isClickable && ` ›`}
                </Text>
              </Box>
              {description && (
                <Text level="xs" variant="tertiary">
                  {description}
                </Text>
              )}
            </Stack>
          </Column>
        </Columns>
        {children}
      </Stack>
    </Box>
  )
}

const ProtoStandardCardWithPalette =
  withPalette<StandardCardProps>(ProtoStandardCard)

const StandardCard: FC<StandardCardProps> = ({ ...props }) => {
  return <ProtoStandardCardWithPalette {...props} palette="baseTwo" />
}

export { StandardCard }
