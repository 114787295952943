import {
  CubeKey,
  GeniusMetadataItemWithId,
  GeniusDataSet,
  CubeTablePivot,
} from 'lib/cubeJs'
import { formatCubeValueToAppValue } from './cubeValueMap'
import { removeCubePrefix } from './cubeHelpers'
import { nanoid } from '@reduxjs/toolkit'

export const formatCubeTableToDataSet = (
  tables: Partial<CubeTablePivot>[],
): GeniusDataSet =>
  tables.map(table =>
    Object.entries(table).reduce((acc, [k, v]) => {
      const nameMapping = removeCubePrefix(k as CubeKey)

      acc[nameMapping] = formatCubeValueToAppValue(k as CubeKey, v)
        .value as never

      acc['id'] = nanoid(6)

      return acc
    }, {} as GeniusMetadataItemWithId),
  )
