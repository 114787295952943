import {
  Heading,
  Link,
  List,
  ListTextItem,
  Stack,
  Text,
} from '_BRIGHT_/components'
import type { LinkProps } from '_BRIGHT_/components/Typography'
import type { Space } from '_BRIGHT_/components/Layout/Common'
import type { FontSize, Variant } from '_BRIGHT_/components/Typography/common'
import type { HigherOrderComponent, HTMLString } from '_BRIGHT_/utils'
import { isExternalLink } from '_BRIGHT_/utils'
import type { Element } from 'domhandler/lib/node'
import DOMPurify from 'dompurify'
import type { DOMNode } from 'html-react-parser'
import parse, { attributesToProps, domToReact } from 'html-react-parser'
import type { FC } from 'react'
import React, { createContext, useContext } from 'react'
import { Box } from '@chakra-ui/react'
import type { HeadingColor } from '_BRIGHT_/components/Typography/Heading'

type CopyLevel = Extract<FontSize, '2xs' | 'xs' | 'sm'>
type CopySpace = Extract<Space, 'lg' | 'xl' | '2xl'>

const spaceMap: Record<CopyLevel, CopySpace> = {
  '2xs': 'lg',
  xs: 'xl',
  sm: '2xl',
}

const CopyContext = createContext<{ level: CopyLevel }>({ level: 'sm' })

const withLevel: HigherOrderComponent = WrappedComponent => {
  return props => {
    const { level } = useContext(CopyContext)

    return <WrappedComponent {...props} level={level} />
  }
}

const isElement = (domNode: DOMNode): domNode is Element => {
  const isTag = domNode.type === 'tag'
  const hasAttributes = (domNode as Element).attribs !== undefined

  return isTag && hasAttributes
}

export type CopyProps = {
  html: HTMLString
  level?: CopyLevel
  space?: Space
  textAlign?: 'center' | 'left' | 'right'
  variant?: Variant
  headingColor?: HeadingColor
}

const Copy: FC<CopyProps> = ({
  html,
  level = 'sm',
  space: customSpace,
  textAlign,
  variant,
  headingColor,
}) => {
  const space = customSpace || spaceMap[level]
  const sanitizedHTML = DOMPurify.sanitize(html)

  const CopyText = withLevel(Text)
  const CopyLink = withLevel(Link)
  const CopyList = withLevel(List)

  const options = {
    replace: (domNode: DOMNode) => {
      if (!isElement(domNode)) {
        return null
      }

      const child = domToReact(domNode.children, options)
      const props = attributesToProps(domNode.attribs)

      switch (domNode.name) {
        case 'h1':
        case 'h2':
        case 'h3':
        case 'h4':
        case 'h5':
          return (
            <Heading as={domNode.name} color={headingColor}>
              {child}
            </Heading>
          )

        case 'p':
        case 'strong':
        case 'b':
        case 'em':
        case 'i':
          return (
            <CopyText as={domNode.name} variant={variant}>
              {child}
            </CopyText>
          )

        case 'a': {
          const isExternal = isExternalLink(props.href)
          return (
            <CopyLink
              {...(props as unknown as LinkProps)}
              isExternal={isExternal}
            >
              {child}
            </CopyLink>
          )
        }

        case 'ul':
          return <CopyList>{child}</CopyList>

        case 'li':
          return <ListTextItem>{child}</ListTextItem>

        default:
          return null
      }
    },
  }

  return (
    <CopyContext.Provider value={{ level }}>
      <Box textAlign={textAlign}>
        <Stack space={space}>{parse(sanitizedHTML, options)}</Stack>
      </Box>
    </CopyContext.Provider>
  )
}

export { Copy }
