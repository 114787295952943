import type { FC } from 'react'
import React from 'react'
import { Icon } from '@chakra-ui/react'
import type { FunctionalIconSvgProps } from '_BRIGHT_/components/Icons/FunctionalIcon/FunctionalIcon'

export const FunctionalInfoOutline: FC<FunctionalIconSvgProps> = ({
  color,
  size,
  height,
  strokeWidth,
  ariaHidden,
}) => (
  <Icon
    viewBox="0 0 24 24"
    fill="none"
    color={color}
    width={size}
    height={height || size}
    aria-hidden={ariaHidden}
  >
    <path
      d="M10 17H14"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M12 11L12 17"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M10.5 11H12"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M12 6C11.7033 6 11.4133 6.08797 11.1666 6.2528C10.92 6.41762 10.7277 6.65189 10.6142 6.92597C10.5006 7.20006 10.4709 7.50166 10.5288 7.79263C10.5867 8.08361 10.7296 8.35088 10.9393 8.56066C11.1491 8.77044 11.4164 8.9133 11.7074 8.97118C11.9983 9.02906 12.2999 8.99935 12.574 8.88582C12.8481 8.77229 13.0824 8.58003 13.2472 8.33335C13.412 8.08668 13.5 7.79667 13.5 7.5C13.5 7.10218 13.342 6.72064 13.0607 6.43934C12.7794 6.15803 12.3978 6 12 6Z"
      fill="currentColor"
    />
    <path
      d="M21 12C21 7.03125 16.9687 3 12 3C7.03125 3 3 7.03125 3 12C3 16.9687 7.03125 21 12 21C16.9687 21 21 16.9687 21 12Z"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      strokeMiterlimit={10}
    />
  </Icon>
)
