import { FunctionalIcon } from '_BRIGHT_/components'
import type { BaseButtonProps } from '_BRIGHT_/components/Buttons/BaseButton'
import { BaseButton } from '_BRIGHT_/components/Buttons/BaseButton'
import type { FunctionalIconVariant } from '_BRIGHT_/components/Icons'
import type { FC } from 'react'
import React from 'react'
import { useButtonStyles } from '_BRIGHT_/components/Buttons/BaseButton/useButtonStyles'
import { useLinkStyles } from '_BRIGHT_/components/Typography/Link/useLinkStyles'
import { isLinkVariant } from '_BRIGHT_/components/Typography/Link/Link'
import type { LinkVariant } from '_BRIGHT_/components/Typography/Link/Link'
import type { ButtonVariant } from '_BRIGHT_/components/Buttons/common'

export type ButtonProps = Pick<
  BaseButtonProps,
  | 'onClick'
  | 'size'
  | 'type'
  | 'buttonRef'
  | 'hasPaddingX'
  | 'ariaLabel'
  | 'ariaCurrent'
  | 'isFullWidthForMobile'
  | 'isLoading'
  | 'level'
  | 'ariaExpanded'
  | 'isFullWidthForDesktop'
> & {
  leftIconName?: FunctionalIconVariant
  rightIconName?: FunctionalIconVariant
  variant?: ButtonVariant | LinkVariant
}

const Button: FC<ButtonProps> = ({
  type,
  children,
  leftIconName,
  rightIconName,
  onClick,
  variant = 'primaryButton',
  size = 'lg',
  ariaLabel,
  ariaCurrent,
  buttonRef,
  ariaExpanded,
  isLoading,
  isFullWidthForMobile = true,
  isFullWidthForDesktop = false,
  hasPaddingX = true,
  level = 'sm',
}) => {
  const buttonStyles = useButtonStyles({
    size,
    isFullWidthForMobile,
    isFullWidthForDesktop,
    hasPaddingX,
    variant,
  })
  const linkStyles = useLinkStyles(variant, level)
  const { iconSize, iconLineHeight } = isLinkVariant(variant)
    ? linkStyles
    : buttonStyles

  const leftIcon = leftIconName && (
    <FunctionalIcon
      variant={leftIconName}
      size={iconSize}
      lineHeight={iconLineHeight}
    />
  )
  const rightIcon = rightIconName && (
    <FunctionalIcon
      variant={rightIconName}
      size={iconSize}
      lineHeight={iconLineHeight}
    />
  )

  return (
    <BaseButton
      type={type}
      leftIcon={leftIcon}
      rightIcon={rightIcon}
      onClick={onClick}
      variant={variant}
      size={size}
      ariaLabel={ariaLabel}
      ariaCurrent={ariaCurrent}
      buttonRef={buttonRef}
      isFullWidthForMobile={isFullWidthForMobile}
      isFullWidthForDesktop={isFullWidthForDesktop}
      hasPaddingX={hasPaddingX}
      isLoading={isLoading}
      level={level}
      ariaExpanded={ariaExpanded}
    >
      {children}
    </BaseButton>
  )
}

export { Button }
