import { useNavigate, useLocation } from 'react-router-dom'
import { useCallback, useMemo, useRef } from 'react'
import * as R from 'ramda'
import type { NonEmptyArray } from './types'
import {
  getNamespacedQueryParamKeys,
  getNamespacedQueryParams,
  stripNamespaceFromQueryParams,
} from './queryParams'
import type { QueryParams } from './queryParams'

type UpdateQueryParams = (
  queryParams: QueryParams,
  usePushStrategy?: boolean,
) => void
type UseQueryParams = (
  watchedParams: NonEmptyArray<string>,
  namespace?: string,
) => {
  queryParams: QueryParams
  updateQueryParams: UpdateQueryParams
}

export const useQueryParams: UseQueryParams = (watchedParams, namespace) => {
  // watchedParams and namespace aren't supposed to change over time.
  // Keep the same instances across renders to avoid re-computing memoized values and callbacks.
  const namespaceRef = useRef(namespace)
  const watchedParamsRef = useRef(
    namespace
      ? getNamespacedQueryParamKeys(namespace, watchedParams)
      : watchedParams,
  )

  const location = useLocation()
  const queryParams = useMemo(() => {
    // eg. { 'namespace-sort': '-id', 'namespace-page': '2' }
    const namespacedQueryParams = R.pick(
      watchedParamsRef.current,
      Object.fromEntries(new URLSearchParams(location.search)),
    )

    // Strip the namespace so consumers don't have to deal with it
    // eg. { 'sort': '-id', 'page': '2' }
    if (namespaceRef.current) {
      return stripNamespaceFromQueryParams(
        namespaceRef.current,
        namespacedQueryParams,
      )
    }

    // When a namespace isn't in use, there's actually no namespace to strip,
    // so just return namespacedQueryParams directly.
    return namespacedQueryParams
  }, [location.search])

  const navigate = useNavigate()
  const updateQueryParams = useCallback<UpdateQueryParams>(
    (queryParams, _usePushStrategy = false) => {
      const updateUrl = navigate
      const unwatchedParams = R.omit(
        watchedParamsRef.current,
        Object.fromEntries(new URLSearchParams(location.search)),
      )
      const updatedWatchedParams = R.pick(
        watchedParamsRef.current,
        namespaceRef.current
          ? getNamespacedQueryParams(namespaceRef.current, queryParams)
          : queryParams,
      )
      const nextUrlSearchParams = new URLSearchParams({
        ...unwatchedParams,
        ...updatedWatchedParams,
      })
      updateUrl(`?${nextUrlSearchParams}`)
    },
    [location.search, navigate],
  )

  return useMemo<ReturnType<UseQueryParams>>(
    () => ({ queryParams, updateQueryParams }),
    [queryParams, updateQueryParams],
  )
}
