import React from 'react'
import { Frame, Stack, Columns, Column } from '_BRIGHT_/components'
import { Skeleton } from '@chakra-ui/react'
import { useColor } from '_BRIGHT_/components/PaletteProvider'

export type SkeletonListProps = {
  listLength?: number
}

export const SkeletonList = ({
  listLength = 5,
}: SkeletonListProps): JSX.Element => {
  // @TODO Genius - Add tests for validating the use of these colors - https://smartpension.atlassian.net/browse/T2-1198
  const startColor = useColor('contrast', 'baseOne')
  const endColor = useColor('active', 'baseOne')

  return (
    <Frame paddingY="sm">
      <Stack hasDivider={true} space="md">
        {[...Array(listLength)].map((_, i) => (
          <Columns space="sm" key={i}>
            {[...Array(3)].map((_, j) => (
              <Column key={j} width={j === 1 ? '1/2' : '1/4'}>
                <Skeleton
                  height="5"
                  w="100%"
                  startColor={startColor}
                  endColor={endColor}
                  data-testid="genius-skeleton"
                />
              </Column>
            ))}
          </Columns>
        ))}
      </Stack>
    </Frame>
  )
}
