import type { GeniusCustomActionRenderers } from '_BRIGHT_/components'
import type {
  GeniusActionsMap,
  GeniusConfig,
  GeniusEnabledAction,
} from '_BRIGHT_/components/Genius'
import { useDisclosure } from '@chakra-ui/hooks'
import { useState } from 'react'

export type GeniusActionLabelsDict = Record<
  GeniusEnabledAction,
  string | undefined
>
export type DrawerView = Exclude<GeniusEnabledAction, 'selectAll'> | 'options'

export type UseDrawerArgs = {
  config: GeniusConfig
  onSelectAll?: () => void
  customActionRenderers?: GeniusCustomActionRenderers
}

export type UseDrawerReturnValue = {
  isOpen: boolean
  open: (view: DrawerView) => void
  close: () => void
  goBack: () => void
  actions: GeniusActionsMap
  viewsHistory: DrawerView[]
  customActionRenderers: GeniusCustomActionRenderers
}

const actionsNotListedInMenu: GeniusEnabledAction[] = ['selectAll', 'bulkEdit']
const getActionVisibility = (actionName: GeniusEnabledAction) =>
  !actionsNotListedInMenu.includes(actionName)

export const useDrawer = ({
  onSelectAll,
  config,
  customActionRenderers = {},
}: UseDrawerArgs): UseDrawerReturnValue => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [viewsHistory, setViewsHistory] = useState<DrawerView[]>([])

  const open = (view: DrawerView) => {
    setViewsHistory(prevViews => [...prevViews, view])
    !isOpen && onOpen()
  }

  const close = () => {
    setViewsHistory([])
    onClose()
  }

  const goBack = () => {
    setViewsHistory(prevEntries => prevEntries.slice(0, prevEntries.length - 1))
  }

  const onSelectAllActionClick = () => {
    close()
    onSelectAll?.()
  }

  const actionMap: GeniusActionsMap = new Map()

  const enabledActions = Object.keys(config.actions) as GeniusEnabledAction[]

  enabledActions.forEach(actionName => {
    const content = config.actions[actionName]

    if (!content) {
      console.warn(
        `Missing content for ${actionName} action. Make sure to either specify it or skip declaring this action in the config altogether.`,
      )
      return
    }

    if (actionName === 'selectAll' && !onSelectAll) {
      console.warn(
        'Missing handler for selectAll action. To enable bulk selection pass onSelectAll handler to useDrawer hook',
      )
      return
    }

    actionMap.set(actionName, {
      name: actionName,
      label: content.title,
      isVisible: getActionVisibility(actionName),
      onClick:
        actionName === 'selectAll'
          ? onSelectAllActionClick
          : () => open(actionName),
      customRenderer: customActionRenderers[actionName],
    })
  })

  return {
    actions: actionMap,
    isOpen,
    open,
    close,
    viewsHistory,
    goBack,
    customActionRenderers,
  }
}
