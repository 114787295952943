const borderWidths = {
  none: 0,
  '2xs': '1px',
  xs: '2px',
  sm: '4px',
  md: '8px',
  'top-accent': '8px 2px 2px',
  'left-accent': '2px 2px 2px 8px',
}

export { borderWidths }
