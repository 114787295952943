import React from 'react'
import type { FC } from 'react'
import { Box } from '@chakra-ui/react'
import { Stack, Frame } from '_BRIGHT_/components'
import type { MetadataItemsSectionProps } from '_BRIGHT_/components/Genius'

export const MetadataSection: FC<MetadataItemsSectionProps> = ({
  metadataItemsProps,
  MetadataItemRenderer,
}) => (
  <Frame palette="baseTwo" paddingTop="xs" paddingBottom="3xl">
    <Stack space="xs" hasDivider dividerColor="borderTertiary">
      {metadataItemsProps.map(({ label, value }) => (
        <Box key={`${label}-${value}`} width="full" paddingRight="2xl">
          <MetadataItemRenderer
            label={label}
            value={value === null ? '-' : value}
          />
        </Box>
      ))}
    </Stack>
  </Frame>
)
