import axios, { AxiosError } from 'axios'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AuthResponse, AppScope } from './types'

type ErrorStatus = 'unauthorised' | 'error'
type AuthStatus = 'idle' | 'login' | 'authorised' | 'signout' | ErrorStatus

export type AuthState = {
  data: AuthResponse
  status: AuthStatus
}

export const initialState: AuthState = {
  data: {} as AuthResponse,
  status: 'idle',
}

export const authSlice = createSlice({
  name: 'core',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(login.pending, state => {
      state.status = 'login'
    })
    builder.addCase(login.fulfilled, (state, action) => {
      if (action.payload) {
        state.data = action.payload
        state.status = 'authorised'
      }
    })
    builder.addCase(login.rejected, (state, action) => {
      if (action.payload) {
        state.status = action.payload
      }
    })
    builder.addCase(signout.pending, state => {
      state.status = 'signout'
    })
    builder.addCase(signout.fulfilled, state => {
      state.status = 'signout'
      redirectToLogin()
    })
  },
})

export const authReducer = authSlice.reducer

export const login = createAsyncThunk<
  AuthResponse | void,
  void,
  {
    rejectValue: ErrorStatus
  }
>('auth/login', async (_, { rejectWithValue, dispatch }) => {
  const queryString = window.location.search
  const URLParams = new URLSearchParams(queryString)
  const URLcode = URLParams.get('code')
  window.history.replaceState(null, '', window.location.pathname)

  const headers = new Headers({
    'Access-Control-Max-Age': '120',
    crossdomain: 'true',
  })

  try {
    const response = await axios.get<AuthResponse>(
      process.env.REACT_APP_CUBE_JS_BASE_URL + '/auth' ?? '',
      {
        ...headers,
        withCredentials: true, // _session HTTP ONLY COOKIE
        params: {
          code: URLcode ?? undefined,
          scope: authScopeHelper().scope,
        },
      },
    )
    return response.data
  } catch (axiosError) {
    const error = axiosError as AxiosError

    if (error.response?.status === 403) {
      return rejectWithValue('unauthorised')
    }

    dispatch(signout())
    return rejectWithValue('error')
  }
})

export const signout = createAsyncThunk('auth.signout', async () => {
  await axios.get<{ signout: boolean }>(
    process.env.REACT_APP_CUBE_JS_BASE_URL + '/signout' ?? '',
    {
      withCredentials: true, // HTTP ONLY COOKIE _SESSION
    },
  )
  return
})

export const redirectToLogin = () => {
  const { scope, redirectURI } = authScopeHelper()

  const OAuthParameters = new URLSearchParams({
    response_type: 'code',
    client_id: process.env.REACT_APP_PARTNER_CLIENT_ID ?? '',
    scope,
    redirect_uri: redirectURI ?? '',
    state: 'analytics',
    company_slug: '',
  })

  const redirectURL =
    process.env.REACT_APP_OAUTH_REDIRECT_URL + '?' + OAuthParameters.toString()

  window.location.href = redirectURL
}

export const authScopeHelper = (): {
  scope: AppScope
  redirectURI: string
} => {
  const subdomain = window.location.hostname.split('.')[0]
  switch (subdomain) {
    case 'employer':
      return {
        scope: 'customer',
        redirectURI: process.env.REACT_APP_PARTNER_REDIRECT_URI_EMPLOYER ?? '',
      }
    case 'analytics':
      return {
        scope: 'user',
        redirectURI: process.env.REACT_APP_PARTNER_REDIRECT_URI_ADVISER ?? '',
      }
    default:
      throw new Error('Cannot read auth scope from subdomain')
  }
}
