import React from 'react'
import { functionalIconMap } from './functionalIconMap'
import { getResponsiveTypography } from '_BRIGHT_/components/Typography/common'
import { useTheme } from '@chakra-ui/react'
import type { Level } from '_BRIGHT_/components/Typography/common'
import type { FC, AriaAttributes } from 'react'
import type { FunctionalIconVariant } from './functionalIconMap'

export type IconSize = 'xs' | 'sm'
type IconSizeValue = '16px' | '24px'
type StrokeWidth = '2.25' | '2'

export type FunctionalIconProps = {
  color?: string
  contrastColor?: string
  size?: IconSize
  lineHeight?: IconLineHeight
  variant: FunctionalIconVariant
  ariaHidden?: AriaAttributes['aria-hidden']
}

export type FunctionalIconSvgProps = {
  color: string
  contrastColor: string
  size: IconSizeValue
  strokeWidth: StrokeWidth
  height: string | null | undefined
  ariaHidden?: AriaAttributes['aria-hidden']
}

export const functionalIconSizeMap: Record<IconSize, IconSizeValue> = {
  xs: '16px',
  sm: '24px',
}

const iconSizeStrokeMap: Record<IconSize, StrokeWidth> = {
  xs: '2.25',
  sm: '2',
}

const iconHeightMap: Record<IconLineHeight, Level> = {
  '2xs': '2xs',
  xs: 'xs',
  sm: 'sm',
}

export type IconLineHeight = Extract<Level, '2xs' | 'xs' | 'sm'>

type UseHeight = (lineHeight: IconLineHeight | undefined) => string | null

const useHeight: UseHeight = lineHeight => {
  const theme = useTheme()

  if (!lineHeight) {
    return null
  }

  const { fontSize, lineHeight: relativeLineHeight } = getResponsiveTypography(
    lineHeight,
    iconHeightMap,
  )
  const fontSizes = theme.fontSizes as unknown as Record<string, string>
  const lineHeights = theme.lineHeights as unknown as Record<string, string>
  const fontSizeValue = fontSizes[fontSize as string]
  const relativeLineHeightValue = lineHeights[relativeLineHeight as string]
  const heightValue =
    parseFloat(fontSizeValue) * parseFloat(relativeLineHeightValue)

  return `${heightValue}rem`
}

export const FunctionalIcon: FC<FunctionalIconProps> = ({
  variant,
  size = 'sm',
  color = 'currentColor',
  contrastColor = 'black',
  lineHeight,
  ariaHidden,
}) => {
  const IconVariant = functionalIconMap[variant]
  const strokeWidth = iconSizeStrokeMap[size]
  const height = useHeight(lineHeight)

  return (
    <IconVariant
      color={color}
      contrastColor={contrastColor}
      size={functionalIconSizeMap[size]}
      height={height || undefined}
      strokeWidth={strokeWidth}
      ariaHidden={ariaHidden}
    />
  )
}
