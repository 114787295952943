import { FilterQuery } from 'lib/cubeJs'

export const analyticsSummaryQuery: FilterQuery = ids => ({
  dimensions: [
    'SMART_ANALYTICS_COMPANIES.companyId',
    'SMART_ANALYTICS_COMPANIES.totalAUM',
    'SMART_ANALYTICS_COMPANIES.totalEmployees',
    'SMART_ANALYTICS_COMPANIES.totalActiveEmployees',
    'SMART_ANALYTICS_COMPANIES.takeUpRatio',
    'SMART_ANALYTICS_COMPANIES.companyState',
    'SMART_ANALYTICS_COMPANIES.lastContributionCreationDate',
    'SMART_ANALYTICS_COMPANIES.schemeStartDate',
    'SMART_ANALYTICS_COMPANIES.dutyStartDate',
    'SMART_ANALYTICS_COMPANIES.nextReEnrolmentDate',
  ],
  filters: [
    {
      member: 'SMART_ANALYTICS_COMPANIES.companyId',
      operator: 'equals',
      values: [...ids],
    },
  ],
})
