import React from 'react'
import type { FC } from 'react'
import { Icon } from '@chakra-ui/react'
import type { FunctionalIconSvgProps } from '_BRIGHT_/components/Icons/FunctionalIcon/FunctionalIcon'

export const FunctionalPdf: FC<FunctionalIconSvgProps> = ({
  color,
  size,
  height,
  strokeWidth,
  ariaHidden,
}) => (
  <Icon
    viewBox="0 0 24 24"
    fill="none"
    color={color}
    width={size}
    height={height || size}
    aria-hidden={ariaHidden}
  >
    <path
      d="M3.55957 20.0001L3.55957 4.69437C3.55957 4.39379 3.68114 4.10552 3.89752 3.89298C4.11391 3.68044 4.4074 3.56104 4.71342 3.56104H12.8896C13.0412 3.56107 13.1913 3.59044 13.3313 3.64749C13.4714 3.70453 13.5986 3.78812 13.7057 3.89348L18.2219 8.32935C18.329 8.43464 18.414 8.55963 18.4719 8.69717C18.5299 8.83472 18.5596 8.98213 18.5596 9.13099V11.6203"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.1846 9.18603H13.6846C13.3862 9.18603 13.1001 9.06751 12.8891 8.85653C12.6781 8.64555 12.5596 8.3594 12.5596 8.06103V3.56104"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 21V15"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 21V15"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 15H22"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 18H22"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 15C12.5 15 12.5 19 8 19"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 15C18.5 15 18.5 21 14 21V15Z"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
)
