import type { GeniusBulkEditContextValue } from '_BRIGHT_/components/Genius/context'
import { GeniusBulkEditContext } from '_BRIGHT_/components/Genius/context'
import { useContext } from 'react'

export const useGeniusBulkEditContext = (): GeniusBulkEditContextValue => {
  const context = useContext(GeniusBulkEditContext)

  if (!context)
    throw new Error(
      'useGeniusBulkEditContext must be used within BulkEditContextProvider',
    )

  return context
}
