import { createRange } from './createRange'

type Options = {
  length: number
  totalPages: number
  currentPage: number
}

export function createPageRange({ totalPages, currentPage, length }: Options): Array<number | string> {
  const radius = Math.floor(length / 2)
  const offset = Math.ceil(length / 2)

  if (totalPages <= length) {
    return createRange(1, totalPages)
  }

  if (currentPage <= offset) {
    const start = 1
    const end = length - 2

    return [...createRange(start, end), '…', totalPages]
  }

  if (currentPage + offset > totalPages) {
    const start = totalPages - length + 3
    const end = totalPages

    return [1, '…', ...createRange(start, end)]
  }

  // NOTE: If an even length is set then the range can't be centred on
  // the current page and an extra number must be removed from one side.
  const start = currentPage - radius + 2
  const end = currentPage + radius - (length % 2 === 0 ? 3 : 2)

  return [1, '…', ...createRange(start, end), '…', totalPages]
}
