type DisabledControl = {
  table: {
    disable: boolean
  }
}

export const disableControls = (...controls: string[]): Record<string, DisabledControl> =>
  [...controls].reduce((disabledControls, currentControl) => {
    return { ...disabledControls, [currentControl]: { table: { disable: true } } }
  }, {})
