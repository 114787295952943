import { FilterQuery } from 'lib/cubeJs'

export const maintenanceOverviewQuery: FilterQuery = ids => ({
  measures: [
    'SMART_ANALYTICS_EMPLOYEES.count',
    'SMART_ANALYTICS_EMPLOYEES.totalInvalidNationalInsuranceNumber',
    'SMART_ANALYTICS_EMPLOYEES.totalMembersWithMissingContributions',
    'SMART_ANALYTICS_EMPLOYEES.totalNeverSignedIn',
    'SMART_ANALYTICS_EMPLOYEES.totalMissingBeneficiaries',
    'SMART_ANALYTICS_EMPLOYEES.totalInvalidHomeAddress',
    'SMART_ANALYTICS_EMPLOYEES.totalInvalidEmailAddress',
  ],
  filters: [
    {
      member: 'SMART_ANALYTICS_EMPLOYEES.companyId',
      operator: 'equals',
      values: [...ids],
    },
  ],
})
