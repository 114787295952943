import React, { useState } from 'react'
import type { FC } from 'react'
import { Box } from '@chakra-ui/react'
import { PaletteProvider } from '_BRIGHT_/components'
import type { BlockPaletteName } from '_BRIGHT_/components/PaletteProvider'
import { NavigationDrawer } from './NavigationDrawer'
import type { NavigationDrawerProps } from './NavigationDrawer'
import { NavigationTopBar } from './NavigationTopBar'
import type { NavigationTopBarProps } from './NavigationTopBar'
export type NavigationPalette = Extract<
  BlockPaletteName,
  'baseTwo' | 'baseThree'
>
export type NavigationVariant = 'neutral' | 'contrast'

const paletteMap: Record<NavigationVariant, NavigationPalette> = {
  neutral: 'baseTwo',
  contrast: 'baseThree',
}

export type NavigationProps = Omit<
  NavigationTopBarProps,
  'palette' | 'onOpen' | 'ariaExpanded'
> &
  Omit<NavigationDrawerProps, 'isOpen' | 'onClose'> & {
    variant: NavigationVariant
    navigationAriaLabel: string
    /**
     * MODIFIED:
     * topBarContent
     */
    topBarContent?: JSX.Element
  }

const Navigation: FC<NavigationProps> = ({
  imageUrl,
  imageAltText,
  imageLink,
  menuButtonLabel,
  menuButtonAriaLabel,
  navigationAriaLabel,
  closeButtonLabel,
  signOutButtonLabel,
  accountDetails,
  variant,
  children,
  onSignOut,
  topBarContent,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const onOpen = (): void => {
    setIsOpen(true)
  }

  const onClose = (): void => {
    setIsOpen(false)
  }

  const palette = paletteMap[variant]

  return (
    <Box as="nav" width="full" aria-label={navigationAriaLabel}>
      <PaletteProvider palette={palette}>
        <NavigationTopBar
          imageUrl={imageUrl}
          imageAltText={imageAltText}
          imageLink={imageLink}
          onOpen={onOpen}
          menuButtonLabel={menuButtonLabel}
          menuButtonAriaLabel={menuButtonAriaLabel}
          palette={palette}
          ariaExpanded={isOpen}
          content={topBarContent}
        />
        <NavigationDrawer
          isOpen={isOpen}
          onClose={onClose}
          onSignOut={onSignOut}
          closeButtonLabel={closeButtonLabel}
          signOutButtonLabel={signOutButtonLabel}
          accountDetails={accountDetails}
        >
          {children}
        </NavigationDrawer>
      </PaletteProvider>
    </Box>
  )
}

export { Navigation }
