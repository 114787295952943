import type { FC } from 'react'
import React from 'react'
import type { HiFiIconPathProps } from '_BRIGHT_/components/Icons'

const Message: FC<HiFiIconPathProps> = ({
  primaryColor,
  secondaryColor,
  baseColor,
  strokeWidth,
  hasShadow,
}) => {
  return (
    <>
      {hasShadow && (
        <path
          opacity="0.2"
          d="M7.07692 28H20.5593H28.339H29.9231C30.4739 28 31.0022 27.7521 31.3917 27.3108C31.7812 26.8696 32 26.2711 32 25.6471V10.3529C32 9.7289 31.7812 9.13042 31.3917 8.68916C31.0022 8.2479 30.4739 8 29.9231 8H7.07692C6.52609 8 5.99782 8.2479 5.60832 8.68916C5.21882 9.13042 5 9.7289 5 10.3529V25.6471C5 26.2711 5.21882 26.8696 5.60832 27.3108C5.99782 27.7521 6.52609 28 7.07692 28Z"
          fill={primaryColor}
        />
      )}
      <path
        d="M3.07692 24H16.5593H24.7857H25.9231C26.4739 24 27.0022 23.7521 27.3917 23.3108C27.7812 22.8696 28 22.2711 28 21.6471V6.35294C28 5.7289 27.7812 5.13042 27.3917 4.68916C27.0022 4.2479 26.4739 4 25.9231 4H3.07692C2.52609 4 1.99782 4.2479 1.60832 4.68916C1.21882 5.13042 1 5.7289 1 6.35294V21.6471C1 22.2711 1.21882 22.8696 1.60832 23.3108C1.99782 23.7521 2.52609 24 3.07692 24Z"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.0711 13.4508L16.0706 13.4512C15.6088 13.8087 15.061 14 14.5 14C13.9391 14 13.3913 13.8087 12.9295 13.4512L12.929 13.4508L2.00599 5.00464L1.60924 4.68822C1.99902 4.24714 2.52708 4 3.07702 4H25.9232C26.4731 4 27.0012 4.24714 27.3909 4.68822L26.9941 5.00461L16.0711 13.4508Z"
        fill={baseColor}
        stroke={secondaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  )
}

export { Message }
