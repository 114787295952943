import { FC, ReactElement } from 'react'
import { BlockPaletteName } from '_BRIGHT_/components/PaletteProvider'
import { useColor } from '_BRIGHT_/components'
import { Grid } from '_BRIGHT_/components/Layout'
import { Box } from '@chakra-ui/react'
import { Footer } from 'components/Footer'
import { FooterLinks } from './footerContent'
import { Menu } from './Menu'
import { EmployerWelcomeHeader } from './EmployerWelcomeHeader'
import { useTracking } from 'lib/hooks'

const currentYear = new Date().getFullYear()

export type PageWrapperProps = {
  children: ReactElement | ReactElement[]
  palette?: BlockPaletteName
}

export const PageWrapper: FC<PageWrapperProps> = ({
  children,
  palette = 'baseTwo',
}): JSX.Element => {
  const bgColor = useColor('core', palette)

  useTracking()

  return (
    <Grid flexDir="column" minHeight="100vh">
      <EmployerWelcomeHeader />
      <Menu />
      <Box
        bg={bgColor}
        d="flex"
        flexDir="column"
        flexGrow={1}
        alignSelf="center"
        width="100%"
      >
        {children}
      </Box>
      <Footer
        content={`<p>We update details every four hours. Figures marked as "Average" use mean averages.</p><p>© Copyright ${currentYear} Smart Pension Ltd. Smart Pension Ltd is a company registered in England and Wales (Company No. 09026697) The Smart Building, 136 George Street, London W1H 5LD United Kingdom</p>`}
      >
        <FooterLinks />
      </Footer>
    </Grid>
  )
}
