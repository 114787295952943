import { useContext } from 'react'
import type { GeniusConfig } from '_BRIGHT_/components/Genius'
import { GeniusConfigContext } from '_BRIGHT_/components/Genius/context'

export const useGeniusConfigContext = (): GeniusConfig => {
  const context = useContext(GeniusConfigContext)

  if (!context)
    throw new Error(
      'useGeniusConfigContext must be used within GeniusContentProvider',
    )

  return context
}
