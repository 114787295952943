import { GeniusConfig } from '_BRIGHT_/generated/generated-bright-components'
import { actionBar } from './actionBar'
import { actions } from './actions'
import { body } from './body'
import { fields } from './fields'

export const geniusConfig: GeniusConfig = {
  collectionType: 'companies',
  actionBar,
  actions,
  body,
  fields,
}
