import type { GeniusMetadataListViewItemProps } from '_BRIGHT_/components/Genius'
import type { GeniusState } from '_BRIGHT_/components/Genius/hooks'

export const getCollapsedMetadataItems: (
  items: GeniusMetadataListViewItemProps[],
  settings?: GeniusState['settings'],
) => GeniusMetadataListViewItemProps[] = (items, settings) =>
  !settings || !items
    ? []
    : settings.reduce((metadataItemProps, setting) => {
        if (setting.showInSummary) {
          const collapsedItem = items.find(
            ({ fieldName }) => fieldName === setting.field,
          )
          collapsedItem && metadataItemProps.push(collapsedItem)
        }

        return metadataItemProps
      }, [] as GeniusMetadataListViewItemProps[])
