import { useFindCompany } from 'pages/analytics/hooks'
import { FC, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export const CompanyGuard: FC = ({ children }) => {
  const { company } = useFindCompany()

  const navigate = useNavigate()

  useEffect(() => {
    if (!!company) {
      return
    }

    navigate('/not-found')
  }, [company, navigate])

  return company ? <>{children}</> : null
}
