import cubejs from '@cubejs-client/core'

export const cubejsApi = cubejs(
  process.env.REACT_APP_CUBEJS_TOKEN ?? 'NO_JWT',
  {
    apiUrl:
      (process.env.REACT_APP_CUBE_JS_BASE_URL ?? 'NO_URL') + '/cubejs-api/v1',
    headers: {
      'Access-Control-Max-Age': '120',
      crossdomain: 'true',
      withCredentials: 'true',
    },
    credentials: 'include',
  },
)
