import type { OnClick } from '_BRIGHT_/utils'
import { useColor, Divider } from '_BRIGHT_/components'
import type { FC } from 'react'
import React from 'react'
import type { DrawerProps } from '@chakra-ui/react'
import {
  Box,
  Flex,
  Drawer,
  DrawerOverlay,
  DrawerContent,
} from '@chakra-ui/react'
import { Links, LinksItem } from './Links'
import { AccountDetails, AccountDetailsItem } from './AccountDetails'
import { CloseButton } from './CloseButton'
import { SignOutButton } from './SignOutButton'
import { useSideDrawerStyles } from '_BRIGHT_/components/Overlays/Drawer'

type AccountDetail = {
  label: string
  content: string
}

export type NavigationDrawerProps = Pick<DrawerProps, 'isOpen' | 'onClose'> & {
  onSignOut: OnClick
  closeButtonLabel: string
  signOutButtonLabel: string
  accountDetails: AccountDetail[]
}

const NavigationDrawer: FC<NavigationDrawerProps> = ({
  children,
  isOpen,
  onClose,
  onSignOut,
  closeButtonLabel,
  signOutButtonLabel,
  accountDetails,
}) => {
  const backgroundColor = useColor('core')

  const { height, maxWidth, placement } = useSideDrawerStyles()

  return (
    <Drawer
      isOpen={isOpen}
      placement={placement}
      isFullHeight
      onClose={onClose}
    >
      <DrawerOverlay />
      <DrawerContent
        overflow="auto"
        maxWidth={maxWidth}
        bg={backgroundColor}
        height={height}
      >
        <Flex px={{ base: 'md', sm: 'lg' }} py="xs" justifyContent="flex-end">
          <CloseButton label={closeButtonLabel} onClick={onClose} />
        </Flex>
        <Flex
          flexDir="column"
          flexGrow={1}
          overflow={{ base: 'auto', sm: 'initial' }}
        >
          <Links>{children}</Links>
          <Box px={{ base: 'md', sm: 'lg' }} pb={{ base: 'xl', sm: '3xl' }}>
            <Divider />
            <AccountDetails>
              {accountDetails.map(({ label, content }, i) => (
                <AccountDetailsItem
                  key={`${label}-${i}`}
                  label={label}
                  content={content}
                />
              ))}
            </AccountDetails>
            <SignOutButton label={signOutButtonLabel} onClick={onSignOut} />
          </Box>
        </Flex>
      </DrawerContent>
    </Drawer>
  )
}

export { NavigationDrawer, LinksItem as NavigationDrawerLinksItem }
