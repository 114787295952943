import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Frame,
} from '_BRIGHT_/components'
import type {
  FieldConfig,
  FilterContent,
  FilterViewContent,
} from '_BRIGHT_/components/Genius'
import { ActionDrawerView } from '_BRIGHT_/components/Genius/components'
import { useGeniusConfigContext } from '_BRIGHT_/components/Genius/hooks'
import {
  isDateField,
  isOptionField,
  isTextField,
  isNumberField,
} from '_BRIGHT_/components/Genius/utils'
import type { FC } from 'react'
import React from 'react'
import { FilterByDate, FilterByOption, FilterByText, FilterByNumber } from './'

export type FilterViewBodyProps = {
  content: FilterViewContent
}

type FilterComponentContentProps = Pick<
  FilterContent,
  'confirmButtonLabel' | 'cancelButtonLabel'
>
type FilterComponentProps = FieldConfig & FilterComponentContentProps

const FilterComponent: FC<FilterComponentProps> = props => {
  const { type } = props
  if (isDateField(props)) {
    const { dateInputConfig, ...otherProps } = props
    if (!dateInputConfig) {
      console.warn(
        `Date input config is missing on a field "${otherProps.fieldName}"`,
      )
      return null
    }
    const { operatorOptions, dateLabels } = dateInputConfig
    return (
      <FilterByDate
        operatorOptions={operatorOptions}
        {...dateLabels}
        {...otherProps}
      />
    )
  }

  if (isOptionField(props)) {
    const { optionInputConfig, ...otherProps } = props as any
    return <FilterByOption {...optionInputConfig} {...otherProps} />
  }

  if (isTextField(props)) return <FilterByText {...props} />

  if (isNumberField(props)) return <FilterByNumber {...props} />

  console.warn(
    `Cannot render a Filter component for type "${type}". Currently supported filter types are: "text", "option", "number" and "date".`,
  )
  return null
}

export const FilterViewBody: FC<FilterViewBodyProps> = ({ content }) => {
  const { fields } = useGeniusConfigContext()

  return (
    <Frame palette="baseTwo" width="full" paddingBottom="4xl">
      <Accordion borderColor="borderSecondary" allowMultiple={false}>
        {Object.values(fields)
          .filter(({ isFilterable }) => isFilterable)
          .map(({ type, label, fieldName, ...otherProps }, i) => {
            const componentProps = {
              fieldName,
              label,
              ...content,
              ...otherProps,
            }
            const accordionItemBorderProps =
              i === 0 ? { borderTopWidth: 0 } : {}

            return (
              /**
               * MODIFIED:
               * unique Key
               */
              <AccordionItem key={fieldName + i} {...accordionItemBorderProps}>
                <AccordionButton>{label}</AccordionButton>
                <AccordionPanel paddingBottom="none">
                  <FilterComponent type={type} {...componentProps} />
                </AccordionPanel>
              </AccordionItem>
            )
          })}
      </Accordion>
    </Frame>
  )
}

export const FilterView: FC = () => {
  const { actions } = useGeniusConfigContext()

  if (!actions.filter) return null
  const { title, ...content } = actions.filter

  return (
    <ActionDrawerView title={title}>
      <FilterViewBody content={content} />
    </ActionDrawerView>
  )
}
