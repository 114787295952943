import {
  Column,
  Columns,
  Stack,
  useColor,
  HiFiIcon,
  Heading,
  Text,
  Divider,
} from '_BRIGHT_/components'
import type { HifiIconVariant } from '_BRIGHT_/components/Icons/HiFiIcon'
import { withPalette } from '_BRIGHT_/components/PaletteProvider'
import {
  useShadow,
  usePointerFocusStyles,
  isExternalLink,
} from '_BRIGHT_/utils'
import { Box } from '@chakra-ui/react'
import React from 'react'
import type { FC } from 'react'
import { spaceMap } from '_BRIGHT_/components/Layout/Common'
import { Link as RouterLink } from 'react-router-dom'

export type ElevatedCardProps = {
  href: string
  iconVariant: HifiIconVariant
  title: string
  description?: string
  withSeparator?: boolean
}

const ProtoElevatedCard: FC<ElevatedCardProps> = ({
  children,
  title,
  description,
  iconVariant,
  href,
  withSeparator = false,
}) => {
  const hoverColor = useColor('active')
  const backgroundColor = useColor('core')
  const boxShadow = useShadow('focus')
  const active = { bg: hoverColor }
  const pointerFocusStyles = usePointerFocusStyles({ boxShadow: 'sm' })
  const isExternal = isExternalLink(href)
  const hrefProps = isExternal ? { href } : { to: href }

  return (
    <Box
      as={isExternal ? 'a' : RouterLink}
      {...hrefProps}
      display="block"
      borderRadius="lg"
      boxShadow="sm"
      backgroundColor={backgroundColor}
      padding={spaceMap.lg}
      _hover={active}
      _active={active}
      _focus={{ boxShadow, outline: 'none' }}
      width="100%"
      {...pointerFocusStyles}
    >
      <Stack space={withSeparator ? 'lg' : 'sm'}>
        <Columns space="sm">
          <Column width="content">
            <HiFiIcon variant={iconVariant} strokeWidth="lg" />
          </Column>

          <Column width="fluid">
            <Stack>
              <Box py="1">
                <Heading as="h5">{title}&nbsp;›</Heading>
              </Box>
              {description && (
                <Text level="xs" variant="tertiary">
                  {description}
                </Text>
              )}
            </Stack>
          </Column>
        </Columns>

        {withSeparator && <Divider />}

        {children}
      </Stack>
    </Box>
  )
}

const ProtoElevatedCardWithPalette =
  withPalette<ElevatedCardProps>(ProtoElevatedCard)

const ElevatedCard: FC<ElevatedCardProps> = ({ ...props }) => {
  return <ProtoElevatedCardWithPalette {...props} palette="baseTwo" />
}

export { ElevatedCard }
