import * as R from 'ramda'
import type { DeepMap, UseFormSetError, FieldError, FieldValues, Path } from 'react-hook-form'

export const GENERAL_FORM_ERROR = 'GENERAL_FORM_ERROR'

type ApiError = {
  code: string
  detail: string
  title: string
  message: string
  pointer: string
  source: { pointer: string }
}
type ApiErrorWithPointer = ApiError & { pointer: string }
type FormErrors = { [pointer: string]: { [code: string]: string } }

export type GeneralFormError = {
  [GENERAL_FORM_ERROR]: string
}

export const hasGeneralError = <T>(errors: DeepMap<T, FieldError>): boolean => {
  return R.has(GENERAL_FORM_ERROR, errors)
}

export const extractPointer = (pointer: string): string =>
  pointer
    .split('/')
    .filter(e => e && !['data', 'attributes', 'relationships'].includes(e))
    .join('.')

const makeErrorListWithPointer = R.map<ApiError, ApiErrorWithPointer>(error =>
  R.assoc('pointer', extractPointer(error.source.pointer), error)
)

const errorsKeyedByCode = R.reduce<ApiError, FormErrors>(
  (accumulatedErrors, { pointer, code, detail }: ApiError) =>
    R.assocPath([pointer, String(code)], detail, accumulatedErrors),
  {}
)

const formatFormErrors = (err: Error) => {
  const apiErrors = R.path<ApiError[]>(['graphQLErrors', '0', 'extensions', 'response', 'body', 'errors'], err)

  return apiErrors && R.pipe(makeErrorListWithPointer, errorsKeyedByCode)(apiErrors)
}

export const withErrorHandler = <FormData extends FieldValues>(
  onSubmit: (formData: FormData) => void,
  setError: UseFormSetError<FormData>
) => {
  return async (formData: FormData): Promise<void> => {
    try {
      await onSubmit(formData)
    } catch (err) {
      if (err instanceof Error) {
        const errors = formatFormErrors(err)

        R.isNil(errors)
          ? setError(GENERAL_FORM_ERROR as Path<FormData>, {
              type: GENERAL_FORM_ERROR,
              message: err.message,
            })
          : Object.entries(errors).forEach(([key, errors]) =>
              setError(key as Path<FormData>, { type: 'server', types: errors })
            )
      }
    }
  }
}
