import {
  List,
  ListIcon,
  ListTextItem,
  Stack,
  Text,
  useColor,
} from '_BRIGHT_/components'
import type { FormErrors } from '_BRIGHT_/components/Forms/common'
import type { TextProps } from '_BRIGHT_/components/Typography'
import type { FormControlProps as IChakraFormControl } from '@chakra-ui/react'
import {
  Box,
  FormControl as ChakraFormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  VisuallyHidden,
} from '@chakra-ui/react'
import * as R from 'ramda'
import type { FC } from 'react'
import React from 'react'

type LabelVariant = 'small' | 'regular'

export type FormControlLabelProps = {
  label?: string
  isRequired?: boolean
  formLabelAs?: 'legend' | 'label'
  useOptionalSuffix?: boolean
  labelVariant?: LabelVariant
}

type FormControlDescriptionProps = {
  description?: string
}

type FormControlErrorsProps = {
  errors: FormErrors
}

export type FormControlProps = Pick<IChakraFormControl, 'isInvalid'> &
  Omit<FormControlLabelProps, 'formLabelAs'> &
  FormControlDescriptionProps &
  Partial<FormControlErrorsProps> & {
    id?: string
    role?: string
    as?: 'div' | 'fieldset'
  }

// The required asterisk after a label cannot be disabled currently in Chakra, so we pass an empty element to override it
const NullElement: FC = () => <VisuallyHidden />

// TODO: replace this string with the internationalised string from the CMS
export const OPTIONAL_LABEL_SUFFIX = '(optional)'

const descriptionTextProps: Pick<TextProps, 'level' | 'variant'> = {
  variant: 'tertiary',
  level: 'xs',
}

const labelTextPropsMap: Record<LabelVariant, TextProps> = {
  small: descriptionTextProps,
  regular: { as: 'strong' },
}

export const FormControlLabel: FC<FormControlLabelProps> = ({
  formLabelAs = 'label',
  label,
  labelVariant = 'regular',
  useOptionalSuffix,
  isRequired,
}) => {
  const showLabelSuffix = useOptionalSuffix && !isRequired

  return (
    <FormLabel
      requiredIndicator={<NullElement />}
      m={0}
      as={formLabelAs}
      lineHeight="none"
    >
      <Text {...labelTextPropsMap[labelVariant]}>
        {label}
        {showLabelSuffix ? ` ${OPTIONAL_LABEL_SUFFIX}` : null}
      </Text>
    </FormLabel>
  )
}

export const FormControlDescription: FC<FormControlDescriptionProps> = ({
  description,
}) => (
  <>
    <Text {...descriptionTextProps}>
      <Box as="span" aria-hidden>
        {description}
      </Box>
    </Text>
    <VisuallyHidden>
      <FormHelperText>{description}</FormHelperText>
    </VisuallyHidden>
  </>
)

export const FormControlErrors: FC<FormControlErrorsProps> = ({ errors }) => {
  const errorColor = useColor('core', 'negativePrimary')

  return (
    <FormErrorMessage m="0" fontSize="md" color={errorColor} width="100%">
      <List level="xs">
        {Object.entries(errors).map(([code, message]) => (
          <ListTextItem
            key={code}
            variant="error"
            marker={
              <ListIcon
                color={errorColor}
                variant="FunctionalExclamationOutline"
              />
            }
          >
            {message}
          </ListTextItem>
        ))}
      </List>
    </FormErrorMessage>
  )
}

const FormControl: FC<FormControlProps> = ({
  children,
  errors,
  isRequired,
  label,
  description,
  id,
  as = 'div',
  role = 'group',
}) => {
  const textColor = useColor('contentSecondary')
  const showLabelAndDescriptionSection = label || description
  return (
    <ChakraFormControl
      isInvalid={errors && !R.isEmpty(errors)}
      isRequired={isRequired}
      id={id}
      color={textColor}
      as={as}
      role={role}
    >
      <Stack space="sm">
        {showLabelAndDescriptionSection && (
          <Stack space="2xs">
            {label && (
              <FormControlLabel
                label={label}
                formLabelAs={as === 'fieldset' ? 'legend' : 'label'}
                isRequired={isRequired}
                useOptionalSuffix={true}
              />
            )}
            {description && (
              <FormControlDescription description={description} />
            )}
          </Stack>
        )}
        {children}
        {errors && !R.isEmpty(errors) && <FormControlErrors errors={errors} />}
      </Stack>
    </ChakraFormControl>
  )
}

export { FormControl }
