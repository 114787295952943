import { BinaryFilter } from '@cubejs-client/core'
import { FilterQuery } from 'lib/cubeJs'

export const companyIdFilter = (ids: string[]): BinaryFilter => ({
  member: 'SMART_ANALYTICS_COMPANIES.companyId',
  operator: 'equals',
  values: [...ids],
})

export const maintenanceQuery: FilterQuery = ids => ({
  dimensions: [
    'SMART_ANALYTICS_COMPANIES.totalMembersWithMissingContributions',
    'SMART_ANALYTICS_COMPANIES.totalInvalidNationalInsuranceNumber',
    'SMART_ANALYTICS_COMPANIES.totalInvalidEmail',
  ],
  filters: [companyIdFilter([...ids])],
})

export const investmentsQuery: FilterQuery = ids => ({
  dimensions: [
    'SMART_ANALYTICS_COMPANIES.totalAUM',
    'SMART_ANALYTICS_COMPANIES.totalAvailableFunds',
    'SMART_ANALYTICS_COMPANIES.membersWithDefaultStrategyRatio',
  ],
  filters: [companyIdFilter([...ids])],
})

export const memberInsightsQuery: FilterQuery = ids => ({
  dimensions: [
    'SMART_ANALYTICS_COMPANIES.averageMemberAUM',
    'SMART_ANALYTICS_COMPANIES.averageActiveMemberAge',
    'SMART_ANALYTICS_COMPANIES.averageActiveMemberSalary',
  ],
  filters: [companyIdFilter([...ids])],
})

export const contributionsQuery: FilterQuery = ids => ({
  dimensions: [
    'SMART_ANALYTICS_COMPANIES.lastContributionCreationDate',
    'SMART_ANALYTICS_COMPANIES.averageActiveMemberPercentage',
    'SMART_ANALYTICS_COMPANIES.averageActiveMemberCompanyPercentage',
  ],
  filters: [companyIdFilter([...ids])],
})
