import { FC } from 'react'
import { CenteredBlock } from 'components/CenteredBlock'
import { Stack } from '_BRIGHT_'
import { homePageQuery } from './homePageQuery'
import { Overview } from 'components/Overview'
import { HomeNavigationCards } from 'components/HomeNavigationCards'
import { PageHeadingBlock } from 'components/PageHeadingBlock'

export const HomePage: FC = () => {
  return (
    <>
      <PageHeadingBlock
        pageHeading="Analytics"
        subheading="Charts and tables to help you support your clients."
      />
      <CenteredBlock>
        <Stack space="4xl">
          <Overview query={homePageQuery} />
          <HomeNavigationCards />
        </Stack>
      </CenteredBlock>
    </>
  )
}
