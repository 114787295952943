import type { FC } from 'react'
import React from 'react'
import { Text } from '_BRIGHT_/components'
import { OptionsMenuItem } from './OptionsMenuItem'
import { Frame, Grid, Stack } from '_BRIGHT_/components/Layout'
import {
  useDrawerContext,
  useGeniusConfigContext,
} from '_BRIGHT_/components/Genius/hooks'

export const OptionsMenuView: FC = () => {
  const {
    actionBar: { actionDrawerMenuLabel },
  } = useGeniusConfigContext()
  const { actions } = useDrawerContext()
  return (
    <Stack space="sm">
      <Frame palette="baseTwo" paddingX="md" paddingTop="sm">
        <Text as="strong" level="xs">
          {actionDrawerMenuLabel}
        </Text>
      </Frame>
      <Frame palette="baseTwo">
        <Grid as="ul" wrap overflow="hidden">
          {Array.from(actions).map(
            ([key, { label, isVisible, onClick }]) =>
              isVisible && (
                <OptionsMenuItem key={key} onClick={onClick} content={label} />
              ),
          )}
        </Grid>
      </Frame>
    </Stack>
  )
}
