import { useEffect } from 'react'

export function useScrollToTop() {
  useEffect(() => {
    if (!window.location.hash) {
      window.scrollTo({
        behavior: 'auto',
        left: 0,
        top: 0,
      })
    }
  }, [])
}
