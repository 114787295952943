import { Button } from '_BRIGHT_/components'
import type { FC } from 'react'
import React from 'react'
import type { OnClick } from '_BRIGHT_/utils'

type CloseButtonProps = {
  label: string
  onClick: OnClick
}

export const CloseButton: FC<CloseButtonProps> = ({ label, onClick }) => {
  return (
    <Button
      rightIconName="FunctionalClose"
      size="md"
      variant="ghostButton"
      onClick={onClick}
      isFullWidthForMobile={false}
      hasPaddingX={false}
      ariaExpanded={true}
    >
      {label}
    </Button>
  )
}
