import { createBreakpoints } from '@chakra-ui/theme-tools'

const breakpoints = createBreakpoints({
  sm: '37.5em',
  md: '50em',
  lg: '50em',
  xl: '50em',
})

export { breakpoints }
