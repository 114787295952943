import { format, getDaysInMonth } from 'date-fns'

export type DateUnits = Partial<{
  day: number
  month: number
  year: number
}>

const DATE_VALUE = /^(\d{4})-(\d{1,2})-(\d{1,2})$/

export const createDate = (day?: number, month?: number, year?: number): Date | null => {
  if (day && month && year) {
    const date = new Date(year, month - 1, day)
    return !isNaN(date.getTime()) ? date : null
  }

  return null
}

export const isDateFilled = (day?: number, month?: number, year?: number): boolean => {
  return Boolean(day && month && year && String(year).length === 4)
}

export const isDateValid = (day?: number, month?: number, year?: number): boolean => {
  return (
    typeof year === 'number' &&
    typeof month === 'number' &&
    typeof day === 'number' &&
    year >= 1900 &&
    year <= 2100 &&
    month > 0 &&
    month <= 12 &&
    day > 0 &&
    day <= getDaysInMonth(new Date(year, month - 1))
  )
}

export const formatDate = (day?: number, month?: number, year?: number): string => {
  const date = createDate(day, month, year)
  return date ? format(date, 'yyyy-MM-dd') : ''
}

export const parseDate = (value: string): DateUnits => {
  const values = value.match(DATE_VALUE) || []
  const [, year, month, day] = values.map(Number)
  return { day, month, year }
}
