import { CubeKey } from 'lib/cubeJs'
import { CubeTablePivot, AppValue } from '.'
import { formatCubeValueToAppValue } from './cubeValueMap'

export const formatTablePivotToCubeValues = (
  tablePivot: CubeTablePivot,
): AppValue<string>[] =>
  Object.entries(tablePivot).map(([k, v]) =>
    formatCubeValueToAppValue(k as CubeKey, v),
  )
