import React from 'react'
import { withPalette } from '_BRIGHT_/components'
import type { Level } from '_BRIGHT_/components/Typography/common'
import type {
  FunctionalIconSize,
  FunctionalIconVariant,
} from '_BRIGHT_/components/Icons'
import { FunctionalIcon } from '_BRIGHT_/components/Icons'
import type { LinkProps as ChakraLinkProps } from '@chakra-ui/react'
import { Link as ChakraLink, Text } from '@chakra-ui/react'
import type { FC, ReactNode } from 'react'
import { useLinkStyles } from '_BRIGHT_/components/Typography/Link/useLinkStyles'
import { useButtonStyles } from '_BRIGHT_/components/Buttons/BaseButton/useButtonStyles'
import type { ButtonVariant, Size } from '_BRIGHT_/components/Buttons/common'
import { paletteMap as buttonPaletteMap } from '_BRIGHT_/components/Buttons/common'
import { Box } from '@chakra-ui/layout'
import type { PaletteName } from '_BRIGHT_/components/PaletteProvider'
import type { Optional } from '_BRIGHT_/utils'
import { isHashLink } from '_BRIGHT_/utils'
import { Link as RouterLink } from 'react-router-dom'
import type { LinkProps as RouterLinkProps } from 'react-router-dom'
import type { SystemProps } from '@chakra-ui/system'

export type LinkLevel = Extract<Level, '2xs' | 'xs' | 'sm'>
export type LinkVariant = 'primaryLink' | 'negativeLink'
export type LinkIconSize = Extract<FunctionalIconSize, 'xs' | 'sm'>

export const isLinkVariant = (variant: string): boolean =>
  variant.includes('Link')

type ProtoLinkProps = Pick<
  ChakraLinkProps,
  'isExternal' | 'onClick' | 'onFocus' | 'onBlur' | 'download'
> & {
  children?: ReactNode
  level?: LinkLevel
  variant: LinkVariant | ButtonVariant
  leftIcon?: FunctionalIconVariant
  rightIcon?: FunctionalIconVariant
  size?: Size
  isFullWidthForMobile?: boolean
  isFullWidthForDesktop?: boolean
  href: string
  display?: SystemProps['display']
} & Omit<RouterLinkProps, 'to'>

export type LinkProps = Optional<ProtoLinkProps, 'variant'> &
  Pick<ProtoLinkProps, 'href'>

const ProtoLink: FC<ProtoLinkProps> = ({
  children,
  level = 'sm',
  variant,
  leftIcon,
  rightIcon,
  isExternal,
  size = 'lg',
  isFullWidthForMobile = true,
  isFullWidthForDesktop = false,
  href,
  onClick,
  onFocus,
  onBlur,
  download,
  display,
}) => {
  const linkStyles = useLinkStyles(variant, level, display)
  const buttonStyles = useButtonStyles({
    size,
    isFullWidthForMobile,
    isFullWidthForDesktop,
    hasPaddingX: true,
    variant,
  })
  const {
    hoverColor,
    hoverBackgroundColor,
    boxShadow,
    textDecoration,
    themeSize,
    iconSize,
    iconLineHeight,
    activeColor,
    focusBackgroundColor,
    ...restStyles
  } = isLinkVariant(variant) ? linkStyles : buttonStyles
  const isHash = isHashLink(href)

  const as = isExternal || isHash || download ? undefined : RouterLink

  return (
    <ChakraLink
      isExternal={isExternal}
      _focus={{ boxShadow }}
      _active={{ boxShadow }}
      _hover={{ color: hoverColor, bg: hoverBackgroundColor }}
      textDecoration="none"
      href={href}
      to={href}
      as={as}
      onClick={onClick}
      onFocus={onFocus}
      onBlur={onBlur}
      height={themeSize}
      minWidth={themeSize}
      download={download}
      {...restStyles}
    >
      {leftIcon && (
        <Box marginRight="xs" lineHeight="none">
          <FunctionalIcon
            variant={leftIcon}
            size={iconSize}
            lineHeight={iconLineHeight}
          />
        </Box>
      )}

      <Text as="span" textDecoration={textDecoration}>
        {children}
      </Text>

      {rightIcon && (
        <Box marginLeft="xs" lineHeight="none">
          <FunctionalIcon
            variant={rightIcon}
            size={iconSize}
            lineHeight={iconLineHeight}
          />
        </Box>
      )}
    </ChakraLink>
  )
}

const ProtoLinkWithPalette = withPalette<ProtoLinkProps>(ProtoLink)

type WithLinkButtonPalette = <
  P extends { variant: LinkVariant | ButtonVariant },
>(args: {
  linkComponent: FC<P>
  buttonComponent: FC<
    P & {
      palette?: PaletteName
    }
  >
  props: P
}) => JSX.Element

export const withLinkButtonPalette: WithLinkButtonPalette = ({
  linkComponent: LinkComponent,
  buttonComponent: ButtonComponent,
  props,
}) => {
  if (props.variant.includes('Link')) {
    return <LinkComponent {...props} />
  } else {
    const palette = buttonPaletteMap[props.variant as ButtonVariant]

    return <ButtonComponent {...props} palette={palette} />
  }
}

export const Link: FC<LinkProps> = ({ variant = 'primaryLink', ...props }) =>
  withLinkButtonPalette({
    linkComponent: ProtoLink,
    buttonComponent: ProtoLinkWithPalette,
    props: { variant, ...props },
  })
