import React from 'react'
import type { FC } from 'react'
import { Spinner, Frame } from '_BRIGHT_/components'

const PageLoading: FC = () => {
  return (
    <Frame palette="baseTwo" width="full" alignX="center" paddingY="4xl">
      <Spinner size="xl" />
    </Frame>
  )
}

export { PageLoading }
