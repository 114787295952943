import type { FieldConfig } from '_BRIGHT_/components/Genius'
import type { SettingsViewBodyProps } from './SettingsView'

type ReturnedConfig = Pick<
  SettingsViewBodyProps,
  'labelsMap' | 'defaultSettings'
>

export const extractSettingsConfig = (
  fields: Record<string, FieldConfig>,
): ReturnedConfig => {
  const labelsMap: Record<string, string> = {}
  const defaultSettings = Object.values(fields)
    .sort((a, b) => a.settings.position - b.settings.position)
    .map(({ settings, fieldName, label }) => {
      labelsMap[fieldName] = label
      return {
        field: fieldName,
        showInSummary: settings.showInSummary,
      }
    })

  return {
    labelsMap,
    defaultSettings,
  }
}
