import { FunctionalIcon, withPalette } from '_BRIGHT_/components'
import { useButtonStyles } from '_BRIGHT_/components/Buttons/BaseButton/useButtonStyles'
import type {
  OnMouseDown,
  OnMouseUp,
  OnMouseLeave,
  OnTouchEnd,
} from '_BRIGHT_/utils'
import type { FC } from 'react'
import React from 'react'
import { Button as ChakraButton } from '@chakra-ui/react'
import type { FunctionalIconVariant } from '_BRIGHT_/components/Icons'

type Icon = Extract<FunctionalIconVariant, 'FunctionalMinus' | 'FunctionalPlus'>
type NumberInputStepper = {
  icon: Icon
  onMouseDown?: OnMouseDown
  onMouseLeave?: OnMouseLeave
  onMouseUp?: OnMouseUp
  onTouchEnd?: OnTouchEnd
  'data-testid'?: string
}

const BaseNumberInputStepper: FC<NumberInputStepper> = ({
  icon,
  onMouseDown,
  onMouseLeave,
  onMouseUp,
  onTouchEnd,
  'data-testid': dataTestId,
}) => {
  const {
    iconSize,
    borderWidth,
    width,
    paddingX,
    themeSize,
    color,
    backgroundColor,
    hoverBackgroundColor,
    activeColor,
  } = useButtonStyles({
    size: 'lg',
    isFullWidthForMobile: false,
    isFullWidthForDesktop: false,
    hasPaddingX: false,
    variant: 'secondaryButton',
  })

  return (
    <ChakraButton
      border={borderWidth}
      paddingX={paddingX}
      height={themeSize}
      width={width}
      minWidth={themeSize}
      color={color}
      backgroundColor={backgroundColor}
      borderColor={color}
      _hover={{ bg: hoverBackgroundColor }}
      _active={{ bg: activeColor }}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseLeave={onMouseLeave}
      onTouchEnd={onTouchEnd}
      tabIndex={-1}
      aria-hidden={true}
      data-testid={dataTestId}
    >
      <FunctionalIcon variant={icon} size={iconSize} />
    </ChakraButton>
  )
}

const NumberInputStepperWithPalette = withPalette<NumberInputStepper>(
  BaseNumberInputStepper,
)

const NumberInputStepper: FC<NumberInputStepper> = props => {
  return <NumberInputStepperWithPalette {...props} palette="highlightOutline" />
}
export { NumberInputStepper }
