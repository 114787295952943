import {
  getFilterTags,
  getSortTag,
} from '_BRIGHT_/components/Genius/components/AppliedActionTags/utils'
import type { FilterValue } from '_BRIGHT_/components/Genius/hooks'
import {
  dispatchRemoveFilter,
  dispatchResetSort,
  useGeniusConfigContext,
  useGeniusStateContext,
} from '_BRIGHT_/components/Genius/hooks'
import type { FieldConfig } from '_BRIGHT_/components/Genius/types'
import { isEmpty } from 'ramda'
import type { FC } from 'react'
import React from 'react'
import { AppliedActionTagsUI } from './AppliedActionTagsUI'

export type AppliedActionTagsContent = {
  fieldName: FieldConfig['fieldName']
  value: FilterValue['value']
  operator?: FilterValue['value']
  actionType: 'sort' | 'filter'
}

export const AppliedActionTags: FC = () => {
  const { body } = useGeniusConfigContext()
  const { dispatch, filters, sort } = useGeniusStateContext()
  const filterTags = getFilterTags(filters)
  const sortTag = getSortTag(sort)
  const tagItems = [...filterTags, ...sortTag]

  if (isEmpty(tagItems)) {
    return null
  }

  const onClear = ({ actionType, fieldName }: AppliedActionTagsContent) => {
    if (actionType === 'filter') {
      dispatchRemoveFilter(dispatch, fieldName)
    } else {
      dispatchResetSort(dispatch)
    }
  }

  return (
    <AppliedActionTagsUI
      appliedActionTagList={tagItems}
      onClear={onClear}
      clearButtonLabel={body.appliedActionTags.clearButtonLabel}
    />
  )
}
