import { useTheme } from '@chakra-ui/react'
import { useColor, PaletteContext } from '_BRIGHT_/components'
import { useContext } from 'react'

export type ShadowName =
  | 'none'
  | 'sm'
  | 'md'
  | 'focus'
  | 'invalid'
  | 'link'
  | 'inset-sm'
type UseShadow = (shadow: ShadowVariants) => string
type ShadowVariants = Extract<
  ShadowName,
  'focus' | 'invalid' | 'link' | 'inset-sm' | 'md'
>

export const useShadow: UseShadow = (shadow: ShadowVariants) => {
  const theme = useTheme()
  const { parentPalette } = useContext(PaletteContext)
  const isShadowInvalid = shadow === 'invalid'
  const primaryColor = isShadowInvalid ? 'core' : 'focus'
  const palette = isShadowInvalid ? 'negativePrimary' : parentPalette
  const color = `${theme.shadows[shadow]} ${useColor(primaryColor, palette)}`

  return ['focus', 'invalid', 'link', 'inset-sm'].includes(shadow)
    ? color
    : theme.shadows[shadow]
}
