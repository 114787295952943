import type { FC } from 'react'
import React from 'react'
import type { HiFiIconPathProps } from '_BRIGHT_/components/Icons'

const Mix: FC<HiFiIconPathProps> = ({
  primaryColor,
  secondaryColor,
  baseColor,
  strokeWidth,
  hasShadow,
}) => {
  return (
    <>
      {hasShadow && (
        <path
          opacity="0.2"
          d="M30.7825 13.5494C30.0807 12.8498 29.1176 12.2572 28.0002 11.7715C27.9733 11.7554 27.941 11.7367 27.903 11.7155C27.7977 11.6569 27.6492 11.5798 27.4535 11.4908C27.0619 11.3128 26.4825 11.0877 25.683 10.8666C24.084 10.4245 21.6089 10 18 10C14.3734 10 10.992 10.5947 8.48128 11.5896C7.22889 12.0859 6.14364 12.7012 5.35489 13.4342C4.56543 14.1679 4 15.0938 4 16.1765V17.9059C4 25.2019 10.3338 31 18 31C25.6662 31 32 25.2019 32 17.9059V16.1765C32 15.1551 31.5024 14.267 30.7825 13.5494Z"
          fill={primaryColor}
        />
      )}
      <path
        d="M24.5333 9.60113C26.685 10.49 28 11.6878 28 13C28 15.7611 22.1795 18 15 18C7.82053 18 2 15.7611 2 13C2 10.5005 6.76998 8.4289 13 8.05884"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 13V14.75C2 21.5167 7.82053 27 15 27C22.1795 27 28 21.5167 28 14.75V13"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.0761 1.99482L20.1904 9.00122"
        stroke={secondaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse
        cx="17.9813"
        cy="11.8475"
        rx="3.63002"
        ry="2.92948"
        transform="rotate(-45 17.9813 11.8475)"
        fill={baseColor}
        stroke={secondaryColor}
        strokeWidth={strokeWidth}
      />
    </>
  )
}

export { Mix }
