import { FC } from 'react'
import { useNavigate } from 'react-router'
import { GeniusListMetadataButton } from '_BRIGHT_/components'
import { FullMetadata } from '_BRIGHT_/components/Genius/geniusDataMungers'
import { ButtonsProps, ListProps } from './types'

type ButtonMunger<T> = (metaData: FullMetadata<T>) => ButtonsProps

export const buttonsPropsMunger: ButtonMunger<ButtonsProps> = ({
  companyId,
}) => ({
  companyId,
})

export const ViewButton: FC<ButtonsProps> = ({ companyId }) => {
  let navigate = useNavigate()

  const handleClick = () => {
    navigate(`/analytics/clients/${companyId}`)
  }

  return (
    <GeniusListMetadataButton variant="secondaryButton" onClick={handleClick}>
      View Analytics
    </GeniusListMetadataButton>
  )
}

export const buttonsRendererList: ListProps['buttonsRendererList'] = [
  ViewButton,
]
