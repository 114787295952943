import React, { useRef, useEffect, useCallback } from 'react'
import type { FC, LegacyRef, RefObject } from 'react'
import gsap from 'gsap'
import { useColor } from '_BRIGHT_/components'
import { blendEases } from './utils/blendEases'
import type { Icon } from './types'

export const Success: FC<Icon> = ({ size, autoPlay }) => {
  const color = useColor('core', 'positivePrimary')
  const strokeWidth = 16
  const tick = useRef()
  const tickSVG = useRef()
  const whole = useRef()
  const bg = useRef()

  const playAnimation = useCallback(() => {
    const dropEase = blendEases('power4.in', 'back(1.2)')
    const drawEase = blendEases('sine.in', 'expo')
    const mainTl = gsap.timeline({ defaults: { duration: autoPlay ? 1 : 0 } })

    gsap.set(bg.current as unknown as gsap.TweenTarget, {
      transformOrigin: '50% 50%',
      rotation: -90,
    })

    mainTl
      .fromTo(
        bg.current as unknown as gsap.TweenTarget,
        { drawSVG: '-50% -50%' },
        { drawSVG: '0% 100%', ease: drawEase },
      )
      .from(
        bg.current as unknown as gsap.TweenTarget,
        { fillOpacity: 0, ease: 'expo.in' },
        0,
      )
      .add('drop', '-=0.28')
      .from(
        tick.current as unknown as gsap.TweenTarget,
        { opacity: 0, duration: 0.01 },
        'drop',
      )
      .from(
        tick.current as unknown as gsap.TweenTarget,
        { y: -160, duration: 0.6, ease: 'elastic(0.73, 0.6)' },
        'drop',
      )
      .fromTo(
        tick.current as unknown as gsap.TweenTarget,
        { drawSVG: '51% 51%' },
        { drawSVG: '27% 86%', duration: 0.5, ease: dropEase },
        'drop+=0.1',
      )
  }, [autoPlay])

  useEffect(() => {
    gsap.set(tickSVG.current as unknown as gsap.TweenTarget, {
      visibility: 'visible',
    })
    playAnimation()
  }, [playAnimation])

  return (
    <svg
      viewBox="0 0 300 300"
      ref={tickSVG as unknown as LegacyRef<SVGSVGElement>}
      onClick={playAnimation}
      width={size}
      height={size}
    >
      <g ref={whole as unknown as LegacyRef<SVGSVGElement>}>
        <circle
          ref={bg as unknown as LegacyRef<SVGCircleElement>}
          cx={150}
          cy={150}
          r={125}
          fill={color}
          fillOpacity={0.1}
          stroke={color}
          strokeOpacity={0.4}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          strokeWidth={strokeWidth}
        />
        <polyline
          ref={tick as unknown as RefObject<SVGPolylineElement>}
          points="33.61 104.79 142.03 197.92 250.1 74.28"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={strokeWidth}
        />
      </g>
    </svg>
  )
}
