export type ResponsiveStyle<S> = S | S[] | Record<string, S>

export const mapResponsiveStyle = <P, U>(
  callbackFn: (value: P) => U,
  responsiveProp: ResponsiveStyle<P>
): ResponsiveStyle<U> => {
  if (Array.isArray(responsiveProp)) {
    return responsiveProp.map(callbackFn)
  }

  if (typeof responsiveProp === 'object') {
    return Object.entries(responsiveProp).reduce((acc, [key, value]) => {
      acc[key] = callbackFn(value)
      return acc
    }, {} as Record<string, U>)
  }

  return callbackFn(responsiveProp)
}
