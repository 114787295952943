import { Stack, useColor } from '_BRIGHT_'
import { useScrollToTop } from 'lib/hooks'
import { Skeleton } from '@chakra-ui/react'
import { useFindCompany, usePageHeadingData } from './hooks'
import { analyticsSummaryQuery } from './queries/analyticsSummaryQuery'
import { CenteredBlock } from 'components/CenteredBlock'
import { Overview } from 'components/Overview'
import { PageHeadingBlock } from 'components/PageHeadingBlock'
import { JourneyCardGrid } from 'components/JourneyCardGrid'
import { CompanyGuard } from 'components/CompanyGuard'

const Heading = () => {
  useScrollToTop()
  const { name, date } = usePageHeadingData()

  const endColor = useColor('contrast', 'baseTwo')

  return (
    <PageHeadingBlock
      pageHeading={name ?? ''}
      subheading={
        <Skeleton isLoaded={!!date} maxW="300px" endColor={endColor}>
          {`Analytics as of ${date}.`}
        </Skeleton>
      }
    />
  )
}

export const AnalyticsPage = () => {
  const { companyId } = useFindCompany()

  return (
    <CompanyGuard>
      <Heading />
      <CenteredBlock>
        <Stack space="4xl">
          <Overview query={analyticsSummaryQuery([companyId])} />
          <JourneyCardGrid />
        </Stack>
      </CenteredBlock>
    </CompanyGuard>
  )
}
