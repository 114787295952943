import type { AlignX, AlignY, FlexAlign, ResponsiveSpace, Space, Width } from './types'

export const alignXMap: Record<AlignX, FlexAlign> = {
  stretch: 'stretch',
  start: 'flex-start',
  center: 'center',
  end: 'flex-end',
}

export const alignYMap: Record<AlignY, FlexAlign> = {
  top: 'flex-start',
  center: 'center',
  bottom: 'flex-end',
}

export const spaceMap: Record<Space, ResponsiveSpace> = {
  '-xl': '-xl',
  '-2xl': '-2xl',
  none: 'none',
  '2xs': '2xs',
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  lg: { base: 'md', sm: 'lg' },
  xl: { base: 'lg', sm: 'xl' },
  '2xl': { base: 'xl', sm: '2xl' },
  '3xl': { base: '2xl', sm: '3xl' },
  '4xl': { base: '3xl', sm: '4xl' },
  '5xl': { base: '4xl', sm: '5xl' },
  '6xl': { base: '5xl', sm: '6xl' },
  '7xl': { base: '6xl', sm: '7xl' },
}

export const widthsMap = {
  '1/4': '25%',
  '1/3': `${100 / 3}%`,
  '1/2': '50%',
  '2/3': `${200 / 3}%`,
  '3/4': '75%',
  full: '100%',
  fluid: '0',
  content: 'auto',
}

export const getAlignItems = (alignY: AlignY): FlexAlign => alignYMap[alignY]
export const getJustifyContent = (alignX: AlignX): FlexAlign => alignXMap[alignX]
export const getWidth = (width: Width): string => widthsMap[width]
export const getFlexGrow = (width: Width): 1 | 0 => (width === 'fluid' ? 1 : 0)
