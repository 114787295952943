import { Text } from '@chakra-ui/react'
import * as emailValidator from 'email-validator'
import { ListProps } from '../SelectClientGenius/renderers/types'
import { useWip } from 'lib/hooks'
import { TOOLTIP_TEXT } from 'lib/utils'
import { Columns, Column, GeniusListText } from '_BRIGHT_'
import { colors } from '_BRIGHT_/theme/default/colors'
import { AppTooltip } from 'components/Tooltip'

export const MetadataItemRenderer: ListProps['MetadataItemRenderer'] = ({
  label,
  value,
}) => {
  label = label.toString()
  value = value ? value?.toString() : ''

  const { canSeeWip } = useWip()
  const highlight = useHighlightValue(label, value)
  const tooltip = tooltipFromValue(label)

  // removes the "invalid--" tag added in the genius metadataListMunger
  value = value.replace('invalid--', '')

  return (
    <Columns alignY="center" space="sm">
      <Column width="1/2">
        <GeniusListText textStyle="light" level="xs">
          {label}
          {canSeeWip && tooltip && <AppTooltip tooltip={tooltip} />}
        </GeniusListText>
      </Column>
      <Column width="1/2">
        <GeniusListText textStyle="regular" level="xs">
          <Text {...highlight}>{value}</Text>
        </GeniusListText>
      </Column>
    </Columns>
  )
}

const tooltipFromValue = (label: string): string | undefined => {
  switch (label) {
    case 'AUM':
      return TOOLTIP_TEXT.AUM
    case 'Data quality':
      return TOOLTIP_TEXT.dataQuality
    case 'Take-up rate':
      return TOOLTIP_TEXT.takeupRate
    case 'Scheme Start Date':
      return TOOLTIP_TEXT.schemeStartDate
    case 'Late contributions':
      return TOOLTIP_TEXT.totalMembersWithMissingContributions
    case 'Missing NI numbers':
      return TOOLTIP_TEXT.sacMissingNINumbers
    case 'Invalid home addresses':
      return TOOLTIP_TEXT.sacInvalidHomeAddress
    case 'Missing email addresses':
      return TOOLTIP_TEXT.sacMissingEmailAddresses
    default:
      return undefined
  }
}

export const useHighlightValue = (label: string, value: string) => {
  const numericValue = Number(value)
  const backgroundColor = colors.warningSecondary.baseOne.core
  let shouldHighlight = false

  if (!['Group', 'Exit reason', 'Exit date'].includes(label)) {
    shouldHighlight =
      numericValue < 0 ||
      value === 'Never' ||
      value === '-' ||
      value === 'Null' ||
      /missing/gi.test(value) ||
      /unknown/gi.test(value) ||
      /invalid--/gi.test(value) ||
      (label === 'Email address' && !emailValidator.validate(value)) ||
      (label.includes('Late') && numericValue > 0) ||
      (label.includes('Invalid') && numericValue > 0) ||
      (label === 'Gone away' && value === 'Yes') ||
      (label.includes('Missing') && numericValue > 0)
  }

  return {
    ...(shouldHighlight && {
      p: '0 4px',
      borderRadius: '2px',
      backgroundColor,
    }),
  }
}
