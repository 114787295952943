export type PaginationOptions = {
  /** Total number of results in data set */
  totalResults: number
  /** The currently selected page */
  currentPage: number
  /** Number of results to show per page */
  resultsPerPage: number
  /** Maximum number of results to show per page */
  limit: number
}

export type PaginationData = PaginationOptions & {
  fromResult: number
  toResult: number
  totalPages: number
  nextPage: number | null
  previousPage: number | null
}

export function getPagination({ totalResults, currentPage, resultsPerPage, limit }: PaginationOptions): PaginationData {
  const fromResult = (currentPage - 1) * limit + 1
  const toResult = Math.min(totalResults, fromResult + resultsPerPage - 1)
  const totalPages = limit ? Math.ceil(totalResults / limit) : 0
  const nextPage = currentPage < totalPages ? currentPage + 1 : null
  const previousPage = currentPage > 1 ? currentPage - 1 : null

  return {
    totalResults,
    currentPage,
    resultsPerPage,
    fromResult,
    toResult,
    totalPages,
    nextPage,
    previousPage,
    limit,
  }
}
