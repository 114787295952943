import { Column, Columns, GeniusListText } from '_BRIGHT_/components'
import { SummaryFullProps } from '_BRIGHT_/components'
import { ListProps, SummaryProps } from './types'
import { FullMetadata } from '_BRIGHT_/components/Genius/geniusDataMungers'

type SummaryMunger<T> = (
  metaData: FullMetadata<T>,
) => SummaryFullProps<SummaryProps>

export const summaryPropsMunger: SummaryMunger<SummaryProps> = ({
  companyId,
  companyName: primaryLabel, // props needs to be renamed to primaryLabel according to SummaryMunger
}) => ({
  companyId,
  primaryLabel: String(primaryLabel),
})

export const SummaryRenderer: ListProps['SummaryRenderer'] = ({
  companyId,
  primaryLabel,
}) => (
  <Columns alignY="center" space="sm">
    <Column width="1/4">
      <GeniusListText
        textStyle="light"
        level="sm"
        mask="00-AAA-0000"
        isTruncated={false}
      >
        {companyId}
      </GeniusListText>
    </Column>
    <Column width="3/4" alignX="start">
      <GeniusListText textStyle="regular" level="sm" isTruncated={false}>
        {primaryLabel}
      </GeniusListText>
    </Column>
  </Columns>
)
