import React from 'react'
import type { FC } from 'react'
import { GeniusListText } from '_BRIGHT_/components'
import type { GeniusItemsCounterProps } from '_BRIGHT_/components/Genius'

export const ItemsCounter: FC<GeniusItemsCounterProps> = ({
  count,
  singularLabel,
  pluralLabel,
}) => {
  const message = `${count} ${count > 1 ? pluralLabel : singularLabel}`

  return (
    <GeniusListText textStyle="light" level="xs">
      {message}
    </GeniusListText>
  )
}
