import { FC } from 'react'
import { Text, Link, Stack, PageHeadingBlock } from '_BRIGHT_'
import { CenteredBlock } from 'components/CenteredBlock'
import { chakra } from '@chakra-ui/react'

export const DisclaimerPage: FC = () => {
  return (
    <>
      <PageHeadingBlock
        backLink="/"
        backLinkText="Back"
        pageHeading="Smart Analytics software disclaimer"
      />
      <CenteredBlock>
        <Stack space="xs">
          <Text>
            <chakra.span>
              Smart Analytics incorporates third party software and services,
              including certain OSS (“
            </chakra.span>
            <chakra.span fontWeight="bold">Open Source Software</chakra.span>
            <chakra.span>
              ”) components. The key OSS components used to provide Smart
              Analytics are: Cube.js and Recharts, which are governed by Apache
              License, Version 2.0 and MIT licensing - further information on
              these components and any others are available upon request. All
              OSS is provided on an “as is” basis without any warranties or
              conditions of any kind.
            </chakra.span>
          </Text>
          <Text>
            <chakra.span>
              Any personal data will be processed by the Smart Analytics
              solution in accordance with the Smart Pension{' '}
            </chakra.span>
            <Link href="https://www.smart.co/footer/privacy-policy" isExternal>
              Privacy Policy
            </Link>
            {'.'}
          </Text>
        </Stack>
      </CenteredBlock>
    </>
  )
}
