import { chakra, Text as ChakraText, HStack } from '@chakra-ui/react'
import { useOption } from 'react-aria'
import type { FC } from 'react'
import React, { useRef, useContext, useMemo } from 'react'
import type { Option } from '_BRIGHT_/components/Forms/common'
import type { OptionListItemProps } from '_BRIGHT_/components/Forms/OptionList'
import * as R from 'ramda'
import { useColor } from '_BRIGHT_/components'

type OptionStates = 'initial' | 'hover' | 'selected'
type StyleObjects = 'bgStyles' | 'labelStyles' | 'descriptionStyles'
type ListDynamicStyle = { optionState: OptionStates; boxShadow: string }

type PhoneItemStyles = Record<
  StyleObjects,
  Partial<Record<OptionStates, string>>
>

export const PhoneItemContext = React.createContext<PhoneItemStyles>({
  bgStyles: {},
  labelStyles: {},
  descriptionStyles: {},
})

export const usePhoneItemStyles = (): PhoneItemStyles => {
  const context = useContext(PhoneItemContext)

  if (!context)
    throw new Error(
      'usePhoneItemStyles must be used within PhoneItemContext.Provider',
    )

  return context
}

export const getItemDynamicStyle = (
  isSelected: boolean,
  isFocused: boolean,
  focusColor: string,
): ListDynamicStyle =>
  R.cond([
    [
      R.always(isSelected),
      R.always({ optionState: 'selected', boxShadow: 'none' }),
    ],
    [
      R.always(isFocused),
      R.always({
        optionState: 'hover',
        boxShadow: `4px 0 0 inset ${focusColor}`,
      }),
    ],
    [R.T, R.always({ optionState: 'initial', boxShadow: 'none' })],
  ])()

export const PhoneListItem: FC<OptionListItemProps<Option>> = ({
  item,
  state,
}) => {
  const {
    key,
    value: { label, icon, description },
  } = item

  const isSelected = state.selectionManager.isSelected(item.key)
  const isFocused = state.selectionManager.focusedKey === item.key

  const { bgStyles, labelStyles, descriptionStyles } = usePhoneItemStyles()

  const focusColor = useColor('focus', 'baseOne')

  const optionRef = useRef(null)

  const { optionProps } = useOption(
    {
      key,
      isSelected,
      shouldFocusOnHover: true,
    },
    state,
    optionRef,
  )

  const {
    optionState,
    boxShadow,
  }: { optionState: OptionStates; boxShadow: string } = useMemo(
    () => getItemDynamicStyle(isSelected, isFocused, focusColor),
    [isSelected, isFocused, focusColor],
  )

  return (
    <chakra.li
      {...optionProps}
      ref={optionRef}
      onClick={() => state.selectionManager.select(key)}
      py="sm"
      px="md"
      outline="none"
      background={bgStyles[optionState]}
      boxShadow={boxShadow}
      _hover={{ boxShadow: 'none' }}
    >
      <HStack spacing="md" align="start" width="100%">
        {icon && <p>{icon}</p>}
        <HStack align="start">
          <ChakraText color={labelStyles[optionState]}>{label}</ChakraText>
          <ChakraText color={descriptionStyles[optionState]}>
            {description}
          </ChakraText>
        </HStack>
      </HStack>
    </chakra.li>
  )
}
