import type { FC } from 'react'
import React from 'react'
import type { HiFiIconPathProps } from '_BRIGHT_/components/Icons'

const Clients: FC<HiFiIconPathProps> = ({
  primaryColor,
  secondaryColor,
  baseColor,
  strokeWidth,
  hasShadow,
}) => {
  return (
    <>
      {hasShadow && (
        <path
          opacity="0.2"
          d="M7.55556 7H16.4444C16.8769 7 17.2787 7.18292 17.5652 7.48704C17.8497 7.78913 18 8.18675 18 8.58974V18H20.5C21.0523 18 21.5 18.4477 21.5 19V31C21.5 31.5523 21.0523 32 20.5 32H7.55556C7.12308 32 6.72131 31.8171 6.43483 31.513C6.15025 31.2109 6 30.8133 6 30.4103V8.58974C6 8.18675 6.15025 7.78913 6.43483 7.48704C6.72131 7.18293 7.12307 7 7.55556 7Z"
          fill={primaryColor}
        />
      )}
      <path
        d="M7 16.6H11"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 20.0667H11"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 23.5333H11"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 9.66675H11"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 13.1333H11"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.4444 5H4.55556C4.40821 5 4.26691 5.06213 4.16272 5.17273C4.05853 5.28333 4 5.43333 4 5.58974V27.4103C4 27.5667 4.05853 27.7167 4.16272 27.8273C4.26691 27.9379 4.40821 28 4.55556 28H14V15.5V5.58974C14 5.43333 13.9415 5.28333 13.8373 5.17273C13.7331 5.06213 13.5918 5 13.4444 5Z"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 16H18V28H14V16Z"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 2V5"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.7333 10H23.2667C23.1959 10 23.1281 10.0192 23.0781 10.0533C23.0281 10.0874 23 10.1336 23 10.1818V13H21.5333C21.3919 13 21.2562 13.0479 21.1562 13.1331C21.0562 13.2184 21 13.334 21 13.4545V28H28.4667C28.6081 28 28.7438 27.9521 28.8438 27.8669C28.9438 27.7816 29 27.666 29 27.5455V13.4545C29 13.334 28.9438 13.2184 28.8438 13.1331C28.7438 13.0479 28.6081 13 28.4667 13H27V10.1818C27 10.1336 26.9719 10.0874 26.9219 10.0533C26.8719 10.0192 26.8041 10 26.7333 10Z"
        fill={baseColor}
        stroke={secondaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25 7V10"
        stroke={secondaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24 17H26"
        stroke={secondaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24 20H26"
        stroke={secondaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24 23H26"
        stroke={secondaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  )
}

export { Clients }
