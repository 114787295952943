import { Grid } from '@chakra-ui/react'
import { JourneyCard } from '../JourneyCard/JourneyCard'
import { useLocation } from 'react-router-dom'
import {
  maintenanceQuery,
  investmentsQuery,
  memberInsightsQuery,
  contributionsQuery,
} from './journeyCardQueries'
import { removeTrailingSlash } from 'lib/utils'

export const JourneyCardGrid = () => {
  const { pathname } = useLocation()

  return (
    <div>
      <Grid templateColumns={{ md: 'repeat(2, 1fr)' }} gap={6} width="100%">
        <JourneyCard
          path={`${removeTrailingSlash(pathname)}/maintenance`}
          icon="Todo"
          title="Maintenance"
          query={maintenanceQuery}
        />
        <JourneyCard
          icon="Proportions"
          title="Investments"
          query={investmentsQuery}
        />
        <JourneyCard
          icon="Users"
          title="Member insights"
          query={memberInsightsQuery}
        />
        <JourneyCard
          icon="Money"
          title="Contributions"
          query={contributionsQuery}
        />
      </Grid>
    </div>
  )
}
