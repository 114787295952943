import { GeniusStateActionType } from '_BRIGHT_/components/Genius/types'
import type {
  Action,
  DeselectAllItemsAction,
  GeniusListViewDataItem,
  ResetSettingsAction,
  SelectMultipleItemsAction,
  SetSettingsAction,
  SetViewTypeAction,
  ToggleSelectedItemAction,
} from '_BRIGHT_/components/Genius'
import type { SortDirection as SortDirectionGQL } from '_BRIGHT_/generated/generated-bright-components'

export type FieldName = string
export type FilterItemValue = string | number | boolean

export type FilterValueWithoutOperator = { value: FilterItemValue }
export type FilterValueWithOperator = FilterValueWithoutOperator & {
  operator?: string
}
export type FilterValue = FilterValueWithOperator | FilterValueWithoutOperator
export type FilterItem = { field: FieldName; filterValue: FilterValue }
export type FiltersMap = Record<FieldName, FilterValue>

export type SortDirection = SortDirectionGQL
export type Sort = { field: FieldName; direction: SortDirection }

export type SettingsItem = {
  field: FieldName
  showInSummary: boolean
}
export type Settings = SettingsItem[]

export type DataSetID = GeniusListViewDataItem<unknown, unknown>['id']

export type SelectedItems = Record<DataSetID, boolean>

export type GeniusViewType = 'list' | 'table'

export type GeniusReducerState = {
  settings: Settings | null
  selectedItems: SelectedItems
  viewType: GeniusViewType
}

export const TABLE_ID: GeniusViewType = 'table'
export const LIST_ID: GeniusViewType = 'list'

export const INIT_SELECTED_ITEMS = {}

export const INIT_VIEW_TYPE: GeniusViewType = 'list'

export const GENIUS_INIT_STATE: GeniusReducerState = {
  settings: null,
  selectedItems: INIT_SELECTED_ITEMS,
  viewType: INIT_VIEW_TYPE,
}

type ActionHandler<A> = (
  state: GeniusReducerState,
  action: A,
) => GeniusReducerState

const setSettingsActionHandler: ActionHandler<SetSettingsAction> = (
  state: GeniusReducerState,
  action: SetSettingsAction,
) => ({
  ...state,
  settings: action.data,
})

const resetSettingsActionHandler: ActionHandler<ResetSettingsAction> = (
  state: GeniusReducerState,
) => ({
  ...state,
  settings: null,
})

const toggleSelectedItemActionHandler: ActionHandler<ToggleSelectedItemAction> =
  (state, { data: { id } }) => ({
    ...state,
    selectedItems: {
      ...state.selectedItems,
      [id]: !state.selectedItems[id],
    },
  })

const selectMultipleItemsActionHandler: ActionHandler<SelectMultipleItemsAction> =
  (state, { data: { ids } }) => ({
    ...state,
    selectedItems: {
      ...state.selectedItems,
      ...ids.reduce(
        (acc: GeniusReducerState['selectedItems'], curr: DataSetID) => {
          acc[curr] = true
          return acc
        },
        {},
      ),
    },
  })

const deselectAllItemsActionHandler: ActionHandler<DeselectAllItemsAction> =
  state => ({
    ...state,
    selectedItems: INIT_SELECTED_ITEMS,
  })

const setViewTypeActionHandler: ActionHandler<SetViewTypeAction> = (
  state,
  { data },
) => ({
  ...state,
  viewType: data,
})

const handlers: Partial<Record<GeniusStateActionType, ActionHandler<Action>>> =
  {
    [GeniusStateActionType.setSettings]:
      setSettingsActionHandler as ActionHandler<Action>,
    [GeniusStateActionType.resetSettings]:
      resetSettingsActionHandler as ActionHandler<Action>,
    [GeniusStateActionType.toggleSelectedItem]:
      toggleSelectedItemActionHandler as ActionHandler<Action>,
    [GeniusStateActionType.selectMultipleItems]:
      selectMultipleItemsActionHandler as ActionHandler<Action>,
    [GeniusStateActionType.deselectAllItems]:
      deselectAllItemsActionHandler as ActionHandler<Action>,
    [GeniusStateActionType.setViewType]:
      setViewTypeActionHandler as ActionHandler<Action>,
  }

export type GeniusStateReducer = (
  state: GeniusReducerState,
  action: Action,
) => GeniusReducerState
export const geniusStateReducer: GeniusStateReducer = (
  state = GENIUS_INIT_STATE,
  action,
) => {
  const handler = handlers[action.type]
  if (handler) {
    return handler(state, action)
  }

  return state
}
