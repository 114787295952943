import type {
  ButtonIconSize,
  ButtonTextLevel,
  ButtonThemeSize,
  PaddingX,
  Palette,
  ResponsiveSize,
  Size,
  ButtonVariant,
} from './types'

export const paletteMap: Record<ButtonVariant, Palette> = {
  primaryButton: 'highlight',
  secondaryButton: 'highlightOutline',
  ghostButton: 'highlightOutline',
  negativeButton: 'negativePrimary',
  negativeOutlineButton: 'negativeOutline',
}

export const responsiveSizeMap: Record<Size, ResponsiveSize> = {
  sm: 'sm',
  md: 'md',
  lg: { base: 'md', sm: 'lg' },
}

export const iconSizeMap: Record<Size, ButtonIconSize> = {
  sm: 'xs',
  md: 'sm',
  lg: 'sm',
}

export const textLevelMap: Record<Size, ButtonTextLevel> = {
  sm: 'xs',
  md: 'sm',
  lg: 'sm',
}

export const spinnerSizeMap: Record<Size, Extract<Size, 'sm' | 'md'>> = {
  sm: 'sm',
  md: 'md',
  lg: 'md',
}

const themeSizeMap: Record<Size, ButtonThemeSize> = {
  sm: 'button-sm',
  md: 'button-md',
  lg: 'button-lg',
}

const paddingXMap: Record<Size, PaddingX> = {
  sm: 'sm',
  md: 'md',
  lg: 'xl',
}

export const getThemeSize = (size: Size): ButtonThemeSize => themeSizeMap[size]
export const getPaddingX = (size: Size): PaddingX => paddingXMap[size]
