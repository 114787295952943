import type { Dispatch, ReducerAction } from 'react'
import { useCallback, useEffect, useMemo, useReducer } from 'react'
import { GeniusStateActionType } from '_BRIGHT_/components/Genius'
import type { Action, GeniusConfig } from '_BRIGHT_/components/Genius'
import {
  getGeniusStatePersistedValues,
  setGeniusStatePersistedValues,
} from './utils/persistedState'
import {
  geniusStateReducer,
  INIT_SELECTED_ITEMS,
  INIT_VIEW_TYPE,
} from './useGeniusStateReducer'
import type {
  GeniusReducerState,
  GeniusStateReducer,
  FieldName,
  FiltersMap,
  FilterValue,
  SortDirection,
  Settings,
  Sort,
  DataSetID,
  GeniusViewType,
} from './useGeniusStateReducer'
import { useGeniusUrlState } from './useGeniusUrlState'

export type GeniusState = GeniusReducerState & {
  filters: FiltersMap | null
  sort: Sort | null
}
export type UseGeniusStateReturnedValue = GeniusState & {
  dispatch: Dispatch<Action>
}

type UseGeniusState = (
  id: string,
  config: GeniusConfig,
) => UseGeniusStateReturnedValue

export const useGeniusState: UseGeniusState = (id, config) => {
  const { urlState, setSort, addFilter, removeFilter, resetSort } =
    useGeniusUrlState(id, config.fields)

  const hookKey = `genius-state-${id}`
  const initState = {
    ...getGeniusStatePersistedValues(hookKey, config.fields),
    filters: urlState.filters ?? null,
    sort: urlState.sort ?? null,
    selectedItems: INIT_SELECTED_ITEMS,
    viewType: INIT_VIEW_TYPE,
  }

  const [state, rawDispatch] = useReducer<GeniusStateReducer>(
    geniusStateReducer,
    initState,
  )

  const dispatch = useCallback(
    (action: Action) => {
      switch (action.type) {
        case GeniusStateActionType.addFilter:
          addFilter(action.data)
          return
        case GeniusStateActionType.removeFilter:
          removeFilter(action.data.field)
          return
        case GeniusStateActionType.setSort:
          setSort(action.data)
          return
        case GeniusStateActionType.resetSort:
          resetSort()
          return
        default:
          rawDispatch(action)
      }
    },
    [addFilter, removeFilter, setSort, resetSort],
  )

  const geniusState = useMemo(
    () => ({ ...state, ...urlState }),
    [state, urlState],
  )

  useEffect(() => {
    setGeniusStatePersistedValues(geniusState, hookKey)
  }, [geniusState, hookKey])

  return useMemo(() => ({ ...geniusState, dispatch }), [geniusState, dispatch])
}

export const dispatchToggleSelectedItem = (
  dispatch: Dispatch<ReducerAction<GeniusStateReducer>>,
  id: DataSetID,
): void =>
  dispatch({
    type: GeniusStateActionType.toggleSelectedItem,
    data: { id },
  })

export const dispatchSelectMultipleItems = (
  dispatch: Dispatch<ReducerAction<GeniusStateReducer>>,
  ids: DataSetID[],
): void =>
  dispatch({
    type: GeniusStateActionType.selectMultipleItems,
    data: { ids },
  })

export const dispatchDeselectAllItems = (
  dispatch: Dispatch<ReducerAction<GeniusStateReducer>>,
): void =>
  dispatch({
    type: GeniusStateActionType.deselectAllItems,
  })

export const dispatchAddFilter = (
  dispatch: Dispatch<ReducerAction<GeniusStateReducer>>,
  field: FieldName,
  filterValue: FilterValue,
): void =>
  dispatch({
    type: GeniusStateActionType.addFilter,
    data: {
      field,
      filterValue,
    },
  })

export const dispatchRemoveFilter = (
  dispatch: Dispatch<ReducerAction<GeniusStateReducer>>,
  field: FieldName,
): void =>
  dispatch({
    type: GeniusStateActionType.removeFilter,
    data: {
      field,
    },
  })

export const dispatchSetSort = (
  dispatch: Dispatch<ReducerAction<GeniusStateReducer>>,
  field: FieldName,
  direction: SortDirection,
): void =>
  dispatch({
    type: GeniusStateActionType.setSort,
    data: {
      field,
      direction,
    },
  })

export const dispatchResetSort = (
  dispatch: Dispatch<ReducerAction<GeniusStateReducer>>,
): void =>
  dispatch({
    type: GeniusStateActionType.resetSort,
  })

export const dispatchSetSettings = (
  dispatch: Dispatch<ReducerAction<GeniusStateReducer>>,
  data: Settings,
): void =>
  dispatch({
    type: GeniusStateActionType.setSettings,
    data,
  })

export const dispatchResetSettings = (
  dispatch: Dispatch<ReducerAction<GeniusStateReducer>>,
): void =>
  dispatch({
    type: GeniusStateActionType.resetSettings,
  })

export const dispatchSetViewType = (
  dispatch: Dispatch<ReducerAction<GeniusStateReducer>>,
  data: GeniusViewType,
): void =>
  dispatch({
    type: GeniusStateActionType.setViewType,
    data,
  })
