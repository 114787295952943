import { Button, Stack } from '_BRIGHT_/components'
import { withController } from '_BRIGHT_/components/Forms'
import { ActionDrawerButtonsWrapper } from '_BRIGHT_/components/Genius/components'
import {
  useGeniusStateContext,
  dispatchAddFilter,
  dispatchRemoveFilter,
  useDrawerContext,
} from '_BRIGHT_/components/Genius/hooks'
import type { FilterValueWithoutOperator } from '_BRIGHT_/components/Genius/hooks'
import React from 'react'
import type { FC } from 'react'
import { useForm } from 'react-hook-form'
import type { SharedFilterComponentProps } from '_BRIGHT_/components/Genius'
import { BaseInput } from '_BRIGHT_/components/Forms/BaseInput'
import type { BaseInputProps } from '_BRIGHT_/components/Forms/BaseInput'

export const FILTER_BY_NUMBER_TEST_ID = 'filter-by-number'

const FilterByNumberInput: FC<BaseInputProps> = ({
  id,
  isRequired,
  label,
  description,
  errors,
  name,
  onBlur,
  onChange,
  value,
}) => (
  <BaseInput
    onBlur={onBlur}
    onChange={onChange}
    name={name}
    value={value}
    id={id}
    isRequired={isRequired}
    ariaLabel={label}
    description={description}
    errors={errors}
    type="number"
  />
)

// TODO: Refactor Genius component so that this 'any' can be removed as part of https://smartpension.atlassian.net/browse/DEVEX-30
export const ControlledFilterByNumberInput =
  withController<any>()(FilterByNumberInput)

export type FilterByNumberProps = SharedFilterComponentProps

export const FilterByNumber: FC<FilterByNumberProps> = ({
  fieldName,
  label,
  confirmButtonLabel,
  cancelButtonLabel,
}) => {
  const { filters, dispatch } = useGeniusStateContext()
  const { close } = useDrawerContext()
  const initialValue = filters?.[fieldName] || { value: '' }
  const { control, handleSubmit, reset } = useForm<FilterValueWithoutOperator>({
    mode: 'onSubmit',
    criteriaMode: 'all',
    defaultValues: initialValue,
  })

  const applyFilter = (formData: FilterValueWithoutOperator): void => {
    dispatchAddFilter(dispatch, fieldName, formData)
    // @TODO Genius - Refactor to trigger the close action when the GeniusState gets updated - https://smartpension.atlassian.net/browse/T2-1096
    close()
  }
  const onClear = () => {
    dispatchRemoveFilter(dispatch, fieldName)
    reset({ value: '' })
    // @TODO Genius - Refactor to trigger the close action when the GeniusState gets updated - https://smartpension.atlassian.net/browse/T2-1096
    close()
  }

  return (
    <form
      data-testid={FILTER_BY_NUMBER_TEST_ID}
      onSubmit={handleSubmit(applyFilter)}
    >
      <Stack space="4xl">
        <ControlledFilterByNumberInput
          control={control}
          name="value"
          isRequired
          label={label}
        />
        <ActionDrawerButtonsWrapper>
          <Button
            isFullWidthForMobile
            variant="secondaryButton"
            onClick={onClear}
          >
            {cancelButtonLabel}
          </Button>
          <Button isFullWidthForMobile type="submit">
            {confirmButtonLabel}
          </Button>
        </ActionDrawerButtonsWrapper>
      </Stack>
    </form>
  )
}
