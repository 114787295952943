import dayjs from 'dayjs'
import { isNumeric } from '.'

export const toPounds =
  (withPence = true) =>
  (value: number) =>
    new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: 'GBP',
      minimumFractionDigits: 0,
    }).format(withPence ? value : Math.round(value))

export const toPercentage = (value: number) =>
  new Intl.NumberFormat('en-GB', {
    style: 'unit',
    unit: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(value)

export const toYears = (value: number) =>
  new Intl.NumberFormat('en-GB', {
    style: 'unit',
    unit: 'year',
    maximumFractionDigits: 0,
  }).format(value)

export const formatDate = (
  value: string | number | Date | dayjs.Dayjs | null,
  fallBackValue?: string,
) =>
  dayjs(value).isValid()
    ? dayjs(value).format('DD MMM YYYY')
    : fallBackValue ?? '-'

export const fallBack =
  (n: unknown, placeholder: string = '-') =>
  (fn: (n: number) => string) =>
    isNumeric(n) ? fn(+n) : placeholder

export const roundedCurrency = (
  currency: unknown,
  withPence?: boolean,
  placeholder?: string,
) => fallBack(currency, placeholder)(toPounds(withPence))

export const roundedPercentage = (value: unknown) =>
  fallBack(value)(toPercentage)

export const formatYears = (value: unknown) => fallBack(value)(toYears)

export const formatNumberWithDelimiters = (value: unknown) =>
  fallBack(value)(v => v?.toLocaleString())
