import { FC } from 'react'
import { Text, Link, Stack, Heading } from '_BRIGHT_'
import { CenteredBlock } from 'components/CenteredBlock'
import { PageHeadingBlock } from 'components/PageHeadingBlock'

export const WelcomePage: FC = () => {
  return (
    <>
      <PageHeadingBlock
        backLink="/"
        backLinkText="Back"
        pageHeading="Welcome to the new employer analytics page"
      />
      <CenteredBlock>
        <Stack space="4xl">
          <Heading as="h3">What's new?</Heading>
          <Stack space="md">
            <Text>
              We are in the process of bulding a series of analytics pages that
              will let you look at tables and charts about your pension scheme
              and your employees.
            </Text>
            <Text>
              To start with, we’ve released one page of analytics. There will be
              more pages to come, and we’ll let you know when they’re ready.
            </Text>
          </Stack>
          <Heading as="h3">How do I give feedback?</Heading>
          <Text>
            At the bottom of the window, there’s a button labelled “Give
            feedback”. Just click that button and follow the steps.
          </Text>
          <Heading as="h3">How do I get help?</Heading>
          <Text>
            You can contact us using{' '}
            <Link href="https://www.smartpension.co.uk/contact-us">
              this form
            </Link>{' '}
            or you can call us on{' '}
            <Link href="tel:+443336662323">0333 666 2323</Link>.
          </Text>
        </Stack>
      </CenteredBlock>
    </>
  )
}
