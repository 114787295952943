import { ResultSet } from '@cubejs-client/core'
import { createApi, retry } from '@reduxjs/toolkit/query/react'
import { CubeTablePivot, GeniusDataSet } from 'lib/cubeJs'
import { formatCubeTableToDataSet } from 'lib/cubeJs/formatCubeTableToDataSet'
import { formatTablePivotToCubeValues } from 'lib/cubeJs/cubeValueFormats'
import { cubeBaseQuery } from '../cubeBaseQuery'
import {
  AllClientsQueryConfig,
  companyDimensions,
  orderCompanies,
  companyFilter,
  CompanyFilter,
} from './companies'

const staggeredBaseQuery = retry(cubeBaseQuery(), {
  maxRetries: 3,
})

export const companiesApi = createApi({
  reducerPath: 'companiesApi',
  baseQuery: staggeredBaseQuery,
  keepUnusedDataFor: 180,

  endpoints: build => ({
    companies: build.query<GeniusDataSet, AllClientsQueryConfig>({
      query: ({ sort, filter, limit, offset }) => ({
        cubeQuery: {
          dimensions: companyDimensions,
          order: orderCompanies(sort),
          filters: companyFilter(filter),
          limit,
          offset,
        },
      }),
      transformResponse: (cubeResponse: ResultSet<any>) => {
        const tables = cubeResponse.tablePivot() as CubeTablePivot[]
        const metaDataList = formatCubeTableToDataSet(tables)
        return metaDataList
      },
    }),

    companiesCount: build.query<number, CompanyFilter>({
      query: filter => ({
        cubeQuery: {
          measures: ['SMART_ANALYTICS_COMPANIES.count'],
          filters: companyFilter(filter),
        },
      }),
      transformResponse: (cubeResponse: ResultSet<any>) => {
        return Number(
          cubeResponse?.tablePivot()[0]['SMART_ANALYTICS_COMPANIES.count'],
        )
      },
    }),

    companyName: build.query<string, { companyId: string }>({
      query: arg => ({
        cubeQuery: {
          dimensions: ['SMART_ANALYTICS_COMPANIES.companyName'],
          filters: [
            {
              member: 'SMART_ANALYTICS_COMPANIES.companyId',
              operator: 'equals',
              values: [arg.companyId],
            },
          ],
        },
      }),
      transformResponse: (cubeResponse: ResultSet<any>) => {
        return formatTablePivotToCubeValues(
          cubeResponse.tablePivot()[0] as CubeTablePivot,
        )[0].value
      },
    }),
  }),
})

export const {
  useCompanyNameQuery,
  useLazyCompanyNameQuery,
  useCompaniesQuery,
  useCompaniesCountQuery,
  useLazyCompaniesCountQuery,
  useLazyCompaniesQuery,
} = companiesApi
