import {
  BreadcrumbItem as ChakraBreadcrumbItem,
  BreadcrumbLink as ChakraBreadcrumbLink,
} from '@chakra-ui/react'
import type { FC } from 'react'
import React from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'
import { useColor } from '_BRIGHT_/components/PaletteProvider'

type BreadcrumbItemProps = {
  isCurrentPage?: boolean
  isLastChild?: boolean
} & ({ href: any; to?: never } | { href?: any; to: string })

const BreadcrumbItem: FC<BreadcrumbItemProps> = ({
  to,
  children,
  isCurrentPage = false,
  href,
  isLastChild,
}) => {
  const linkColor = useColor('link')
  return (
    <ChakraBreadcrumbItem
      spacing="xs"
      separator="›"
      isCurrentPage={isCurrentPage}
      isLastChild={isLastChild}
    >
      <ChakraBreadcrumbLink
        as={to ? ReactRouterLink : undefined}
        to={to ? to : undefined}
        {...(href && { href })}
        color={linkColor}
        textDecoration="underline"
        textUnderlineOffset="3px"
        _hover={{ color: useColor('linkHover'), textDecoration: 'none' }}
      >
        {children}
      </ChakraBreadcrumbLink>
    </ChakraBreadcrumbItem>
  )
}

export { BreadcrumbItem }
