import { disableControls } from '_BRIGHT_/utils'
import type { FormErrors } from './types'

export type ErrorMessages = {
  errorMessageOne: string
  errorMessageTwo: string
}

type GetErrors = (
  errorMessageOne: string,
  errorMessageTwo?: string,
) => Record<'errors', FormErrors | undefined>

export const getErrors: GetErrors = (errorMessageOne, errorMessageTwo) => {
  let errors: FormErrors = {}

  if (errorMessageOne) {
    errors = { ...errors, 101: errorMessageOne }
  }

  if (errorMessageTwo) {
    errors = { ...errors, 102: errorMessageTwo }
  }

  return { errors }
}

export const inputArgTypes = {
  errorMessageOne: {
    control: {
      type: 'text',
    },
  },
  errorMessageTwo: {
    control: {
      type: 'text',
    },
  },
  ...disableControls('onChange', 'errors'),
}
