import React from 'react'
import type { FC } from 'react'
import { Icon } from '@chakra-ui/react'
import type { FunctionalIconSvgProps } from '_BRIGHT_/components/Icons/FunctionalIcon/FunctionalIcon'

export const FunctionalPlus: FC<FunctionalIconSvgProps> = ({
  color,
  size,
  height,
  strokeWidth,
  ariaHidden,
}) => (
  <Icon
    viewBox="0 0 24 24"
    fill="none"
    color={color}
    width={size}
    height={height || size}
    aria-hidden={ariaHidden}
  >
    <path
      d="M11.9998 5.25037V18.7504"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.7497 12.0004H5.24976"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
)
