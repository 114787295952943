import {
  Frame,
  PageLoading,
  Pagination,
  PaletteProvider,
  Stack,
} from '_BRIGHT_/components'
import { AppliedActionTags } from '_BRIGHT_/components/Genius/components'
import {
  DrawerContextProvider,
  GeniusBulkEditContextProvider,
  GeniusConfigProvider,
  GeniusStateContextProvider,
} from '_BRIGHT_/components/Genius/context'
import {
  dispatchDeselectAllItems,
  dispatchSelectMultipleItems,
  useDrawer,
  useGeniusState,
} from '_BRIGHT_/components/Genius/hooks'
import { getPagination } from '_BRIGHT_/components/Pagination'
import { useIsMobileViewport } from '_BRIGHT_/utils'
import * as R from 'ramda'
import type { PropsWithChildren } from 'react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ActionBar, ActionDrawer, ListView } from './components'
import { EmptyDatasetView } from './components/EmptyDatasetView'
import { GeniusErrorBoundary } from './components/GeniusErrorBoundary'
import { SkeletonList } from './components/ListView/sections/SkeletonList'
import type { FullMetadata } from './geniusDataMungers'
import { geniusConfigMunger, geniusDatasetMunger } from './geniusDataMungers'
import { useGeniusUrlState } from './hooks'
import type {
  GeniusListViewDataItem,
  GeniusProps,
  GeniusUIProps,
  OnBulkEditSubmit,
} from './types'

export const GeniusUI = <SummaryProps, ButtonsProps>({
  id,
  onStateChange,
  isLoadingData,
  config,
  dataset,
  resultsPerPage,
  totalResults = 0,
  limit,
  onBulkEditSubmit = (() => undefined) as OnBulkEditSubmit,
  SummaryRenderer,
  MetadataItemRenderer,
  buttonsRendererList,
  availableViews = ['list'],
  customActionRenderers,
}: PropsWithChildren<
  GeniusUIProps<SummaryProps, ButtonsProps>
>): JSX.Element => {
  const { urlState: urlState, setPage } = useGeniusUrlState(id, config.fields)

  const currentPagination = getPagination({
    currentPage: urlState.currentPage,
    totalResults: totalResults,
    resultsPerPage,
    limit,
  })

  const geniusState = useGeniusState(id, config)
  const isMobile = Boolean(useIsMobileViewport())
  const [isActionBarCondensed, setIsActionBarCondensed] = useState(isMobile)
  const bulkItemsAmount = Object.values(geniusState.selectedItems).filter(
    i => i,
  ).length
  const isSelectAllChecked =
    Boolean(dataset?.length) && bulkItemsAmount === dataset?.length
  const isDatasetEmpty = R.isEmpty(dataset)
  const { isOpen, actions, ...drawerContext } = useDrawer({
    config,
    onSelectAll: () => {
      isSelectAllChecked
        ? dispatchDeselectAllItems(geniusState.dispatch)
        : dispatchSelectMultipleItems(
            geniusState.dispatch,
            dataset?.map(({ id }) => id) || [],
          )
    },
    customActionRenderers,
  })

  useEffect(() => {
    onStateChange({
      ...geniusState,
      currentPage: currentPagination.currentPage,
    })
  }, [geniusState]) /* eslint-disable-line react-hooks/exhaustive-deps */

  const onActionBarOverflowChange = useCallback(
    isOverflowing => {
      setIsActionBarCondensed(isOverflowing || isMobile)
    },
    [isMobile],
  )

  return (
    <GeniusConfigProvider value={config}>
      <GeniusStateContextProvider value={geniusState}>
        <DrawerContextProvider value={{ actions, isOpen, ...drawerContext }}>
          <GeniusBulkEditContextProvider value={{ onBulkEditSubmit }}>
            <Stack space="sm" data-testid="genius-component">
              <ActionBar
                isSelectAllChecked={isSelectAllChecked}
                bulkItemsAmount={bulkItemsAmount}
                availableViews={availableViews}
                isCondensed={isActionBarCondensed}
                isDatasetEmpty={isDatasetEmpty}
                onOverflowChange={onActionBarOverflowChange}
              />
              <AppliedActionTags />
              <PaletteProvider palette="baseTwo">
                {isLoadingData ? (
                  <SkeletonList />
                ) : isDatasetEmpty ? (
                  <EmptyDatasetView>
                    {config.body.emptyDatasetContent}
                  </EmptyDatasetView>
                ) : (
                  <ListView
                    hasCollapsedGutters={isActionBarCondensed}
                    dataset={
                      dataset as GeniusListViewDataItem<
                        SummaryProps,
                        ButtonsProps
                      >[]
                    }
                    SummaryRenderer={SummaryRenderer}
                    MetadataItemRenderer={MetadataItemRenderer}
                    buttonsRendererList={buttonsRendererList}
                    totalResults={totalResults}
                  />
                )}
              </PaletteProvider>
              {isOpen && <ActionDrawer />}
            </Stack>
            {currentPagination.totalPages > 1 && (
              <Frame palette="baseTwo" alignX="center" paddingY="6xl">
                <Pagination
                  previousPage={currentPagination.previousPage}
                  nextPage={currentPagination.nextPage}
                  currentPage={currentPagination.currentPage}
                  totalPages={currentPagination.totalPages}
                  onClick={setPage}
                />
              </Frame>
            )}
          </GeniusBulkEditContextProvider>
        </DrawerContextProvider>
      </GeniusStateContextProvider>
    </GeniusConfigProvider>
  )
}

// @TODO Genius - handle errors querying the data (add an datasetError prop) - https://smartpension.atlassian.net/browse/T2-1180
// @TODO Genius - handle errors querying the genius config (https://smartpension.atlassian.net/browse/T2-1181)
const BaseGenius = <SummaryProps, DatasetItem, ButtonsProps>({
  id,
  dataset: rawDataset,
  isLoadingData,
  datasetError,
  SummaryRenderer,
  MetadataItemRenderer,
  buttonsRendererList,
  onStateChange,
  onBulkEditSubmit,
  limit,
  totalResults,
  buttonsPropsMunger,
  summaryPropsMunger,
  metadataListMunger,
  isLoadingGeniusConfig,
  geniusConfigFromQuery,
  geniusConfigError,
  availableViews,
  customActionRenderers,
}: PropsWithChildren<
  GeniusProps<SummaryProps, DatasetItem, ButtonsProps>
>): JSX.Element => {
  const config = useMemo(
    () =>
      geniusConfigFromQuery ? geniusConfigMunger(geniusConfigFromQuery) : null,
    [geniusConfigFromQuery],
  )

  if (isLoadingGeniusConfig) return <PageLoading />
  if (geniusConfigError) throw geniusConfigError
  if (datasetError) throw datasetError
  if (!config) throw geniusConfigError

  const dataset = geniusDatasetMunger<SummaryProps, DatasetItem, ButtonsProps>({
    itemsList: rawDataset as unknown as FullMetadata<DatasetItem>[],
    summaryPropsMunger,
    buttonsPropsMunger,
    metadataListMunger,
    config,
  })

  return (
    <GeniusUI
      id={id}
      onStateChange={onStateChange}
      config={config}
      dataset={dataset}
      resultsPerPage={dataset?.length as number}
      totalResults={totalResults}
      limit={limit}
      onBulkEditSubmit={onBulkEditSubmit}
      SummaryRenderer={SummaryRenderer}
      MetadataItemRenderer={MetadataItemRenderer}
      buttonsRendererList={buttonsRendererList}
      isLoadingData={isLoadingData}
      availableViews={availableViews}
      customActionRenderers={customActionRenderers}
    />
  )
}

export const Genius: typeof BaseGenius = props => (
  <GeniusErrorBoundary id={props.id}>
    <BaseGenius {...props} />
  </GeniusErrorBoundary>
)
