import type { FC } from 'react'
import React from 'react'
import type { HiFiIconPathProps } from '_BRIGHT_/components/Icons'

const PersonalDetailsEdit: FC<HiFiIconPathProps> = ({
  primaryColor,
  secondaryColor,
  baseColor,
  strokeWidth,
  hasShadow,
}) => {
  return (
    <>
      {hasShadow && (
        <>
          <path
            opacity="0.2"
            d="M14.5 4C12.7761 4 11.1228 4.68482 9.90381 5.90381C8.68482 7.12279 8 8.77609 8 10.5C8 12.2239 8.68482 13.8772 9.90381 15.0962C11.1228 16.3152 12.7761 17 14.5 17C16.2239 17 17.8772 16.3152 19.0962 15.0962C20.3152 13.8772 21 12.2239 21 10.5C21 8.77609 20.3152 7.12279 19.0962 5.90381C17.8772 4.68482 16.2239 4 14.5 4Z"
            fill={primaryColor}
          />
          <path
            opacity="0.2"
            d="M14 18C9.70955 18 6.02405 20.7808 4.42179 24.7552C4.1746 25.3683 4.65382 26 5.31492 26H22.6851C23.3462 26 23.8254 25.3683 23.5782 24.7552C21.976 20.7808 18.2904 18 14 18Z"
            fill={primaryColor}
          />
        </>
      )}
      <path
        d="M6 7.5C6 8.95869 6.57946 10.3576 7.61091 11.3891C8.64236 12.4205 10.0413 13 11.5 13C12.9587 13 14.3576 12.4205 15.3891 11.3891C16.4205 10.3576 17 8.95869 17 7.5C17 6.04131 16.4205 4.64236 15.3891 3.61091C14.3576 2.57946 12.9587 2 11.5 2C10.0413 2 8.64236 2.57946 7.61091 3.61091C6.57946 4.64236 6 6.04131 6 7.5V7.5Z"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.2767 20.2228L20.375 29.1256L16 30.0006L16.875 25.6256L25.7767 16.7228C26.2396 16.26 26.8674 16 27.522 16C28.1766 16 28.8044 16.26 29.2673 16.7228L29.2767 16.7333C29.5061 16.9623 29.6881 17.2342 29.8122 17.5336C29.9364 17.833 30.0003 18.1539 30.0003 18.478C30.0003 18.8021 29.9364 19.1231 29.8122 19.4225C29.6881 19.7218 29.5061 19.9938 29.2767 20.2228Z"
        fill={baseColor}
        stroke={secondaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6637 16.8579C14.3871 16.3059 12.9793 16 11.5 16C7.51122 16 4.04191 18.2242 2.26465 21.5"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
    </>
  )
}

export { PersonalDetailsEdit }
