import { useColor } from '_BRIGHT_/components'
import { useShadow } from '_BRIGHT_/utils'
import { getResponsiveTypography } from '_BRIGHT_/components/Typography/common'
import type { Level } from '_BRIGHT_/components/Typography/common'
import type {
  LinkLevel,
  LinkVariant,
} from '_BRIGHT_/components/Typography/Link/Link'
import type { ButtonVariant } from '_BRIGHT_/components/Buttons/common'
import type { ButtonLinkStyles } from '_BRIGHT_/components/Buttons/BaseButton/useButtonStyles'
import type { LinkIconSize } from '_BRIGHT_/components/Typography/Link/Link'
import type { SystemProps } from '@chakra-ui/system'

export const levelMap: Record<LinkLevel, Level> = {
  '2xs': '2xs',
  xs: 'xs',
  sm: 'sm',
}

const iconSizeMap: Record<LinkLevel, LinkIconSize> = {
  '2xs': 'xs',
  xs: 'xs',
  sm: 'sm',
}

export const useLinkStyles = (
  variant: LinkVariant | ButtonVariant,
  level: LinkLevel,
  display?: SystemProps['display'],
): ButtonLinkStyles => {
  const color = useColor(
    variant === 'primaryLink' ? 'link' : 'error',
    undefined,
    false,
  )
  const hoverColor = useColor('linkHover', undefined, false)
  const boxShadow = useShadow('link')
  const { fontFamily, fontSize, fontWeight, lineHeight } =
    getResponsiveTypography(level, levelMap)
  const iconSize = iconSizeMap[level]

  return {
    color,
    hoverColor,
    boxShadow,
    fontFamily,
    fontSize,
    fontWeight,
    lineHeight,
    iconSize,
    iconLineHeight: level,
    display: display ?? 'inline-flex',
    alignItems: 'top',
    justifyContent: 'flex-start',
    width: 'auto',
    borderWidth: '0',
    borderColor: 'transparent',
    borderRadius: undefined,
    backgroundColor: 'transparent',
    hoverBackgroundColor: 'transparent',
    focusBackgroundColor: undefined,
    paddingX: '0',
    themeSize: '',
    activeColor: '',
    textDecoration: 'underline',
  }
}
