import type {
  FieldConfig,
  GeniusDateFieldConfig,
  GeniusNumberFieldConfig,
  GeniusOptionFieldConfig,
  GeniusTextFieldConfig,
} from '_BRIGHT_/components'
import type {
  FilterValueWithOperator,
  FilterValueWithoutOperator,
} from '_BRIGHT_/components/Genius/hooks'

export const isDateField = (
  field: FieldConfig,
): field is GeniusDateFieldConfig => field.type === 'date'
export const isOptionField = (
  field: FieldConfig,
): field is GeniusOptionFieldConfig => field.type === 'option'
export const isTextField = (
  field: FieldConfig,
): field is GeniusTextFieldConfig => field.type === 'text'
export const isNumberField = (
  field: FieldConfig,
): field is GeniusNumberFieldConfig => field.type === 'number'

export const isFilterValueWithOperator = (
  value: unknown,
): value is FilterValueWithOperator =>
  typeof value === 'object' &&
  value !== null &&
  Object.keys(value).every(key => ['value', 'operator'].includes(key))

export const isFilterValueWithoutOperator = (
  value: unknown,
): value is FilterValueWithoutOperator =>
  typeof value === 'object' &&
  value !== null &&
  Object.keys(value).every(key => ['value'].includes(key))
