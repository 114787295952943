const shadows = {
  none: 'none',
  'inset-sm': 'inset 0 0 0 1px',
  sm: '0px 0px 1px rgba(0, 0, 0, 0.08), 0px 2px 2px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(0, 0, 0, 0.04)',
  md: '0px 6px 8px rgba(0, 0, 0, 0.1), 0px 4px 12px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.12)',
  focus: '0 0 0 4px',
  invalid: '0 0 0 1px',
  link: '0 0 0 2px',
}

export { shadows }
