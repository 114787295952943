import type { FC } from 'react'
import React from 'react'
import type { HiFiIconPathProps } from '_BRIGHT_/components/Icons'

const ChangePassword: FC<HiFiIconPathProps> = ({
  primaryColor,
  secondaryColor,
  baseColor,
  strokeWidth,
  hasShadow,
}) => {
  return (
    <>
      {hasShadow && (
        <path
          opacity="0.2"
          d="M7.58929 6C6.89667 6 6.23617 6.28104 5.75204 6.77526C5.26851 7.26886 5 7.93456 5 8.625V19.125C5 19.8154 5.26851 20.4811 5.75204 20.9747C6.23617 21.469 6.89667 21.75 7.58929 21.75H27.875C28.5676 21.75 29.2281 21.469 29.7123 20.9747C30.1958 20.4811 30.4643 19.8154 30.4643 19.125L30.4643 8.625C30.4643 7.93456 30.1958 7.26886 29.7123 6.77526C29.2281 6.28104 28.5676 6 27.875 6H7.58929Z"
          fill={primaryColor}
        />
      )}
      <path
        d="M15.7143 17H3.71429C3.25963 17 2.82359 16.8156 2.5021 16.4874C2.18061 16.1592 2 15.7141 2 15.25V4.75C2 4.28587 2.18061 3.84075 2.5021 3.51256C2.82359 3.18437 3.25963 3 3.71429 3H24.2857C24.7404 3 25.1764 3.18437 25.4979 3.51256C25.8194 3.84075 26 4.28587 26 4.75V11.75"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.3125 9.5625C7.41139 9.5625 7.50806 9.59183 7.59029 9.64677C7.67251 9.70171 7.7366 9.7798 7.77444 9.87116C7.81228 9.96252 7.82218 10.0631 7.80289 10.16C7.7836 10.257 7.73598 10.3461 7.66605 10.4161C7.59613 10.486 7.50704 10.5336 7.41005 10.5529C7.31306 10.5722 7.21252 10.5623 7.12116 10.5244C7.0298 10.4866 6.95171 10.4225 6.89677 10.3403C6.84183 10.2581 6.8125 10.1614 6.8125 10.0625C6.81233 9.99679 6.82514 9.93169 6.8502 9.87095C6.87527 9.81021 6.91209 9.75502 6.95856 9.70856C7.00502 9.66209 7.06021 9.62527 7.12095 9.6002C7.18169 9.57514 7.24679 9.56233 7.3125 9.5625"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.3125 9.5625C14.4114 9.5625 14.5081 9.59183 14.5903 9.64677C14.6725 9.70171 14.7366 9.7798 14.7744 9.87116C14.8123 9.96252 14.8222 10.0631 14.8029 10.16C14.7836 10.257 14.736 10.3461 14.6661 10.4161C14.5961 10.486 14.507 10.5336 14.41 10.5529C14.3131 10.5722 14.2125 10.5623 14.1212 10.5244C14.0298 10.4866 13.9517 10.4225 13.8968 10.3403C13.8418 10.2581 13.8125 10.1614 13.8125 10.0625C13.8123 9.99679 13.8251 9.93169 13.8502 9.87095C13.8753 9.81021 13.9121 9.75502 13.9586 9.70856C14.005 9.66209 14.0602 9.62527 14.121 9.6002C14.1817 9.57514 14.2468 9.56233 14.3125 9.5625"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.3125 9.5625C21.4114 9.5625 21.5081 9.59183 21.5903 9.64677C21.6725 9.70171 21.7366 9.7798 21.7744 9.87116C21.8123 9.96252 21.8222 10.0631 21.8029 10.16C21.7836 10.257 21.736 10.3461 21.6661 10.4161C21.5961 10.486 21.507 10.5336 21.41 10.5529C21.3131 10.5722 21.2125 10.5623 21.1212 10.5244C21.0298 10.4866 20.9517 10.4225 20.8968 10.3403C20.8418 10.2581 20.8125 10.1614 20.8125 10.0625C20.8123 9.99679 20.8251 9.93169 20.8502 9.87095C20.8753 9.81021 20.9121 9.75502 20.9586 9.70856C21.005 9.66209 21.0602 9.62527 21.121 9.6002C21.1817 9.57514 21.2468 9.56233 21.3125 9.5625"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.75 20.5H28.25V29.25H17.75V20.5Z"
        fill={baseColor}
        stroke={secondaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23 15.25C22.0717 15.25 21.1815 15.6187 20.5251 16.2751C19.8687 16.9315 19.5 17.8217 19.5 18.75V20.5H26.5V18.75C26.5 17.8217 26.1313 16.9315 25.4749 16.2751C24.8185 15.6187 23.9283 15.25 23 15.25Z"
        fill={baseColor}
        stroke={secondaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23 24.4713C23.0865 24.4713 23.1711 24.497 23.2431 24.545C23.315 24.5931 23.3711 24.6614 23.4042 24.7414C23.4373 24.8213 23.446 24.9093 23.4291 24.9942C23.4122 25.079 23.3705 25.157 23.3094 25.2182C23.2482 25.2794 23.1702 25.321 23.0854 25.3379C23.0005 25.3548 22.9125 25.3461 22.8326 25.313C22.7526 25.2799 22.6843 25.2238 22.6362 25.1519C22.5882 25.0799 22.5625 24.9953 22.5625 24.9088C22.5625 24.7928 22.6086 24.6815 22.6906 24.5995C22.7727 24.5174 22.884 24.4713 23 24.4713Z"
        fill={baseColor}
      />
      <path
        d="M23 24.4713C23.0865 24.4713 23.1711 24.497 23.2431 24.545C23.315 24.5931 23.3711 24.6614 23.4042 24.7414C23.4373 24.8213 23.446 24.9093 23.4291 24.9942C23.4122 25.079 23.3705 25.157 23.3094 25.2182C23.2482 25.2794 23.1702 25.321 23.0854 25.3379C23.0005 25.3548 22.9125 25.3461 22.8326 25.313C22.7526 25.2799 22.6843 25.2238 22.6362 25.1519C22.5882 25.0799 22.5625 24.9953 22.5625 24.9088C22.5625 24.7928 22.6086 24.6815 22.6906 24.5995C22.7727 24.5174 22.884 24.4713 23 24.4713"
        stroke={secondaryColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  )
}

export { ChangePassword }
