import { CubeKey } from 'lib/cubeJs'
import {
  formatNumberWithDelimiters,
  TOOLTIP_TEXT,
  roundedCurrency,
  formatTextAsSentence,
  roundedPercentage,
  SENSITIVE_DATA_PLACEHOLDER,
  formatYears,
  formatDate,
} from 'lib/utils'
import { AppValue } from '.'

export const membershipValues: Record<string, string> = {
  active: 'Active',
  deferred: 'Deferred',
  opted_out: 'Opted out',
  'paid_up/ceased': 'Paid up / ceased',
  'pre-joiner': 'Pre-joiner',
}

export const formatCubeValueToAppValue = (
  cubeKey: CubeKey,
  value: unknown,
): AppValue<string> => {
  switch (cubeKey) {
    // COMPANIES CUBE
    case 'SMART_ANALYTICS_COMPANIES.companyName':
      return {
        title: 'Name',
        value: String(value),
      }

    case 'SMART_ANALYTICS_COMPANIES.count':
      return {
        title: 'Number of clients',
        value: formatNumberWithDelimiters(Number(value)),
      }

    case 'SMART_ANALYTICS_COMPANIES.sumAUM':
      return {
        title: 'Total AUM for all clients',
        tooltip: TOOLTIP_TEXT.AUM,
        value: roundedCurrency(Number(value), false),
      }

    case 'SMART_ANALYTICS_COMPANIES.totalAUM':
      return {
        title: 'AUM',
        tooltip: TOOLTIP_TEXT.AUM,
        value: roundedCurrency(Number(value), false),
      }

    case 'SMART_ANALYTICS_COMPANIES.totalMissingDirectDebitState':
      return {
        title: 'Missing Direct Debits',
        tooltip: TOOLTIP_TEXT.sacMissingDirectDebits,
        value: formatNumberWithDelimiters(Number(value)),
      }

    case 'SMART_ANALYTICS_COMPANIES.sumActiveEmployees':
      return {
        title: 'Active members for all clients',
        value: formatNumberWithDelimiters(Number(value)),
      }

    case 'SMART_ANALYTICS_COMPANIES.directDebitState':
      return {
        title: 'Direct Debit',
        value: formatTextAsSentence(String(value)),
      }

    case 'SMART_ANALYTICS_COMPANIES.sumEmployees':
      return {
        title: 'Employees for all clients',
        value: formatNumberWithDelimiters(Number(value)),
      }

    case 'SMART_ANALYTICS_COMPANIES.totalTakeUpRatio':
      return {
        title: 'Take-up rate across all clients',
        tooltip: TOOLTIP_TEXT.takeupRate,
        value: roundedPercentage(Number(value) * 100),
      }

    case 'SMART_ANALYTICS_COMPANIES.sumInvalidEmail':
      return {
        title: 'Missing email addresses',
        tooltip: TOOLTIP_TEXT.sacMissingEmailAddresses,
        value: formatNumberWithDelimiters(Number(value)),
      }

    case 'SMART_ANALYTICS_COMPANIES.sumInvalidFields':
      return {
        title: 'Data quality issues',
        value: formatNumberWithDelimiters(Number(value)),
      }

    case 'SMART_ANALYTICS_COMPANIES.sumInvalidNationalInsuranceNumber':
      return {
        title: 'Missing NI numbers',
        tooltip: TOOLTIP_TEXT.sacMissingNINumbers,
        value: formatNumberWithDelimiters(Number(value)),
      }
    case 'SMART_ANALYTICS_COMPANIES.sumInvalidAddresses':
      return {
        title: 'Invalid home addresses',
        tooltip: TOOLTIP_TEXT.sacInvalidHomeAddress,
        value: formatNumberWithDelimiters(Number(value)),
      }

    case 'SMART_ANALYTICS_COMPANIES.takeUpRatio':
      return {
        title: 'Take-up rate',
        tooltip: TOOLTIP_TEXT.takeupRate,
        value: roundedPercentage(Number(value) * 100),
      }

    case 'SMART_ANALYTICS_COMPANIES.totalDataQualityScoreRatio':
      return {
        title: 'Data quality for all clients',
        tooltip: TOOLTIP_TEXT.dataQuality,
        value: roundedPercentage(Number(value) * 100),
      }

    case 'SMART_ANALYTICS_COMPANIES.dataQualityScoreRatio':
      return {
        title: 'Data quality',
        tooltip: TOOLTIP_TEXT.dataQuality,
        value: roundedPercentage(Number(value) * 100),
      }

    case 'SMART_ANALYTICS_COMPANIES.companyId':
      return {
        title: 'Company ID',
        value: String(value),
      }

    case 'SMART_ANALYTICS_COMPANIES.totalEmployees':
      return {
        title: 'Employees',
        value: formatNumberWithDelimiters(Number(value)),
      }

    case 'SMART_ANALYTICS_COMPANIES.totalActiveEmployees':
      return {
        title: 'Active members',
        value: formatNumberWithDelimiters(Number(value)),
      }

    case 'SMART_ANALYTICS_COMPANIES.totalDeferredEmployees':
      return {
        title: 'Deferred members',
        value: formatNumberWithDelimiters(Number(value)),
      }

    case 'SMART_ANALYTICS_COMPANIES.companyState':
      return {
        title: 'Status',
        value: formatTextAsSentence(String(value)),
      }

    case 'SMART_ANALYTICS_COMPANIES.lastContributionCreationDate':
      return {
        title: 'Last payroll upload',
        value: formatDate(String(value)),
      }

    case 'SMART_ANALYTICS_COMPANIES.schemeStartDate': {
      return {
        title: 'Scheme start date',
        tooltip: TOOLTIP_TEXT.schemeStartDate,
        value: formatDate(String(value)),
      }
    }

    case 'SMART_ANALYTICS_COMPANIES.dutyStartDate':
      return {
        title: 'Duties start date',
        value: formatDate(String(value)),
      }

    case 'SMART_ANALYTICS_COMPANIES.nextReEnrolmentDate':
      return {
        title: 'Re-enrolment date',
        value: formatDate(String(value)),
      }

    case 'SMART_ANALYTICS_COMPANIES.totalInvalidNationalInsuranceNumber':
      return {
        title: 'Missing NI numbers',
        value: String(value),
        tooltip: TOOLTIP_TEXT.maintenanceMissingNINumbers,
      }

    case 'SMART_ANALYTICS_COMPANIES.totalMembersWithMissingContributions':
      return {
        title: 'Late contributions',
        value: String(value),
        tooltip: TOOLTIP_TEXT.totalMembersWithMissingContributions,
      }

    case 'SMART_ANALYTICS_COMPANIES.totalCompaniesMissingMemberContributions':
      return {
        title: 'Late contributions',
        value: String(value),
        tooltip: TOOLTIP_TEXT.totalCompaniesMissingMemberContributions,
      }

    case 'SMART_ANALYTICS_COMPANIES.totalInvalidEmail':
      return {
        title: 'Missing email addresses',
        value: String(value),
      }

    case 'SMART_ANALYTICS_COMPANIES.totalInvalidAddresses':
      return {
        title: 'Missing home addresses',
        value: String(value),
        tooltip: TOOLTIP_TEXT.maintenanceInvalidHomeAddress,
      }

    case 'SMART_ANALYTICS_COMPANIES.averageActiveMemberPercentage':
      return {
        title: 'Average employee contribution',
        value: roundedPercentage(Number(value)),
      }

    case 'SMART_ANALYTICS_COMPANIES.membersWithDefaultStrategyRatio':
      return {
        title: 'Members in model strategy funds',
        value: roundedPercentage(Number(value)),
      }

    case 'SMART_ANALYTICS_COMPANIES.averageMemberAUM':
      return {
        title: 'Average fund value',
        value: roundedCurrency(value, false, SENSITIVE_DATA_PLACEHOLDER),
      }

    case 'SMART_ANALYTICS_COMPANIES.averageActiveMemberAge':
      return {
        title: 'Average age',
        value: formatYears(Number(value)),
      }

    case 'SMART_ANALYTICS_COMPANIES.averageActiveMemberCompanyPercentage':
      return {
        title: 'Average employer contribution',
        value: roundedPercentage(Number(value)),
      }

    case 'SMART_ANALYTICS_COMPANIES.averageActiveMemberSalary':
      return {
        title: 'Average salary',
        value: roundedCurrency(value, true, SENSITIVE_DATA_PLACEHOLDER),
      }

    case 'SMART_ANALYTICS_COMPANIES.totalNewMembersThisMonth':
      return {
        title: 'New members this month',
        value: String(value),
      }

    case 'SMART_ANALYTICS_COMPANIES.totalLeaversThisMonth':
      return {
        title: 'Leavers this month',
        value: String(value),
      }

    case 'SMART_ANALYTICS_COMPANIES.totalMembersWithinOneYearToRetirement':
      return {
        title: 'Within 1 yr of retirement',
        value: String(value),
      }

    case 'SMART_ANALYTICS_COMPANIES.totalClaims':
      return {
        title: 'Claims',
        value: String(value),
      }

    case 'SMART_ANALYTICS_COMPANIES.totalAvailableFunds':
      return {
        title: 'Total number of funds',
        value: String(value),
      }

    case 'SMART_ANALYTICS_EMPLOYEES.companyId':
      return {
        title: 'Filter by Company ID',
        value: String(value),
      }

    case 'SMART_ANALYTICS_COMPANIES.hasMissingPaymentMethod':
      return {
        title: 'Has missing payment method',
        value: value ? 'invalid--' : '',
      }

    case 'SMART_ANALYTICS_EMPLOYEES.employeeId':
      return {
        title: 'Employee ID',
        value: String(value),
      }

    case 'SMART_ANALYTICS_EMPLOYEES.fullName':
      return {
        title: 'Full name',
        value: String(value),
      }

    case 'SMART_ANALYTICS_EMPLOYEES.membershipStatus':
      return {
        title: 'Membership status',
        value: membershipValues[String(value)] ?? 'Missing',
      }

    case 'SMART_ANALYTICS_EMPLOYEES.externalId':
      return {
        title: 'External ID (Payroll ID)',
        value: String(value),
      }

    case 'SMART_ANALYTICS_EMPLOYEES.nationalInsuranceNumber':
      return {
        title: 'NI number',
        value: value ? String(value) : 'Missing',
      }

    case 'SMART_ANALYTICS_EMPLOYEES.total1DaysLateMissingContributions':
      return {
        title: 'Late contributions (1-29 days)',
        value: String(value),
      }

    case 'SMART_ANALYTICS_EMPLOYEES.total30DaysLateMissingContributions':
      return {
        title: 'Late contributions (30-59 days)',
        value: String(value),
      }

    case 'SMART_ANALYTICS_EMPLOYEES.total60DaysLateMissingContributions':
      return {
        title: 'Late contributions (60-89 days)',
        value: String(value),
      }

    case 'SMART_ANALYTICS_EMPLOYEES.total90DaysLateMissingContributions':
      return {
        title: 'Late contributions (90+ days)',
        value: String(value),
      }

    case 'SMART_ANALYTICS_EMPLOYEES.lastContributionEndDate':
      return {
        title: 'Last contribution date',
        value: formatDate(String(value)),
      }

    case 'SMART_ANALYTICS_EMPLOYEES.lastSignInDate':
      return {
        title: 'Last sign-in',
        value: formatDate(String(value), 'Never'),
      }

    case 'SMART_ANALYTICS_EMPLOYEES.lastBeneficiariesUpdatedDate':
      return {
        title: 'Beneficiaries updated',
        value: formatDate(String(value), 'Never'),
      }

    case 'SMART_ANALYTICS_EMPLOYEES.groupName':
      return {
        title: 'Group',
        value: value ? String(value) : '-',
      }

    case 'SMART_ANALYTICS_EMPLOYEES.startDate':
      return {
        title: 'Start date',
        value: formatDate(String(value)),
      }

    case 'SMART_ANALYTICS_EMPLOYEES.exitDate':
      return {
        title: 'Exit date',
        value: formatDate(String(value)),
      }

    case 'SMART_ANALYTICS_EMPLOYEES.exitReason':
      return {
        title: 'Exit reason',
        value: value ? String(value) : '-',
      }

    case 'SMART_ANALYTICS_EMPLOYEES.emailAddress':
      return {
        title: 'Email address',
        value: value ? String(value) : 'Missing',
      }

    case 'SMART_ANALYTICS_EMPLOYEES.telephone':
      return {
        title: 'Telephone',
        value: String(value),
      }

    case 'SMART_ANALYTICS_EMPLOYEES.homeAddress':
      return {
        title: 'Home address',
        value: value ? String(value) : 'Missing',
      }

    case 'SMART_ANALYTICS_EMPLOYEES.gender':
      return {
        title: 'Gender',
        value: String(value),
      }

    case 'SMART_ANALYTICS_EMPLOYEES.count':
      return {
        title: 'Employees',
        value: String(value),
      }

    case 'SMART_ANALYTICS_EMPLOYEES.totalInvalidNationalInsuranceNumber':
      return {
        title: 'Missing NI numbers',
        value: String(value),
        tooltip: TOOLTIP_TEXT.maintenanceMissingNINumbers,
      }

    case 'SMART_ANALYTICS_EMPLOYEES.totalMembersWithMissingContributions':
      return {
        title: 'Late contributions',
        value: String(value),
        tooltip: TOOLTIP_TEXT.totalMembersWithMissingContributions,
      }

    case 'SMART_ANALYTICS_EMPLOYEES.totalNeverSignedIn':
      return {
        title: 'Never signed in',
        value: String(value),
      }

    case 'SMART_ANALYTICS_EMPLOYEES.totalMissingBeneficiaries':
      return {
        title: 'Missing beneficiaries',
        value: String(value),
        tooltip: TOOLTIP_TEXT.maintenanceMissingBeneficiaries,
      }

    case 'SMART_ANALYTICS_EMPLOYEES.totalInvalidHomeAddress':
      return {
        title: 'Invalid home addresses',
        value: String(value),
        tooltip: TOOLTIP_TEXT.maintenanceInvalidHomeAddress,
      }

    case 'SMART_ANALYTICS_EMPLOYEES.totalInvalidEmailAddress':
      return {
        title: 'Missing email addresses',
        value: String(value),
      }

    case 'SMART_ANALYTICS_EMPLOYEES.isGoneAway':
      return {
        title: 'Gone away',
        value: value ? 'Yes' : 'No',
      }

    case 'SMART_ANALYTICS_EMPLOYEES.hasValidHomeAddress':
      return {
        title: 'Valid Home Address',
        value: value ? '' : 'invalid--',
      }

    // DATASETS CUBE
    case 'SMART_ANALYTICS_DATASETS_INFO.latestUpdate':
      return {
        title: 'Latest Update',
        value: formatDate(String(value)),
      }

    default:
      throw Error('Cube Accessor not found: ' + cubeKey)
  }
}
