import { FC } from 'react'
import { useSmallViewport } from 'lib/hooks'
import { Grid, GridItem, Divider } from '@chakra-ui/react'
import { useColor } from '_BRIGHT_'
import { flatArrayToTwoColumns } from './utils/flatArrayToColumns'
import { DataRow } from 'components/DataRow'
import { OverviewProps } from './Overview'
import { nanoid } from '@reduxjs/toolkit'

export const OverviewContent: FC<OverviewProps> = ({ data, skeletonText }) => {
  const columns = flatArrayToTwoColumns(data)
  const smallViewPort = useSmallViewport()
  const dividerBorderColor = useColor('borderSecondary')

  return (
    <Grid
      templateColumns={{ md: `repeat(2, 1fr)` }}
      gap={{ base: '2xs', md: 14 }}
      width="100%"
    >
      {columns?.map(rows => (
        <GridItem key={nanoid(6)} colSpan={{ base: 2, md: 1 }}>
          <Grid gap="2xs">
            {rows?.map((data, i) => (
              <Grid gap="2xs" key={nanoid(6)}>
                <DataRow
                  skeletonText={skeletonText}
                  textVariant="secondary"
                  {...data}
                />
                {/* only show divider on large viewports, and when it's not the last item in the rows array */}
                {!smallViewPort && i + 1 !== rows.length && (
                  <Divider borderColor={dividerBorderColor} />
                )}
              </Grid>
            ))}
          </Grid>
        </GridItem>
      ))}
    </Grid>
  )
}
