import { BinaryOperator } from '@cubejs-client/core'
import {
  AppAccessors,
  CubeCompaniesAccessor,
  CubeCompaniesKey,
  CubeDataSetsInfoAccessor,
  CubeDataSetsInfoKey,
  CubeEmployeesAccessor,
  CubeEmployeesKey,
  CubeKey,
} from 'lib/cubeJs'

export const removeCubePrefix = (k: CubeKey): AppAccessors =>
  k.replace(/^[^.]*./, '') as AppAccessors

export const addCubeCompany = (
  a: CubeCompaniesAccessor | string,
): CubeCompaniesKey => ('SMART_ANALYTICS_COMPANIES.' + a) as CubeCompaniesKey

export const addCubeEmployees = (
  a: CubeEmployeesAccessor | string,
): CubeEmployeesKey => ('SMART_ANALYTICS_EMPLOYEES.' + a) as CubeEmployeesKey

export const addCubeDataSets = (
  a: CubeDataSetsInfoAccessor | string,
): CubeDataSetsInfoKey =>
  ('SMART_ANALYTICS_DATASETS_INFO.' + a) as CubeDataSetsInfoKey

export const geniusOperatorToCubeOperator = (o: string): BinaryOperator => {
  const op: { [k: string]: BinaryOperator } = {
    equal: 'equals',
    before: 'beforeDate',
    after: 'afterDate',
  }
  return op[o] ?? 'contains'
}
