import type { FC } from 'react'
import { PageHeadingBlock } from 'components/PageHeadingBlock'
import { CenteredBlock } from 'components/CenteredBlock'
import { useCompanyId, useScrollToTop } from 'lib/hooks'
import { Overview } from 'components/Overview'
import { maintenanceOverviewQuery } from './queries/maintenanceOverviewQuery'
import { MaintenanceGenius } from 'components/MaintenanceGenius'
import { Stack } from '_BRIGHT_'
import { CompanyGuard } from 'components/CompanyGuard'

export const MaintenancePage: FC = () => {
  useScrollToTop()
  const { companyId } = useCompanyId()

  return (
    <CompanyGuard>
      <PageHeadingBlock pageHeading="Maintenance" hasDivider={false} />
      <CenteredBlock>
        {companyId && (
          <Stack space="md">
            <Overview query={maintenanceOverviewQuery([companyId])} />
            <MaintenanceGenius />
          </Stack>
        )}
      </CenteredBlock>
    </CompanyGuard>
  )
}
